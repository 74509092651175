import React from 'react';
import { InfinityScrollProps } from './InfinityScroll';
import { Tooltip, Box, Typography, Chip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from 'react-i18next';
import { ListTableAction } from '../common/types';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TableProperty } from '../common/constants';
import i18next from 'i18next';
import moment from 'moment';
import clsx from 'clsx';
import {
  Table,
  TableProps,
  TableHead,
  TableBody,
  TableRow,
  TableRowLoader,
  TableCell,
  TableCellActions,
  InfinityScroll,
} from '.';
import { getTheme } from '../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    container: {
      width: '100%',
    },
    circle: {
      width: 14,
      height: 14,
      borderRadius: '50%',
    },
    circleGreen: {
      backgroundColor: theme.palette.success.main,
    },
    circleOrange: {
      backgroundColor: theme.palette.warning.main,
    },
    circleGrey: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    circleRed: {
      backgroundColor: theme.palette.error.main,
    },
  };
};

export type ListTableActions = (ListTableAction | undefined)[];

type ListTableProps = WithStyles<any> &
  RouteComponentProps & {
    InfinityScrollProps?: Omit<InfinityScrollProps, 'loading'>;
    TableProps?: Partial<TableProps>;
    loading?: boolean;
    emptyTitle?: string;
    idKey?: string;
    properties: TableProperty;
    rows: any[];
    rowActions?: ListTableActions;
    disableRowHover?: boolean;
    filterActions?: boolean;
    clickRow?: (row: any) => () => Promise<void>;
  };

class _ListTable extends React.Component<ListTableProps> {
  get hasCellActions(): boolean {
    return (
      !!this.props.rowActions && !!this.props.rowActions.length && !!this.props.rowActions!.find((a) => a !== undefined)
    );
  }

  renderReadableSizeFromBytes = (bytes: number): string => {
    if (bytes >= 1000000000) return `${(bytes / 1000000000).toFixed(1)} GB`;
    if (bytes >= 1000000) return `${(bytes / 1000000).toFixed(1)} MB`;
    if (bytes >= 1000) return `${(bytes / 1000).toFixed(1)} KB`;
    return `${bytes} bytes`;
  };

  renderCellValueCircle = (title: string, clsx: any): React.ReactNode => {
    return (
      <Tooltip placement="top" title={title}>
        <div className={clsx} role="status" />
      </Tooltip>
    );
  };

  renderCellValue = (row: any, key: string, property: { [key: string]: any }): React.ReactNode => {
    if (![null, undefined, '', []].includes(row[key])) {
      // Text array
      if (property.displayType === 'text' && Array.isArray(row[key])) {
        return (
          <div style={{ margin: '-5px 0' }}>
            {row[key].map((value: string, i: number) => (
              <Chip key={i} size="small" label={value} style={{ margin: '1px 2px 1px 0' }} />
            ))}
          </div>
        );
      }
      // Release enable boolean
      if (property.displayType === 'boolean' && {}.hasOwnProperty.call(row, 'releaseId') && key === 'enabled') {
        return this.renderCellValueCircle(
          !row[key]
            ? i18next.t('description.releaseIsDisabled')
            : row.groupId
            ? i18next
                .t('description.enabledForGroupsWithRollout')
                .replace('%groupsRule%', (row.groupsRule || 'N/A').replace('_', ' ').toLowerCase())
                .replace('%groups%', row.groupId.join(', '))
                .replace('%rollout%', row.rollout)
            : i18next.t('description.enabledWithRollout').replace('%rollout%', row.rollout),
          clsx(this.props.classes.circle, {
            [this.props.classes.circleGreen]: !!row[key] && !row.groupId && row.rollout === 100,
            [this.props.classes.circleOrange]: !!row[key] && (!!row.groupId || row.rollout !== 100),
            [this.props.classes.circleGrey]: !row[key],
          }),
        );
      }
      // User enable boolean
      if (property.displayType === 'boolean' && {}.hasOwnProperty.call(row, 'userId') && key === 'enabled') {
        return this.renderCellValueCircle(
          !row[key]
            ? i18next.t('description.userIsDisabled')
            : row.status === 'CONFIRMED'
            ? i18next.t('description.userIsEnabled')
            : i18next.t('description.userIsEnabledWithStatus').replace('%status%', row.status),
          clsx(this.props.classes.circle, {
            [this.props.classes.circleGreen]: !!row[key] && row.status === 'CONFIRMED',
            [this.props.classes.circleOrange]: !!row[key] && row.status !== 'CONFIRMED',
            [this.props.classes.circleGrey]: !row[key],
          }),
        );
      }
      // Api enable boolean
      if (property.displayType === 'boolean' && {}.hasOwnProperty.call(row, 'apiId') && key === 'enabled') {
        return this.renderCellValueCircle(
          !row[key] ? i18next.t('description.apiIsDisabled') : i18next.t('description.apiIsEnabled'),
          clsx(this.props.classes.circle, {
            [this.props.classes.circleGreen]: !!row[key],
            [this.props.classes.circleGrey]: !row[key],
          }),
        );
      }
      // Subscription status string
      if (property.displayType === 'text' && {}.hasOwnProperty.call(row, 'subscriptionId') && key === 'status') {
        return this.renderCellValueCircle(
          `${i18next.t('description.subscriptionStatusIs')} ${row.status.toLowerCase()}`,
          clsx(this.props.classes.circle, {
            [this.props.classes.circleGreen]: row.status === 'OK',
            [this.props.classes.circleOrange]: !row.status || row.status === 'UNKNOWN',
            [this.props.classes.circleGrey]: row.status === 'DISABLED',
            [this.props.classes.circleRed]: row.status === 'FAILING',
          }),
        );
      }
      // Package state text
      if (property.displayType === 'text' && {}.hasOwnProperty.call(row, 'packageId') && key === 'state') {
        return this.renderCellValueCircle(
          row.state === 'READY'
            ? i18next.t('description.packageIsReadyToUse')
            : row.state === 'AWAITING_CONFIRM'
            ? i18next.t('description.awaitingPackageConfirmation')
            : row.state === 'AWAITING_UPLOAD'
            ? i18next.t('description.awaitingPackageUpload')
            : i18next.t('description.failedToParseUpload'),
          clsx(this.props.classes.circle, {
            [this.props.classes.circleGreen]: !!row[key] && row.state === 'READY',
            [this.props.classes.circleOrange]: !!row[key] && row.state === 'AWAITING_CONFIRM',
            [this.props.classes.circleGrey]: !row[key] && row.state === 'AWAITING_UPLOAD',
            [this.props.classes.circleRed]: !row[key] && row.state === 'FAILED',
          }),
        );
      }
      // Device state text
      if (property.displayType === 'text' && {}.hasOwnProperty.call(row, 'deviceId') && key === 'state') {
        return this.renderCellValueCircle(
          row.state === 'ACTIVATED'
            ? i18next.t('description.deviceIsActivated')
            : row.state === 'CREATED'
            ? i18next.t('description.deviceHasBeenCreated')
            : row.state === 'DEACTIVATED'
            ? i18next.t('description.deviceIsDeactivated')
            : i18next.t('description.deviceHasBeenRevoked'),
          clsx(this.props.classes.circle, {
            [this.props.classes.circleGreen]: row.state === 'ACTIVATED',
            [this.props.classes.circleOrange]: row.state === 'CREATED',
            [this.props.classes.circleGrey]: row.state === 'DEACTIVATED',
            [this.props.classes.circleRed]: row.state === 'REVOKED',
          }),
        );
      }
      // Device connection status text
      if (
        property.displayType === 'text' &&
        ({}.hasOwnProperty.call(row, 'connected') || {}.hasOwnProperty.call(row, 'disconnected')) &&
        key === 'status'
      ) {
        return this.renderCellValueCircle(
          row.status === 'CONNECTED'
            ? i18next.t('description.deviceIsConnected')
            : i18next.t('description.deviceWasDisconnected'),
          clsx(this.props.classes.circle, {
            [this.props.classes.circleGreen]: row.status === 'CONNECTED',
            [this.props.classes.circleGrey]: row.status === 'DISCONNECTED',
          }),
        );
      }
      // Device connection status text
      if (property.displayType === 'boolean' && {}.hasOwnProperty.call(row, 'templateId') && key === 'default') {
        return this.renderCellValueCircle(
          row.default ? i18next.t('description.isDefaultTemplate') : i18next.t('description.isNotDefaultTemplate'),
          clsx(this.props.classes.circle, {
            [this.props.classes.circleGreen]: row.default,
            [this.props.classes.circleGrey]: !row.default,
          }),
        );
      }
      // Package size text
      if (property.displayType === 'text' && {}.hasOwnProperty.call(row, 'packageId') && key === 'size') {
        return (
          <Tooltip placement="top" title={`${row.size} bytes`}>
            <span>{this.renderReadableSizeFromBytes(parseInt(row.size))}</span>
          </Tooltip>
        );
      }
      // Key locked boolean
      if (property.displayType === 'boolean' && {}.hasOwnProperty.call(row, 'keyId') && key === 'locked') {
        return this.renderCellValueCircle(
          i18next.t(row[key] ? 'form.locked' : 'form.unlocked'),
          clsx(this.props.classes.circle, {
            [this.props.classes.circleGreen]: !!row[key],
            [this.props.classes.circleGrey]: !row[key],
          }),
        );
      }
      // Text or boolean
      if (property.displayType === 'text' || property.displayType === 'boolean') {
        return `${row[key]}`;
      }
      // Date
      if (property.displayType === 'date') {
        return (
          <Tooltip placement="top" title={row[key]}>
            <span>{moment(row[key]).format('YYYY-MM-DD')}</span>
          </Tooltip>
        );
      }
      // Date time
      if (property.displayType === 'dateTime') {
        return (
          <Tooltip placement="top" title={row[key]}>
            <span>{moment(row[key]).format('YYYY-MM-DD HH:mm:ss')}</span>
          </Tooltip>
        );
      }
      // Date from now
      if (property.displayType === 'dateFromNow') {
        return (
          <Tooltip placement="top" title={row[key]}>
            <span>{moment(row[key]).fromNow()}</span>
          </Tooltip>
        );
      }
    }
    // Empty value
    return '';
  };

  renderCell = (row: any, key: string, property: { [key: string]: any }, i: number): React.ReactNode => {
    return (
      <TableCell
        key={i}
        align={
          property.align
            ? property.align
            : Object.keys(this.props.properties).length !== 1 &&
              i === Object.keys(this.props.properties).length - 1 &&
              !this.hasCellActions
            ? 'right'
            : 'left'
        }
        padding={
          key === 'enabled' && ({}.hasOwnProperty.call(row, 'releaseId') || {}.hasOwnProperty.call(row, 'userId'))
            ? 'checkbox'
            : 'normal'
        }
        onClick={
          !!property.path && !!row[key]
            ? (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
                event.stopPropagation();
                this.props.history.push((property.path as (id: string) => string)(row[property.pathProperty || key])!);
              }
            : undefined
        }
        clickable={!!property.path && !!row[key]}
      >
        {this.renderCellValue(row, key, property)}
      </TableCell>
    );
  };

  renderCellActions = (row: any, i: number): React.ReactNode => {
    if (!this.hasCellActions) return null;
    const { idKey } = this.props;
    return (
      <TableCellActions
        key={`table-action-cell-${idKey ? row[idKey] : i}`}
        item={row}
        filterActions={this.props.filterActions}
        actions={this.props.rowActions!.filter((a) => a !== undefined) as ListTableAction[]}
      />
    );
  };

  renderRow = (row: any, i: number): React.ReactNode => {
    const { idKey } = this.props;
    return (
      <TableRow
        key={`row-${idKey ? row[idKey] : i}`}
        onClick={this.props.clickRow ? this.props.clickRow(row) : undefined}
        disableRowHover={this.props.disableRowHover}
      >
        {Object.entries(this.props.properties).map(([key, property], i) => this.renderCell(row, key, property, i))}
        {this.renderCellActions(row, i)}
      </TableRow>
    );
  };

  renderTable = (): React.ReactNode => (
    <>
      <Table size="small" {...this.props.TableProps}>
        <TableHead>
          <TableRow>
            {Object.values(this.props.properties).map((property, i) => (
              <TableCell
                key={i}
                align={
                  property.align
                    ? property.align
                    : Object.keys(this.props.properties).length !== 1 &&
                      i === Object.keys(this.props.properties).length - 1 &&
                      !this.hasCellActions
                    ? 'right'
                    : 'left'
                }
              >
                {i18next.t(property.displayName)}
              </TableCell>
            ))}
            {!!this.props.rowActions &&
            this.props.rowActions.length &&
            !!this.props.rowActions!.find((a) => a !== undefined) ? (
              <TableCell key="action-cell-header" align={'right'}>
                {i18next.t('action.actions')}
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>{this.props.rows.map((row, i) => this.renderRow(row, i))}</TableBody>
      </Table>
      {!this.props.loading && !this.props.rows.length ? (
        <Box display="flex" justifyContent="center" width="100%" p={2}>
          <Typography variant="caption">
            <Trans>{this.props.emptyTitle || 'form.empty.items'}</Trans>
          </Typography>
        </Box>
      ) : null}
      {this.props.loading ? <TableRowLoader /> : null}
    </>
  );

  render = (): React.ReactNode => {
    if (this.props.InfinityScrollProps)
      return (
        <InfinityScroll {...this.props.InfinityScrollProps} loading={this.props.loading!}>
          <div className={this.props.classes.container}>{this.renderTable()}</div>
        </InfinityScroll>
      );
    return this.renderTable();
  };
}

export const ListTable = withStyles(styles)(withRouter(_ListTable));
