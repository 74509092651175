import AWS from 'aws-sdk';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { AwsRequestConfig, IrisUser, Role, ServiceType, ServiceTypes } from '../../common/types';
import { SnackbarUtils } from '../../components/StyledSnackbarProvider';
import { _Authentication, SessionOrChallange } from './authenticationUtils';
import { CognitoServiceProvider } from './CognitoServiceProvider';
import axios, { AxiosResponse } from 'axios';
import sigV4Client from '../common/sigV4Client';
import { PromiseResult } from 'aws-sdk/lib/request';

export class AuthenticationServiceProvider {
  service: _Authentication;

  constructor(public serviceType: ServiceType) {
    switch (serviceType) {
      case ServiceTypes.COGNITO:
        this.service = new CognitoServiceProvider();
        break;
      default:
        throw new Error('serviceType not provided');
    }
  }

  static instance?: AuthenticationServiceProvider;

  static async createFromCache(): Promise<AuthenticationServiceProvider | undefined> {
    if (AuthenticationServiceProvider.instance) return AuthenticationServiceProvider.instance;

    let authenticationServiceProvider;
    const cognitoServiceProvider = await CognitoServiceProvider.createFromCache();
    if (cognitoServiceProvider) {
      authenticationServiceProvider = new AuthenticationServiceProvider(ServiceTypes.COGNITO);
      authenticationServiceProvider.service = cognitoServiceProvider;
      return authenticationServiceProvider;
    }

    return undefined;
  }

  static checkCache(): boolean {
    return CognitoServiceProvider.checkCache();
  }

  static clear(): void {
    CognitoServiceProvider.clear();
  }

  get iris(): IrisUser | undefined {
    return this.service.iris;
  }

  /**
   * Get role
   */
  getRole = (): Role => {
    if (this.service.iris && this.service.iris.RoleName)
      return this.service.iris.RoleName!.split('-').slice(-1)[0] as Role;
    throw new Error('Missing role');
  };

  /**
   * Authenticate user with given credentials
   */
  authenticate = (username: string, password: string): Promise<SessionOrChallange> => {
    const sessionOrChallange = this.service.authenticate(username, password);
    AuthenticationServiceProvider.instance = this;
    return sessionOrChallange;
  };

  /**
   * Signing in for the first time will trigger a request for new password,
   * this is the method to be called with new password after authenticate
   */
  newPasswordChallange = async (password: string): Promise<AmazonCognitoIdentity.CognitoUserSession> =>
    this.service.newPasswordChallange(password);

  /**
   * Sends SMS or email to user with a verification code later used in verifyForgotPassword
   */
  forgotPassword = async (email: string): Promise<void> => this.service.forgotPassword(email);

  /**
   * Uses verification code sent from forgot password to change password
   */
  verifyForgotPassword = async (password: string, verificationCode: string): Promise<void> =>
    this.service.verifyForgotPassword(password, verificationCode);

  /**
   * Upload file to S3
   */
  uploadFile = (params: AWS.S3.PutObjectRequest): AWS.S3.ManagedUpload => {
    return new AWS.S3({ apiVersion: '2006-03-01', ...this.service.credentials }).upload(params);
  };

  /**
   * Download file from S3
   */
  downloadFile = async (
    config: AWS.S3.ClientConfiguration,
  ): Promise<PromiseResult<AWS.S3.GetObjectOutput, AWS.AWSError>> => {
    if (!this.service.userSession?.isValid()) await this.service.refresh();
    const s3 = new AWS.S3({ apiVersion: '2006-03-01', ...config });
    return await s3.getObject().promise();
  };

  /**
   * Download file and convert to string
   */
  downloadFileAsString = async (config: AWS.S3.ClientConfiguration): Promise<string> => {
    const { Body, ContentType } = await this.downloadFile(config);
    const data: string | undefined = await new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e: any) => resolve(e.target.result);
      fileReader.readAsText(new Blob([Body as Uint8Array], { type: ContentType }));
    });
    return data as string;
  };

  /**
   * Download file and convert to string
   */
  s3ListObjects = async (config: AWS.S3.ClientConfiguration): Promise<any> => {
    if (!this.service.userSession?.isValid()) await this.service.refresh();
    const s3 = new AWS.S3({ apiVersion: '2006-03-01', ...config });
    return new Promise((resolve, reject) => s3.listObjects((err, data) => (err ? reject(err) : resolve(data))));
  };

  /**
   * Signs request and then invokes it, also retries calls once with new auth if failed
   */
  call = async <T>(config: AwsRequestConfig, returnError = false, retryCount = 0): Promise<AxiosResponse<T>> => {
    if (!this.service.credentials) throw new Error('Missing AWS credentials');
    if (!this.service.iris) throw new Error('Missing iris configuration');

    const { baseURL, path, method, headers: configHeaders, params, data } = config;
    const signerConfig = {
      accessKey: this.service.credentials.accessKeyId,
      secretKey: this.service.credentials.secretAccessKey,
      sessionToken: this.service.credentials.sessionToken,
      region: this.service.iris.region,
      endpoint: baseURL ? baseURL : `https://${this.service.iris.apiV2}.${this.service.iris.domain}`,
      apikey: this.service.iris.apiKey,
    };

    const { url, headers } = sigV4Client
      .newClient(signerConfig)
      .signRequest({ method, path, headers: configHeaders, queryParams: params, body: data });

    try {
      return await axios.request<T>({ method, headers, data, url });
    } catch (eCall) {
      const e: any = eCall;
      if (returnError || retryCount === 4) {
        if (e.code === 'NetworkError' || e.message.search('Network Error') > -1) SnackbarUtils.error('Network error');
        throw eCall;
      }

      const statusCode = e.statusCode || (e.response && e.response.status) || undefined;
      const code = e.code || (e.response && e.response.code) || undefined;
      if (![403, 429].includes(statusCode) && !['NetworkError', 'NotAuthorizedException'].includes(code)) throw eCall;

      if (statusCode === 403 || code === 'NotAuthorizedException') {
        try {
          await this.service.refresh();
        } catch (eRefresh) {
          const error: any = eRefresh;
          const message =
            error.message ||
            (error.response && error.response.message) ||
            (error.response && error.response.data && error.response.data.message);
          if (message) SnackbarUtils.error(message === 'Refresh Token has expired' ? 'Session has expired' : message);
          throw eCall;
        }
      } else if (statusCode === 429 || e.code === 'NetworkError') {
        return new Promise((resolve) => setTimeout(() => resolve(this.call(config, false, retryCount++)), 1000));
      }
      return this.call(config, true);
    }
  };

  axios = axios;
  Get = <T>(config: AwsRequestConfig): Promise<AxiosResponse<T>> => this.call<T>({ ...config, method: 'get' });
  Post = <T>(config: AwsRequestConfig): Promise<AxiosResponse<T>> => this.call<T>({ ...config, method: 'post' });
  Patch = <T>(config: AwsRequestConfig): Promise<AxiosResponse<T>> => this.call<T>({ ...config, method: 'patch' });
  Put = <T>(config: AwsRequestConfig): Promise<AxiosResponse<T>> => this.call<T>({ ...config, method: 'put' });
  Delete = <T>(config: AwsRequestConfig): Promise<AxiosResponse<T>> => this.call<T>({ ...config, method: 'delete' });
}
