import React from 'react';
import RefParser from 'json-schema-ref-parser';
import { Typography, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SchemaFormInput } from '.';

const styles = () => ({});

export type SchemaFormShadowProps = WithStyles<any> & {
  depth?: number;
  data: Omit<RefParser.JSONSchema, 'type'> & {
    key: string;
    type: 'string' | 'number' | 'boolean' | 'integer' | 'object' | 'array';
    readOnly?: boolean;
    variant?: 'select' | 'json';
  };
  disabled?: boolean;
  required?: boolean;
  hideUnset?: boolean;
  objectKey?: string;
  defaultValue?: { [key: string]: any };
  onChange?: (path: string, value: any, isValid: boolean, callback?: () => void) => void;
};

type SchemaFormShadowState = {
  inputValid: { [key: string]: boolean };
};

class _SchemaFormShadow extends React.Component<SchemaFormShadowProps, SchemaFormShadowState> {
  state: SchemaFormShadowState = {
    inputValid: {},
  };

  get depth(): number {
    if (typeof this.props.depth === 'number') return this.props.depth;
    return 1;
  }

  onChange = (path: string, value: any, isValid: boolean, callback?: () => void): void => {
    const inputValid = JSON.parse(JSON.stringify(this.state.inputValid));
    inputValid[path] = isValid;
    this.setState({ inputValid });
    if (this.props.onChange) this.props.onChange(path, value, !Object.values(inputValid).includes(false), callback);
  };

  renderObjectGrid = (children: React.ReactNode, lowDepth = 3, maxDepth = 4): React.ReactNode => (
    <Grid
      key={this.props.data.key}
      item
      xs={12}
      sm={this.props.data.type === 'object' && this.depth === 4 ? 6 : 12}
      md={this.props.data.type === 'object' && this.depth === 4 ? 6 : 12}
      lg={this.props.data.type === 'object' && this.depth === 4 ? 6 : 12}
      xl={this.props.data.type === 'object' && this.depth === 4 ? 4 : 12}
    >
      <Grid container key={this.props.data.key} spacing={4}>
        {this.depth >= lowDepth && this.depth <= maxDepth ? (
          <Grid item xs={12}>
            <Typography variant={`h${this.depth + 2}` as any}>{this.props.data.key}</Typography>
          </Grid>
        ) : null}
        {children}
      </Grid>
    </Grid>
  );

  renderObject = (): React.ReactNode =>
    this.renderObjectGrid(
      this.props.data.properties
        ? Object.entries(this.props.data.properties).map(([key, value]) => (
            <SchemaFormShadow
              key={key}
              depth={this.depth + 1}
              data={{ key, ...value }}
              disabled={this.props.disabled}
              hideUnset={this.props.hideUnset}
              required={
                this.props.data.required &&
                Array.isArray(this.props.data.required) &&
                this.props.data.required.includes(key)
              }
              objectKey={this.props.objectKey ? `${this.props.objectKey}.${key}` : key}
              defaultValue={this.props.defaultValue}
              onChange={this.onChange}
            />
          ))
        : null,
    );

  renderJsonTopLevelObject = (): React.ReactNode => {
    const otherProps: Record<string, any> = { hideLabel: true };
    if (this.depth === 2) otherProps['jsonInputHeight'] = '500px';
    return this.renderObjectGrid(this.renderInput(otherProps), 1);
  };

  renderInput = (otherProps: { [key: string]: any } = {}): React.ReactNode => {
    return (
      <SchemaFormInput
        state="UPDATE"
        depthToShowObjectTitleInLabel={3}
        {...(this.props as any)}
        {...(otherProps as any)}
      />
    );
  };

  renderContent = (): React.ReactNode => {
    if (this.props.data.type === 'object' && !this.props.data.variant) return this.renderObject();
    if (this.props.data.type === 'object' && this.props.data.variant && this.depth < 4)
      return this.renderJsonTopLevelObject();
    return this.renderInput();
  };

  render = (): React.ReactNode => {
    if (!this.props.data.type) return null;
    return this.renderContent();
  };
}

export const SchemaFormShadow = withStyles(styles)(_SchemaFormShadow);
