import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import { UserService } from '../../../services';
import { SubdomainRoutePath, ModalVariants, UserGroup } from '../../../common/types';
import { storeOpenModal } from '../../../store/actions/modal';
import { tableProperties, itemIcons, TableNames } from '../../../common/constants';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';
import { SnackbarUtils } from '../../../components';

type _Props = {
  openModal: (callback: () => any) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class UserGroupView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
  };

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.accessControlSection = 'userGroups';
    this.title = i18next.t('userGroups');
    this.icon = itemIcons.userGroup;
    this.idKey = 'groupName';
    this.tableConfig = {
      tableProperties: tableProperties(TableNames.userGroup),
      emptyTitle: 'form.empty.userGroups',
      rowActions: [
        {
          title: 'action.delete',
          action: (userGroup: UserGroup) => this.setState({ dialogOpen: userGroup.groupName }),
        },
      ],
    };
    this.dialogConfig = {
      title: i18next.t('action.delete'),
      description: i18next.t('description.delete.userGroup'),
      continueTitle: i18next.t('action.delete'),
      onClose: () => this.setState({ dialogOpen: undefined }),
      onContinue: () => this.state.dialogOpen && this.deleteUserGroup(this.state.dialogOpen),
    };
    this.fabConfig = [
      {
        title: i18next.t('action.createUserGroup'),
        action: () => this.props.openModal(this.reloadList),
        icon: itemIcons.userGroup,
        tryAccessRight: ['userGroup', 'create'],
      },
    ];

    // Functions
    this.getUrlPathForItem = SubdomainRoutePath.userGroup;
  }

  componentDidMount = async () => {
    this.service = await UserService.create();
    this.list = this.service!.userGroup.list;
    this.setState({ initLoaded: true });
  };

  deleteUserGroup = async (userGroup: string): Promise<void> => {
    try {
      await this.service.userGroup.del(userGroup);
      SnackbarUtils.success(i18next.t('success.delete.userGroup'));
      if (!this.isUnmounted) {
        const items = this.state.items.filter((u: UserGroup) => u.groupName !== userGroup);
        this.setState({ items });
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any) => dispatch(storeOpenModal(ModalVariants.CreateUserGroupMC, { callback })),
});

export default withStyles(ListBaseStyles)(withRouter(connect(null, mapDispatchToProps)(UserGroupView)));
