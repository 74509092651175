import React, { Component } from 'react';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  OutlinedInput,
  Grid,
  Box,
  Button,
  Tooltip,
  Menu,
  CircularProgress,
  Checkbox,
  ListItemText,
  Chip,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from 'react-i18next';
import { Close, ExpandLess, ExpandMore, AddCircleOutline, InfoOutlined } from '@mui/icons-material';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import i18next from 'i18next';
import { Table, TableHead, TableBody, TableRow, TableCell } from '.';
import { SnackbarUtils } from './StyledSnackbarProvider';
import * as filterUtils from '../common/filterUtils';
import {
  ListFilterProperty,
  ListFilterProps,
  ListFilterOperator,
  FilterCombination,
  BaseFilterCombination,
} from '../common/types';

const styles = () => ({
  tooltip: {
    backgroundColor: 'transparent',
    maxWidth: 'none',
  },
});

type Props = WithStyles<any> & {
  combinations: {
    predefinedFilters: FilterCombination[];
    selectedPredefinedFilter: number;
    createFilter: (fc: BaseFilterCombination) => Promise<void>;
    duplicateFilter: (fc?: BaseFilterCombination) => Promise<void>;
    updateFilter: (fc: FilterCombination) => Promise<void>;
    deleteFilter: (id: string) => Promise<void>;
    cancelTemporary: () => void;
  };
  countFound?: number;
  availableFilters: ListFilterProperty[];
  availableProperties: string[];
  setFilterCombination: (data: {
    properties: string;
    params?: { [key: string]: string };
    callback?: () => void;
  }) => void;
};

type State = {
  filters: ListFilterProps[];
  label: string;
  properties: string;
  editing: boolean;
  actionsOpen: boolean;
  filter?: FilterCombination;
  loading: boolean;
};

class _ListFilter extends Component<Props, State> {
  state: State = {
    filters: [],
    label: '',
    properties: '',
    editing: false,
    actionsOpen: false,
    filter: undefined,
    loading: false,
  };
  timeout?: any;
  actionButtonRef: React.RefObject<HTMLButtonElement>;

  constructor(props: Props) {
    super(props);
    this.actionButtonRef = React.createRef();
  }

  componentDidMount = (): void => {
    if (window.location.search) {
      this.setState({ ...filterUtils.createFilterAndMetaDataFromUrl(window.location.search) }, () =>
        this.props.setFilterCombination({
          properties: this.state.properties,
          params: filterUtils.createParamsFromFilter(this.state.filters),
        }),
      );
    }
  };

  componentDidUpdate = (prevProps: Props): void => {
    if (
      (!window.location.search &&
        !prevProps.combinations.predefinedFilters.length &&
        !!this.props.combinations.predefinedFilters.length) ||
      (!prevProps.combinations.predefinedFilters.length && !!this.props.combinations.predefinedFilters.length)
    ) {
      this.setPredefinedFilter();
    }
    if (prevProps.combinations.selectedPredefinedFilter !== this.props.combinations.selectedPredefinedFilter) {
      this.setPredefinedFilter(
        this.props.combinations.selectedPredefinedFilter,
        !!prevProps.combinations.predefinedFilters.length &&
          prevProps.combinations.predefinedFilters.length < this.props.combinations.predefinedFilters.length,
      );
    }
  };

  setPredefinedFilter = (index = 0, editing = false): void => {
    if (index > this.props.combinations.predefinedFilters.length || index < 0) return;
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}${this.props.combinations.predefinedFilters[index].value}`,
    );
    this.setState(
      {
        editing: false,
        filter: this.props.combinations.predefinedFilters[index],
        ...filterUtils.createFilterAndMetaDataFromUrl(window.location.search),
      },
      () =>
        this.props.setFilterCombination({
          properties: this.state.properties,
          params: filterUtils.createParamsFromFilter(this.state.filters),
          callback: editing ? () => this.setState({ editing: true }) : undefined,
        }),
    );
  };

  updateParams = (): void => {
    const { label, properties } = this.state.filter!;
    const search = filterUtils.createUrlFromFilter(this.state.filters, label, properties);
    const params = filterUtils.createParamsFromFilter(this.state.filters);
    this.props.setFilterCombination({ properties: this.state.filter!.properties, params });
    window.history.replaceState({}, '', `${window.location.pathname}${search}`);
  };

  timoutUpdateParams = (force = false): void => {
    if (this.timeout) clearTimeout(this.timeout);
    force ? this.updateParams() : (this.timeout = setTimeout(this.updateParams, 1000));
  };

  updateFilter = (key: string, i?: number) => (value: any): void => {
    const { filters } = this.state;
    if (typeof i === 'number') {
      (filters[i] as any)[key] = value;
      const chosenFilter = this.props.availableFilters.find((f) => f.property === this.state.filters[i].property);
      if (key === 'property' && chosenFilter) {
        (filters[i] as any).type = chosenFilter.type;
        (filters[i] as any).operator = chosenFilter.operators[0];
        (filters[i] as any).value1 =
          chosenFilter.type === 'date' ? 0 : chosenFilter.type === 'selector' ? chosenFilter.options![0] : '';
        (filters[i] as any).value2 = chosenFilter.type === 'date' ? 0 : '';
      } else if (key === 'operator' && chosenFilter && chosenFilter.type === 'date') {
        (filters[i] as any).value1 = value === 'between' ? 0 : moment().format('YYYY-MM-DD');
        (filters[i] as any).value2 = value === 'between' ? 0 : moment().format('YYYY-MM-DD');
      }
      return this.setState({ filters }, this.timoutUpdateParams);
    }

    const chosenFilter = this.props.availableFilters.find((f) => f.property === value);
    if (chosenFilter) {
      filters.push({
        type: chosenFilter.type,
        property: value,
        operator: chosenFilter.type === 'date' ? 'between' : chosenFilter.operators[0],
        value1: chosenFilter.type === 'date' ? 0 : chosenFilter.type === 'selector' ? chosenFilter.options![0] : '',
        value2: chosenFilter.type === 'date' ? 0 : '',
      } as any);
      return this.setState({ filters }, this.timoutUpdateParams);
    }
  };

  getAvailableFilters = (i?: number): ListFilterProperty[] => {
    const usedFilterProperties = this.state.filters.map((f) => f.property);
    return this.props.availableFilters.filter(
      (af) =>
        (i === undefined && !usedFilterProperties.includes(af.property)) ||
        (i !== undefined && af.property === this.state.filters[i!].property) ||
        !usedFilterProperties.includes(af.property),
    );
  };

  save = async () => {
    this.setState({ loading: true });
    try {
      if (this.state.filter!.temporary) {
        await this.props.combinations.createFilter({
          label: this.state.filter!.label,
          value: filterUtils.createUrlFromFilter(
            this.state.filters,
            this.state.filter!.label,
            this.state.filter!.properties,
          ),
          properties: this.state.filter!.properties,
        });
        this.setState({
          filter: this.props.combinations.predefinedFilters[this.props.combinations.selectedPredefinedFilter],
        });
      } else {
        await this.props.combinations.updateFilter({
          ...this.state.filter!,
          value: filterUtils.createUrlFromFilter(
            this.state.filters,
            this.state.filter!.label,
            this.state.filter!.properties,
          ),
        });
      }
      SnackbarUtils.success(i18next.t('success.update.filter'));
      this.setState({ loading: false, editing: false });
    } catch {
      SnackbarUtils.error(i18next.t('error.tryAgain'));
      this.setState({ loading: false });
    }
  };

  cancel = () => {
    if (!!this.state.filter && this.state.filter.temporary) {
      this.props.combinations.cancelTemporary();
    } else {
      let state: any = {
        filter: this.props.combinations.predefinedFilters[this.props.combinations.selectedPredefinedFilter],
        editing: false,
      };
      const query = this.props.combinations.predefinedFilters[this.props.combinations.selectedPredefinedFilter].value;
      state = { ...state, ...filterUtils.createFilterAndMetaDataFromUrl(query) };
      const params = filterUtils.createParamsFromFilter(state.filters);
      this.props.setFilterCombination({ properties: state.properties, params });
      window.history.replaceState({}, '', `${window.location.pathname}${query}`);
      this.setState(state);
    }
  };

  duplicate = async () => {
    this.setState({ loading: true });
    try {
      const filter = this.props.combinations.predefinedFilters[this.props.combinations.selectedPredefinedFilter!];
      await this.props.combinations.duplicateFilter({
        label: `${filter.label} copy`,
        value: filter.value,
        properties: filter.properties,
      });
      SnackbarUtils.success(i18next.t('success.create.filterDuplicate'));
    } catch {
      SnackbarUtils.error(i18next.t('error.tryAgain'));
    } finally {
      this.setState({ loading: false });
    }
  };

  delete = async () => {
    this.setState({ loading: true });
    try {
      const filter = this.props.combinations.predefinedFilters[this.props.combinations.selectedPredefinedFilter!];
      await this.props.combinations.deleteFilter(filter.id);
      if (this.props.combinations.predefinedFilters.length === 1) this.setPredefinedFilter(0);
      SnackbarUtils.success(i18next.t('success.delete.filter'));
    } catch {
      SnackbarUtils.error(i18next.t('error.tryAgain'));
    } finally {
      this.setState({ loading: false });
    }
  };

  renderFilterRowValueInput = (key: string, i?: number): React.ReactNode => {
    const filter =
      (i !== undefined && this.props.availableFilters.find((af) => af.property === this.state.filters[i].property)) ||
      undefined;
    if ((filter && filter.type === 'string') || typeof i !== 'number')
      return (
        <TextField
          value={typeof i === 'number' ? (this.state.filters[i] as any)[key] : ''}
          disabled={typeof i !== 'number'}
          onChange={(e) => this.updateFilter(key, i)(e.target.value)}
          variant="outlined"
          margin="dense"
          fullWidth
        />
      );
    if (filter && filter.type === 'date')
      return (
        <DatePicker
          inputFormat="YYYY-MM-DD"
          value={
            this.state.filters[i!].operator === 'between'
              ? moment()
                  .add((this.state.filters[i!] as any)[key], 'days')
                  .toDate()
              : moment((this.state.filters[i!] as any)[key]).toDate()
          }
          onChange={(date: any) => {
            if (this.state.filters[i!].operator === 'between') {
              const diff = (!!date && moment(date)!.diff(moment(), 'days')) || 0;
              this.updateFilter(key, i)(diff);
            } else {
              this.updateFilter(key, i)((date || moment()).format('YYYY-MM-DD'));
            }
          }}
          renderInput={(params: any) => (
            <TextField
              variant="outlined"
              style={{ marginTop: 0, marginBottom: 0 }}
              id={`filter-date-${key}`}
              {...params}
            />
          )}
        />
      );
    if (filter && filter.type === 'selector')
      return (
        <FormControl fullWidth>
          <Select
            input={<OutlinedInput margin="dense" id="value-selector" />}
            value={(this.state.filters[i!] as any)[key]}
            onChange={(e) => this.updateFilter(key, i)(e.target.value)}
            disabled={typeof i !== 'number'}
          >
            {filter!.options!.map((option) => (
              <MenuItem value={option} key={option}>
                <Trans>{option}</Trans>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    return null;
  };

  renderFilterRow = (showSecondValue: boolean, i?: number): React.ReactNode => {
    const operators = (() => {
      if (typeof i !== 'number') return [];
      const property = this.props.availableFilters.find((f) => f.property === this.state.filters[i].property);
      if (property) return property.operators;
      return [];
    })();
    return (
      <TableRow key={i} disableRowHover>
        <TableCell noBorder disableForceHeight>
          <FormControl variant="outlined" fullWidth>
            <Select
              input={<OutlinedInput margin="dense" id="property-selector" />}
              value={typeof i === 'number' ? this.state.filters[i].property : ''}
              onChange={(e) => this.updateFilter('property', i)(e.target.value)}
            >
              {this.getAvailableFilters(i).map((af) => (
                <MenuItem value={af.property} key={af.property}>
                  <Trans>{af.displayName}</Trans>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell noBorder disableForceHeight>
          <FormControl variant="outlined" fullWidth>
            <Select
              input={<OutlinedInput margin="dense" id="operator-selector" />}
              value={typeof i === 'number' ? this.state.filters[i].operator : ''}
              onChange={(e) => this.updateFilter('operator', i)(e.target.value)}
              disabled={typeof i !== 'number'}
            >
              {operators.map((operator) => (
                <MenuItem value={operator} key={operator}>
                  <Trans>{`form.operators.${operator}`}</Trans>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell noBorder disableForceHeight>
          {this.renderFilterRowValueInput('value1', i)}
        </TableCell>
        {typeof i === 'number' &&
        !!this.state.filters[i].operator &&
        (['between', 'betweenAbsolute'] as ListFilterOperator[]).includes(this.state.filters[i].operator) ? (
          <TableCell noBorder disableForceHeight>
            {this.renderFilterRowValueInput('value2', i)}
          </TableCell>
        ) : showSecondValue ? (
          <TableCell noBorder disableForceHeight />
        ) : null}
        <TableCell align="center" padding="none" noBorder disableForceHeight>
          {i !== undefined ? (
            <IconButton
              onClick={() => {
                const { filters } = this.state;
                filters.splice(i, 1);
                this.setState({ filters }, this.updateParams);
              }}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
    );
  };

  renderFilterChips = (): React.ReactChild => {
    return (
      <Grid container spacing={2} alignItems="center">
        {this.state.filters.map((f) => (
          <Grid item key={f.property}>
            <Chip
              size="small"
              label={`${i18next.t(`form.${f.property}`)}: ${filterUtils.createChipValueFromFilter(f)}`.replace(
                'form.',
                '',
              )}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  renderCount = (editMode = false): React.ReactNode => {
    return this.props.countFound !== undefined ? (
      <Tooltip
        title={this.renderFilterChips()}
        aria-label="filters"
        placement="right"
        classes={{ tooltip: this.props.classes.tooltip }}
        disableFocusListener={editMode}
        disableHoverListener={editMode}
        disableTouchListener={editMode}
      >
        <Box mb={editMode ? 1 : 0} fontSize="14px" style={{ cursor: 'pointer' }}>
          Found: <b>{this.props.countFound}</b>
          {editMode ? null : <InfoOutlined style={{ width: 16, height: 16, marginBottom: -3, marginLeft: 10 }} />}
        </Box>
      </Tooltip>
    ) : null;
  };

  render = (): React.ReactNode => {
    const showSecondValue = this.state.filters
      .map((f) => f.type === 'date' && (['between', 'betweenAbsolute'] as ListFilterOperator[]).includes(f.operator))
      .includes(true);
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box mt={1} mb={1}>
            <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
              {this.state.editing ? (
                <>
                  <Grid item>
                    <Box ml={2}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            style={{ marginTop: 0, marginBottom: 0 }}
                            placeholder={i18next.t('form.name')}
                            value={(!!this.state.filter && this.state.filter.label) || ''}
                            onChange={(e) => {
                              if (!e.target.value.endsWith('*'))
                                this.setState({ filter: { ...this.state.filter!, label: e.target.value } });
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <FormControl>
                            <Select
                              multiple
                              input={<OutlinedInput margin="dense" />}
                              value={(this.state.filter && this.state.filter.properties.split(',')) || []}
                              onChange={(e: any) =>
                                this.setState(
                                  { filter: { ...this.state.filter!, properties: e.target.value.join(',') } },
                                  () => this.timoutUpdateParams(true),
                                )
                              }
                              renderValue={() => i18next.t('form.displayProperties')}
                            >
                              {this.props.availableProperties.map((property) => (
                                <MenuItem key={property} value={property}>
                                  <Checkbox
                                    checked={
                                      ((!!this.state.filter && this.state.filter.properties) || '')
                                        .split(',')
                                        .indexOf(property) > -1
                                    }
                                  />
                                  <ListItemText primary={property} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button size="small" color="secondary" onClick={this.cancel}>
                          <Trans>action.cancel</Trans>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={this.save}
                          disabled={!this.state.filters.length}
                        >
                          {this.state.loading ? <CircularProgress size={24} /> : <Trans>action.save</Trans>}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item style={{ height: 48, display: 'flex', alignItems: 'center' }}>
                    {this.renderCount()}
                  </Grid>
                  <Grid item>
                    {this.state.filter && this.state.filter.temporary ? (
                      <Button
                        size="small"
                        color="secondary"
                        variant="text"
                        onClick={this.save}
                        disabled={!this.state.filters.length}
                      >
                        {this.state.loading ? <CircularProgress size={24} /> : <Trans>action.save</Trans>}
                      </Button>
                    ) : (
                      <>
                        <Button
                          size="small"
                          color="secondary"
                          variant="text"
                          ref={this.actionButtonRef}
                          onClick={() => this.setState({ actionsOpen: true })}
                        >
                          <Trans>action.query</Trans>
                          {this.state.loading ? (
                            <CircularProgress size={24} />
                          ) : this.state.actionsOpen ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </Button>
                        <Menu
                          open={this.state.actionsOpen}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          anchorEl={this.actionButtonRef.current}
                          onClose={() => this.setState({ actionsOpen: false })}
                          onClick={() => this.setState({ actionsOpen: false })}
                        >
                          <MenuItem onClick={() => this.props.combinations.duplicateFilter()}>
                            <Trans>action.createNewFilter</Trans>
                          </MenuItem>
                          <MenuItem onClick={() => this.setState({ editing: true })}>
                            <Trans>action.editFilter</Trans>
                          </MenuItem>
                          <MenuItem onClick={this.duplicate}>
                            <Trans>action.duplicateFilter</Trans>
                          </MenuItem>
                          <MenuItem onClick={this.delete}>
                            <Trans>action.deleteFilter</Trans>
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Grid>
        {this.state.editing ? (
          <Grid item xs={12}>
            <Box mt={2} mb={2}>
              <Table size="small" disableBackgroundPaper>
                <TableHead>
                  <TableRow>
                    <TableCell noBorder disableForceHeight>
                      <Trans>form.property</Trans>
                    </TableCell>
                    <TableCell noBorder disableForceHeight>
                      <Trans>form.operator</Trans>
                    </TableCell>
                    <TableCell noBorder disableForceHeight>
                      <Trans>form.value</Trans>
                    </TableCell>
                    {showSecondValue ? (
                      <TableCell noBorder disableForceHeight>
                        <Trans>form.value</Trans>
                      </TableCell>
                    ) : null}
                    <TableCell align="center" padding="none" noBorder disableForceHeight />
                  </TableRow>
                </TableHead>
                <TableBody>{this.state.filters.map((_, i) => this.renderFilterRow(showSecondValue, i))}</TableBody>
              </Table>
              {this.state.filters.length < 3 && !!this.getAvailableFilters().length ? (
                <Button
                  size="small"
                  color="primary"
                  style={{ marginLeft: 16 }}
                  startIcon={<AddCircleOutline />}
                  onClick={() => this.updateFilter('property')(this.getAvailableFilters()[0].property)}
                >
                  <Trans>action.addNew</Trans>
                </Button>
              ) : null}
            </Box>
          </Grid>
        ) : null}
        {this.state.editing ? this.renderCount(true) : null}
      </Grid>
    );
  };
}

const ListFilter = withStyles(styles)(_ListFilter);
export { ListFilter };
