import React from 'react';
import { CircularProgress, Button, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import i18next from 'i18next';
import { SchemaForm, PageLoader, TabPanel, SnackbarUtils } from '../../../../components';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { DataService } from '../../../../services';
import schemaUtils from '../../../../common/schemaUtils';
import fleetApiGwSchema from '../../../../assets/schemas/fleet-api-gw.schema.parsed.json';
import { getTheme } from '../../../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    label: {
      marginLeft: -4,
      marginRight: -4,
      paddingLeft: 4,
      paddingRight: 4,
      backgroundColor: 'white',
    },
    response: {
      maxWidth: '100%',
      overflowX: 'auto' as const,
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[200],
    },
  };
};

type Props = RouteComponentProps<{ apiId: string }> & WithStyles<any>;

type State = {
  data: {
    deviceId?: string;
    groupId?: string;
    appendedTopic?: string;
    message: { [key: string]: string };
  };
  response?: { [key: string]: string };
};

class TestTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    data: {
      deviceId: '',
      groupId: '',
      appendedTopic: '',
      message: {},
    },
    response: undefined,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    DataService.create().then((service) => (this.service = service));
  }

  runTest = async (): Promise<void> => {
    this.setState({ loading: true });
    const params: any = {};
    if (this.state.data.deviceId) params.deviceId = this.state.data.deviceId;
    if (this.state.data.groupId) params.groupId = this.state.data.groupId;
    if (this.state.data.appendedTopic) params.appendedTopic = this.state.data.appendedTopic;
    try {
      const { data: response } = await this.service.deviceApiGw.test(
        this.props.match.params.apiId,
        params,
        this.state.data.message,
      );
      if (!this.isUnmounted) this.setState({ response, loading: false });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false, response: undefined });
    }
  };

  handleUpdate = (key: string | undefined, value: any) => {
    const data = schemaUtils.setValue(this.state.data || {}, key === undefined ? [] : key.split('.'), value);
    this.setState({ data: data || {} });
  };

  renderContent = (): React.ReactNode => (
    <Grid container spacing={2}>
      <SchemaForm
        state="CREATE"
        data={{
          ...(fleetApiGwSchema as any).properties.apiTest,
        }}
        disabled={false}
        defaultValue={this.state.data}
        onChange={this.handleUpdate}
      />
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={this.runTest} disabled={this.state.loading}>
          {this.state.loading ? <CircularProgress color="inherit" size={24} /> : i18next.t('action.runTest')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {this.state.response ? (
          <pre className={this.props.classes.response}>{JSON.stringify(this.state.response, undefined, 2)}</pre>
        ) : null}
      </Grid>
    </Grid>
  );

  render = (): React.ReactNode => {
    return (
      <TabPanel tab={this.props.tab} activeTab={this.props.activeTab}>
        {this.props.parentLoading && <PageLoader />}
        {!this.props.parentLoading && this.renderContent()}
      </TabPanel>
    );
  };
}

export default withStyles(styles)(withRouter(TestTab));
