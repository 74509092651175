import React, { useState, useEffect } from 'react';
import { TabPanel } from '../../../../components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { MqttWssService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';
import i18next from 'i18next';
import { jsons } from '../../../../services/MqttService/helpers';
import moment from 'moment';
import { IconButton, Grid, Button, CardContent, Card, CardHeader, Typography, Collapse, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ExpandMoreOutlined } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  cardRoot: {
    backgroundColor: '#f6f6f6',
  },
  cardContentRoot: {
    padding: 16,
    backgroundColor: '#fff',
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  cardContentText: {
    overflowX: 'auto',
  },
  cardHeaderRoot: {
    padding: '0 !important',
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  subheader: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: 12,
    fontSize: '0.775rem !important',
  },
}));

type MqttData = {
  message: string;
  binary?: string;
  backup?: string;
  topic: string;
  ts: string;
  length: number;
};

type DataCardProps = {
  data: MqttData;
  open: boolean;
  toggleOpen: () => void;
};

const DataCard: React.FunctionComponent<DataCardProps> = ({ open, toggleOpen, data }: DataCardProps) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  const getMessage = (data: MqttData): string => {
    const { message, binary, backup, topic } = data;
    if (binary) return binary;
    try {
      const obj = JSON.parse(message);
      return JSON.stringify(obj, undefined, 2);
    } catch (e) {
      const error: any = e;
      if (jsons.some((jsonEnd) => topic.endsWith(jsonEnd))) {
        console.error(error.message);
        return i18next.t('error.parse');
      }
      return backup || '';
    }
  };

  useEffect(() => {
    setMessage(getMessage(data));
  }, [data, data.topic]);

  return (
    <Card className={classes.cardRoot}>
      <CardHeader
        className={classes.cardHeaderRoot}
        classes={{ content: classes.headerContent, title: classes.title, subheader: classes.subheader }}
        avatar={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: open,
            })}
            onClick={toggleOpen}
            size="small"
            aria-expanded={open}
            aria-label="show more"
          >
            <ExpandMoreOutlined />
          </IconButton>
        }
        titleTypographyProps={{ align: 'center' }}
        title={data.topic}
        subheaderTypographyProps={{ align: 'right' }}
        subheader={
          <div>
            <span style={{ fontWeight: 500 }}>{moment(data.ts as string).format('YYYY-MM-DD HH:mm:ss')}</span>
            <br />
            <span style={{ fontWeight: 500 }}>{data.length + i18next.t('description.bytes')}</span>
          </div>
        }
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardContentRoot}>
          <Typography component="pre" variant="body2" color="textSecondary" className={classes.cardContentText}>
            {message}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

type _Props = RouteComponentProps<{ deviceId: string; customerId: string }>;
type Props = TabViewBaseProps<_Props>;
type _State = {
  listening: boolean;
  startingOrStopping: boolean;
  events: MqttData[];
  topic: string;
  open: { [key: string]: boolean };
  hasConnection: boolean;
};
type State = TabViewBaseState<_State>;

class ListenTab extends TabViewBase<Props, State> {
  // startingOrStopping: start or stop in progress, editing not used, events: mqtt events, listening: listening after events,
  // topic: what kind of events, open: which cards are open, hasConnection: has connection with device
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    startingOrStopping: false,
    listening: false,
    events: [],
    topic: '',
    open: {},
    hasConnection: false,
  };
  mqttService: MqttWssService | undefined | null;

  destroyMqtt = async (): Promise<void> => {
    if (this.mqttService) {
      try {
        const stop = await this.mqttService.stop();
        console.log('ListenTab destroyMqttService', stop);
        return Promise.resolve();
      } catch (e) {
        console.error('ListenTab cannot destroyMqttService', e);
      } finally {
        console.log('mqttService null');
        this.mqttService = null;
      }
    }
  };

  createsMqtt = async (): Promise<void> => {
    if (this.mqttService) {
      console.log('ListenTab destroys previous mqttService');
      await this.destroyMqtt();
    }
    this.mqttService = await MqttWssService.create();
    const customerId = this.mqttService.customerId;
    this.setState({ topic: `${customerId}/+/+/${this.props.match.params.deviceId}/#` });
    return Promise.resolve();
  };

  componentDidUpdate = async (prevProps: TabViewBaseProps): Promise<void> => {
    const { tab, activeTab } = this.props;
    // Creates mqtt service when entering traffic tab
    if (tab === activeTab) {
      if (tab !== prevProps.activeTab) {
        if (!this.mqttService) {
          await this.createsMqtt();
        }
      }
    }
  };

  componentWillUnmount = async (): Promise<void> => {
    await this.destroyMqtt();
  };

  start = async (): Promise<void> => {
    this.setState({ startingOrStopping: true });
    try {
      if (!this.mqttService) {
        await this.createsMqtt();
      }
      await this.mqttService!.start(this.state.topic, this.mqttEventHandler);
      this.setState({ startingOrStopping: false, listening: true });
    } catch (e) {
      console.error('ListenTab cannot start device traffic', e);
      SnackbarUtils.error(i18next.t('error.mqtt.start'));
      await this.destroyMqtt();
      this.setState({ startingOrStopping: false, listening: false });
    }
  };

  stop = async (): Promise<void> => {
    this.setState({ startingOrStopping: true });
    try {
      await this.destroyMqtt();
      this.setState({ startingOrStopping: false, listening: false });
    } catch (e) {
      console.error('Error stop device traffic', e);
      SnackbarUtils.error(i18next.t('error.mqtt.stop'));
      await this.destroyMqtt();
      this.setState({ startingOrStopping: false, listening: false });
    }
  };

  mqttEventHandler = async (event: MqttData | null) => {
    // Close connection on error or credentials refresh
    if (!event) {
      this.setState({ startingOrStopping: false, listening: false });
      console.log('ListenTab mqttService closed due to for example network error or inactivity');
      await this.destroyMqtt();
      SnackbarUtils.warning(i18next.t('error.mqtt.closed'));
      return;
    }
    const events = [event, ...this.state.events];
    const MAX_EVENTS = 100;
    if (events.length > MAX_EVENTS) {
      const last = events.pop();
      if (last) this.setState({ events });
    }
    this.setState({ events, open: { ...this.state.open, [event.ts]: true }, startingOrStopping: false });
  };

  clear = () => {
    this.setState({ events: [], open: {} });
  };

  toggleOpenEvent(data: MqttData) {
    this.setState({ open: { ...this.state.open, [data.ts]: !this.state.open[data.ts] } });
  }

  renderContent = (): React.ReactNode => {
    const { listening, startingOrStopping, events, open } = this.state;
    if ((listening || startingOrStopping) && events.length < 1) {
      return (
        <Box display="flex" width="100%" height="67vh" justifyContent="center">
          <Typography variant="caption">{i18next.t('form.empty.noEvents')}</Typography>
        </Box>
      );
    } else if (events.length > 0) {
      return (
        <Grid
          container
          spacing={2}
          style={{ width: 'calc(100%+16px)', maxHeight: '68vh', maxWidth: 'calc(100%+16px)', overflowY: 'auto' }}
        >
          {events.map((data) => (
            <Grid item key={data.ts} xs={12}>
              <DataCard key={data.ts} data={data} open={open[data.ts]} toggleOpen={() => this.toggleOpenEvent(data)} />
            </Grid>
          ))}
        </Grid>
      );
    } else {
      return (
        <Box display="flex" width="100%" height="67vh" justifyContent="center">
          <Typography variant="caption">{i18next.t('form.empty.events')}</Typography>
        </Box>
      );
    }
  };

  renderCustomAction = (): React.ReactNode => (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          color="secondary"
          size="small"
          variant="text"
          disabled={this.state.startingOrStopping}
          onClick={this.state.listening ? this.stop : this.start}
        >
          {i18next.t(this.state.listening ? 'action.stop' : 'action.listen')}
        </Button>
      </Grid>
    </Grid>
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        customAction: this.renderCustomAction(),
        actionProps: {
          actionTitle: 'action.clear',
          onAction: this.clear,
          disabled: this.state.events.length < 1,
        },
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

export default withRouter(ListenTab);
