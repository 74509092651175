import { combineReducers } from 'redux';
import common from './common';
import device from './device';
import modal from './modal';
import user from './user';
import CommonInitialState from '../init/common';
import DeviceInitialState from '../init/device';
import ModalInitialState from '../init/modal';
import UserInitialState from '../init/user';

export default combineReducers({
  commonStore: common,
  deviceStore: device,
  modalStore: modal,
  userStore: user,
});

export const mochStore = {
  commonStore: CommonInitialState,
  deviceStore: DeviceInitialState,
  modalStore: ModalInitialState,
  userStore: UserInitialState,
};
