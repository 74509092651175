import React from 'react';
import {
  Paper,
  IconButton,
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Box,
  Stepper,
  MobileStepper,
  Step,
  StepLabel,
  Autocomplete,
  Tooltip,
  Chip,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined, InfoOutlined, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { DeviceService, FirmwareService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import { MenuItem } from '@mui/material';
import translations from '../../assets/translations';
import { getTheme } from '../../common/theme';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type CommonData = {
  name: string;
  key: string;
  options?: string;
  config: { pattern: string; description: string };
};
type ApiData = {
  config: {
    required: boolean;
    mode: 'READ_WRITE' | 'READ_WRITEONCE' | 'READONLY';
    pattern: string;
    description: string;
  };
  key: string;
};

type State = {
  type: string;
  //meta data
  loading: boolean;
  activeStep: number;
  //fetched data
  products: string[];
  types: string[];
  groups: string[];
  //authorize data
  required: ApiData[];
  optional: ApiData[];
  common: CommonData[];
  error: Record<string, boolean>;
  requiredData: Record<string, any>;
  optionalData: Record<string, any>;
  commonData: Record<string, any>;
};

class AuthorizeDeviceMC extends React.Component<Props, State> {
  store = store.getState();
  state: State = {
    type: '',
    //meta data
    loading: false,
    activeStep: 0,
    //fetched data
    products: [],
    types: [],
    groups: [],
    //authorize data
    required: [],
    optional: [],
    common: [
      { name: 'name', key: 'name', config: { pattern: '^[a-zA-Z0-9]+', description: 'Name' } },
      { name: 'description', key: 'description', config: { pattern: '^[a-zA-Z0-9]+', description: 'Description' } },
      {
        name: 'groups',
        key: 'groups',
        options: 'groups',
        config: { pattern: '^[a-zA-Z0-9]+', description: 'Device groups' },
      },
    ],
    error: {},
    requiredData: {},
    optionalData: {},
    commonData: {},
  };
  firmwareService!: FirmwareService;
  deviceService!: DeviceService;
  maxSteps = translations.en.translation.modals.authorizeDevice.activePageHeader.length;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    this.deviceService = await DeviceService.create();
    const { data } = await this.firmwareService.product.list({ params: { limit: 500 } });
    const types = await this.loadDeviceTypes();
    const type = (localStorage.getItem('deviceTypeSelected')
      ? types.find((t) => t === localStorage.getItem('deviceTypeSelected'))
        ? localStorage.getItem('deviceTypeSelected')
        : ''
      : '') as string;
    this.setState({
      products: ['NONE'].concat(data.data.map((p) => p.productId)),
      types,
      type,
      groups: await this.loadDeviceGroups(),
    });
    if (type) {
      this.setFields(type);
    }
  };

  loadDeviceTypes = async (): Promise<any[]> => {
    const { data } = await this.deviceService.deviceType.list({
      params: { parent: '*', limit: 500 },
    });
    return data.data.map((t: any) => t.id);
  };

  get isNextDisabled() {
    const hasError = Object.values(this.state.error).some((val) => val === true);
    if (this.state.activeStep === 1) {
      return (
        hasError ||
        this.state.required.some((r) => {
          if (r.config.required) {
            return this.state.requiredData[r.key] === '' || this.state.requiredData[r.key] === undefined;
          } else {
            return hasError;
          }
        })
      );
    } else {
      return hasError;
    }
  }

  get isBackDisabled() {
    const hasError = Object.values(this.state.error).some((val) => val === true);
    if (this.state.activeStep === 1) {
      return (
        hasError ||
        this.state.required.some((r) => {
          if (r.config.required) {
            return this.state.requiredData[r.key] === '' || this.state.requiredData[r.key] === undefined;
          } else {
            return hasError;
          }
        })
      );
    } else {
      return hasError;
    }
  }

  loadDeviceGroups = async (): Promise<string[]> => {
    const { data } = await this.deviceService.deviceGroup.list({
      params: { parent: '*', limit: 500 },
    });
    return data.data.map((dg) => dg.groupId);
  };

  updateStep = (direction: number) => {
    let newStep = this.state.activeStep + direction;
    if (this.state.optional.length === 0 && newStep === 2) {
      if (direction > 0) {
        newStep = newStep + 1;
      } else {
        newStep = newStep - 1;
      }
    }
    this.setState({
      activeStep: newStep,
    });
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });
    const data: Record<string, any> = { type: this.state.type };
    this.state.required.forEach((r) => {
      if (this.state.requiredData[r.key]) {
        data[r.key] = this.state.requiredData[r.key];
      }
    });
    this.state.optional.forEach((r) => {
      if (this.state.optionalData[r.key]) {
        data[r.key] =
          r.key === 'productId' && this.state.optionalData[r.key] === 'NONE'
            ? undefined
            : this.state.optionalData[r.key];
      }
    });

    this.state.common.forEach((r) => {
      if (this.state.commonData[r.key]) {
        data[r.key] = this.state.commonData[r.key];
      }
    });

    try {
      const { data: device } = await this.deviceService.device.post(data);
      const groups = this.state.common.find((c) => c.name === 'groups');
      if (!groups || (groups && this.state.commonData[groups.key] && !this.state.commonData[groups.key].length)) {
        SnackbarUtils.success(`${i18next.t('success.create.device')} - id: ${device.deviceId}`);
        if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
        return this.props.closeModal();
      }

      const result =
        this.state.commonData[groups.key] &&
        (await Promise.all(
          (this.state.commonData[groups.key] as string[]).map((groupId) =>
            this.deviceService!.deviceGroup.postDeviceToDeviceGroup(groupId, device.deviceId)
              .then(() => ({
                isError: false,
                title: groupId,
                subtitle: i18next.t('success.attach.deviceToDeviceGroup'),
              }))
              .catch((e) => {
                const error: any = e;
                return {
                  isError: true,
                  title: groupId,
                  subtitle:
                    (error.response && error.response.data && error.response.data.message) ||
                    i18next.t('error.attach.deviceToDeviceGroup'),
                };
              }),
          ),
        ));

      if (
        result &&
        this.state.commonData[groups.key] &&
        !result.map((res: { isError: any }) => res.isError).includes(false)
      ) {
        SnackbarUtils.warning(
          `${i18next.t('error.create.deviceCreatedFailedToAddGroups')} (${this.state.commonData[groups.key].length})`,
          result as any,
        );
      } else if (
        result &&
        this.state.commonData[groups.key] &&
        !result.map((res: { isError: any }) => res.isError).includes(true)
      ) {
        SnackbarUtils.success(
          `${i18next.t('success.create.device')} (${this.state.commonData[groups.key].length})`,
          result as any,
        );
      } else if (result && this.state.commonData[groups.key]) {
        SnackbarUtils.warning(
          `${i18next.t('warning.create.deviceCreatedPartiallyAddedGroups')} (${
            this.state.commonData[groups.key].length
          })`,
          result as any,
        );
      }

      if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      console.log('ERROR', e);
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  setFields = async (type: string) => {
    const deviceTypeParameters = await this.deviceService.deviceType.deviceTypeParameters(type, {
      params: { parent: '*', limit: 500 },
    });

    this.setState({
      required: deviceTypeParameters.data.filter((o) => o.config.required === true),
      optional: deviceTypeParameters.data.filter((o) => o.config.required === false),
    });
  };

  getError = (obj: ApiData | CommonData, data: string): boolean => {
    if (typeof obj.config.pattern === 'string') {
      const regex = new RegExp(obj.config.pattern);
      return !regex.test(data as string);
    } else {
      return false;
    }
  };

  setError = (obj: ApiData | CommonData, data: any) => {
    if (typeof obj.config.pattern === 'string') {
      const regex = new RegExp(obj.config.pattern);
      const hasError = data ? !regex.test(data as string) : false;
      const error = this.state.error;
      error[obj.key] = hasError;
      this.setState({ error });
    }
  };

  cleanState = () => {
    this.setState({ error: {}, requiredData: {}, commonData: {}, optionalData: {} });
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container style={{ flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
          <Grid container spacing={4} style={{ flexDirection: 'column', flexWrap: 'nowrap', flex: '1 0 auto' }}>
            <Grid item xs={12} style={{ flex: 1 }}>
              <Typography variant="h3">
                {translations.en.translation.modals.authorizeDevice.activePageHeader[this.state.activeStep]}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ flex: 1, minHeight: '20%' }}>
              <Typography variant="body1">
                {translations.en.translation.modals.authorizeDevice.activePageDescription[this.state.activeStep]}
              </Typography>
            </Grid>
            {this.state.activeStep === 0 && (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ minWidth: 465, maxWidth: 465 }}>
                  <Stepper alternativeLabel orientation="horizontal" activeStep={-1}>
                    {translations.en.translation.modals.authorizeDevice.activePageHeader.slice(1).map((step) => (
                      <Step key={step}>
                        <StepLabel>{step}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>
            )}
            {this.state.activeStep === 1 && (
              <Grid key={'typekey'} item xs={12} style={{ position: 'relative' }}>
                <div style={{ position: 'relative' }}>
                  <Tooltip title={'Device type'} placement="left">
                    <InfoOutlined
                      fontSize="small"
                      style={{
                        position: 'absolute' as const,
                        color: getTheme().palette.text.secondary,
                        cursor: 'pointer' as const,
                        top: -8,
                        right: 6,
                        backgroundColor: 'white',
                        display: 'block',
                        zIndex: 1,
                        borderRadius: '200%',
                      }}
                    />
                  </Tooltip>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={false}
                      disabled={false}
                      required={false}
                      style={{ marginBottom: '15px' }}
                    >
                      <InputLabel
                        id={`select-label${'0'}`}
                        htmlFor={`inputselect${'0'}`}
                        shrink
                        style={{ backgroundColor: 'white', paddingLeft: 4, paddingRight: 4, marginLeft: -4 }}
                      >
                        {'type'}
                      </InputLabel>
                      <Select
                        id={`select${'type'}`}
                        inputProps={{
                          id: `inputselect${'type'}`,
                        }}
                        labelId={`select-label${'type'}`}
                        value={this.state.type}
                        onChange={(e) => {
                          this.cleanState();
                          this.setState({ type: e.target.value });
                          localStorage.setItem('deviceTypeSelected', e.target.value), this.setFields(e.target.value);
                        }}
                      >
                        {this.state.types.map(
                          (option: string | { label: string; value: any; description?: string }) => (
                            <MenuItem
                              value={typeof option === 'object' ? option.value : option}
                              key={typeof option === 'object' ? option.value : option}
                            >
                              <div>
                                <div>{typeof option === 'object' ? option.label : option}</div>
                                {typeof option === 'object' && option?.description && (
                                  <div style={{ color: '#696969' }}> {option?.description} </div>
                                )}
                              </div>
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                    {this.state.required.map((r, index) => (
                      <Grid key={`${r.key}grid`} item xs={12} style={{ position: 'relative' }}>
                        <div key={`${r.key}description`} style={{ position: 'relative' }}>
                          {r.config.description ? (
                            <Tooltip key={`${r.key}tt`} title={r.config.description} placement="left">
                              <InfoOutlined
                                fontSize="small"
                                key={`${r.key}info`}
                                style={{
                                  position: 'absolute' as const,
                                  color: getTheme().palette.text.secondary,
                                  cursor: 'pointer' as const,
                                  top: -8,
                                  right: 6,
                                  backgroundColor: 'white',
                                  display: 'block',
                                  zIndex: 1,
                                  borderRadius: '200%',
                                }}
                              />
                            </Tooltip>
                          ) : undefined}
                          <TextField
                            id={r.key}
                            key={r.key}
                            fullWidth
                            type="string"
                            variant="outlined"
                            required={true}
                            label={r.key}
                            error={
                              this.state.requiredData[r.key] ? this.getError(r, this.state.requiredData[r.key]) : false
                            }
                            FormHelperTextProps={{ id: `helper${'a'}` }}
                            InputLabelProps={{
                              id: `label${'a'}`,
                              shrink: true,
                              htmlFor: `input${'a'}`,
                              classes: { root: this.props.classes.labelBackground },
                            }}
                            value={this.state.requiredData[r.key] || ''}
                            disabled={false}
                            onChange={(e) => {
                              this.setError(r, e.target.value);
                              this.setState({
                                requiredData: { ...this.state.requiredData, [`${r.key}`]: e.target.value },
                              });
                            }}
                            InputProps={{
                              id: `input${'a'}`,
                              'aria-describedby': `label${'a'}`,
                            }}
                            autoFocus={index === 0}
                            style={{ marginBottom: '15px' }}
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            )}
            {this.state.optional.length > 0 && this.state.activeStep === 2 && (
              <Grid item xs={12}>
                {this.state.optional.map((r, index) =>
                  r.key === 'productId' ? (
                    <Grid key={'productIdkey'} item xs={12} style={{ position: 'relative' }}>
                      <div style={{ position: 'relative' }}>
                        <Tooltip title={'Product id'} placement="left">
                          <InfoOutlined
                            fontSize="small"
                            style={{
                              position: 'absolute' as const,
                              color: getTheme().palette.text.secondary,
                              cursor: 'pointer' as const,
                              top: -8,
                              right: 6,
                              backgroundColor: 'white',
                              display: 'block',
                              zIndex: 1,
                              borderRadius: '200%',
                            }}
                          />
                        </Tooltip>
                        <Grid item xs={12}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={false}
                            disabled={false}
                            required={false}
                            style={{ marginBottom: '15px' }}
                          >
                            <InputLabel
                              id={`select-label${'0'}`}
                              htmlFor={`inputselect${'0'}`}
                              shrink
                              style={{ backgroundColor: 'white', paddingLeft: 4, paddingRight: 4, marginLeft: -4 }}
                            >
                              {r.key}
                            </InputLabel>
                            <Select
                              id={`select${r.key}`}
                              inputProps={{
                                id: `inputselect${r.key}`,
                              }}
                              labelId={`select-label${r.key}`}
                              value={this.state.optionalData[r.key] || ''}
                              onChange={(e) => {
                                this.setState({
                                  optionalData: { ...this.state.optionalData, [`${r.key}`]: e.target.value },
                                });
                              }}
                            >
                              {this.state.products.map(
                                (option: string | { label: string; value: any; description?: string }) => (
                                  <MenuItem
                                    value={typeof option === 'object' ? option.value : option}
                                    key={typeof option === 'object' ? option.value : option}
                                  >
                                    <div>
                                      <div>{typeof option === 'object' ? option.label : option}</div>
                                      {typeof option === 'object' && option?.description && (
                                        <div style={{ color: '#696969' }}> {option?.description} </div>
                                      )}
                                    </div>
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      </div>
                    </Grid>
                  ) : (
                    <Grid key={`${r.key}grid`} item xs={12} style={{ position: 'relative' }}>
                      <div key={`${r.key}description`} style={{ position: 'relative' }}>
                        {r.config.description ? (
                          <Tooltip key={`${r.key}tt`} title={r.config.description} placement="left">
                            <InfoOutlined
                              fontSize="small"
                              key={`${r.key}info`}
                              style={{
                                position: 'absolute' as const,
                                color: getTheme().palette.text.secondary,
                                cursor: 'pointer' as const,
                                top: -8,
                                right: 6,
                                backgroundColor: 'white',
                                display: 'block',
                                zIndex: 1,
                                borderRadius: '200%',
                              }}
                            />
                          </Tooltip>
                        ) : undefined}
                        <TextField
                          id={r.key}
                          key={r.key}
                          fullWidth
                          type="string"
                          variant="outlined"
                          required={false}
                          label={r.key}
                          error={
                            this.state.optionalData[r.key] ? this.getError(r, this.state.optionalData[r.key]) : false
                          }
                          FormHelperTextProps={{ id: `helper${'a'}` }}
                          InputLabelProps={{
                            id: `label${'a'}`,
                            shrink: true,
                            htmlFor: `input${'a'}`,
                            classes: { root: this.props.classes.labelBackground },
                          }}
                          value={this.state.optionalData[r.key] || ''}
                          disabled={false}
                          onChange={(e) => {
                            this.setError(r, e.target.value);
                            this.setState({
                              optionalData: { ...this.state.optionalData, [`${r.key}`]: e.target.value },
                            });
                          }}
                          InputProps={{
                            id: `input${'a'}`,
                            'aria-describedby': `label${'a'}`,
                          }}
                          onFocus={() => console.log()}
                          onBlur={() => console.log()}
                          autoFocus={index === 0}
                          style={{ marginBottom: '15px' }}
                        />
                      </div>
                    </Grid>
                  ),
                )}
              </Grid>
            )}
            {((this.state.optional.length === 0 && this.state.activeStep === 2) || this.state.activeStep === 3) && (
              <Grid item xs={12}>
                {this.state.common.map((r, index) =>
                  r.options ? (
                    <Grid key={'optionskey'} item xs={12} style={{ position: 'relative' }}>
                      <div style={{ position: 'relative' }}>
                        <Tooltip title={r.config.description} placement="left">
                          <InfoOutlined
                            fontSize="small"
                            style={{
                              position: 'absolute' as const,
                              color: getTheme().palette.text.secondary,
                              cursor: 'pointer' as const,
                              top: -8,
                              right: 6,
                              backgroundColor: 'white',
                              display: 'block',
                              zIndex: 1,
                              borderRadius: '200%',
                            }}
                          />
                        </Tooltip>
                        <Autocomplete
                          id={`autocomplete${r.key}`}
                          key={`autocomplete${r.key}`}
                          multiple
                          filterSelectedOptions
                          renderTags={(value: readonly string[], getTagProps) => {
                            return value.map((option: string, index: number) => (
                              <Tooltip
                                key={option}
                                placement="top"
                                title={'' /*this.toolTipCreate(option.toString())*/}
                              >
                                <Chip size={'small'} label={option} {...getTagProps({ index })} />
                              </Tooltip>
                            ));
                          }}
                          options={this.state.groups}
                          disabled={false}
                          getOptionLabel={(option: string) => option}
                          value={this.state.commonData[r.key] || []}
                          isOptionEqualToValue={(option: string, value: string) => option === value}
                          onChange={(_: any, list: any) => {
                            this.setState({ commonData: { ...this.state.commonData, [`${r.key}`]: list } });
                          }}
                          //onInputChange={!this.props.options ? this.onValueChange : undefined}
                          //onOpen={!this.props.options && !this.state.tmpOptions.length ? () => this.runValueChange('') : undefined}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              id={r.key}
                              error={false}
                              helperText={''}
                              InputLabelProps={{
                                shrink: true,
                                htmlFor: `autocomplete${r.key}`,
                              }}
                              label={r.name}
                              variant="outlined"
                              required={false}
                              fullWidth
                              autoFocus={index === 0}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <Grid key={`namekey${index}`} item xs={12} style={{ position: 'relative' }}>
                      <div style={{ position: 'relative' }}>
                        <Tooltip title={r.config.description} placement="left">
                          <InfoOutlined
                            fontSize="small"
                            style={{
                              position: 'absolute' as const,
                              color: getTheme().palette.text.secondary,
                              cursor: 'pointer' as const,
                              top: -8,
                              right: 6,
                              backgroundColor: 'white',
                              display: 'block',
                              zIndex: 1,
                              borderRadius: '200%',
                            }}
                          />
                        </Tooltip>
                        <TextField
                          id={r.name}
                          key={r.name}
                          fullWidth
                          type="string"
                          variant="outlined"
                          required={false}
                          label={r.name}
                          error={this.state.commonData[r.key] ? this.getError(r, this.state.commonData[r.key]) : false}
                          FormHelperTextProps={{ id: `helper${'a'}` }}
                          InputLabelProps={{
                            id: `label${'a'}`,
                            shrink: true,
                            htmlFor: `input${'a'}`,
                            classes: { root: this.props.classes.labelBackground },
                          }}
                          value={this.state.commonData[r.key] || ''}
                          disabled={false}
                          onChange={(e) => {
                            this.setError(r, e.target.value);
                            this.setState({ commonData: { ...this.state.commonData, [`${r.key}`]: e.target.value } });
                          }}
                          InputProps={{
                            id: `input${'a'}`,
                            'aria-describedby': `label${'a'}`,
                          }}
                          autoFocus={index === 0}
                          style={{ marginBottom: '15px' }}
                        />
                      </div>
                    </Grid>
                  ),
                )}
              </Grid>
            )}
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <MobileStepper
              variant="dots"
              steps={this.maxSteps}
              position="static"
              activeStep={this.state.activeStep}
              nextButton={
                <Grid style={{ minWidth: '85px', display: 'flex', justifyContent: 'flex-end' }}>
                  {this.state.activeStep === this.maxSteps - 1 ? (
                    <Button color="secondary" variant="contained" disabled={this.isNextDisabled} onClick={this.create}>
                      {this.state.loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <Trans>action.authorize</Trans>
                      )}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      style={{ paddingLeft: '24px' }}
                      onClick={() => this.updateStep(1)}
                      disabled={this.isNextDisabled}
                    >
                      {'NEXT'}
                      {<KeyboardArrowRight />}
                    </Button>
                  )}
                </Grid>
              }
              backButton={
                <Grid style={{ minWidth: '85px' }}>
                  {this.state.activeStep !== 0 ? (
                    <Button
                      size="small"
                      style={{ paddingRight: '24px' }}
                      onClick={() => this.updateStep(-1)}
                      disabled={this.state.activeStep === 0 || this.isBackDisabled}
                    >
                      {<KeyboardArrowLeft />}
                      BACK
                    </Button>
                  ) : (
                    <Button size="small" disabled={true}></Button>
                  )}
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(AuthorizeDeviceMC);
