import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, TextField } from '@mui/material';
import { Trans } from 'react-i18next';
import { Store, ShadowAlias } from '../../common/types';
import { SchemaForm, PageLoader, TabPanel } from '../../components';
import { DataService, DeviceService, UserService, FirmwareService } from '../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from './TabViewBase';
import { SnackbarUtils } from '../../components/StyledSnackbarProvider';
import schemaUtils from '../../common/schemaUtils';

type Props = {
  schema: Record<string, any>;
  schemaKey: string;
  schemaFormInputProps?: {
    service?: 'DataService' | 'DeviceService' | 'UserService' | 'FirmwareService';
    subservice?: string;
    type: 'onValueChange' | 'options' | 'json';
    key: string;
    nameKey?: string;
    onValueChange?: {
      filterKey: string;
      returnKey: string;
      staticFilters: Record<string, any>;
    };
    json?: {
      objectPath?: string;
      schema: { [key: string]: any };
    };
    navigate?: (id: string) => string;
    extraData?: string[];
    filterFunc?: (obj: any) => boolean;
  }[];
  shadowAliases?: ShadowAlias[];
  customAction?: React.ReactNode;
  accessControlSection: string;
  disableEdit?: boolean;
  update: (data: any) => void;
  reload: () => Promise<void>;
};

type State = {
  editedItem: { [key: string]: string };
  saveDisabled: boolean;
  products?: string[];
  schemaFormInputData: { [key: string]: any[] };
};

export class PropertiesTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    editedItem: {},
    saveDisabled: true,
    products: undefined,
    schemaFormInputData: {},
  };
  DataService!: DataService;
  DeviceService!: DeviceService;
  UserService!: UserService;
  FirmwareService!: FirmwareService;

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    this.lastReload = this.time();
  }

  componentDidMount = async (): Promise<void> => {
    if (this.props.schemaFormInputProps) {
      const initServices = this.props.schemaFormInputProps!.map((obj) => obj.service);
      if (initServices.includes('DataService')) this.DataService = await DataService.create();
      if (initServices.includes('DeviceService')) this.DeviceService = await DeviceService.create();
      if (initServices.includes('UserService')) this.UserService = await UserService.create();
      if (initServices.includes('FirmwareService')) this.FirmwareService = await FirmwareService.create();
    }
  };

  componentDidUpdate = async (
    prevProps: TabViewBaseProps<Props>,
    prevState: TabViewBaseState<State>,
  ): Promise<void> => {
    if (!prevState.editing && this.state.editing && this.props.schemaFormInputProps) {
      // Initialize option values for schema
      const initOptionServices = this.props.schemaFormInputProps!.filter(
        (obj) => obj.service && obj.subservice && obj.type === 'options',
      );
      initOptionServices.forEach(async (obj) => {
        if (this.state.schemaFormInputData[obj.key] !== undefined) return;
        let list: any[] = [];
        let nextToken: string | undefined = undefined;
        let success = true;

        do {
          try {
            const { data } = await (this as any)[obj.service!][obj.subservice!].list({ params: { limit: 500 } });
            if (obj.filterFunc) {
              list = list.concat(data.data.filter(obj.filterFunc).map((data: any) => data[obj.nameKey || obj.key]));
            } else {
              list = list.concat(data.data.map((data: any) => data[obj.key]));
            }
            if (obj.extraData) {
              list = obj.extraData.concat(list);
            }
            nextToken = data.nextToken;
          } catch (e) {
            console.log(e);
            success = false;
            SnackbarUtils.error(`Failed to load options for ${obj.key}`);
            break;
          }
        } while (nextToken);

        if (success) this.setState({ schemaFormInputData: { ...this.state.schemaFormInputData, [obj.key]: list } });
      });

      // Initialize onValueChange functions for schema
      const initOnValueChangeServices = this.props.schemaFormInputProps!.filter(
        (obj) => obj.service && obj.subservice && obj.type === 'onValueChange' && obj.onValueChange,
      );
      initOnValueChangeServices.forEach(async (obj) => {
        if ((this as any)[`load${obj.key}`] !== undefined) return;
        (this as any)[`load${obj.key}`] = async (value: string) => {
          const { data } = await (this as any)[obj.service!][obj.subservice!].list({
            params: {
              limit: 500,
              ...(obj.onValueChange!.staticFilters || {}),
              [obj.onValueChange!.filterKey]: value.endsWith('*') ? value : `${value}*`,
            },
          });
          return data.data.map((val: any) => val[obj.onValueChange!.returnKey]);
        };
      });
    }
  };

  reload = this.props.reload;

  update = async (): Promise<void> => {
    this.setState({ loading: true });
    await this.props.update(this.state.editedItem || {});
    this.setState({ loading: false, editing: false });
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean): void => {
    const editedItem = schemaUtils.setValue(
      this.state.editedItem || {},
      key === undefined ? [] : key.split('.'),
      value,
    );
    this.setState({ editedItem: editedItem || {}, saveDisabled: !(!!Object.keys(editedItem).length && isValid) });
  };

  renderSchema = (): React.ReactNode => (
    <Grid item xs={12}>
      <SchemaForm
        state="UPDATE"
        SchemaFormInputProps={(this.props.schemaFormInputProps || []).reduce((acc: any, val) => {
          if (val.type === 'onValueChange') acc[val.key] = { onValueChange: (this as any)[`load${val.key}`] };
          else if (val.type === 'options') {
            acc[val.key] = {
              options: this.state.schemaFormInputData[val.key],
              navigate: val.navigate,
            };
          } else if (val.type === 'json') acc[val.key] = { json: val.json };
          return acc;
        }, {})}
        key={`schema-editing-${this.state.editing}`}
        data={{
          ...(this.props.schema as any).properties[this.props.schemaKey],
        }}
        disabled={!this.state.editing}
        defaultValue={this.props.item}
        onChange={this.handleUpdate}
      />
    </Grid>
  );

  renderShadowAliases = (): React.ReactNode => {
    if (!this.props.shadowAliases) return null;
    const shadowAliases = this.props.shadowAliases!.filter(
      (sa) => !['firmwareProductId', 'firmwareVersion', 'firmwareUpdated'].includes(sa.alias),
    );
    if (!this.props.item || !shadowAliases.length) return null;

    return (
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6">
              <Trans>shadowAliases</Trans>
            </Typography>
          </Grid>
          {shadowAliases.map((sa) => (
            <Grid item xs={12} key={sa.alias}>
              <TextField fullWidth disabled label={sa.alias} value={(this.props.item! as any)[sa.alias] || 'null'} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  renderContent = (): React.ReactNode => (
    <Grid container spacing={4}>
      {this.renderSchema()}
      {this.renderShadowAliases()}
    </Grid>
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.props.parentLoading || this.state.loading,
        },
        customAction: this.props.customAction,
        ...(this.props.accessControl(this.props.accessControlSection, 'update') && !this.props.disableEdit
          ? {
              editProps: {
                loading: this.state.loading,
                editing: this.state.editing,
                saveDisabled: this.state.saveDisabled,
                editDisabled: !this.props.item,
                onEdit: () => this.setState({ editing: true }),
                onSave: this.update,
                onCancel: () => this.setState({ editing: false }),
              },
            }
          : {}),
      }}
    >
      {this.props.parentLoading && <PageLoader />}
      {!this.props.parentLoading && this.renderContent()}
    </TabPanel>
  );
}

const mapStateToProps = ({ deviceStore }: Store) => ({
  shadowAliases: deviceStore.shadowAliases,
});

export default connect(mapStateToProps)(PropertiesTab);
