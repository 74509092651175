import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, TextField, Tooltip } from '@mui/material';
import { Product, ModalVariants } from '../../../common/types';
import { PageLoader, TabPanel } from '../../../components';
import { FirmwareService } from '../../../services';
import {
  TabViewBase,
  TabViewBaseProps,
  TabViewBaseState,
} from '../../baseClasses/TabViewBase';
import { InfoOutlined } from '@mui/icons-material';
import { getTheme } from '../../../common/theme';
import moment from 'moment-timezone';
import { Action, Dispatch } from 'redux';
import { storeOpenModal } from '../../../store/actions/modal';

type Data = {
  name: string;
  key: string;
  options?: string;
  data?: any;
  default?: string;
  config: {
    pattern: string;
    description: string;
    mode: 'READ_WRITE' | 'READ_WRITEONCE' | 'READONLY';
    required: boolean;
  };
};

type _Props = {
  customAction?: React.ReactNode;
  accessControlSection: string;
  disableEdit?: boolean;
  update: (data: any) => Promise<void>;
  reload: () => Promise<void>;
  openModal: (callback: () => any) => void;
};

type Props = TabViewBaseProps<_Props>;

type State = {
  editedItem: { [key: string]: string };
  saveDisabled: boolean;
  products: Product[];
  commonData: Data[];
  specialData: Data[];
  release: undefined | string;
  status: string | undefined;
};

type ChangeProductResult = { productId: string };

class FirmwareTab extends TabViewBase<_Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    editedItem: {},
    saveDisabled: true,
    status: undefined,
    products: [],
    release: undefined,
    commonData: [
      {
        name: 'Product Id (reported by device)',
        key: 'firmwareProductId',
        default: 'UNKNOWN',
        config: {
          pattern: '^[a-zA-Z0-9]+',
          description: 'Firmware product on device',
          mode: 'READ_WRITE',
          required: true,
        },
      },
      {
        name: 'Version',
        key: 'firmwareVersion',
        default: 'UNKNOWN',
        config: {
          pattern: '',
          description: 'Current firmware version',
          mode: 'READONLY',
          required: false,
        },
      },
      {
        name: 'Status',
        key: 'status',
        config: {
          pattern: '',
          description: 'Status',
          mode: 'READONLY',
          required: false,
        },
      },
    ],
    specialData: [
      {
        name: 'Description',
        key: 'description',
        config: {
          pattern: '^[a-zA-Z0-9 _.,()-]*$',
          description: 'Additional human readable description of device',
          mode: 'READONLY',
          required: false,
        },
      },
      {
        name: 'Release name',
        key: 'releaseName',
        config: {
          pattern: '',
          description: 'Release name',
          mode: 'READONLY',
          required: false,
        },
      },
      {
        name: 'Updated',
        key: 'firmwareUpdated',
        config: {
          pattern: '',
          description: 'Last update time of firmware',
          mode: 'READONLY',
          required: false,
        },
      },
    ],
  };
  FirmwareService!: FirmwareService;

  constructor(props: Props) {
    super(props);
    this.lastReload = this.time();
  }

  componentDidMount = async (): Promise<void> => {
    this.FirmwareService = await FirmwareService.create();
    const { data } = await this.FirmwareService.product.list({
      params: { limit: 500 },
    });
    this.setState({
      products: data.data,
    });
  };

  componentDidUpdate = async (
    prevProps: TabViewBaseProps<Props>,
  ): Promise<void> => {
    if (!this.props.item) {
      return;
    }

    const { deviceId, firmwareProductId } = this.props.item;
    const result = {} as Partial<State>;

    if (
      firmwareProductId &&
      firmwareProductId !== prevProps.item?.firmwareProductId
    ) {
      result['release'] = await this.getRelease(
        this.props.item?.firmwareProductId,
        this.props.item?.firmwareVersion,
      );
    }

    if (deviceId && prevProps.item?.deviceId !== deviceId) {
      result['status'] = await this.getUpdateStatus(deviceId);
    }

    if (Object.keys(result).length > 0) {
      this.setState(result as State);
    }
  };

  reload = async () => {
    await this.props.reload();
    if (this.props.item?.deviceId) {
      const status = await this.getUpdateStatus(this.props.item.deviceId);
      this.setState({ status });
    }
  };

  save = () => {
    this.setState({ loading: true });
    this.props.update(this.state.editedItem || {});
    this.setState({ loading: false, editing: false });
  };

  update = async (data: ChangeProductResult): Promise<void> => {
    await this.props.update(data);

    if (this.props.item?.deviceId && data.productId) {
      const status = await this.getUpdateStatus(
        this.props.item.deviceId,
        data.productId,
      );
      this.setState({ status });
    }
  };

  getUpdateStatus = async (
    deviceId: string,
    desiredProductId?: string,
  ): Promise<string> => {
    try {
      const { data } = await this.FirmwareService.checkForUpdate.checkForUpdate(
        deviceId,
        desiredProductId,
      );
      return data.updateStatus;
    } catch {
      return 'Pending device activation';
    }
  };

  getRelease = async (
    productId: string,
    version: string,
  ): Promise<string | undefined> => {
    const { data } = await this.FirmwareService.release.list({
      params: {
        productId,
        limit: 50,
      },
    });
    const r = data.data.find((i) => i.toVersion === version);
    return r?.name;
  };

  handleUpdate = (key: string, value: any): void => {
    const editedItem = this.state.editedItem;
    editedItem[key] = value;
    this.setState({
      editedItem,
      saveDisabled: Object.keys(editedItem).length === 0,
    });
  };

  getValue = (index: number) => {
    if (index === 0 && this.props.item?.firmwareProductId) {
      const prod = this.state.products.find(
        (p) => p.productId === this.props.item?.firmwareProductId,
      );
      return prod
        ? prod?.description || ''
        : this.props.item[this.state.specialData[index].key];
    } else if (index === 1) {
      return this.state.release || '';
    } else if (index === 2) {
      return `${moment(this.props.item['firmwareUpdated'])
        .tz(moment.tz.guess())
        .format('YYYY-MM-DD')} (${moment(
          this.props.item['firmwareUpdated'],
        ).fromNow()})`;
    } else {
      return this.props.item[this.state.specialData[index].key];
    }
  };

  getLeft = (
    key: string,
    defaultValue: string | undefined,
    tooltip?: boolean,
  ): string => {
    if (
      ((!tooltip && defaultValue && key === 'firmwareVersion') ||
        key === 'firmwareProductId') &&
      (this.props.item[key] === '' ||
        this.props.item[key] === undefined ||
        this.props.item[key] === null)
    ) {
      return defaultValue || '';
    } else if (key === 'firmwareUpdated') {
      if (tooltip) {
        return moment(this.props.item[key])
          .tz(moment.tz.guess())
          .format('YYYY-MM-DD HH:mm');
      } else {
        return `${moment(this.props.item[key])
          .tz(moment.tz.guess())
          .format('YYYY-MM-DD')} (${moment(this.props.item[key]).fromNow()})`;
      }
    } else if (!tooltip) {
      return this.props.item[key] ?? this.state[key as keyof State];
    } else {
      return '';
    }
  };

  renderData = (): React.ReactNode =>
    this.state.commonData &&
    this.state.commonData.map((d: Data, index) => (
      <Grid
        item
        xs={12}
        key={index}
        style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}
      >
        <Grid item xs={12}>
          <div
            style={{
              minHeight: 64,
              paddingLeft: 14,
              position: 'relative',
              marginTop: '32px',
            }}
            key={index}
          >
            <Typography
              variant="body2"
              style={{
                fontSize: 12,
                position: 'relative',
                marginTop: -8,
                marginLeft: 14,
                color: getTheme().palette.text.disabled,
              }}
            >
              {(!this.state.editing ||
                d.config.mode === 'READ_WRITEONCE' ||
                d.config.mode === 'READONLY') &&
                d.name}
              <Tooltip title={d.config.description} placement="left">
                <InfoOutlined
                  fontSize="small"
                  style={{
                    position: 'absolute' as const,
                    color: getTheme().palette.text.disabled,
                    cursor: 'pointer' as const,
                    top: 0,
                    right: 20,
                    backgroundColor: 'white',
                    display: 'block',
                    zIndex: 1,
                    borderRadius: '200%',
                  }}
                />
              </Tooltip>
            </Typography>
            {/* Left common edit */}
            {this.state.editing &&
              d.config.mode !== 'READ_WRITEONCE' &&
              d.config.mode !== 'READONLY' ? (
              <TextField
                key={`${d.key}edit`}
                style={{ marginTop: '6px' }}
                fullWidth
                type="string"
                variant="outlined"
                required={d.config.required}
                label={d.name}
                error={false}
                InputLabelProps={{
                  id: `label${d.key}`,
                  shrink: true,
                  htmlFor: d.key ? `input${d.key}` : undefined,
                }}
                value={this.state.editedItem[d.key] || this.props.item[d.key]}
                disabled={false}
                onChange={(e) => this.handleUpdate(d.key, e.target.value)}
                InputProps={{
                  id: `input${d.key}`,
                  'aria-describedby': `label${d.key}`,
                }}
                autoFocus={index === 0}
              />
            ) : (
              <Tooltip
                title={this.getLeft(d.key, d.default, true)}
                placement="top-start"
              >
                <div
                  style={{
                    marginTop: '6px',
                    marginLeft: 14,
                    wordBreak: 'break-all',
                  }}
                >
                  {this.getLeft(d.key, d.default)}
                </div>
              </Tooltip>
            )}
          </div>
        </Grid>
        {this.state.specialData &&
          this.state.specialData.length > 0 &&
          this.state.specialData.length - 1 >= index &&
          this.state.specialData[index] && (
            <Grid item xs={12}>
              <div
                style={{
                  minHeight: 64,
                  paddingLeft: 14,
                  position: 'relative',
                  marginTop: '32px',
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    fontSize: 12,
                    position: 'relative',
                    marginTop: -8,
                    marginLeft: 14,
                    color: getTheme().palette.text.disabled,
                  }}
                >
                  {(!this.state.editing ||
                    this.state.specialData[index].config.mode ===
                    'READ_WRITEONCE' ||
                    this.state.specialData[index].config.mode === 'READONLY') &&
                    this.state.specialData[index].name}
                  <Tooltip
                    title={this.state.specialData[index].config.description}
                    placement="left"
                  >
                    <InfoOutlined
                      fontSize="small"
                      style={{
                        position: 'absolute' as const,
                        color: getTheme().palette.text.disabled,
                        cursor: 'pointer' as const,
                        top: 0,
                        right: 6,
                        backgroundColor: 'white',
                        display: 'block',
                        zIndex: 1,
                        borderRadius: '200%',
                      }}
                    />
                  </Tooltip>
                </Typography>
                {/* Right special edit */}
                {this.state.editing &&
                  this.state.specialData[index].config.mode !==
                  'READ_WRITEONCE' &&
                  this.state.specialData[index].config.mode !== 'READONLY' ? (
                  <TextField
                    key={`${this.state.specialData[index].key}edit`}
                    style={{ marginTop: '8px' }}
                    fullWidth
                    type="string"
                    variant="outlined"
                    required={this.state.specialData[index].config.required}
                    label={this.state.specialData[index].name}
                    error={false}
                    InputLabelProps={{
                      id: `label${this.state.specialData[index].key}`,
                      shrink: true,
                      htmlFor: this.state.specialData[index].key
                        ? `input${this.state.specialData[index].key}`
                        : undefined,
                    }}
                    value={
                      this.state.editedItem[
                      this.state.specialData[index].key
                      ] || this.getValue(index)
                    }
                    disabled={false}
                    onChange={(e) =>
                      this.handleUpdate(
                        this.state.specialData[index].key,
                        e.target.value,
                      )
                    }
                    InputProps={{
                      id: `input${this.state.specialData[index].key}`,
                      'aria-describedby': `label${this.state.specialData[index].key}`,
                    }}
                    autoFocus={false}
                  />
                ) : (
                  <div
                    style={{
                      marginTop: '6px',
                      marginLeft: 14,
                      wordBreak: 'break-all',
                    }}
                  >
                    {this.getValue(index)}
                  </div>
                )}
              </div>
            </Grid>
          )}
      </Grid>
    ));

  renderContent = (): React.ReactNode => (
    <Grid container spacing={4}>
      {this.props.item && this.renderData()}
    </Grid>
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.props.parentLoading || this.state.loading,
        },
        ...(this.props.accessControl(
          this.props.accessControlSection,
          'update',
        ) && !this.props.disableEdit
          ? {
            editProps: {
              loading: this.state.loading,
              editing: this.state.editing,
              saveDisabled: this.state.saveDisabled,
              editDisabled: !this.props.item,
              customEditName: 'action.changeProduct',
              onEdit: () => {
                this.props.openModal &&
                  this.props.openModal(() => ({
                    value: this.props.item.productId,
                    products: this.state.products,
                    update: this.update,
                  }));
              },
              onSave: this.save,
              onCancel: () => this.setState({ editing: false }),
            },
          }
          : {}),
      }}
    >
      {this.props.parentLoading && <PageLoader />}
      {!this.props.parentLoading && this.renderContent()}
    </TabPanel>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any) =>
    dispatch(storeOpenModal(ModalVariants.ChangeProductMC, { callback })),
});

export default connect(null, mapDispatchToProps)(FirmwareTab);
