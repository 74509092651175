// Defines attribute inside object
export type KeyValue<T, U> = {
  key: T;
  value: U;
};

export const APIPrefix = {
  customer: '/customers',
  data: '/data',
  firmware: '/firmware',
  fleet: '/fleet',
  fleetApiGw: '/fleet-api-gw',
  user: '/user',
};

export const APIPath = {
  spec: {
    fleet: (): string => `${APIPrefix.fleet}/spec`,
    'fleet-api-gw': (): string => `${APIPrefix.fleetApiGw}/spec`,
    user: (): string => `${APIPrefix.user}/spec`,
    data: (): string => `${APIPrefix.data}/spec`,
    firmware: (): string => `${APIPrefix.firmware}/spec`,
  },
  customer: {
    configuration: (subdomain: string): string => `${APIPrefix.customer}/config/${subdomain}/`,
  },
  data: {
    subscriptions: (): string => `${APIPrefix.data}/subscriptions`,
    subscriptionDetailed: (subscriptionId: string): string => `${APIPrefix.data}/subscriptions/${subscriptionId}`,
    deviceApiGw: (): string => `${APIPrefix.fleetApiGw}/endpoint`,
    deviceApiGwDetailed: (apiId: string): string => `${APIPrefix.fleetApiGw}/endpoint/${apiId}/`,
    deviceApiGwtest: (apiId: string): string => `${APIPrefix.fleetApiGw}/endpoint/${apiId}/test/`,
    trust: (): string => `${APIPrefix.data}/trusts`,
    trustDetailed: (trustId: string): string => `${APIPrefix.data}/trusts/${trustId}/`,
  },
  firmware: {
    package: () => `${APIPrefix.firmware}/packages`,
    packagePrepare: () => `${APIPrefix.firmware}/packages/prepare`,
    packageDetailed: (packageId: string) => `${APIPrefix.firmware}/packages/${packageId}`,
    packageConfirm: (packageId: string) => `${APIPrefix.firmware}/packages/${packageId}/confirm`,
    product: () => `${APIPrefix.firmware}/products`,
    productDetailed: (productId: string) => `${APIPrefix.firmware}/products/${productId}`,
    release: () => `${APIPrefix.firmware}/releases`,
    releaseDetailed: (releaseId: string) => `${APIPrefix.firmware}/releases/${releaseId}`,
    releaseMapping: (releaseId: string, fromVersion?: string) =>
      `${APIPrefix.firmware}/releases/${releaseId}/mapping${fromVersion ? `/${fromVersion}` : ''}`,
    releaseEntrypoint: (releaseId: string) => `${APIPrefix.firmware}/releases/${releaseId}/entrypoint`,
    key: () => `${APIPrefix.firmware}/keys`,
    keyDetailed: (keyId: string) => `${APIPrefix.firmware}/keys/${keyId}`,
    keyType: () => `${APIPrefix.firmware}/key-types`,
    checkForUpdate: () => `${APIPrefix.firmware}/checkForUpdate`,
  },
  fleet: {
    devices: (): string => `${APIPrefix.fleet}/devices`,
    deviceTypes: (): string => `${APIPrefix.fleet}/deviceTypes`,
    deviceType: (deviceId: string): string => `${APIPrefix.fleet}/deviceTypes/${deviceId}/parameters`,
    deviceDetailed: (deviceId: string): string => `${APIPrefix.fleet}/devices/${deviceId}/`,
    trigger: (): string => `${APIPrefix.fleet}/triggers`,
    triggers: (triggerId?: string): string => `${APIPrefix.fleet}/triggers/${triggerId || ''}${triggerId ? '/' : ''}`,
    deviceShadow: (deviceId: string): string => `${APIPrefix.fleet}/devices/${deviceId}/shadow`,
    deviceConnections: (deviceId: string): string => `${APIPrefix.fleet}/devices/${deviceId}/connections`,
    deviceGroups: (): string => `${APIPrefix.fleet}/groups`,
    deviceAddToDeviceGroup: (groupId: string): string => `${APIPrefix.fleet}/groups/${groupId}/devices`,
    deviceRemoveFromDeviceGroup: (groupId: string, deviceId: string): string =>
      `${APIPrefix.fleet}/groups/${groupId}/devices/${deviceId}`,
    deviceGroupDetailed: (groupId: string): string => `${APIPrefix.fleet}/groups/${groupId}/`,
    shadowAliases: (): string => `${APIPrefix.fleet}/shadow-aliases`,
    shadowAliasDetailed: (aliasId: string): string => `${APIPrefix.fleet}/shadow-aliases/${aliasId}`,
    shadowTemplates: (): string => `${APIPrefix.fleet}/shadow-templates`,
    shadowTemplateDetailed: (templateId: string): string => `${APIPrefix.fleet}/shadow-templates/${templateId}`,
    shadowTemplatesDefault: (): string => `${APIPrefix.fleet}/shadow-templates-default`,
  },
  user: {
    users: (): string => `${APIPrefix.user}/users`,
    userDetailed: (userId: string): string => `${APIPrefix.user}/users/${userId}/`,
    userSelfCheck: (): string => `${APIPrefix.user}/users/self`,
    userGroups: (): string => `${APIPrefix.user}/groups`,
    userGroupDetailed: (groupName: string): string => `${APIPrefix.user}/groups/${groupName}/`,
    userGroupUsers: (groupName: string): string => `${APIPrefix.user}/groups/${groupName}/users`,
    UserGroupRemoveUser: (groupName: string, userId: string): string =>
      `${APIPrefix.user}/groups/${groupName}/users/${userId}`,
  },
};

export interface Principal {
  userId: string;
  userName: string;
  principalType: string;
  account: string;
  arn: string;
  sessionName?: string;
}

export interface IrisUser {
  account: string;
  apiDomain: string;
  apiKey: string;
  apiStage: string;
  apiV2: string;
  defaultApiVersion: string;
  domain?: string;
  iotEndpoint: string;
  milieu: string;
  region: string;
  roleArn: string;
  RoleName: string;
}
