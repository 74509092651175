import React, { Component } from 'react';
import {
  Paper,
  IconButton,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
  Step,
  StepLabel,
  Stepper,
  MobileStepper,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SchemaForm } from '..';
import { CloseOutlined, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Ajv from 'ajv';
import { Store, Trust } from '../../common/types';
import { AuthenticationServiceProvider, DataService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import translations from '../../assets/translations';
import RefParser from 'json-schema-ref-parser';
import fleetApiGwSchema from '../../assets/schemas/fleet-api-gw.schema.parsed.json';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

const TYPE = {
  Https: 'HTTPS',
  Static: 'STATIC',
};

type State = {
  data: { type: 'HTTPS' | 'STATIC' | undefined; [key: string]: any };
  isBackDisabled: boolean;
  isNextDisabled: boolean;
  customerId: string;
  loading: boolean;
  trusts: { label: string; value: any; description?: string }[];
  activeStep: number;
  file?: {
    name?: string;
    result: any;
  };
  schema: Omit<RefParser.JSONSchema, 'type'> & any;
};

class CreateDeviceApiGwMC extends Component<Props, State> {
  store: Store;
  state: State;
  fileUploadRef: React.RefObject<HTMLInputElement>;
  dataService!: DataService;
  ajv: any;
  maxSteps = translations.en.translation.modals.createDeviceApiGw.activePageHeader.length;
  authenticationServiceProvider?: AuthenticationServiceProvider;

  constructor(props: Props) {
    super(props);
    this.ajv = new Ajv({ allErrors: true, useDefaults: true });
    for (let i = 0; i < this.maxSteps; i += 1) {
      const schema = this.splitSchema(i);
      this.ajv.addSchema(
        {
          ...fleetApiGwSchema,
          ['$id']: `data${i}`,
          ['properties']: { ...fleetApiGwSchema.properties, ['api']: schema },
          ['definitions']: { ...fleetApiGwSchema.definitions, ['api']: schema },
        },
        `data${i}`,
      );
    }
    this.fileUploadRef = React.createRef<HTMLInputElement>();
    this.store = store.getState();
    this.state = {
      data: { type: undefined },
      customerId: '',
      isNextDisabled: false,
      isBackDisabled: false,
      loading: false,
      trusts: [],
      file: undefined,
      activeStep: 0,
      schema: {
        ...this.splitSchema(),
      },
    };
  }

  componentDidMount = async (): Promise<void> => {
    let customerId = '';
    this.authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (this.authenticationServiceProvider && this.authenticationServiceProvider.iris) {
      customerId = this.authenticationServiceProvider.iris.roleArn.split('-')[1];
    }

    this.dataService = await DataService.create();
    const trusts = await this.loadTrusts();
    this.setState({ trusts: [{ label: 'NONE', value: 'NONE' }].concat(trusts), customerId });
  };

  splitSchema = (step?: number) => {
    const schema = JSON.parse(JSON.stringify((fleetApiGwSchema as any).definitions['api']));
    Object.keys((schema as any).properties).forEach((item) => {
      if (!(schema as any).steps[step || 0].content.includes(item)) {
        delete (schema as any).properties[item];
      }
    });
    const required = (schema as any).required.filter((r: any) => (schema as any).steps[step || 0].content.includes(r));
    schema.required = required;
    return schema;
  };

  loadTrusts = async (): Promise<any[]> => {
    const { data } = await this.dataService.trust.list({
      params: { parent: '*', limit: 500 },
    });
    return data.data.map((t: Trust) => ({ label: t.trustId, description: t.description, value: t.trustId }));
  };

  getCleanData = () => {
    let host;
    let path;
    const clone = { ...this.state.data };
    if (clone.httpsApi && clone.httpsApi['url(https)']) {
      const url = clone.httpsApi['url(https)'] && clone.httpsApi['url(https)'].replace('https://', '');
      const urlPart = url && url.split('/');
      host = urlPart && urlPart[0];
      urlPart.shift();
      path = urlPart && urlPart.length > 0 ? `/${urlPart.join('/')}` : undefined;
      delete clone.httpsApi['url(https)'];
    }
    const subTopic = clone['apiId(subtopic)'];
    delete clone['apiId(subtopic)'];
    return {
      ...clone,
      enabled: true,
      subTopic,
      staticApi: clone.staticApi
        ? {
            ...clone.staticApi,
            dataType: clone.staticApi.dataType === 'json' ? 'json' : 'base64',
          }
        : undefined,
      httpsApi: host
        ? {
            ...clone.httpsApi,
            host,
            path,
            requestType: 'EXTENSION_BASED',
          }
        : undefined,
    };
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const dataToUse =
        this.state.data?.staticApi?.dataType === 'file'
          ? {
              ...this.getCleanData(),
              staticApi: { ...this.state.data.staticApi, dataType: 'base64', data: this.state.file?.result },
            }
          : this.getCleanData();
      await this.dataService.deviceApiGw.post(dataToUse);
      SnackbarUtils.success(i18next.t('success.create.deviceApiGw'));
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      console.log('error', e);
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  setValue = (data: { [key: string]: any }, keys: string[], value: any): any => {
    if (keys.length === 0) {
      data = value;
    } else if (keys.length === 1) {
      data[keys[0]] = value;
    } else {
      data[keys[0]] = this.setValue(data[keys[0]] || {}, keys.slice(1), value);
    }
    return data;
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean) => {
    let fileCheck = false;
    if (key === 'type') {
      //When user change type the data needs to be cleaned to not send incorrect parameters
      const cleanData = this.state.data;
      ['httpsApi', 'staticApi']
        .filter((t) => t !== `${(value as string).toLowerCase()}Api`)
        .forEach((cKey) => {
          delete cleanData[cKey];
        });
      this.setState({ data: cleanData });
    }
    const data = this.setValue(this.state.data || {}, key === undefined ? [] : key.split('.'), value);
    const ajvIsValid = this.ajv.validate(`data${this.state.activeStep}`, { api: data });
    if (this.state.data.staticApi && this.state.activeStep === 2) {
      const hasFile =
        this.state.data.staticApi && this.state.data?.staticApi?.dataType === 'file' && this.state.file !== undefined;
      fileCheck =
        this.state.activeStep === 2
          ? this.state.data.staticApi && this.state.data?.staticApi?.dataType === 'file' && !hasFile
          : !(!!Object.keys(this.state.data).length && ajvIsValid);
    }
    this.setState({
      data: data || {},
      isNextDisabled:
        this.state.data.staticApi && this.state.activeStep === 2 && !!Object.keys(data).length && ajvIsValid && isValid
          ? fileCheck
          : !(!!Object.keys(data).length && ajvIsValid && isValid),
      isBackDisabled: !isValid,
    });
  };

  handleFileSelect = (e: any): void => {
    e.preventDefault();
    const data = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      if (e && e.target && e.target.result) {
        const result = e.target!.result.split('base64,')[1];
        this.setState({ file: { result, name: data.name }, isNextDisabled: false });
      }
    };
    if (data) {
      reader.readAsDataURL(data);
    }
  };

  updateStep = (direction: number) => {
    const newStep = this.state.activeStep + direction;
    const ajvIsValid = this.ajv.validate(`data${newStep}`, {
      ['api']: this.state.data,
    });
    this.setState({
      activeStep: newStep,
      schema: this.splitSchema(newStep),
      isNextDisabled: newStep === 0 ? false : !(!!Object.keys(this.state.data).length && ajvIsValid),
      isBackDisabled: newStep === this.maxSteps && false,
    });
  };

  reviewData = (index: string, type: 'HTTPS' | 'STATIC' | undefined): string | string[] => {
    const { data, customerId } = this.state;
    const id = data['apiId(subtopic)'];
    if (type === TYPE.Https) {
      switch (index) {
        case 'common0':
          return type;
        case 'common1':
          return id;
        case 'common2':
          return `${data.httpsApi['url(https)']}`;
        case '01':
          return `${customerId}/req/<groupId>/<deviceId>/${id}/state.j`;
        case '02':
          return '{"state": 0}';
        case '11':
          return 'POST';
        case '12':
          if (data.httpsApi) {
            const { parameterMapping } = data.httpsApi;
            type ParameterMapping = Record<string, string>;
            const queryKeys = Object.entries(parameterMapping as ParameterMapping)
              .filter(([, val]) => {
                return !!(val === 'query');
              })
              .map(([key]) => key);
            const queryParameters: string[] = queryKeys.map((qp, i) => {
              if (i === queryKeys.length - 1 && i !== 0) {
                return `${qp}=<${qp}>`;
              } else {
                return `${i === 0 ? '?' : ''}${qp}=<${qp}>${i !== queryKeys.length - 1 ? '&' : ''}`;
              }
            });
            return `${data.httpsApi['url(https)']}/state${queryParameters.join('')}`;
          }
          return '';
        case '13':
          if (data.httpsApi) {
            const { parameterMapping } = data.httpsApi;
            type ParameterMapping = Record<string, string>;
            const constantHeader = [
              'Authorization: Bearer <JWT token>',
              'Content-Type: application/json',
              'x-topic-extension: j',
            ];
            const headerKeys = Object.entries(parameterMapping as ParameterMapping)
              .filter(([, val]) => {
                return !!(val === 'header');
              })
              .map(([key]) => `${key}: <${key}>`);
            return constantHeader.concat(headerKeys);
          }
          return '';
        case '14':
          return '{"state": 0}';
        case '21':
          if (data.httpsApi) {
            const constantHeader = ['x-topic-extension: j'];
            return constantHeader;
          }
          return '';
        case '22':
          return '{"message": "ETA 15 min"}';
        case '31':
          return `${customerId}/resp/<groupId>/<deviceId>/${id}/state/data.j`;
        case '32':
          return '{"data": {"message": "ETA 15 min"}} ';
        case '42':
          return `${customerId}/resp/<groupId>/<deviceId>/${id}/state/error.j`;
        case '43':
          return '{"error":{"errorCode": <code>, "message": <message>}}';
        default:
          return '';
      }
    } else if (type === TYPE.Static) {
      switch (index) {
        case 'common0':
          return type;
        case 'common1':
          return id;
        case 'common2':
          return 'empty';
        case '00':
          return ' ';
        case '01':
          if (data.staticApi) {
            return `${customerId}/req/<groupId>/<deviceId>/${id}/state${
              (data.staticApi.responseExtension && !data.staticApi.responseExtension.startsWith('.') && '.') || ''
            }${data.staticApi.responseExtension || ''}`;
          }
          return '';
        case '02':
          if (data.staticApi) {
            return `${JSON.stringify(data.staticApi.data) || '{}'}`;
          }
          return '';
        case '11':
          if (data.staticApi) {
            return `${customerId}/req/<groupId>/<deviceId>/${id}/state/data${
              (data.staticApi.responseExtension && !data.staticApi.responseExtension.startsWith('.') && '.') || ''
            }${data.staticApi.responseExtension || ''}`;
          }
          return '';
        case '12':
          if (data.staticApi) {
            if (data.staticApi.dataType === 'json') {
              return ' <abertary payload>';
            } else if (this.state.file) {
              return ' <user selected file>';
            }
          }
          return '';
        default:
          return '';
      }
    } else {
      return '';
    }
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper} style={{ padding: '64px 0 20px 0' }}>
        <IconButton
          sx={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container sx={{ flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
          <Grid
            container
            spacing={4}
            sx={{ flexDirection: 'column', flexWrap: 'nowrap', flex: '1 0 auto', height: '100%' }}
          >
            <Grid item xs={12} style={{ flex: 1, margin: '0 32px' }}>
              <Typography variant="h3">
                {translations.en.translation.modals.createDeviceApiGw.activePageHeader[this.state.activeStep]}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ flex: 1, minHeight: '27%', margin: '0 32px', overflowY: 'auto' }}>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                {this.state.activeStep === 2 && this.state.data.type === 'STATIC'
                  ? translations.en.translation.modals.createDeviceApiGw.activePageDescriptionStatic
                  : translations.en.translation.modals.createDeviceApiGw.activePageDescription[this.state.activeStep]}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {this.state.activeStep === this.maxSteps - 1 &&
                  translations.en.translation.modals.createDeviceApiGw.reviewCommon.map((step: string, i: number) => (
                    <div key={`reviewCommon${i}}`}>
                      {this.reviewData(`common${i}`, this.state.data.type) && (
                        <div style={{ display: 'grid', gridTemplateColumns: '17% 83%', lineHeight: 1 }}>
                          {this.reviewData(`common${i}`, this.state.data.type) === 'empty' ? (
                            <>
                              <Typography variant="body1" style={{ fontWeight: '500' }}>
                                {' '}
                                &nbsp;
                              </Typography>
                              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
                                {' '}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="body1" style={{ fontWeight: '500' }}>
                                {`${step}`}&nbsp;
                              </Typography>
                              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
                                {this.reviewData(`common${i}`, this.state.data.type)}
                              </Typography>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </Box>
            </Grid>
            {this.state.activeStep === 0 ? (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: 400 }}>
                  <Stepper alternativeLabel orientation="horizontal" activeStep={-1}>
                    {translations.en.translation.modals.createDeviceApiGw.activePageHeader.slice(1).map((step) => (
                      <Step key={step}>
                        <StepLabel>{step}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>
            ) : this.state.activeStep === this.maxSteps - 1 ? (
              <Grid
                item
                xs={12}
                sx={{ overflow: 'auto', maxHeight: '100%', background: '#f9f9f9' }}
                style={{ paddingTop: 0, paddingLeft: '10px', marginLeft: '32px', lineBreak: 'strict' }}
              >
                <Typography
                  variant="body1"
                  style={{ position: 'absolute', fontWeight: '500', top: '31%', left: '32px' }}
                >
                  {'Example:'}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                  }}
                  style={{ paddingLeft: '20px', paddingBottom: '15px', paddingRight: '20px' }}
                >
                  {this.state.data.type &&
                    Object.keys(
                      translations.en.translation.modals.createDeviceApiGw[`review${this.state.data.type}`],
                    ).map((k, index) =>
                      //@ts-ignore
                      translations.en.translation.modals.createDeviceApiGw[
                        `review${this.state.data.type as 'STATIC' | 'HTTPS'}`
                      ][k].map((step: string, i: number) => (
                        <div key={`review${i}${index}`}>
                          {i === 0 ? (
                            <h4 style={{ margin: '15px 0 5px', fontWeight: '500', fontSize: '12px' }}>{step}</h4>
                          ) : this.reviewData(`${index}${i}`, this.state.data.type) &&
                            !Array.isArray(this.reviewData(`${index}${i}`, this.state.data.type)) ? (
                            <div style={{ display: 'grid', gridTemplateColumns: '17% 83%', lineHeight: 1.5 }}>
                              <code style={{ margin: '0', fontSize: '12px', fontWeight: 400 }}>{`${step}`}&nbsp;</code>
                              <code
                                style={{
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  overflowWrap: 'anywhere',
                                }}
                              >
                                {this.reviewData(`${index}${i}`, this.state.data.type)}
                              </code>
                            </div>
                          ) : (
                            <div>
                              {this.reviewData(`${index}${i}`, this.state.data.type) &&
                              Array.isArray(this.reviewData(`${index}${i}`, this.state.data.type)) ? (
                                (this.reviewData(`${index}${i}`, this.state.data.type) as string[]).map((data, i) => (
                                  <div
                                    key={`key${index}${i}`}
                                    style={{ display: 'grid', gridTemplateColumns: '17% 83%', lineHeight: 1.5 }}
                                  >
                                    {i === 0 ? (
                                      <code style={{ margin: '0', fontSize: '12px', fontWeight: 400 }}>
                                        {`${step}`}&nbsp;
                                      </code>
                                    ) : (
                                      <code> </code>
                                    )}
                                    <code style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                      {data}
                                    </code>
                                  </div>
                                ))
                              ) : (
                                <h5 style={{ margin: '0 0 10px', fontWeight: 300, fontSize: '12px' }}>
                                  {`${step}`}&nbsp;
                                </h5>
                              )}
                            </div>
                          )}
                        </div>
                      )),
                    )}
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ overflow: 'auto', maxHeight: '100%', margin: '0 32px' }}>
                <SchemaForm
                  state="CREATE"
                  SchemaFormInputProps={{
                    trustId: {
                      options: this.state.trusts,
                    },
                  }}
                  data={this.state.schema}
                  grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
                  defaultValue={this.state.data}
                  onChange={this.handleUpdate}
                />
              </Grid>
            )}
            {this.state.data &&
              this.state.data.staticApi &&
              this.state.data?.staticApi?.dataType === 'file' &&
              this.state.activeStep === 2 && (
                <Grid item xs={12} style={{ margin: '0 32px', paddingTop: 0 }}>
                  <Button variant="outlined" fullWidth onClick={() => this.fileUploadRef.current!.click()}>
                    <input
                      id="file-upload"
                      ref={this.fileUploadRef}
                      type="file"
                      accept={undefined}
                      style={{
                        display: 'none',
                      }}
                      onChange={this.handleFileSelect}
                    />
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        <Trans>{this.state.file ? 'form.changeFile' : 'form.selectFile'}</Trans>
                      </Grid>
                      <Grid item xs={12}>
                        <pre style={{ display: 'inline-block', margin: 0 }}>
                          {this.state.file?.name ? this.state.file.name : i18next.t('description.noFileSelected')}
                        </pre>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              )}
          </Grid>
          <Grid item sx={{ width: '100%', padding: '0 32px' }}>
            <MobileStepper
              variant="dots"
              steps={this.maxSteps}
              position="static"
              activeStep={this.state.activeStep}
              nextButton={
                <Grid sx={{ minWidth: '85px', display: 'flex', justifyContent: 'flex-end' }}>
                  {this.state.activeStep === this.maxSteps - 1 ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={this.state.isNextDisabled}
                      onClick={this.create}
                    >
                      {this.state.loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <Trans>action.create</Trans>
                      )}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      style={{ paddingLeft: '24px' }}
                      onClick={() => this.updateStep(1)}
                      disabled={this.state.isNextDisabled}
                    >
                      {'NEXT'}
                      {<KeyboardArrowRight />}
                    </Button>
                  )}
                </Grid>
              }
              backButton={
                <Grid sx={{ minWidth: '85px' }}>
                  {this.state.activeStep !== 0 ? (
                    <Button
                      size="small"
                      style={{ paddingRight: '24px' }}
                      onClick={() => this.updateStep(-1)}
                      disabled={this.state.activeStep === 0 || this.state.isBackDisabled}
                    >
                      {<KeyboardArrowLeft />}
                      BACK
                    </Button>
                  ) : (
                    <Button size="small" disabled={true}></Button>
                  )}
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateDeviceApiGwMC);
