import { ListFilterProperty } from '../types';

export const deviceFilterProperty: { value: string; label: string }[] = [
  { value: 'deviceId', label: 'form.deviceId' },
  { value: 'certificateId', label: 'form.certificateId' },
  { value: 'certificateCN', label: 'form.certificateCN' },
  { value: 'name', label: 'form.name' },
  { value: 'imei', label: 'form.imei' },
  { value: 'imsi', label: 'form.imsi' },
];

// Used for filter component in list views
export const listFilter: { [key: string]: ListFilterProperty[] } = {
  deviceGroup: [
    { property: 'groupId', displayName: 'form.groupId', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'parent', displayName: 'form.parent', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'groupType', displayName: 'form.groupType', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'policyType', displayName: 'form.policyType', type: 'string', operators: ['startsWith', 'equal'] },
  ],
  device: [
    { property: 'deviceId', displayName: 'form.deviceId', type: 'string', operators: ['startsWith', 'equal'] },
    {
      property: 'certificateId',
      displayName: 'form.certificateId',
      type: 'string',
      operators: ['startsWith', 'equal'],
    },
    {
      property: 'certificateCN',
      displayName: 'form.certificateCN',
      type: 'string',
      operators: ['startsWith', 'equal'],
    },
    {
      property: 'state',
      displayName: 'form.state',
      type: 'selector',
      operators: ['equal'],
      options: ['CREATED', 'ACTIVATED', 'DEACTIVATED', 'REVOKED'],
    },
    { property: 'name', displayName: 'form.name', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'type', displayName: 'form.type', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'productId', displayName: 'form.productId', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'imei', displayName: 'form.imei', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'imsi', displayName: 'form.imsi', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'lastSeen', displayName: 'form.lastSeen', type: 'date', operators: ['between', 'betweenAbsolute'] },
    { property: 'created', displayName: 'form.created', type: 'date', operators: ['between', 'betweenAbsolute'] },
    { property: 'activated', displayName: 'form.activated', type: 'date', operators: ['between', 'betweenAbsolute'] },
    { property: 'query', displayName: 'form.query', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'groupId', displayName: 'form.groupId', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'generation', displayName: 'form.generation', type: 'string', operators: ['equal'] },
  ],
  shadowTemplate: [
    { property: 'templateId', displayName: 'form.templateId', type: 'string', operators: ['startsWith', 'equal'] },
  ],
  user: [
    { property: 'name', displayName: 'form.name', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'email', displayName: 'form.email', type: 'string', operators: ['startsWith', 'equal'] },
  ],
  firmwareProduct: [
    { property: 'productId', displayName: 'form.productId', type: 'string', operators: ['startsWith', 'equal'] },
  ],
  firmwarePackage: [
    { property: 'productId', displayName: 'form.productId', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'type', displayName: 'form.type', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'version', displayName: 'form.version', type: 'string', operators: ['startsWith', 'equal'] },
  ],
  firmwareRelease: [
    { property: 'releaseId', displayName: 'form.releaseId', type: 'string', operators: ['startsWith', 'equal'] },
    { property: 'productId', displayName: 'form.productId', type: 'string', operators: ['equal'] },
    { property: 'toVersion', displayName: 'form.toVersion', type: 'string', operators: ['equal'] },
    { property: 'type', displayName: 'form.type', type: 'string', operators: ['equal'] },
    { property: 'enabled', displayName: 'form.enabled', type: 'boolean', operators: ['equal'] },
  ],
};
