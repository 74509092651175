import { getTheme } from '../../../common/theme';

export const BaseModalStyles = () => {
  const theme = getTheme();
  return {
    paper: {
      position: 'relative' as const,
      height: '100%',
      outline: 'none',
      backgroundColor: '#fff',
      padding: theme.spacing(8, 4, 4, 4),
      'overflow-x': 'hidden',
      'overflow-y': 'auto',
      [theme.breakpoints.down('md')]: {
        height: '100vh',
        width: '100%',
      },
      [theme.breakpoints.only('md')]: {
        height: '100vh',
        width: '40%',
        marginLeft: '60%',
      },
      [theme.breakpoints.only('lg')]: {
        height: '100vh',
        width: '30%',
        marginLeft: '70%',
      },
      [theme.breakpoints.only('xl')]: {
        height: '100vh',
        width: '20%',
        marginLeft: '80%',
      },
    },
    closeButton: {
      position: 'absolute' as const,
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  };
};
