import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import { RouteComponentProps } from 'react-router-dom';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import {
  FilterCombination,
  AwsRequestConfig,
  ListFilterProperty,
  ListTableProperty,
  ListTableAction,
} from '../../common/types';
import { FilterService, AuthenticationServiceProvider } from '../../services';
import { AxiosResponse } from 'axios';
import { Dialog, ListFilter, ListTable, TabsHeader } from '../../components';
import { AccessControl, AccessControlSectionAction } from '../../common/permissions';
import { getTheme } from '../../common/theme';

export const ListBaseStyles = () => {
  const theme = getTheme();
  return {
    root: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
      },
    },
    fabContainer: {
      float: 'right' as const,
      marginRight: theme.spacing(4),
      width: 56,
    },
    fab: {
      position: 'fixed' as const,
      bottom: theme.spacing(4),
    },
  };
};

type _ListBaseProps = RouteComponentProps & WithStyles<typeof ListBaseStyles>;
export type ListBaseProps<P = unknown> = _ListBaseProps & Omit<P, keyof _ListBaseProps>;

type _ListBaseState = {
  speedDialOpen?: boolean;
  dialogOpen?: string | undefined;
  initLoaded: boolean;
  loading: boolean;
  items: any[];
  params?: { [key: string]: string };
  nextToken?: string;
  activeTabIndex: number;
  predefinedFilters?: FilterCombination[];
  properties: string[];
  count?: number;
};
export type ListBaseState<S = unknown> = _ListBaseState & Omit<S, keyof _ListBaseState>;

export class ListBase<P = unknown, S = unknown> extends React.Component<ListBaseProps<P>, ListBaseState<S>> {
  state!: ListBaseState<S>;
  title!: string;
  icon!: React.ReactNode;
  idKey!: string;
  filterConfig?: {
    listFilter: ListFilterProperty[];
    availableProperties: string[];
  };
  tableConfig!: {
    tableProperties: { [key: string]: ListTableProperty };
    emptyTitle: string;
    rowActions?: ListTableAction[];
    disableRowHover?: boolean;
  };
  fabConfig?: { title: string; action: () => void; icon: React.ReactNode; tryAccessRight: [string, string] }[];
  dialogConfig?: {
    title: string;
    description: string;
    continueTitle: string;
    onClose: () => void;
    onContinue: () => void;
  };
  accessControlSection!: string;
  private _accessControl?: AccessControl;
  filterService?: FilterService;
  isUnmounted = false;
  service!: any;

  constructor(props: any) {
    super(props);
    AuthenticationServiceProvider.createFromCache().then((service) => {
      this._accessControl = new AccessControl(service!.getRole()!);
      this.forceUpdate();
    });
  }

  componentWillUnmount = (): void => {
    this.isUnmounted = false;
  };

  getUrlPathForItem?: (id: string) => string;

  accessControl = (section: string, action: AccessControlSectionAction): boolean => {
    if (!this._accessControl) return false;
    return this._accessControl!.access(section)[action]();
  };

  list!: (
    config?: Partial<AwsRequestConfig>,
  ) => Promise<
    AxiosResponse<{
      data: any[];
      nextToken?: string | undefined;
      count?: number;
    }>
  >;

  reloadList: () => void = () =>
    this.setState({ initLoaded: false, nextToken: undefined } as any, () => this.setState({ initLoaded: true } as any));

  loadData = async (clearTable = false): Promise<void> => {
    if (this.state.params === undefined && this.filterConfig) return;
    this.setState({ loading: true } as any);
    const params = { ...this.state.params, limit: 50 };
    if (this.state.nextToken) (params as any)['nextToken'] = this.state.nextToken;
    try {
      const response = (await this.list({ params })) as any;
      if (!this.isUnmounted)
        this.setState({
          loading: false,
          items: (clearTable ? [] : this.state.items.slice()).concat(response.data.data),
          nextToken: response.data.nextToken,
          count: (response.data as any).count,
        } as any);
    } catch (e) {
      if (!this.isUnmounted) this.setState({ loading: false } as any);
      throw e;
    }
  };

  setFilterCombination = ({
    properties,
    params,
    callback,
  }: {
    properties?: string;
    params?: { [key: string]: string };
    callback?: () => void;
  }): void => {
    if (params)
      this.setState(
        {
          ...this.state,
          properties: (properties && properties.split(',')) || this.state.properties,
          params: params || this.state.params,
          nextToken: undefined,
        },
        callback,
      );
  };

  clickRow = (row: any): (() => Promise<void>) => async (): Promise<void> => {
    if (this.getUrlPathForItem) this.props.history.push(this.getUrlPathForItem(row[this.idKey]));
  };

  changeTab = (activeTabIndex: number): Promise<void> => {
    if (activeTabIndex === this.state.activeTabIndex) return Promise.resolve();
    return new Promise((resolve) => this.setState({ activeTabIndex } as any, resolve));
  };

  renderFilter = (): React.ReactNode => {
    if (!this.filterService || !this.state.predefinedFilters || !this.filterConfig) return null;
    return (
      <ListFilter
        combinations={{
          predefinedFilters: this.state.predefinedFilters,
          selectedPredefinedFilter: this.state.activeTabIndex,
          createFilter: this.filterService!.createFilter,
          duplicateFilter: this.filterService!.createTemporaryFilter,
          updateFilter: this.filterService!.updateFilter,
          deleteFilter: this.filterService!.deleteFilter,
          cancelTemporary: this.filterService!.cancelTemporary,
        }}
        countFound={this.state.count}
        availableFilters={this.filterConfig!.listFilter!}
        availableProperties={this.filterConfig!.availableProperties}
        setFilterCombination={this.setFilterCombination}
      />
    );
  };

  renderTable = (): React.ReactNode => (
    <ListTable
      InfinityScrollProps={{
        initLoaded: this.state.initLoaded,
        nextToken: this.state.nextToken,
        hash: JSON.stringify(
          Array.from(new URLSearchParams(this.state.params).entries()).filter(
            ([key]) => !['queryLabel', 'queryTableProperties'].includes(key),
          ),
        ),
        loadFunc: this.loadData,
      }}
      idKey={this.idKey}
      TableProps={{ disableBackgroundPaper: true }}
      loading={this.state.loading}
      emptyTitle={this.tableConfig.emptyTitle}
      disableRowHover={this.tableConfig?.disableRowHover}
      rowActions={this.tableConfig.rowActions}
      properties={
        this.filterConfig
          ? Object.entries(this.tableConfig.tableProperties).reduce(
              (acc: any, [key, value]: [string, ListTableProperty]) => {
                if (this.state.properties.includes(key)) acc[key] = value;
                return acc;
              },
              {},
            )
          : this.tableConfig.tableProperties
      }
      rows={this.state.items}
      clickRow={this.clickRow}
    />
  );

  renderFAB = (): React.ReactNode => {
    if (!this.fabConfig) return null;
    const fabConfig = this.fabConfig.filter((conf) =>
      this.accessControl(conf.tryAccessRight[0], conf.tryAccessRight[1] as AccessControlSectionAction),
    );
    if (fabConfig.length === 1) {
      return (
        <div className={this.props.classes.fabContainer}>
          <Tooltip title={fabConfig[0].title} placement="left">
            <Fab
              aria-label={fabConfig[0].title}
              className={this.props.classes.fab}
              color="secondary"
              onClick={fabConfig[0].action}
            >
              <>{fabConfig[0].icon}</>
            </Fab>
          </Tooltip>
        </div>
      );
    }
    if (fabConfig.length > 1) {
      return (
        <div className={this.props.classes.fabContainer}>
          <SpeedDial
            ariaLabel=""
            className={this.props.classes.fab}
            FabProps={{ color: 'secondary' }}
            icon={<SpeedDialIcon />}
            onClose={() => this.setState({ speedDialOpen: false } as any)}
            onOpen={() => this.setState({ speedDialOpen: true } as any)}
            open={!!this.state.speedDialOpen}
            direction="up"
          >
            {fabConfig!.map((action) => (
              <SpeedDialAction
                key={action.title}
                icon={action.icon}
                tooltipTitle={action.title}
                onClick={() => {
                  action.action();
                  this.setState({ speedDialOpen: false } as any);
                }}
              />
            ))}
          </SpeedDial>
        </div>
      );
    }
    return null;
  };

  renderDialog = (): React.ReactNode => (
    <Dialog
      open={true}
      title={this.dialogConfig!.title}
      description={this.dialogConfig!.description}
      continueTitle={this.dialogConfig!.continueTitle}
      onClose={() => this.dialogConfig!.onClose()}
      onContinue={() => this.dialogConfig!.onContinue()}
    />
  );

  render = (): React.ReactNode => (
    <>
      <div className={this.props.classes.root}>
        <TabsHeader
          title={this.title}
          icon={this.icon}
          tabIndex={this.state.activeTabIndex}
          onChangeTab={this.changeTab}
          tabs={
            this.state.predefinedFilters
              ? this.state.predefinedFilters.map((pf: any) => (pf.temporary ? pf.label + '*' : pf.label))
              : undefined
          }
        >
          {this.renderFilter()}
          {this.renderTable()}
        </TabsHeader>
      </div>
      {this.state.dialogOpen && this.dialogConfig && this.renderDialog()}
      {this.renderFAB()}
    </>
  );
}
