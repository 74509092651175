import React, { Component } from 'react';
import { SwipeableDrawer, Toolbar, IconButton, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { Nav } from '.';
import { Role, RouteType, Store } from '../../common/types';
import { storeSetLeftNavOpen } from '../../store/actions/common';
import { appBarHeight, drawerWidth } from '../../common/constants';
import { getTheme } from '../../common/theme';

export const styles = () => {
  const theme = getTheme();
  return {
    swipeableDrawerRoot: {
      'overflow-y': 'auto' as const,
    },
    swipeableDrawerPaper: {
      [theme.breakpoints.only('xs')]: {
        width: '80%',
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        width: drawerWidth,
      },
    },
    swipeableDrawer: {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      padding: theme.spacing(0, 2, 2, 2),
    },
    container: {
      height: `calc(100% - ${appBarHeight}px)`,
    },
  };
};

type Props = WithStyles<any> & {
  role: Role;
  routes: RouteType[];
  open: boolean;
  setLeftNavOpen: (open: boolean) => void;
};

class NavLeft extends Component<Props> {
  render = (): React.ReactNode => (
    <SwipeableDrawer
      classes={{ root: this.props.classes.swipeableDrawerRoot, paper: this.props.classes.swipeableDrawerPaper }}
      open={this.props.open}
      onOpen={() => this.props.setLeftNavOpen(true)}
      onClose={() => this.props.setLeftNavOpen(false)}
    >
      <div className={this.props.classes.swipeableDrawer} role="presentation">
        <Toolbar variant={appBarHeight === 64 ? 'regular' : 'dense'} disableGutters>
          <IconButton color="primary" onClick={() => this.props.setLeftNavOpen(false)} aria-label="close" size="large">
            <CloseOutlined />
          </IconButton>
          <Typography color="secondary" variant="subtitle2">
            {window.location.host.split('.')[0] !== 'www'
              ? window.location.host.split('.')[0]
              : window.location.host.split('.')[1]}
          </Typography>
        </Toolbar>
        <Nav isDrawer role={this.props.role} routes={this.props.routes} className={this.props.classes.container} />
      </div>
    </SwipeableDrawer>
  );
}

const mapStateToProps = ({ commonStore }: Store) => ({
  open: commonStore.leftNavOpen,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLeftNavOpen: (open: boolean) => dispatch(storeSetLeftNavOpen(open)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NavLeft));
