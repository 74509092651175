import { IrisUser } from '../../common/types';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { CognitoServiceProvider } from './CognitoServiceProvider';
import { NodeCallback } from 'amazon-cognito-identity-js';

export enum MFA_SETUP {
  CONFIRM = 'CONFIRM',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
  QR = 'QR',
  VALIDATE = 'VALIDATE',
}

export type ServiceType = CognitoServiceProvider;

export type SecurityCallback = (stage: MFA_SETUP, res: boolean, code?: string) => void;

export enum CognitoSessionChallange {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  RESET_REQUIRE = 'RESET_REQUIRE',
  MFA_REQUIRE = 'MFA_REQUIRE',
}

export type SessionOrChallange = AmazonCognitoIdentity.CognitoUserSession | CognitoSessionChallange;

export interface _Authentication {
  // Data
  iris?: IrisUser;

  // Getters
  credentials?: AWS.Credentials;
  userSession?: AmazonCognitoIdentity.CognitoUserSession;

  // Functions
  authenticate: (username: string, password: string) => Promise<SessionOrChallange>;
  refresh: () => Promise<void>;
  verifyForgotPassword: (password: string, verificationCode: string) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  username: () => Promise<string>;
  //Cognito specific
  userId: () => string | undefined;
  newPasswordChallange: (password: string) => Promise<AmazonCognitoIdentity.CognitoUserSession>;
  changePassword: (oldPassword: string, newPassword: string, callback: NodeCallback<Error, 'SUCCESS'>) => void;
  setUpMfa: (
    stage: MFA_SETUP,
    callback: (stage: MFA_SETUP, res: boolean, code?: string, userName?: string) => void,
    validationCode?: string,
    username?: string,
    password?: string,
  ) => void;
}

/**
 * Parses describe from in object returned from getRoleWithIAM
 */
export const parseIrisUserData = (description: string, arn: string): IrisUser => {
  const iris = description.split(', ').reduce((iris: Partial<IrisUser>, prop: string) => {
    if (!prop) return iris;
    const [name, value] = prop.split('=');
    (iris as any)[name] = value as string;
    return iris;
  }, {}) as IrisUser;
  iris.roleArn = arn;
  return iris;
};
