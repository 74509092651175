import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment, { Moment } from 'moment';
import i18next from 'i18next';
import { ListTable, TabPanel } from '../../../../components';
import { DeviceConnection } from '../../../../common/types';
import { tableProperties, TableNames } from '../../../../common/constants';
import { getDefaultTableProperties, DeviceService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';

type Props = RouteComponentProps<{ deviceId: string }>;

type State = {
  connections: DeviceConnection[];
};

class ConnectionTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = { loading: false, editing: false, connections: [] };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    DeviceService.create().then((service) => (this.service = service));
  }

  reload = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const response = await this.service.device.getConnections(this.props.match.params.deviceId);
      this.setState({ connections: response.data.data, loading: false });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.fetch.connections'),
      );
      this.setState({ loading: false });
    }
  };

  calculateDuration = (start: Moment, end: Moment): string => {
    const duration = moment.duration(end.diff(start));
    const strArr = [];
    if (duration.days()) strArr.push(`${duration.days()}d`);
    if (duration.hours()) strArr.push(`${duration.hours()}h`);
    if (duration.minutes()) strArr.push(`${duration.minutes()}m`);
    strArr.push(`${duration.seconds()}s`);
    return strArr.join(' ');
  };

  renderContent = (): React.ReactNode => (
    <ListTable
      disableRowHover
      TableProps={{ disableBackgroundPaper: true }}
      loading={this.state.loading}
      emptyTitle="form.empty.connections"
      properties={getDefaultTableProperties(tableProperties(TableNames.deviceConnections))}
      rows={this.state.connections.map((c: DeviceConnection) => ({
        ...c,
        status: c.disconnected
          ? i18next.t('form.disconnected').toUpperCase()
          : i18next.t('form.connected').toUpperCase(),
        when: c.disconnected ? moment(c.disconnected).fromNow() : moment(c.connected || c.disconnected).fromNow(),
        duration: this.calculateDuration(moment(c.connected || c.disconnected), moment(c.disconnected)),
      }))}
    />
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.state.loading,
        },
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

export default withRouter(ConnectionTab);
