import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { SchemaForm, PageLoader, TabPanel, SnackbarUtils } from '../../../../components';
import { DeviceService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import schemaUtils from '../../../../common/schemaUtils';
import { ShadowTemplate, Trigger, Action, Attached, Detached } from '../../../../common/types';

type Props = {
  schema: Record<string, any>;
  schemaKey: string;
  customAction?: React.ReactNode;
  accessControlSection: string;
  disableEdit?: boolean;
  reload: () => Promise<void>;
  triggers: Trigger[];
} & RouteComponentProps<{ groupId: string }>;

type State = {
  editedItemAttach: { [key: string]: any };
  editedItemDetach: { [key: string]: any };
  saveDisabled: boolean;
  shadowTemplates: string[];
  defaultAttached: Record<string, any> | undefined;
  defaultDetached: Record<string, any> | undefined;
};

export class TriggersTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    editedItemAttach: {},
    editedItemDetach: {},
    saveDisabled: true,
    shadowTemplates: [],
    defaultAttached: undefined,
    defaultDetached: undefined,
  };
  DeviceService!: DeviceService;

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    this.lastReload = this.time();
  }

  reload = this.props.reload;

  componentDidMount = async (): Promise<void> => {
    DeviceService.create().then(async (service) => {
      const { data } = await service.shadowTemplate.list({
        params: { parent: '*', limit: 500 },
      });
      this.setState({
        shadowTemplates: data
          ? data.data.map((s: ShadowTemplate) => {
              return s.templateId;
            })
          : [],
      });

      return (this.service = service);
    });
  };

  componentDidUpdate = async () => {
    if (!this.state.defaultAttached) {
      await this.getDefault(Attached);
    } else if (!this.state.defaultDetached) {
      await this.getDefault(Detached);
    }
  };

  update = async (): Promise<void> => {
    this.setState({ loading: true });
    const { editedItemAttach, editedItemDetach } = this.state;
    const attached = this.getDefaultValue(Attached);
    const detached = this.getDefaultValue(Detached);
    try {
      if (Object.keys(editedItemAttach).length > 0 && attached) {
        let attachParameterMapping;
        if ((Object.keys(editedItemAttach).length === 1, 'enabled' in editedItemAttach && attached)) {
          attachParameterMapping = attached?.shadowTemplateAction?.parameterSubstitutionMapping
            ? attached?.shadowTemplateAction?.parameterSubstitutionMapping
            : {};
        } else {
          attachParameterMapping = editedItemAttach?.shadowTemplateAction?.parameterSubstitutionMapping
            ? Object.keys(editedItemAttach?.shadowTemplateAction.parameterSubstitutionMapping).reduce(
                (acc: Record<string, any>, key) => {
                  acc[key] =
                    editedItemAttach?.shadowTemplateAction.parameterSubstitutionMapping[key] === 'null'
                      ? null
                      : editedItemAttach?.shadowTemplateAction.parameterSubstitutionMapping[key];
                  return acc;
                },
                {},
              )
            : {};
        }
        await this.service.deviceGroup.updateTrigger(
          {
            ...editedItemAttach,
            shadowTemplateAction: {
              ...editedItemAttach.shadowTemplateAction,
              parameterSubstitutionMapping: attachParameterMapping,
            },
          },
          attached.triggerId,
        );
      }

      if (Object.keys(this.state.editedItemDetach).length > 0 && detached) {
        let detachedParameterMapping;
        if ((Object.keys(editedItemAttach).length === 1, 'enabled' in editedItemDetach && detached)) {
          detachedParameterMapping = detached?.shadowTemplateAction?.parameterSubstitutionMapping
            ? detached?.shadowTemplateAction?.parameterSubstitutionMapping
            : {};
        } else {
          detachedParameterMapping = editedItemDetach?.shadowTemplateAction?.parameterSubstitutionMapping
            ? Object.keys(editedItemDetach?.shadowTemplateAction.parameterSubstitutionMapping).reduce(
                (acc: Record<string, any>, key) => {
                  acc[key] =
                    editedItemDetach?.shadowTemplateAction.parameterSubstitutionMapping[key] === 'null'
                      ? null
                      : editedItemDetach?.shadowTemplateAction.parameterSubstitutionMapping[key];
                  return acc;
                },
                {},
              )
            : {};
        }
        await this.service.deviceGroup.updateTrigger(
          {
            ...editedItemDetach,
            shadowTemplateAction: {
              ...editedItemDetach.shadowTemplateAction,
              parameterSubstitutionMapping: detachedParameterMapping,
            },
          },
          detached.triggerId,
        );
      }
    } catch (e) {
      console.log('e', e);
      SnackbarUtils.error(i18next.t('error.triggers'));
    } finally {
      this.reload().then(() => {
        SnackbarUtils.success(i18next.t('success.update.triggers.triggers'));
      });
    }
    this.setState({ loading: false, editing: false });
  };

  getDeepKeys = (obj: Record<string, any>, propStr = ''): Record<string, string> => {
    let parsed: Record<string, string> = {};
    for (const [key, val] of Object.entries(obj)) {
      const nestedKey = propStr + (propStr ? '.' : '') + key;
      if (typeof val === 'object' && val !== null) {
        const nested = this.getDeepKeys(val, nestedKey);
        parsed = {
          ...parsed,
          ...nested,
        };
      } else {
        parsed[nestedKey] = val;
      }
    }
    return parsed;
  };

  setParameterSubstitutionMapping = async (
    editedItem: Record<string, Record<string, any>>,
  ): Promise<{ shadowTemplateAction: { parameterSubstitutionMapping: Record<string, string> } }> => {
    let parameterSubstitutionMapping;
    await this.getTemplate(editedItem.shadowTemplateAction.shadowTemplateId).then((template) => {
      parameterSubstitutionMapping = template;
    });
    const newObj = {
      ...editedItem,
      shadowTemplateAction: {
        ...editedItem.shadowTemplateAction,
        parameterSubstitutionMapping: parameterSubstitutionMapping || {},
      },
    };
    return newObj;
  };

  handleUpdate = async (
    key: string | undefined,
    value: any,
    isValid: boolean,
    type: 'Attached' | 'Detached',
  ): Promise<void> => {
    const data = type === 'Attached' ? this.state.editedItemAttach : this.state.editedItemDetach;
    let editedItem = schemaUtils.setValue(data, key === undefined ? [] : key.split('.'), value);

    if (key === 'shadowTemplateAction.shadowTemplateId') {
      editedItem = await this.setParameterSubstitutionMapping(editedItem);
      if (type === 'Attached') {
        this.setState({ defaultAttached: editedItem.shadowTemplateAction.parameterSubstitutionMapping });
      } else if (type === 'Detached') {
        this.setState({ defaultDetached: editedItem.shadowTemplateAction.parameterSubstitutionMapping });
      }
    }
    if ('shadowTemplateAction' in editedItem && 'parameterSubstitutionMapping' in editedItem.shadowTemplateAction) {
      const key = 'system.group.groupId';
      if (
        key in editedItem.shadowTemplateAction.parameterSubstitutionMapping &&
        this.props.item?.policyType === 'NONE'
      ) {
        SnackbarUtils.warning(i18next.t('warning.trigger.key'));
      }
    }
    this.setState({
      editedItemAttach: type === 'Attached' ? editedItem : this.state.editedItemAttach,
      editedItemDetach: type === 'Detached' ? editedItem : this.state.editedItemDetach,
      saveDisabled: !(!!Object.keys(editedItem).length && isValid),
    });
    if (key === 'enabled' && !this.state.editing) {
      await this.update();
    }
  };

  getDefaultValue = (type: Action): Trigger | undefined => {
    let trigger =
      this.props.triggers && this.props.triggers.find((trigger) => trigger.groupChangeTrigger.action === type);
    if (
      trigger &&
      type === Attached &&
      Object.keys(this.state.editedItemAttach).length > 0 &&
      this.state.editedItemAttach.shadowTemplateAction &&
      this.state.editedItemAttach.shadowTemplateAction.parameterSubstitutionMapping
    ) {
      trigger = {
        ...trigger,
        shadowTemplateAction: {
          ...trigger.shadowTemplateAction,
          parameterSubstitutionMapping: this.state.editedItemAttach.shadowTemplateAction.parameterSubstitutionMapping,
        },
      };
    } else if (
      trigger &&
      type === Detached &&
      Object.keys(this.state.editedItemDetach).length &&
      this.state.editedItemDetach.shadowTemplateAction &&
      this.state.editedItemDetach.shadowTemplateAction.parameterSubstitutionMapping
    ) {
      trigger = {
        ...trigger,
        shadowTemplateAction: {
          ...trigger.shadowTemplateAction,
          parameterSubstitutionMapping: this.state.editedItemDetach.shadowTemplateAction.parameterSubstitutionMapping,
        },
      };
    }
    return trigger;
  };

  getTemplate = async (shadowTemplateId: string): Promise<Record<string, string> | undefined> => {
    if (this.service) {
      const { data } = await this.service.shadowTemplate.get(shadowTemplateId, {
        params: { parent: '*', limit: 500 },
      });
      return this.getDeepKeys(data.template);
    } else {
      return undefined;
    }
  };

  getDefault = async (type: Action) => {
    const shadowTemplateId = this.props.triggers.find((trigger) => trigger.groupChangeTrigger.action === type)
      ?.shadowTemplateAction.shadowTemplateId;
    if (
      shadowTemplateId &&
      ((type === Attached && !this.state.defaultAttached) || (type === Detached && !this.state.defaultDetached))
    ) {
      await this.getTemplate(shadowTemplateId).then((template) => {
        if (template && Object.keys(template).length > 0) {
          this.setState({
            defaultAttached: type === Attached ? template : this.state.defaultAttached,
            defaultDetached: type === Detached ? template : this.state.defaultDetached,
          });
        }
      });
    }
  };

  renderSchema = (): React.ReactNode => (
    <Grid item xs={12} style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
      <div style={{ marginLeft: '20px' }}>
        <Typography variant="subtitle2" style={{ marginBottom: '20px' }}>
          <Trans>description.attached</Trans>
        </Typography>
        <SchemaForm
          state="UPDATE"
          SchemaFormInputProps={{
            ['shadowTemplateAction.shadowTemplateId']: {
              options: this.state.shadowTemplates,
            },
            ['shadowTemplateAction.parameterSubstitutionMapping']: {
              options: ['{groupId}', '{groupDescription}', '{default}'],
              default: this.state.defaultAttached,
            },
          }}
          key={`schema-editing-${this.state.editing}`}
          data={{
            ...(this.props.schema as any).properties[this.props.schemaKey],
          }}
          disabled={!this.state.editing}
          defaultValue={this.getDefaultValue(Attached)}
          onChange={async (key: string | undefined, value: any, isValid: boolean) =>
            await this.handleUpdate(key, value, isValid, 'Attached')
          }
        />
      </div>
      <div style={{ marginLeft: '20px' }}>
        <Typography variant="subtitle2" style={{ marginBottom: '20px' }}>
          {' '}
          <Trans>description.detached</Trans>
        </Typography>
        <SchemaForm
          state="UPDATE"
          SchemaFormInputProps={{
            ['shadowTemplateAction.shadowTemplateId']: {
              options: this.state.shadowTemplates,
            },
            ['shadowTemplateAction.parameterSubstitutionMapping']: {
              options: ['{groupId}', '{groupDescription}', '{default}'],
              default: this.state.defaultDetached,
            },
          }}
          key={`schema-editing-${this.state.editing}`}
          data={{
            ...(this.props.schema as any).properties[this.props.schemaKey],
          }}
          disabled={!this.state.editing}
          defaultValue={this.getDefaultValue(Detached)}
          onChange={async (key: string | undefined, value: any, isValid: boolean) =>
            await this.handleUpdate(key, value, isValid, 'Detached')
          }
        />
      </div>
    </Grid>
  );

  renderContent = (): React.ReactNode => (
    <Grid container spacing={4}>
      {this.renderSchema()}
    </Grid>
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.props.parentLoading || this.state.loading,
        },
        customAction: this.props.customAction,
        ...(this.props.accessControl(this.props.accessControlSection, 'update') && !this.props.disableEdit
          ? {
              editProps: {
                loading: this.state.loading,
                editing: this.state.editing,
                saveDisabled: this.state.saveDisabled,
                editDisabled: !this.props.item,
                onEdit: () => this.setState({ editing: true }),
                onSave: this.update,
                onCancel: () =>
                  this.setState({
                    editing: false,
                    editedItemAttach: {},
                    editedItemDetach: {},
                  }),
              },
            }
          : {}),
      }}
    >
      {this.props.parentLoading && <PageLoader />}
      {!this.props.parentLoading && this.props.triggers.length > 0 && this.renderContent()}
    </TabPanel>
  );
}

export default withRouter(TriggersTab);
