import React, { Component } from 'react';
import { Paper, IconButton, Typography, Grid, Button, CircularProgress, TextField } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { User } from '../../common/types';
import { UserService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import i18next from 'i18next';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  addUsers: User[];
  users: User[];
  defaultUsers: User[];
};

export class AddUserToUserGroupMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    addUsers: [],
    users: [],
    defaultUsers: [],
  };
  userService!: UserService;
  userDebouncer: any;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.userService = await UserService.create()!;
    const { data } = await this.userService.user.list({ params: { limit: 50 } });
    if (this._isMounted) this.setState({ users: data.data, defaultUsers: data.data });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  get isValid(): boolean {
    return !this.state.loading && !!this.state.addUsers.length;
  }

  addUsersToUserGroup = async (): Promise<void> => {
    this.setState({ loading: true });
    const result = await Promise.all(
      this.state.addUsers.map((user) =>
        this.userService!.userGroup.postUserToUserGroup(this.store.modalStore.data.groupName, user.userId)
          .then(() => ({
            isError: false,
            title: user.email,
            subtitle: i18next.t('success.attach.userToUserGroup'),
          }))
          .catch((e) => {
            const error: any = e;
            return {
              isError: true,
              title: user.email,
              subtitle:
                (error.response && error.response.data && error.response.data.message) ||
                i18next.t('error.attach.userToUserGroup'),
            };
          }),
      ),
    );

    if (!result.map((res) => res.isError).includes(false)) {
      SnackbarUtils.error(
        `${i18next.t('error.attach.usersToUserGroup')} (${this.state.addUsers.length})`,
        result as any,
      );
    } else if (!result.map((res) => res.isError).includes(true)) {
      SnackbarUtils.success(
        `${i18next.t('success.attach.usersToUserGroup')} (${this.state.addUsers.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } else {
      SnackbarUtils.warning(
        `${i18next.t('warning.attach.partiallyUsersToUserGroup')} (${this.state.addUsers.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    }
    this.setState({ loading: false });
  };

  getUsers = async (value: string): Promise<void> => {
    try {
      const { data } = await this.userService.user.list({
        params: { limit: 50, userEmail: value.endsWith('*') ? value : `${value}*` },
      });
      if (!!data && !!data.data && this._isMounted) this.setState({ users: data.data });
    } catch {
      SnackbarUtils.error(i18next.t('error.fetch.users'));
    }
  };

  handleGetUsers = (_: React.ChangeEvent<unknown>, value: any, reason: AutocompleteInputChangeReason): void => {
    if (this.userDebouncer) clearTimeout(this.userDebouncer);
    if (reason === 'reset' || value === '') {
      this.setState({ users: this.state.defaultUsers });
    } else {
      this.userDebouncer = setTimeout(() => this.getUsers(value), 1000);
    }
  };

  render = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Paper tabIndex={-1} square className={classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.addUsersToUserGroup.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.addUsersToUserGroup.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="autu"
              multiple
              filterSelectedOptions
              ChipProps={{ size: 'small' }}
              options={this.state.users}
              getOptionLabel={(option: User) => option.email}
              value={this.state.addUsers}
              isOptionEqualToValue={(option: User, value: User) => option.userId === value.userId}
              onChange={(_: any, addUsers: User[]) => this.setState({ addUsers })}
              onInputChange={this.handleGetUsers}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'autu' }}
                  label={`${i18next.t('users')}*`}
                  placeholder={i18next.t('form.placeholder.selectUsers')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={!this.isValid} onClick={this.addUsersToUserGroup}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.add</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(AddUserToUserGroupMC);
