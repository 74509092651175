import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Action, Dispatch } from 'redux';
import i18next from 'i18next';
import { FirmwareService } from '../../../../services';
import { Dialog, TabsHeader, SnackbarUtils } from '../../../../components';
import { PropertiesTab } from '../../../baseClasses/PropertiesTab';
import MetadataTab from './MetadataTab';
import { Package, ModalVariants } from '../../../../common/types';
import { itemIcons } from '../../../../common/constants';
import { storeOpenModal } from '../../../../store/actions/modal';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../../baseClasses/ViewBase';
import firmwareSchema from '../../../../assets/schemas/firmware.schema.parsed.json';

type _Props = {
  openModal: (cPackage: Package, callback: () => void) => void;
};
type Props = ViewBaseProps<_Props, { packageId: string }>;

type _State = unknown;
type State = ViewBaseState<_State>;

class FirmwarePackageDetailedView extends ViewBase<_Props, _State, { packageId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.properties', 'tab.metadata'];
    FirmwareService.create().then((service) => (this.service = service));
    this.subservice = 'package';
    this.messages = {
      load: { error: 'error.fetch.package' },
      update: { success: 'success.update.package', error: 'error.tryAgain' },
      delete: { success: 'success.delete.package', error: 'error.tryAgain' },
    };
  }

  download = async (): Promise<void> => {
    const fileName = this.state.item.name;
    const snackId = SnackbarUtils.download(`Downloading ${fileName}`);
    try {
      const { data } = await this.service.authenticationServiceProvider.axios.get(this.state.item!.url, {
        responseType: 'blob',
      });
      const blob = new Blob([data as Uint8Array]);
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = this.state.item!.name.replace(/ /g, '_').replace(/\./g, '-') + '.ota';
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      SnackbarUtils.close(snackId);
      SnackbarUtils.success(`Successfully downloaded ${fileName}`);
    } catch {
      SnackbarUtils.close(snackId);
      SnackbarUtils.error(`Failed to download ${fileName}`);
    }
  };

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('package', 'delete'))
      list.push(
        <MenuItem key="1" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    if (this.accessControl('package', 'update') && this.state.item && this.state.item.state === 'AWAITING_CONFIRM')
      list.push(
        <MenuItem key="0" onClick={() => this.props.openModal(this.state.item!, this.load)}>
          <Trans>action.confirmPackage</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <PropertiesTab
        tab={0}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="package"
        parentLoading={this.state.loading}
        schema={firmwareSchema}
        schemaKey="package"
        item={this.state.item}
        customAction={
          <Button
            size="small"
            color="secondary"
            disabled={!this.state.item || !this.state.item.url}
            onClick={this.download}
          >
            {i18next.t('action.download')}
          </Button>
        }
        update={this.update}
        reload={this.load}
      />
      <MetadataTab
        tab={1}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
        metadata={!!this.state.item && this.state.item.metadata ? this.state.item.metadata : undefined}
        reload={this.load}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <Dialog
      open={this.state.deleteDialogOpen}
      title={<Trans>action.delete</Trans>}
      description={<Trans>description.delete.package</Trans>}
      continueTitle={<Trans>action.delete</Trans>}
      onClose={() => this.setState({ deleteDialogOpen: false })}
      onContinue={this.delete}
    />
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={
          this.state.item ? `${this.state.item.version} ${this.state.item.name}` : this.props.match.params.packageId
        }
        icon={itemIcons.firmwarePackage}
        subtitle={
          this.state.item ? `${i18next.t('package')} | ${this.state.item.productId}` : i18next.t('form.packageId')
        }
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (cPackage: Package, callback: () => void) =>
    dispatch(storeOpenModal(ModalVariants.ConfirmFirmwarePackageMC, { package: cPackage, callback })),
  ...ViewBaseMapDispatchToProps(dispatch),
});

export default withStyles(ViewBaseStyles)(withRouter(connect(null, mapDispatchToProps)(FirmwarePackageDetailedView)));
