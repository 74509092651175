import React from 'react';
import RefParser from 'json-schema-ref-parser';
import { Typography, Grid } from '@mui/material';
import { SchemaFormInput } from '.';
import * as textUtils from '../common/textUtils';

export type SchemaFormProps = {
  state: 'CREATE' | 'UPDATE';
  depth?: number;
  grid?: {
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  };
  inputGrid?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  SchemaFormInputProps?: {
    [key: string]: {
      onValueChange?(value: string): Promise<string[]>;
      options?: any[];
      default?: Record<string, any>;
      json?: {
        objectPath?: string;
        schema: { [key: string]: any };
      };
      navigate?: (id: string) => string;
    };
  };
  data: Omit<RefParser.JSONSchema, 'type'> & {
    key?: string;
    type: 'string' | 'number' | 'boolean' | 'integer' | 'object' | 'array';
    displayName?: string;
    readOnly?: boolean;
    mandatoryKeys?: string[];
    mappingValues?: string[];
    propertyGroups?: { name: string; properties: string[] }[];
    variant?: 'select' | 'json' | 'textarea' | 'switch';
    hiddenIn?: ('CREATE' | 'UPDATE')[];
    editableIn?: ('CREATE' | 'UPDATE')[];
    hiddenIf?: [string, 'EQUAL' | 'NOT_EQUAL', string][];
  };
  disabled?: boolean;
  required?: boolean;
  hideUnset?: boolean;
  objectKey?: string;
  defaultValue?: { [key: string]: any };
  onChange?: (path: string, value: any, isValid: boolean, inputValid?: Record<string, boolean>) => void;
  isRequired?: (key: string) => boolean | undefined;
};

type SchemaFormState = {
  inputValid: { [key: string]: boolean };
};

class _SchemaForm extends React.Component<SchemaFormProps, SchemaFormState> {
  state: SchemaFormState = {
    inputValid: {},
  };

  get depth(): number {
    if (typeof this.props.depth === 'number') {
      if (this.props.depth < 3) return this.props.depth;
      return 3;
    }
    return 1;
  }

  evaluateHiddenIf = (key: string, operator: 'EQUAL' | 'NOT_EQUAL', value: string): boolean => {
    if (!this.props.defaultValue) return false;
    const keys = key.split('.');
    const defaultValue =
      keys.length === 1
        ? (this.props.defaultValue as any)[keys[0]]
        : ((this.props.defaultValue as any)[keys[0]] || {})[keys[1]];
    if (operator === 'EQUAL' && defaultValue === value) return true;
    if (operator === 'NOT_EQUAL' && defaultValue !== value) return true;
    return false;
  };

  get isHidden(): boolean {
    if (!!this.props.data.hiddenIn && this.props.data.hiddenIn.includes(this.props.state)) return true;
    if (
      !!this.props.data.hiddenIf &&
      !!this.props.data.hiddenIf.length &&
      !!this.props.defaultValue &&
      this.props.data.hiddenIf.map(([k, o, v]) => this.evaluateHiddenIf(k, o, v)).includes(true)
    )
      return true;
    return false;
  }

  onChange = (path: string, value: any, isValid: boolean): void => {
    const inputValid = JSON.parse(JSON.stringify(this.state.inputValid));
    inputValid[path] = isValid;
    this.setState({ inputValid });
    if (this.props.onChange) this.props.onChange(path, value, !Object.values(inputValid).includes(false), inputValid);
  };

  renderNonePropertyGrouped = (): React.ReactNode => {
    if (!this.props.data.properties) return null;
    const groupedProperties = (this.props.data.propertyGroups || []).reduce(
      (acc: string[], val: { properties: string[] }) => {
        return acc.concat(val.properties);
      },
      [],
    );
    return Object.entries(this.props.data.properties)
      .filter(([key]) => !groupedProperties.includes(key))
      .map(([key, value]) => (
        <SchemaForm
          key={key}
          state={this.props.state}
          depth={this.depth + 1}
          grid={this.props.grid}
          inputGrid={this.props.inputGrid}
          SchemaFormInputProps={this.props.SchemaFormInputProps}
          data={{ key, ...value }}
          disabled={this.props.disabled || false}
          hideUnset={this.props.hideUnset}
          required={
            (this.props?.isRequired && this.props?.isRequired(key)) ||
            (!!this.props.data.required &&
              Array.isArray(this.props.data.required) &&
              this.props.data.required.includes(key)) ||
            (!!this.props.data.anyOf &&
              (this.props.data.anyOf as { [key: string]: any }[])
                .map((props) => props.required.includes(key))
                .includes(true))
          }
          objectKey={this.props.objectKey ? `${this.props.objectKey}.${key}` : key}
          defaultValue={this.props.defaultValue}
          onChange={this.onChange}
        />
      ));
  };

  renderPropertyGroups = (): React.ReactNode => {
    if (!this.props.data.properties || !this.props.data.propertyGroups) return null;
    return this.props.data.propertyGroups.map((group, i) => {
      const children = Object.entries(this.props.data.properties!)
        .filter(
          ([key, value]) =>
            group.properties.includes(key) && (!value.hiddenIn || !value.hiddenIn.includes(this.props.state)),
        )
        .map(([key, value]) => (
          <SchemaForm
            key={key}
            state={this.props.state}
            depth={this.depth + 2}
            grid={this.props.grid}
            inputGrid={this.props.inputGrid}
            SchemaFormInputProps={this.props.SchemaFormInputProps}
            data={{ key, ...value }}
            disabled={this.props.disabled || false}
            hideUnset={this.props.hideUnset}
            required={
              (this.props?.isRequired && this.props?.isRequired(key)) ||
              (!!this.props.data.required &&
                Array.isArray(this.props.data.required) &&
                this.props.data.required.includes(key)) ||
              (!!this.props.data.anyOf &&
                (this.props.data.anyOf as { [key: string]: any }[])
                  .map((props) => props.required.includes(key))
                  .includes(true))
            }
            objectKey={this.props.objectKey ? `${this.props.objectKey}.${key}` : key}
            defaultValue={this.props.defaultValue}
            onChange={this.onChange}
          />
        ));
      if (!children.length) return null;
      return (
        <Grid
          key={i}
          item
          xs={(this.depth + 1 === 2 && !!this.props.grid && this.props.grid.xs) || 12}
          sm={
            this.props.data.type === 'object' && this.depth + 1 === 2
              ? (this.props.grid && this.props.grid.sm) || 6
              : 12
          }
          md={
            this.props.data.type === 'object' && this.depth + 1 === 2
              ? (this.props.grid && this.props.grid.md) || 6
              : 12
          }
          lg={
            this.props.data.type === 'object' && this.depth + 1 === 2
              ? (this.props.grid && this.props.grid.lg) || 6
              : 12
          }
          xl={
            this.props.data.type === 'object' && this.depth + 1 === 2
              ? (this.props.grid && this.props.grid.xl) || 4
              : 12
          }
        >
          <Grid container key={this.props.data.key} spacing={4}>
            {this.props.state !== 'CREATE' && this.depth + 1 <= 2 ? (
              <Grid item xs={12}>
                <Typography variant={`h${this.depth + 5}` as any}>{group.name}</Typography>
              </Grid>
            ) : null}
            {children}
          </Grid>
        </Grid>
      );
    });
  };

  object = (): React.ReactNode => {
    return (
      <Grid
        key={`${this.props.objectKey}-${this.props.data.key}`}
        item
        xs={(this.depth === 2 && !!this.props.grid && this.props.grid.xs) || 12}
        sm={this.props.data.type === 'object' && this.depth === 2 ? (this.props.grid && this.props.grid.sm) || 6 : 12}
        md={this.props.data.type === 'object' && this.depth === 2 ? (this.props.grid && this.props.grid.md) || 6 : 12}
        lg={this.props.data.type === 'object' && this.depth === 2 ? (this.props.grid && this.props.grid.lg) || 6 : 12}
        xl={this.props.data.type === 'object' && this.depth === 2 ? (this.props.grid && this.props.grid.xl) || 4 : 12}
      >
        <Grid container key={this.props.data.key} spacing={4}>
          {this.props.state !== 'CREATE' && !!this.props.data.key && this.depth <= 2 ? (
            <Grid item xs={12}>
              <Typography variant={`h${this.depth + 4}` as any}>
                {textUtils.uncamelCase(this.props.data.key)}
              </Typography>
            </Grid>
          ) : null}
          {this.renderPropertyGroups()}
          {this.renderNonePropertyGrouped()}
        </Grid>
      </Grid>
    );
  };

  renderContent = (): React.ReactNode => {
    if (this.isHidden || !this.props.data.type) return null;
    if (this.props.data.type === 'object' && !this.props.data.variant) return this.object();
    const { SchemaFormInputProps, ...props } = this.props;
    return (
      <SchemaFormInput {...(props as any)} {...(((SchemaFormInputProps as any) || {})[this.props.data.key!] || {})} />
    );
  };

  render = (): React.ReactNode => {
    if (!this.props.data.type) return null;
    return this.renderContent();
  };
}

export const SchemaForm = _SchemaForm;
