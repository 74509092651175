import React, { Component } from 'react';
import i18next from 'i18next';
import { SnackbarUtils } from './StyledSnackbarProvider';

export type InfinityScrollProps = {
  initLoaded: boolean;
  loading: boolean;
  nextToken?: string;
  hash?: string;
  loadFunc: (clearTable?: boolean) => Promise<void>;
  children?: React.ReactNode;
};

export class InfinityScroll extends Component<InfinityScrollProps> {
  componentWillUnmount = (): void => {
    document.removeEventListener('scroll', this.handleScrollEvent);
  };

  componentDidUpdate = async (prevProps: InfinityScrollProps): Promise<void> => {
    const { initLoaded, hash, loading } = this.props;
    if (!prevProps.initLoaded && initLoaded) await this.handleLoad(true);
    if (!loading && initLoaded && prevProps.hash !== hash) this.handleLoad(true);
  };

  handleScrollEvent = async (): Promise<void> => {
    const { nextToken, loadFunc, loading } = this.props;
    if (loading) return;
    if (!!nextToken && document.body.scrollHeight - window.innerHeight - window.pageYOffset < 400) {
      try {
        await loadFunc();
      } catch (e) {
        const error: any = e;
        const { response, code } = error;
        const { status, data } = response;
        const statusCode = error.statusCode || (response && status) || undefined;
        if (statusCode !== 403 && code !== 'NetworkError')
          SnackbarUtils.error((response && data && data.message) || i18next.t('error.loadList'));
        document.removeEventListener('scroll', this.handleScrollEvent);
      }
    }
  };

  handleLoad = async (reload = false): Promise<void> => {
    let clear: boolean = reload;
    let addListener = true;
    const { nextToken, loadFunc } = this.props;

    document.removeEventListener('scroll', this.handleScrollEvent);

    do {
      try {
        await loadFunc(clear);
        clear = false;
      } catch (e) {
        const error: any = e;
        const { response, code } = error;
        addListener = false;
        const statusCode = error.statusCode || (response && response.status) || undefined;
        if (statusCode !== 403 && code !== 'NetworkError')
          SnackbarUtils.error((response && response.data && response.data.message) || i18next.t('error.loadList'));
        break;
      }
    } while (!!nextToken && document.body.scrollHeight - window.innerHeight - window.pageYOffset < 1);

    if (addListener) document.addEventListener('scroll', this.handleScrollEvent);
  };

  render = (): React.ReactNode => this.props.children;
}
