import { Dispatch } from 'redux';
import * as types from '../../common/types';
import { LOCALES } from '../../assets/translations';

// action types
export enum actions {
  SET_LOCALE = 'SET_LOCALE',
  SET_CONFIG = 'SET_CONFIG',
}

// action creators
const setLocale = (locale: string) => ({
  store: types.StoreName.USER,
  type: actions.SET_LOCALE,
  locale,
});

export const storeSetCustomerConfiguration = (config: types.CustomerConfiguration) => ({
  store: types.StoreName.USER,
  type: actions.SET_CONFIG,
  config,
});

// conditional action creators
export const storeSetLocale = (locale: string) => (dispatch: Dispatch) => {
  const index = LOCALES.indexOf(locale);
  if (index === -1) return; // TODO: Call something-went-wrong-store to show some kind of error message
  dispatch(setLocale(LOCALES[index]));
};
