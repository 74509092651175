import React, { Component } from 'react';
import { Paper, IconButton, Typography, TextField, Grid, Button, CircularProgress } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { PostShadowAlias } from '../../common/types';
import { DeviceService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  shadowAlias: PostShadowAlias;
};

export class CreateShadowAliasMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    shadowAlias: {
      alias: '',
      description: '',
      property: '',
    },
  };
  deviceService!: DeviceService;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.deviceService = await DeviceService.create();
    if (this.store.modalStore.data.shadowAlias) this.setState({ shadowAlias: this.store.modalStore.data.shadowAlias });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.deviceService.shadowAlias.post(this.state.shadowAlias);
      SnackbarUtils.success(i18next.t('success.create.shadowAlias'));
      if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      return this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  update = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.deviceService.shadowAlias.put(this.state.shadowAlias.alias, {
        description: this.state.shadowAlias.description,
        property: this.state.shadowAlias.property,
      });
      SnackbarUtils.success(i18next.t('success.update.shadowAlias'));
      if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      return this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  get isValid(): boolean {
    return !this.state.loading && !!this.state.shadowAlias.alias && !!this.state.shadowAlias.property;
  }

  render = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Paper tabIndex={-1} square className={classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>
                {this.store.modalStore.data.shadowAlias
                  ? 'modals.updateShadowAlias.title'
                  : 'modals.createShadowAlias.title'}
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>
                {this.store.modalStore.data.shadowAlias
                  ? 'modals.updateShadowAlias.description'
                  : 'modals.createShadowAlias.description'}
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={`${i18next.t('form.alias')}*`}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!!this.store.modalStore.data.shadowAlias}
              value={this.state.shadowAlias.alias}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ shadowAlias: { ...this.state.shadowAlias, alias: e.target.value } })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={`${i18next.t('form.property')}*`}
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.shadowAlias.property}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ shadowAlias: { ...this.state.shadowAlias, property: e.target.value } })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={i18next.t('form.description') + '*'}
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.shadowAlias.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ shadowAlias: { ...this.state.shadowAlias, description: e.target.value } })
              }
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              disabled={!this.isValid}
              onClick={this.store.modalStore.data.shadowAlias ? this.update : this.create}
            >
              {this.state.loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Trans>{this.store.modalStore.data.shadowAlias ? 'action.update' : 'action.create'}</Trans>
              )}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateShadowAliasMC);
