import React, { Component } from 'react';
import { Paper, IconButton, Grid, Typography, Button, CircularProgress } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Ajv from 'ajv';
import { SchemaForm } from '../../components';
import { UserService } from '../../services';
import { store } from '../../store';
import { UserGroup } from '../../common/types';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import userSchema from '../../assets/schemas/user.schema.parsed.json';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  data: { [key: string]: any };
  isSaveDisabled: boolean;
  loading: boolean;
  userGroups: string[];
};

class InviteUserMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    data: {},
    isSaveDisabled: true,
    loading: false,
    userGroups: [],
  };
  userService!: UserService;
  ajv: any;

  constructor(props: Props) {
    super(props);
    this.ajv = new Ajv({ allErrors: true, useDefaults: true });
    this.ajv.addSchema(userSchema, 'userSchema');
  }

  componentDidMount = async (): Promise<void> => {
    this.userService = await UserService.create();
    this.loadUserGroups();
  };

  loadUserGroups = async (): Promise<void> => {
    let list: string[] = [];
    let nextToken: string | undefined;
    do {
      try {
        const { data }: any = await this.userService.userGroup.list(nextToken ? { params: { nextToken } } : undefined);
        list = list.concat(data.data.map((dg: UserGroup) => dg.groupName));
        nextToken = data.nextToken;
      } catch {
        SnackbarUtils.error(i18next.t('error.fetch.userGroups'));
        return;
      }
    } while (nextToken);
    this.setState({ userGroups: list });
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const { groups, email, ...data } = this.state.data;
      const { data: user } = await this.userService.user.post({
        ...data,
        userEmail: email.toLowerCase(),
      });

      if (!groups || !groups.length) {
        SnackbarUtils.success(i18next.t('success.create.user'));
        if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
        return this.props.closeModal();
      }

      const result = await Promise.all(
        (groups as string[]).map((groupName) =>
          this.userService!.userGroup.postUserToUserGroup(groupName, user!.userId)
            .then(() => ({
              isError: false,
              title: groupName,
              subtitle: i18next.t('success.attach.userToUserGroup'),
            }))
            .catch((e) => {
              const error: any = e;
              return {
                isError: true,
                title: groupName,
                subtitle:
                  (error.response && error.response.data && error.response.data.message) ||
                  i18next.t('error.attach.userToUserGroup'),
              };
            }),
        ),
      );

      if (!result.map((res) => res.isError).includes(false)) {
        SnackbarUtils.error(
          `${i18next.t('error.create.createUserFailedToAddUserGroup')} (${groups.length})`,
          result as any,
        );
      } else if (!result.map((res) => res.isError).includes(true)) {
        SnackbarUtils.success(`${i18next.t('success.create.user')} (${groups.length})`, result as any);
      } else {
        SnackbarUtils.warning(
          `${i18next.t('warning.attach.userCreatedPartiallyAddedToAllGroups')} (${groups.length})`,
          result as any,
        );
      }
      if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.setState({ loading: false });
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      this.setState({ loading: false });
      if (error.response && error.response.status === 403) {
        SnackbarUtils.error(i18next.t('error.create.permission'));
        return;
      } else {
        SnackbarUtils.error(
          (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
        );
        return;
      }
    }
  };

  setValue = (data: { [key: string]: any }, keys: string[], value: any): any => {
    if (keys.length === 0) {
      data = value;
    } else if (keys.length === 1) {
      if (value === null) delete data[keys[0]];
      else data[keys[0]] = value;
    } else {
      data[keys[0]] = this.setValue(data[keys[0]] || {}, keys.slice(1), value);
    }
    return data;
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean) => {
    const data = this.setValue(this.state.data || {}, key === undefined ? [] : key.split('.'), value);
    const ajvIsValid = this.ajv.validate('userSchema', { user: data });
    this.setState({ data: data || {}, isSaveDisabled: !(!!Object.keys(data).length && ajvIsValid && isValid) });
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.inviteUser.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.inviteUser.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SchemaForm
              state="CREATE"
              SchemaFormInputProps={{
                groups: {
                  options: this.state.userGroups,
                },
              }}
              data={{
                ...(userSchema as any).properties.user,
              }}
              grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
              defaultValue={this.state.data}
              onChange={this.handleUpdate}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={this.state.isSaveDisabled} onClick={this.create}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.create</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(InviteUserMC);
