import React, { Component } from 'react';
import { Paper, IconButton, Typography, TextField, Grid, CircularProgress, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { FirmwareService } from '../../services';
import { store } from '../../store';
import { Package } from '../../common/types';
import { Dialog } from '..';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';

const styles = () => {
  return {
    ...BaseModalStyles(),
    stepper: {
      padding: 0,
    },
    fileInput: {
      display: 'none',
    },
  };
};

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  package?: Package;
  packages: Package[];
  releaseId?: string;
  force: boolean;
  foceDialogOpen: boolean;
};

export class UpdateEntryPackageMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    package: undefined,
    packages: [],
    releaseId: undefined,
    force: false,
    foceDialogOpen: false,
  };
  firmwareService!: FirmwareService;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    if (this.store.modalStore.data.release)
      this.setState({
        releaseId: this.store.modalStore.data.release.releaseId,
        packages: this.store.modalStore.data.release.packages || [],
      });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  update = async (): Promise<void> => {
    this.setState({ loading: true });

    try {
      await this.firmwareService.release.setEntrypoint(
        this.state.releaseId!,
        { packageId: this.state.package!.packageId },
        { force: this.state.force },
      );
      SnackbarUtils.success(i18next.t('success.update.releasePackageEntry'));
      if (!!this.store.modalStore.data && !!this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      if (error.response && error.response.data && error.response.data.errorCode === 'FORCE_REQUIRED') {
        this.setState({ foceDialogOpen: true });
      } else {
        SnackbarUtils.error(
          (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
        );
      }
    }
    this.setState({ loading: false });
  };

  get isValid(): boolean {
    return !!this.state.releaseId && !!this.state.package;
  }

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.updateEntryPackageMC.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.updateEntryPackageMC.description</Trans>
            </Typography>
          </Grid>
          {this.state.package && (
            <Grid item xs={12}>
              <Autocomplete
                id="uep"
                ChipProps={{ size: 'small' }}
                options={this.state.packages}
                getOptionLabel={(option: Package) => `${option.name} - ${option.packageId}`}
                value={this.state.package}
                isOptionEqualToValue={(option: Package, value: Package) => option.packageId === value.packageId}
                onChange={(_: any, p: Package | null) => this.setState({ package: p || undefined })}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputLabelProps={{ shrink: true, htmlFor: 'uep' }}
                    label={`${i18next.t('form.package')}*`}
                    placeholder={i18next.t('form.placeholder.selectPackage')}
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
          <Grid item>
            <Button color="secondary" variant="contained" disabled={!this.isValid} onClick={this.update}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.update</Trans>}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.foceDialogOpen}
          title={<Trans>action.forceEntrypoint</Trans>}
          description={<Trans>description.forceEntrypoint</Trans>}
          continueTitle={<Trans>action.continue</Trans>}
          onClose={() => this.setState({ loading: false, foceDialogOpen: false })}
          onContinue={() => {
            this.setState({ foceDialogOpen: false, force: true }, this.update);
          }}
        />
      </Paper>
    );
  };
}

export default withStyles(styles)(UpdateEntryPackageMC);
