import React, { Component } from 'react';
import { List, ListItemButton, ListItemText, Grid, Box, Collapse, ListItemAvatar, Avatar } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ExpandMore } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import clsx from 'clsx';
import { storeSetLeftNavOpen } from '../../store/actions/common';
import { itemIcons } from '../../common/constants';
import {
  Store,
  LastVisited,
  DeviceGroup,
  Device,
  SubdomainRoutePath,
  User,
  UserGroup,
  MarkdownDocument,
  Subscription,
  Release,
  Package,
  Product,
  DeviceApiGw,
  ShadowTemplate,
  Key,
  Trust,
} from '../../common/types';
import { getTheme } from '../../common/theme';

import { AuthenticationServiceProvider } from '../../services';

type LastVisitedProps = {
  title: string;
  subtitle: string;
  icon: any;
  onClick: () => void | undefined;
  classes: Record<string, string>;
};

const LastVisitedListItem: React.FC<
  { title: string; subtitle: string; icon: any; onClick: () => void } & WithStyles<any>
> = ({ title, subtitle, icon, onClick, classes }: LastVisitedProps) => (
  <ListItemButton dense disableGutters onClick={onClick || null} className={classes.lastVisitedRoot}>
    <ListItemAvatar>
      <Avatar className={classes.avatar}>{icon}</Avatar>
    </ListItemAvatar>
    <ListItemText
      classes={{ root: classes.lastVisitedTextRoot }}
      color="secondary"
      primary={title}
      secondary={subtitle}
    />
  </ListItemButton>
);

const styles = () => {
  const theme = getTheme();
  return {
    drawerLinkRoot: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    menuSubHeader: {
      lineHeight: '36px',
      color: theme.palette.primary.main,
    },
    listItemIconContainer: {
      marginLeft: '-20px',
      marginRight: '-4px',
    },
    listItemIcon: {
      fontSize: '1rem',
      transform: 'rotate(-90deg)',
      transition: 'transform .2s ease-in-out',
    },
    listItemIconOpen: {
      transform: 'rotate(0deg)',
    },
    lastVisitedRoot: {
      cursor: 'pointer' as const,
      '&:hover': {
        '& span': {
          color: theme.palette.secondary.main,
        },
        '& p': {
          color: theme.palette.secondary.main,
        },
      },
    },
    lastVisitedTextRoot: {
      'overflow-x': 'hidden' as const,
      'white-space': 'nowrap',
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
    },
  };
};

type Props = WithStyles<any> &
  RouteComponentProps & {
    lastVisited: LastVisited[];
    closeLeftNavOpen: () => void;
  };

type State = {
  lastVisitedIsOpen: boolean;
  userId: string | undefined;
};

class LastVisistedList extends Component<Props, State> {
  state: State = {
    lastVisitedIsOpen: false,
    userId: undefined,
  };
  LOCAL_STORAGE_OPEN = 'LOCAL_STORAGE_OPEN';

  componentDidMount = async (): Promise<void> => {
    await AuthenticationServiceProvider.createFromCache().then((ap) => {
      this.setState({ userId: ap?.service.userId() });
    });
    const isOpen = localStorage.getItem(this.LOCAL_STORAGE_OPEN);
    if (!isOpen) {
      localStorage.setItem(this.LOCAL_STORAGE_OPEN, 'open');
    }
    this.setState({ lastVisitedIsOpen: isOpen === 'open' });
  };

  goToLastVisisted = (item: LastVisited): void => {
    let path: string | undefined;

    if ({}.hasOwnProperty.call(item, 'deviceId')) {
      path = SubdomainRoutePath.device((item as Device).deviceId);
    } else if ({}.hasOwnProperty.call(item, 'trustId')) {
      path = SubdomainRoutePath.trust((item as Trust).trustId);
    } else if ({}.hasOwnProperty.call(item, 'email')) {
      path = SubdomainRoutePath.user((item as User).userId);
    } else if ({}.hasOwnProperty.call(item, 'groupName')) {
      path = SubdomainRoutePath.userGroup((item as UserGroup).groupName);
    } else if ({}.hasOwnProperty.call(item, 'subscriptionId')) {
      path = SubdomainRoutePath.subscription((item as Subscription).subscriptionId);
    } else if ({}.hasOwnProperty.call(item, 'path')) {
      path = (item as MarkdownDocument).path;
    } else if ({}.hasOwnProperty.call(item, 'releaseId')) {
      path = SubdomainRoutePath.firmwareRelease((item as Release).releaseId);
    } else if ({}.hasOwnProperty.call(item, 'packageId')) {
      path = SubdomainRoutePath.firmwarePackage((item as Package).packageId);
    } else if ({}.hasOwnProperty.call(item, 'productId')) {
      path = SubdomainRoutePath.firmwareProduct((item as Product).productId);
    } else if ({}.hasOwnProperty.call(item, 'groupId')) {
      path = SubdomainRoutePath.deviceGroup((item as DeviceGroup).groupId);
    } else if ({}.hasOwnProperty.call(item, 'apiId')) {
      path = SubdomainRoutePath.deviceApiGw((item as DeviceApiGw).apiId);
    } else if ({}.hasOwnProperty.call(item, 'templateId')) {
      path = SubdomainRoutePath.shadowTemplate((item as ShadowTemplate).templateId);
    } else if ({}.hasOwnProperty.call(item, 'keyId')) {
      path = SubdomainRoutePath.key((item as Key).keyId);
    }

    this.props.closeLeftNavOpen();
    if (path) this.props.history.push(path);
  };

  renderLastVisited = (): React.ReactNode => {
    if (!this.props.lastVisited || !this.props.lastVisited.length) return null;
    return (
      <React.Fragment key="last-visited-list">
        <ListItemButton
          dense
          classes={{ root: this.props.classes.drawerLinkRoot }}
          onClick={() => {
            localStorage.setItem(this.LOCAL_STORAGE_OPEN, this.state.lastVisitedIsOpen ? 'closed' : 'open');
            this.setState({ lastVisitedIsOpen: !this.state.lastVisitedIsOpen });
          }}
        >
          <ListItemText>
            <Grid container alignItems="center" spacing={1}>
              <Grid item className={this.props.classes.listItemIconContainer}>
                <Box display="flex" alignContent="center">
                  <ExpandMore
                    className={clsx({
                      [this.props.classes.listItemIcon]: true,
                      [this.props.classes.listItemIconOpen]: this.state.lastVisitedIsOpen,
                    })}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Trans>Recent</Trans>
              </Grid>
            </Grid>
          </ListItemText>
        </ListItemButton>
        <Collapse in={this.state.lastVisitedIsOpen} timeout="auto" unmountOnExit>
          <Box ml={2}>
            <List disablePadding component="div">
              {this.props.lastVisited
                .filter((item) => {
                  if ({}.hasOwnProperty.call(item, 'origin') && this.state.userId) {
                    return item.origin === this.state.userId;
                  }
                  return false;
                })
                .slice(0, 5)
                .map((item, i) => {
                  if ({}.hasOwnProperty.call(item, 'deviceId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as Device).deviceId}
                        subtitle={i18next.t('device') + ((item as Device).name ? ' | ' + (item as Device).name : '')}
                        icon={itemIcons.device}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'groupId') && !{}.hasOwnProperty.call(item, 'releaseId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as DeviceGroup).groupId}
                        subtitle={i18next.t('deviceGroup')}
                        icon={itemIcons.deviceGroup}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'email'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as User).email}
                        subtitle={i18next.t('user') + ((item as User).name ? ` | ${(item as User).name}` : '')}
                        icon={itemIcons.user}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'groupName'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as UserGroup).groupName}
                        subtitle={i18next.t('userGroup')}
                        icon={itemIcons.userGroup}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'trustId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as Trust).trustId}
                        subtitle={i18next.t('trusts')}
                        icon={itemIcons.trust}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'subscriptionId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as Subscription).subscriptionId}
                        subtitle={i18next.t('subscription')}
                        icon={itemIcons.subscription}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'path'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as MarkdownDocument).name}
                        subtitle={i18next.t((item as MarkdownDocument).type === 'docs' ? 'document' : 'download')}
                        icon={(item as MarkdownDocument).type === 'docs' ? itemIcons.docs : itemIcons.download}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'releaseId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={`${(item as Release).toVersion} ${(item as Release).name}`}
                        subtitle={`${i18next.t('release')} | ${(item as Release).productId}`}
                        icon={itemIcons.firmwareRelease}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'packageId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={`${(item as Package).version} ${(item as Package).name}`}
                        subtitle={`${i18next.t('package')} | ${(item as Release).productId}`}
                        icon={itemIcons.firmwarePackage}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'productId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as Product).productId}
                        subtitle={i18next.t('product')}
                        icon={itemIcons.firmwareProduct}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'apiId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as DeviceApiGw).apiId}
                        subtitle={i18next.t('deviceApiGw')}
                        icon={itemIcons.deviceApiGw}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'templateId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as ShadowTemplate).templateId}
                        subtitle={i18next.t('shadowTemplate')}
                        icon={itemIcons.shadowTemplate}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  if ({}.hasOwnProperty.call(item, 'keyId'))
                    return (
                      <LastVisitedListItem
                        key={`last-visited-${i}`}
                        title={(item as Key).keyId}
                        subtitle={i18next.t('key')}
                        icon={itemIcons.key}
                        onClick={() => this.goToLastVisisted(item)}
                        classes={this.props.classes}
                      />
                    );
                  return null;
                })}
            </List>
          </Box>
        </Collapse>
      </React.Fragment>
    );
  };

  render = (): React.ReactNode => this.renderLastVisited();
}

const mapStateToProps = ({ commonStore }: Store) => ({
  lastVisited: commonStore.lastVisited[commonStore.activeTab],
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  closeLeftNavOpen: () => dispatch(storeSetLeftNavOpen(false)),
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(LastVisistedList)));
