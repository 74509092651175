import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { UserService } from '../../../services';
import { Dialog, TabsHeader } from '../../../components';
import { PropertiesTab } from '../../baseClasses/PropertiesTab';
import { UserGroupsTab } from './UserDetailedView';
import { UserSecurityTab } from './UserSettingsView';
import { itemIcons } from '../../../common/constants';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../baseClasses/ViewBase';
import userSchema from '../../../assets/schemas/user.schema.parsed.json';

type _Props = unknown;
type Props = ViewBaseProps<_Props, { userId: string }>;

type _State = unknown;
type State = ViewBaseState<_State>;

class SettingsView extends ViewBase<_Props, _State, { userId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.properties', 'tab.userGroups', 'tab.security'];
    UserService.create().then((service) => (this.service = service));
    this.subservice = 'user';
    this.messages = {
      load: { error: 'error.fetch.user' },
      update: { success: 'success.update.user', error: 'error.tryAgain' },
      delete: { success: 'success.delete.user', error: 'error.tryAgain' },
    };
    this.id = 'self';
  }

  toggleBetweenEnabledDisabled = async (): Promise<void> => {
    this.setState({ loading: true });
    await this.update({ enabled: !this.state.item!.enabled });
    this.setState({ loading: false });
  };

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('user', 'update'))
      list.push(
        <MenuItem key="1" disabled={!this.state.item} onClick={this.toggleBetweenEnabledDisabled}>
          <Trans>{!!this.state.item && !this.state.item!.enabled ? 'action.enable' : 'action.disable'}</Trans>
        </MenuItem>,
      );
    if (this.accessControl('user', 'delete'))
      list.push(
        <MenuItem key="2" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <PropertiesTab
        tab={0}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="user"
        parentLoading={this.state.loading}
        schema={userSchema}
        schemaKey="user"
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
      <UserGroupsTab
        tab={1}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
        userGroups={this.state.item && this.state.item.groups}
        reload={this.load}
      />
      <UserSecurityTab
        tab={2}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
        reload={this.load}
        item={this.state.item}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <Dialog
      open={this.state.deleteDialogOpen}
      title={<Trans>action.delete</Trans>}
      description={<Trans>description.delete.user</Trans>}
      continueTitle={<Trans>action.delete</Trans>}
      onClose={() => this.setState({ deleteDialogOpen: false })}
      onContinue={() => this.delete()}
    />
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={
          this.state.loading ? (
            this.state.item ? (
              this.state.item.email
            ) : (
              <CircularProgress size={24} />
            )
          ) : this.state.item ? (
            this.state.item.email
          ) : (
            'undefined'
          )
        }
        icon={itemIcons.user}
        subtitle={this.state.item ? i18next.t('form.email') : undefined}
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

export default withStyles(ViewBaseStyles)(withRouter(connect(null, ViewBaseMapDispatchToProps)(SettingsView)));
