import React, { Component } from 'react';
import {
  Paper,
  IconButton,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { PostRelease, Product } from '../../common/types';
import { FirmwareService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  activeStep: number;
  release: PostRelease;
  file?: {
    data: any;
    progress?: number;
  };
  product?: Product;
  products: Product[];
};

export class CreateFirmwareReleaseMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    activeStep: 0,
    release: {
      name: '',
      description: '',
      productId: '',
      type: '',
      toVersion: '',
    },
    product: undefined,
    products: [],
  };
  firmwareService!: FirmwareService;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    const { data } = await this.firmwareService.product.list({ params: { limit: 500 } });
    let product: Product = data.data[0];

    if (!!this.store.modalStore.data && !!this.store.modalStore.data.product)
      product = this.store.modalStore.data.product!;

    if (this._isMounted)
      this.setState({
        products: data.data,
        product,
        release: {
          ...this.state.release,
          productId: product!.productId,
          type:
            this.store.modalStore.data && this.store.modalStore.data.package
              ? this.store.modalStore.data && this.store.modalStore.data.package.type
              : product!.updateTypes[0],
        },
      });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.firmwareService.release.post(this.state.release);
      SnackbarUtils.success(i18next.t('success.create.release'));
      if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  get isValid(): boolean {
    return (
      !this.state.loading &&
      !!this.state.release.toVersion &&
      !!this.state.release.productId &&
      !!this.state.release.type &&
      !!this.state.release.name
    );
  }

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.createFirmwareRelease.create.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.createFirmwareRelease.create.description</Trans>
            </Typography>
          </Grid>
          {this.state.product && (
            <Grid item xs={6}>
              <Autocomplete
                id="cfr"
                disableClearable
                options={this.state.products}
                getOptionLabel={(option: Product) => (!!option && option.productId) || ''}
                value={this.state.product}
                isOptionEqualToValue={(option: Product, value: Product) => option.productId === value.productId}
                disabled={!!this.store.modalStore.data && !!this.store.modalStore.data.product}
                onChange={(_: any, product: Product) =>
                  this.setState({ product, release: { ...this.state.release, productId: product.productId } })
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputLabelProps={{ shrink: true, htmlFor: 'cfr' }}
                    label={`${i18next.t('form.product')}*`}
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                shrink
                id="type-label"
                style={{ backgroundColor: 'white', paddingLeft: 4, paddingRight: 4, marginLeft: -4 }}
              >
                <Trans>form.type</Trans>*
              </InputLabel>
              <Select
                labelId="type-label"
                id="type"
                value={this.state.release.type}
                onChange={(e) => this.setState({ release: { ...this.state.release, type: e.target.value as string } })}
              >
                {((this.state.product && this.state.product.updateTypes) || []).map((updateType) => (
                  <MenuItem key={updateType} value={updateType}>
                    {updateType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={`${i18next.t('form.version')}*`}
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.release.toVersion}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ release: { ...this.state.release, toVersion: e.target.value } })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={`${i18next.t('form.name')}*`}
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.release.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ release: { ...this.state.release, name: e.target.value } })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={i18next.t('form.description')}
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.release.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ release: { ...this.state.release, description: e.target.value } })
              }
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={!this.isValid} onClick={this.create}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.create</Trans>}
            </Button>
          </Grid>
          {this.state.activeStep === 1 ? (
            <Grid item>
              <Button color="secondary" onClick={this.props.closeModal}>
                <Trans>action.skip</Trans>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateFirmwareReleaseMC);
