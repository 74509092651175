import { AuthenticationServiceProvider } from '.';
import { AxiosResponse } from 'axios';
import {
  APIPath,
  RoutePathPrefix,
  Subscription,
  SubscriptionDetailed,
  DeviceApiGw,
  DeviceApiGwDetailed,
  Trust,
  TrustDetailed,
} from '../common/types';
import { BaseCRUDService } from './common/BaseCRUDService';

class SubscriptionDataService extends BaseCRUDService<
  Subscription,
  Subscription,
  SubscriptionDetailed,
  Subscription,
  Subscription
> {}

class DeviceApiDataService extends BaseCRUDService<
  DeviceApiGw,
  DeviceApiGw,
  DeviceApiGwDetailed,
  DeviceApiGw,
  DeviceApiGw
> {
  test = (apiId: string, params?: any, data?: any): Promise<AxiosResponse<any>> =>
    this.authenticationServiceProvider.Post<any>({ params, data, path: APIPath.data.deviceApiGwtest(apiId) });
}

class TrustService extends BaseCRUDService<Trust, Trust, TrustDetailed, Trust, Trust> {}

export class DataService {
  subscription: SubscriptionDataService;
  deviceApiGw: DeviceApiDataService;
  trust: TrustService;

  constructor(public authenticationServiceProvider: AuthenticationServiceProvider) {
    this.subscription = new SubscriptionDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.data.subscriptions,
      get: APIPath.data.subscriptionDetailed,
    });
    this.deviceApiGw = new DeviceApiDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.data.deviceApiGw,
      get: APIPath.data.deviceApiGwDetailed,
    });
    this.trust = new TrustService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.data.trust,
      get: APIPath.data.trustDetailed,
    });
  }

  static create = async (): Promise<DataService> => {
    const authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (!authenticationServiceProvider) throw new Error('No cached AuthenticationService available in DataService');
    return new DataService(authenticationServiceProvider);
  };
}
