import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'typeface-roboto';

window.history.pushState = ((f) =>
  function pushState() {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    const ret = f.apply(this, arguments);
    window.dispatchEvent(new Event('pushstate'));
    document.dispatchEvent(new Event('pushstate'));
    window.dispatchEvent(new Event('locationchange'));
    return ret;
  })(window.history.pushState);

/*
window.history.replaceState = ( f => function replaceState(){
  // @ts-ignore
  var ret = f.apply(this, arguments);
  window.dispatchEvent(new Event('replacestate'));
  document.dispatchEvent(new Event('replacestate'));
  window.dispatchEvent(new Event('locationchange'));
  return ret;
})(window.history.replaceState);
*/

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
