import React, { Component } from 'react';
import { Box, Button, Typography, TextField, Link, Grid, CircularProgress, AppBar, Toolbar } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from 'react-i18next';
import { CognitoSessionChallange } from '../services/authentication/authenticationUtils';
import { AuthenticationServiceProvider, CognitoServiceProvider } from '../services';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { transparentSignInBackground, passwordRegex } from '../common/constants';
import { SubdomainRoutePath, Store, CustomerConfiguration } from '../common/types';
import { getLogo } from '../common/theme';
import { getTheme } from '../common/theme';

import bg from '../assets/images/sign-in-bg.jpg';
import sonyLogo from '../assets/images/sony_logo_white_RGB.png';

enum SignInState {
  SIGN_IN,
  CHANGE_PASSWORD,
  RESET_REQUIRE,
  FORGOT_PASSWORD,
  VERIFY_FORGOT_PASSWORD,
  MFA,
}

const ActionButton = (props: any) => <Button color="secondary" variant="contained" {...props} />;

const styles = () => {
  const theme = getTheme();
  return {
    logo: {
      height: 54,
    },
    background: {
      height: 'calc(100vh - 60px)',
      background: `url(${bg}) no-repeat center center fixed`,
      backgroundSize: 'cover',
    },
    formContainer: {
      backgroundColor: transparentSignInBackground,
    },
    gridContainer: {
      flexGrow: 1,
    },
    marginTop: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginTop: '15vh',
      },
    },
    link: {
      textDecoration: 'none !important' as 'none',
      color: theme.palette.secondary.main,
    },
    copyright: {
      textAlign: 'center' as const,
      '& > span': {
        fontSize: 10,
        margin: '0 auto',
      },
    },
    pointer: {
      cursor: 'pointer' as const,
    },
  };
};

type Props = WithStyles<any> &
  RouteComponentProps & {
    config?: CustomerConfiguration;
  };

interface State {
  state: SignInState;
  showError: boolean;
  errorMessages: string[];
  email: string;
  password: string;
  mfaCode: string;
  newPassword: string;
  code: string;
  loading: boolean;
  mfaInfo: boolean;
}

const initState: State = {
  state: SignInState.SIGN_IN,
  showError: false,
  errorMessages: [],
  email: '',
  password: '',
  mfaCode: '',
  newPassword: '',
  code: '',
  loading: false,
  mfaInfo: false,
};

export class SignInCognitoView extends Component<Props, State> {
  state: State = initState;
  cognito!: CognitoServiceProvider;

  UNSAFE_componentWillMount = (): void => {
    if (AuthenticationServiceProvider.checkCache()) this.props.history.push(SubdomainRoutePath.base());
  };

  componentDidMount = (): void => {
    this.cognito = new CognitoServiceProvider();
  };

  getSignInPath = (): string => {
    if (window.location.search.search('path') === -1) return SubdomainRoutePath.base();

    const urlParams = new URLSearchParams(window.location.search);
    let path = urlParams.get('path');
    urlParams.delete('path');
    path += Array.from(urlParams.keys()).length
      ? `?${urlParams.toString()}${window.location.hash}`
      : window.location.hash;

    return path || SubdomainRoutePath.base();
  };

  signIn = async (): Promise<void> => {
    const { loading, email, password, mfaCode } = this.state;
    if (loading) {
      console.log('already loading');
      return;
    }
    if (!email || !password) {
      return this.setState({ showError: true, errorMessages: ['Missing credentials'], loading: false });
    }
    if (this.state.state === SignInState.MFA && !mfaCode)
      return this.setState({ showError: true, loading: false, errorMessages: ['Missing validation code'] });
    this.setState({ loading: true, showError: false, errorMessages: [] });
    try {
      const sessionOrChallange = await this.cognito.authenticate(email.toLocaleLowerCase(), password, mfaCode);
      switch (sessionOrChallange) {
        case CognitoSessionChallange.CHANGE_PASSWORD:
          this.setState({ state: SignInState.CHANGE_PASSWORD, showError: false, loading: false });
          break;
        case CognitoSessionChallange.RESET_REQUIRE:
          this.setState({ state: SignInState.RESET_REQUIRE, showError: false, loading: false });
          break;
        case CognitoSessionChallange.MFA_REQUIRE:
          this.setState({ state: SignInState.MFA, showError: false, loading: false });
          break;
        default:
          this.setState({ showError: false, loading: false });
          this.props.history.push(this.getSignInPath());
      }
    } catch (e) {
      const error: any = e;
      if (this.cognito.cognitoUser) {
        this.cognito.cognitoUser.signOut();
      }
      const errorMessages: string[] = [];

      if (error && error.code) {
        if (error.code === 'CodeMismatchException') {
          errorMessages.push('Incorrect validation code');
        } else if (['NotAuthorizedException', 'UserNotFoundException'].includes(error.code)) {
          errorMessages.push('Incorrect email and/or password');
        } else if (error.message) {
          errorMessages.push(error.message);
        }
      }
      this.setState({
        state: error.code === 'CodeMismatchException' ? SignInState.MFA : SignInState.SIGN_IN,
        loading: false,
        showError: true,
        errorMessages,
      });
    }
  };
  changePassword = async (): Promise<void> => {
    const { loading, newPassword } = this.state;
    if (loading) return;
    if (!newPassword) return this.setState({ showError: true, errorMessages: ['Missing new password'] });
    if (!passwordRegex.test(newPassword))
      return this.setState({ showError: true, errorMessages: ["Password doesn't meet the requirements"] });
    this.setState({ loading: true, showError: false, errorMessages: [] });
    try {
      await this.cognito.newPasswordChallange(newPassword);
      this.props.history.push(SubdomainRoutePath.base());
    } catch (e) {
      const error: any = e;
      const errorMessages: string[] = [];
      errorMessages.push(error.code ? 'Password was not valid, please try a new password' : error.message);
      this.setState({ loading: false, showError: true, errorMessages });
    }
  };

  forgotPassword = async (): Promise<void> => {
    const { loading, email } = this.state;
    if (loading || !email) return;
    this.setState({ loading: true });
    try {
      await this.cognito.forgotPassword(email);
      this.setState({
        state: SignInState.VERIFY_FORGOT_PASSWORD,
        loading: false,
        showError: false,
        errorMessages: [],
      });
    } catch (e) {
      const error: any = e;
      this.setState({
        loading: false,
        showError: true,
        errorMessages: [error.message ? error.message : 'Something went wrong'],
      });
    }
  };

  verifyPassword = async (): Promise<void> => {
    const { loading, newPassword, code } = this.state;
    if (loading) return;
    if (!newPassword) return this.setState({ showError: true, errorMessages: ['Missing new password'] });
    if (!passwordRegex.test(newPassword))
      return this.setState({ showError: true, errorMessages: ["Password doesn't meet the requirements"] });
    try {
      await this.cognito.verifyForgotPassword(newPassword, code);
      this.setState({ state: SignInState.SIGN_IN, loading: false, showError: false, errorMessages: [] });
    } catch (e) {
      const error: any = e;
      const errorMessages: string[] = [];
      if (error.code === 'ExpiredCodeException') {
        await this.cognito.forgotPassword(this.state.email);
        errorMessages.push('Code has expired, new code has been sent');
      } else {
        errorMessages.push(error.code ? 'Password was not valid, please try a new password' : error.message);
      }
      this.setState({ loading: false, showError: true, errorMessages });
    }
  };

  renderButtons = (): React.ReactNode => {
    const { loading, newPassword } = this.state;
    switch (this.state.state) {
      case SignInState.SIGN_IN:
        return (
          <Grid container justifyContent="flex-end" spacing={3}>
            <Grid item>
              <ActionButton onClick={this.signIn}>
                {loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.signIn</Trans>}
              </ActionButton>
            </Grid>
          </Grid>
        );
      case SignInState.CHANGE_PASSWORD:
        return (
          <Grid container justifyContent="flex-end" spacing={3}>
            <Grid item>
              <Button color="secondary" onClick={() => this.setState(initState)} disabled={loading}>
                <Trans>action.cancel</Trans>
              </Button>
            </Grid>
            <Grid item>
              <ActionButton disabled={!passwordRegex.test(newPassword)} onClick={this.changePassword}>
                {loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.continue</Trans>}
              </ActionButton>
            </Grid>
          </Grid>
        );
      case SignInState.RESET_REQUIRE:
        return (
          <Grid container justifyContent="flex-end" spacing={3}>
            <Grid item>
              <Button color="secondary" onClick={() => this.setState(initState)} disabled={loading}>
                <Trans>action.cancel</Trans>
              </Button>
            </Grid>
            <Grid item>
              <ActionButton onClick={this.verifyPassword}>
                {loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.continue</Trans>}
              </ActionButton>
            </Grid>
          </Grid>
        );
      case SignInState.FORGOT_PASSWORD:
        return (
          <Grid container justifyContent="flex-end" spacing={3}>
            <Grid item>
              <Button color="secondary" onClick={() => this.setState(initState)} disabled={loading}>
                <Trans>action.cancel</Trans>
              </Button>
            </Grid>
            <Grid item>
              <ActionButton onClick={this.forgotPassword}>
                {loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.continue</Trans>}
              </ActionButton>
            </Grid>
          </Grid>
        );
      case SignInState.VERIFY_FORGOT_PASSWORD:
        return (
          <Grid container justifyContent="flex-end" spacing={3}>
            <Grid item>
              <Button color="secondary" onClick={() => this.setState(initState)} disabled={loading}>
                <Trans>action.cancel</Trans>
              </Button>
            </Grid>
            <Grid item>
              <ActionButton onClick={this.verifyPassword}>
                {loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.continue</Trans>}
              </ActionButton>
            </Grid>
          </Grid>
        );
      case SignInState.MFA:
        return this.state.mfaInfo ? (
          <div />
        ) : (
          <Grid container justifyContent="flex-end" spacing={3}>
            <Grid item>
              <ActionButton onClick={this.signIn}>
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Verify'}
              </ActionButton>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  renderSignInState = (): React.ReactNode => (
    <>
      <Box ml={3} mr={3} mt="10vh" mb={0}>
        <TextField
          fullWidth
          error={this.state.showError && !this.state.email}
          label={i18next.t('form.email')}
          type="text"
          autoComplete="email"
          margin="normal"
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') this.signIn();
          }}
          variant="standard"
        />
      </Box>
      <Box ml={3} mr={3} mt={0} mb={0}>
        <TextField
          fullWidth
          error={this.state.showError && !this.state.password}
          label={i18next.t('form.password')}
          type="password"
          autoComplete="current-password"
          margin="normal"
          value={this.state.password}
          onChange={(e) => this.setState({ password: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') this.signIn();
          }}
          variant="standard"
        />
      </Box>
      <Box ml={3} mr={3} mt={0} mb={1}>
        <Link
          href="javascript:;"
          color="secondary"
          underline="none"
          className={this.props.classes.pointer}
          onClick={() =>
            this.setState({ state: SignInState.FORGOT_PASSWORD, newPassword: '', showError: false, errorMessages: [] })
          }
        >
          <Trans>form.forgotPassword</Trans>?
        </Link>
      </Box>
    </>
  );

  renderMFAState = (): React.ReactNode => (
    <>
      <Box ml={3} mr={3} mt="10vh" mb={0}>
        <TextField
          fullWidth
          error={this.state.showError && !this.state.mfaCode}
          label="Authentication code"
          type="text"
          margin="normal"
          value={this.state.mfaCode}
          onChange={(e) => this.setState({ mfaCode: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') this.signIn();
          }}
          autoComplete="one-time-code"
          variant="standard"
        />
      </Box>
      {this.state.mfaInfo && (
        <Box ml={3} mr={3} mt={0} mb={1}>
          <Typography variant="body2" paragraph>
            Authentication code can be found in the mfa device that you used when enabling two-factor (mfa)
            authentication, for example Google or Microsoft Authenticator.
          </Typography>
          <br />
          <Typography variant="body2" paragraph>
            If you lose access to your mfa device please talk to an admin.
          </Typography>
        </Box>
      )}
      <Box ml={3} mr={3} mt={0} mb={1}>
        <Link
          href="javascript:;"
          color="secondary"
          className={this.props.classes.pointer}
          onClick={() => this.setState({ mfaInfo: !this.state.mfaInfo })}
          underline="none"
        >
          {i18next.t(this.state.mfaInfo ? 'action.close' : 'action.moreInfo')}
        </Link>
      </Box>
    </>
  );
  renderUpdatePasswordState = (): React.ReactNode => (
    <>
      <Box ml={3} mr={3} mt="10vh" mb={0}>
        <Typography variant="body2" paragraph>
          <Trans>view.signInView.updatePassword</Trans>
        </Typography>
      </Box>
      <Box ml={3} mr={3} mt={0} mb={0}>
        <TextField
          fullWidth
          error={this.state.showError && !passwordRegex.test(this.state.newPassword)}
          label={i18next.t('form.newPassword')}
          type="password"
          autoComplete="new password"
          margin="normal"
          value={this.state.newPassword}
          onChange={(e) => this.setState({ newPassword: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') this.changePassword();
          }}
          variant="standard"
        />
      </Box>
    </>
  );

  renderResetRequireState = (): React.ReactNode => (
    <>
      <Box ml={3} mr={3} mt="10vh" mb={0}>
        <Typography variant="body2" paragraph>
          <Trans>view.signInView.resetRequire</Trans>
        </Typography>
      </Box>
      <Box ml={3} mr={3} mt={0} mb={0}>
        <TextField
          fullWidth
          error={this.state.showError && !this.state.code}
          label={i18next.t('form.verificationCode')}
          type="text"
          autoComplete="verification-code"
          margin="normal"
          value={this.state.code}
          onChange={(e) => this.setState({ code: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') this.verifyPassword();
          }}
          variant="standard"
        />
      </Box>
      <Box ml={3} mr={3} mt="10vh" mb={0}>
        <TextField
          fullWidth
          error={this.state.showError && !passwordRegex.test(this.state.newPassword)}
          label={i18next.t('form.newPassword')}
          type="password"
          autoComplete="new password"
          margin="normal"
          value={this.state.newPassword}
          onChange={(e) => this.setState({ newPassword: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') this.verifyPassword();
          }}
          variant="standard"
        />
      </Box>
    </>
  );

  renderForgotPasswordState = (): React.ReactNode => (
    <>
      <Box ml={3} mr={3} mt="10vh" mb={0}>
        <Typography variant="body2" paragraph>
          <Trans>view.signInView.forgotPassword</Trans>
        </Typography>
      </Box>
      <Box ml={3} mr={3} mt={0} mb={0}>
        <TextField
          fullWidth
          error={this.state.showError && !this.state.email}
          label={i18next.t('form.email')}
          type="text"
          autoComplete="email"
          margin="normal"
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') this.forgotPassword();
          }}
          variant="standard"
        />
      </Box>
    </>
  );

  renderVerifyForgotPasswordState = (): React.ReactNode => (
    <>
      <Box ml={3} mr={3} mt="10vh" mb={0}>
        <Typography variant="body2" paragraph>
          <Trans>view.signInView.verifyForgotPassword</Trans>
        </Typography>
      </Box>
      <Box ml={3} mr={3} mt={0} mb={0}>
        <TextField
          fullWidth
          error={this.state.showError && !this.state.code}
          label={i18next.t('form.verificationCode')}
          type="text"
          autoComplete="verification-code"
          margin="normal"
          value={this.state.code}
          onChange={(e) => this.setState({ code: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') this.verifyPassword();
          }}
          variant="standard"
        />
      </Box>
      <Box ml={3} mr={3} mt={0} mb={0}>
        <TextField
          fullWidth
          error={this.state.showError && !passwordRegex.test(this.state.newPassword)}
          label={i18next.t('form.newPassword')}
          type="password"
          autoComplete="new password"
          margin="normal"
          value={this.state.newPassword}
          onChange={(e) => this.setState({ newPassword: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') this.verifyPassword();
          }}
          variant="standard"
        />
      </Box>
    </>
  );

  renderStateContent = (): React.ReactNode | null => {
    switch (this.state.state) {
      case SignInState.SIGN_IN:
        return this.renderSignInState();
      case SignInState.CHANGE_PASSWORD:
        return this.renderUpdatePasswordState();
      case SignInState.RESET_REQUIRE:
        return this.renderResetRequireState();
      case SignInState.FORGOT_PASSWORD:
        return this.renderForgotPasswordState();
      case SignInState.VERIFY_FORGOT_PASSWORD:
        return this.renderVerifyForgotPasswordState();
      case SignInState.MFA:
        return this.renderMFAState();
      default:
        return null;
    }
  };

  render = (): React.ReactNode => (
    <>
      <AppBar position="static" style={{ height: 60, backgroundColor: 'black', boxShadow: 'none' }}>
        <Toolbar style={{ height: 60, minHeight: 60 }}>
          <img src={sonyLogo} alt="sony logo" style={{ height: '85%' }} />
        </Toolbar>
      </AppBar>
      <Box width="100%" className={this.props.classes.background}>
        <Grid container justifyContent="flex-start" style={{ height: '100%' }}>
          <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              color="primary"
              p={2}
              className={this.props.classes.formContainer}
            >
              <Grid container alignContent="space-between" className={this.props.classes.gridContainer}>
                <Grid item xs={12}>
                  <Box m={3} className={this.props.classes.marginTop} mb={1}>
                    <img className={this.props.classes.logo} src={getLogo()} alt="logo" />
                    <Typography variant="subtitle1">
                      <Trans>Portal</Trans>
                    </Typography>
                  </Box>
                  {this.renderStateContent()}
                  <Box ml={3} mr={3} mt={1} mb={1}>
                    {this.state.errorMessages.map((message) => (
                      <Typography key={message} variant="caption" color="error">
                        <Trans>{message}</Trans>
                      </Typography>
                    ))}
                  </Box>
                  <Box m={3} mt={2}>
                    {this.renderButtons()}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box m={2} display="flex" justifyContent="center">
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item>
                        <a href="https://www.sonynetworkcom.com/legal" className={this.props.classes.link}>
                          <Typography variant="caption">Legal</Typography>
                        </a>
                      </Grid>
                      <Grid item>
                        <a href="https://www.sonynetworkcom.com/legal/privacy" className={this.props.classes.link}>
                          <Typography variant="caption">Privacy policy</Typography>
                        </a>
                      </Grid>
                      <Grid item>
                        <a href="https://www.sonynetworkcom.com/legal/cookies" className={this.props.classes.link}>
                          <Typography variant="caption">Cookies</Typography>
                        </a>
                      </Grid>
                      <Grid item xs={12} className={this.props.classes.copyright}>
                        <Typography variant="caption">
                          Copyright © 2020 Sony Network Communications Europe. All rights reserved
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const mapStateToProps = ({ userStore }: Store) => ({
  config: userStore.config,
});

export default withStyles(styles)(connect(mapStateToProps, null)(SignInCognitoView));
