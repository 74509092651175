/* eslint-disable react/display-name */
import React from 'react';
import {
  SettingsView,
  SignInCognitoView,
  DeviceView,
  DeviceDetailedView,
  DeviceGroupView,
  DeviceGroupDetailedView,
  UserView,
  UserDetailedView,
  UserGroupView,
  UserGroupDetailedView,
  ConfigurationView,
  ApiSpecView,
  SubscriptionView,
  SubscriptionDetailedView,
  ShadowAliasView,
  ShadowTemplateDetailedView,
  ShadowTemplateView,
  HomeView,
  FirmwarePackageDetailedView,
  FirmwareProductDetailedView,
  FirmwareProductView,
  FirmwareReleaseDetailedView,
  DeviceApiGwView,
  DeviceApiGwDetailedView,
  KeyView,
  KeyDetailedView,
  TrustsView,
  TrustsDetailedView,
  PublicView,
  ValidatedView,
} from '../../views';
import { RouteType, RouteParentType, SubdomainRoutePath, PublicRoutePath, Role } from '../types';
import { AccessControl } from '../permissions';

// Used when building up public site routes and drawer
export const publicRoutes = (): (RouteType | RouteParentType)[] => {
  return [
    {
      path: PublicRoutePath.base(),
      component: PublicView,
      routes: [
        {
          name: 'routes.home',
          path: PublicRoutePath.base(),
          exact: true,
          component: HomeView,
        },
        {
          name: 'routes.deviceLicenses',
          path: PublicRoutePath.deviceLicenses(),
          exact: true,
          href: 'https://www.sonynetworkcom.com/msafety/oss/',
          component: HomeView,
        },
      ],
    },
  ];
};

// Used when building up site subdomain routes and drawer
export const _subdomainRoutes = (routes: RouteParentType[] = []): (RouteType | RouteParentType)[] => [
  {
    path: [SubdomainRoutePath.signIn(), SubdomainRoutePath.signInDeepLink()],
    component: SignInCognitoView,
  },
  {
    path: SubdomainRoutePath.base(),
    component: ValidatedView,
    authenticated: true,
    routes: [
      {
        name: 'routes.operations',
        path: SubdomainRoutePath.operations(),
        routes: [
          {
            name: 'routes.deviceManagement',
            path: SubdomainRoutePath.deviceManagement(),
            routes: [
              {
                name: 'routes.devices',
                path: SubdomainRoutePath.devices(),
                exact: true,
                component: DeviceView,
                roles: AccessControl.list('device'),
              },
              {
                path: SubdomainRoutePath.device(),
                exact: true,
                component: DeviceDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('device'),
              },
              {
                name: 'routes.deviceGroups',
                path: SubdomainRoutePath.deviceGroups(),
                exact: true,
                component: DeviceGroupView,
                roles: AccessControl.list('deviceGroup'),
              },
              {
                path: SubdomainRoutePath.deviceGroup(),
                exact: true,
                component: DeviceGroupDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('deviceGroup'),
              },
              {
                name: 'routes.shadowAliases',
                path: SubdomainRoutePath.shadowAliases(),
                exact: true,
                component: ShadowAliasView,
                roles: AccessControl.list('shadowAlias'),
              },
              {
                name: 'routes.shadowTemplates',
                path: SubdomainRoutePath.shadowTemplates(),
                exact: true,
                component: ShadowTemplateView,
                roles: AccessControl.list('shadowTemplate'),
              },
              {
                path: SubdomainRoutePath.shadowTemplate(),
                exact: true,
                component: ShadowTemplateDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('shadowTemplate'),
              },
            ],
          },
          {
            name: 'routes.userManagement',
            path: SubdomainRoutePath.userManagement(),
            routes: [
              {
                name: 'routes.users',
                path: SubdomainRoutePath.users(),
                exact: true,
                component: UserView,
                roles: AccessControl.list('user'),
              },
              {
                path: SubdomainRoutePath.user(),
                exact: true,
                component: UserDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('user'),
              },
              {
                name: 'routes.userGroups',
                path: SubdomainRoutePath.userGroups(),
                exact: true,
                component: UserGroupView,
                roles: AccessControl.list('userGroup'),
              },
              {
                path: SubdomainRoutePath.userGroup(),
                exact: true,
                component: UserGroupDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('userGroup'),
              },
            ],
          },
          {
            name: 'routes.dataManagement',
            path: SubdomainRoutePath.dataManagement(),
            routes: [
              {
                name: 'routes.subscriptions',
                path: SubdomainRoutePath.subscriptions(),
                exact: true,
                component: SubscriptionView,
                roles: AccessControl.list('subscription'),
              },
              {
                path: SubdomainRoutePath.subscription(),
                exact: true,
                component: SubscriptionDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('subscription'),
              },
              {
                name: 'routes.deviceApiGw',
                path: SubdomainRoutePath.deviceApisGw(),
                exact: true,
                component: DeviceApiGwView,
                roles: AccessControl.list('deviceApiGw'),
              },
              {
                path: SubdomainRoutePath.deviceApiGw(),
                exact: true,
                component: DeviceApiGwDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('deviceApiGw'),
              },
              {
                name: 'routes.trusts',
                path: SubdomainRoutePath.trusts(),
                exact: true,
                component: TrustsView,
                roles: AccessControl.list('deviceApiGw'),
              },
              {
                path: SubdomainRoutePath.trust(),
                exact: true,
                component: TrustsDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('deviceApiGw'),
              },
            ],
          },
          {
            name: 'routes.firmwareManagement',
            path: SubdomainRoutePath.firmwareManagement(),
            routes: [
              {
                name: 'routes.firmwareProducts',
                path: SubdomainRoutePath.firmwareProducts(),
                exact: true,
                component: FirmwareProductView,
                roles: AccessControl.list('product'),
              },
              {
                path: SubdomainRoutePath.firmwareProduct(),
                exact: true,
                component: FirmwareProductDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('product'),
              },
              {
                path: SubdomainRoutePath.firmwarePackage(),
                exact: true,
                component: FirmwarePackageDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('package'),
              },
              {
                path: SubdomainRoutePath.firmwareRelease(),
                exact: true,
                component: FirmwareReleaseDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('release'),
              },
              {
                name: 'routes.keys',
                path: SubdomainRoutePath.keys(),
                exact: true,
                component: KeyView,
                roles: AccessControl.list('key'),
              },
              {
                path: SubdomainRoutePath.key(),
                exact: true,
                component: KeyDetailedView,
                hideInMenu: true,
                roles: AccessControl.read('key'),
              },
            ],
          },
          {
            name: 'routes.customerConfiguration',
            path: SubdomainRoutePath.customerConfig(),
            exact: true,
            component: ConfigurationView,
            roles: AccessControl.read('configuration'),
          },
        ],
      },
      {
        name: 'routes.docs',
        path: SubdomainRoutePath.docsAndDownloads(),
        routes: [
          {
            name: 'routes.apiSpec',
            path: SubdomainRoutePath.apiSpec(),
            roles: AccessControl.read('apiSpec'),
            hideInMenu: true,
            routes: [
              {
                name: 'routes.apiFleet',
                path: SubdomainRoutePath.apiSpec('fleet'),
                exact: true,
                render: (props: any) => <ApiSpecView type="fleet" {...props} />,
              },
              {
                name: 'routes.apiFleetApiGw',
                path: SubdomainRoutePath.apiSpec('fleet-api-gw'),
                exact: true,
                render: (props: any) => <ApiSpecView type="fleet-api-gw" {...props} />,
              },
              {
                name: 'routes.user',
                path: SubdomainRoutePath.apiSpec('user'),
                exact: true,
                render: (props: any) => <ApiSpecView type="user" {...props} />,
              },
              {
                name: 'routes.data',
                path: SubdomainRoutePath.apiSpec('data'),
                exact: true,
                render: (props: any) => <ApiSpecView type="data" {...props} />,
              },
              {
                name: 'routes.data',
                path: SubdomainRoutePath.apiSpec('firmware'),
                exact: true,
                render: (props: any) => <ApiSpecView type="firmware" {...props} />,
              },
            ],
          },
        ].concat(routes as any),
      },
      {
        path: SubdomainRoutePath.settings(),
        exact: true,
        component: SettingsView,
        hideInMenu: true,
      },
    ],
  },
];

function removeNotAllowedRoutes(
  routes: (RouteType | RouteParentType)[],
  role: Role | undefined = undefined,
): (RouteType | RouteParentType)[] {
  return routes
    .map((route) => {
      if (route.routes) {
        route.routes = removeNotAllowedRoutes(route.routes, role);
        if (!route.roles) route.roles = [];
        route.roles = route.roles!.concat(
          (route.routes as any[]).reduce((roles: Role[], route: RouteType | RouteParentType) => {
            return roles.concat(route.roles || []);
          }, []),
        );
      }

      return route;
    })
    .filter((route) => (route.roles && route.roles.includes(role as any)) || !route.roles);
}

export const subdomainRoutes = (
  routes: RouteParentType[] = [],
  role: Role | undefined = undefined,
): (RouteType | RouteParentType)[] => removeNotAllowedRoutes(_subdomainRoutes(routes), role);
