import React, { Component } from 'react';
import { Paper, IconButton, Typography, Grid, Button, CircularProgress, Select, MenuItem, Box } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { AuthenticationServiceProvider } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { Product } from '../../common/types';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  showError?: 'new' | 'old' | 'both';
  errorMessage?: string;
  data: { value: string; products: Product[]; update: (data: any) => Promise<void> } | undefined;
};

export class ChangeProductMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    errorMessage: undefined,
    showError: undefined,
    data: undefined,
  };
  authenticationServiceProvider?: AuthenticationServiceProvider;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    this.setState({ data: this.store.modalStore.data.callback() });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container style={{ flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
          <Grid container spacing={4} style={{ flexDirection: 'column', flexWrap: 'nowrap', flex: '1 0 auto' }}>
            <Grid item xs={12} style={{ flex: 1 }}>
              <Typography variant="h3">
                <Trans>modals.changeProduct.changeProduct</Trans>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ flex: 1, minHeight: '20%' }}>
              <Typography variant="body1">
                <Trans>modals.changeProduct.description</Trans>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <div style={{ marginTop: 5, marginBottom: 5 }}>
                  <Trans>modals.changeProduct.subheader</Trans>
                </div>
                <div>
                  <Select
                    id={`select`}
                    inputProps={{
                      id: `inputselect`,
                    }}
                    labelId={`select-label`}
                    value={this.state.data ? this.state.data.value : ''}
                    disabled={false}
                    onChange={(e) => {
                      this.setState({
                        data: this.state.data ? { ...this.state.data, value: e.target.value } : this.state.data,
                      });
                    }}
                    style={{ marginTop: 5, width: '100%', marginRight: '32px' }}
                  >
                    {this.state.data?.products
                      .map((product) => product.productId)
                      .map((option: string | { label: string; value: any; description?: string }) => (
                        <MenuItem
                          value={typeof option === 'object' ? option.value : option}
                          key={typeof option === 'object' ? option.value : option}
                        >
                          <div>
                            <div>{typeof option === 'object' ? option.label : option}</div>
                            {typeof option === 'object' && option?.description && (
                              <div style={{ color: '#696969' }}> {option?.description} </div>
                            )}
                          </div>
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Button
              style={{ marginBottom: '8px', marginRight: '8px' }}
              color="secondary"
              variant="contained"
              disabled={false}
              onClick={async () => {
                await this.state.data?.update({ productId: this.state.data.value });
                this.props.closeModal();
              }}
            >
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.update</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(ChangeProductMC);
