import { StoreName, ShadowAlias } from '../../common/types';

// action types
export enum actions {
  SET_SHADOW_ALIASES = 'SET_SHADOW_ALIASES',
}

// action creators
export const storeSetShadowAliases = (shadowAliases: ShadowAlias[]) => ({
  store: StoreName.DEVICE,
  type: actions.SET_SHADOW_ALIASES,
  shadowAliases,
});
