import { LastVisited, MenuToggle, StoreName, RoutePathPrefix } from '../../common/types';

// action types
export enum actions {
  ADD_LAST_VISITED = 'ADD_LAST_VISITED',
  REMOVE_LAST_VISITED = 'REMOVE_LAST_VISITED',
  SET_MENU_TOGGLE_LIST = 'SET_MENU_TOGGLE_LIST',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  SET_LEFT_NAV_STATE = 'SET_LEFT_NAV_STATE',
  SET_RIGHT_NAV_STATE = 'SET_RIGHT_NAV_STATE',
}

// action creators
export const storeAddLastVisited = (lastVisited: LastVisited, tab: RoutePathPrefix) => ({
  store: StoreName.COMMON,
  type: actions.ADD_LAST_VISITED,
  lastVisited,
  tab,
});

export const storeRemoveLastVisited = (id: string, tab: RoutePathPrefix) => ({
  store: StoreName.COMMON,
  type: actions.REMOVE_LAST_VISITED,
  id,
  tab,
});

export const storeSetMenuToggleList = (menuListToggle: { [key: string]: MenuToggle }) => ({
  store: StoreName.COMMON,
  type: actions.SET_MENU_TOGGLE_LIST,
  menuListToggle,
});

export const storeSetActiveTab = (activeTab: string) => ({
  store: StoreName.COMMON,
  type: actions.SET_ACTIVE_TAB,
  activeTab,
});

export const storeSetLeftNavOpen = (leftNavOpen: boolean) => ({
  store: StoreName.COMMON,
  type: actions.SET_LEFT_NAV_STATE,
  leftNavOpen,
});

export const storeSetRightNavOpen = (rightNavOpen: boolean) => ({
  store: StoreName.COMMON,
  type: actions.SET_RIGHT_NAV_STATE,
  rightNavOpen,
});
