export const Attached = 'ATTACHED';
export const Detached = 'DETACHED';
export type Action = typeof Attached | typeof Detached;
export type Trigger = {
  enabled: boolean;
  actionType: string;
  triggerType: string;
  groupChangeTrigger: { groupId: string; action: Action };
  shadowTemplateAction: { parameterSubstitutionMapping: Record<string, any>; shadowTemplateId: string };
  triggerId: string;
};
