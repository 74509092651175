import { ModalStoreAction, StoreName } from '../../common/types';
import { actions } from '../actions/modal';
import ModalInitialState from '../init/modal';

export default (state = ModalInitialState, { store, type, ...data }: ModalStoreAction) => {
  if (store !== StoreName.MODAL) return state;
  switch (type) {
    case actions.OPEN_MODAL:
      return { ...state, ...data, open: true };
    case actions.CLOSE_MODAL:
      return { ...state, version: undefined, open: false };
    default:
      return state;
  }
};
