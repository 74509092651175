import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@mui/material';
import i18next from 'i18next';
import { SubdomainRoutePath, User, ModalVariants } from '../../../../common/types';
import { TableNames, tableProperties } from '../../../../common/constants';
import { ListTable, TabPanel } from '../../../../components';
import { getDefaultTableProperties, UserService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';
import { storeOpenModal } from '../../../../store/actions/modal';

type Props = RouteComponentProps<{ groupName: string }> & {
  openModal: (groupName: string, callback: () => void) => () => void;
};

type State = {
  users: User[];
  initLoaded: boolean;
  nextToken?: string;
};

class UsersTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    users: [],
    initLoaded: false,
    nextToken: undefined,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    UserService.create().then((service) => (this.service = service));
  }

  reload = (): void =>
    this.setState({ users: [], initLoaded: false, nextToken: undefined }, () => this.setState({ initLoaded: true }));

  load = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const { data } = await this.service.userGroup.getUsers(this.props.match.params.groupName, {
        limit: 50,
        ...(this.state.nextToken ? { nextToken: this.state.nextToken! } : {}),
      });
      this.setState({ loading: false, users: data.data, nextToken: data.nextToken });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.fetch.users'),
      );
      this.setState({ loading: false });
    }
  };

  deleteUserFromUserGroup = async (userId: string): Promise<void> => {
    try {
      await this.service.userGroup.delUserFromUserGroup(this.props.match.params.groupName, userId);
      if (!this.isUnmounted) this.reload();
      SnackbarUtils.success(i18next.t('success.detach.userFromUserGroup'));
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
  };

  clickRow = (user: User): (() => Promise<void>) => async (): Promise<void> => {
    this.props.history.push(SubdomainRoutePath.user(user.userId));
  };

  renderContent = (): React.ReactNode => (
    <ListTable
      InfinityScrollProps={{
        initLoaded: this.state.initLoaded,
        nextToken: this.state.nextToken,
        loadFunc: this.load,
      }}
      TableProps={{ disableBackgroundPaper: true }}
      loading={this.state.loading}
      emptyTitle="form.empty.users"
      properties={getDefaultTableProperties(tableProperties(TableNames.user))}
      rows={this.state.users}
      rowActions={
        this.props.accessControl('userGroup', 'update')
          ? [
              {
                title: 'action.detach',
                action: (user: User) => this.deleteUserFromUserGroup(user.userId),
              },
            ]
          : []
      }
      clickRow={this.clickRow}
    />
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        customAction: (
          <Button color="secondary" size="small" variant="text" disabled={this.state.loading} onClick={this.reload}>
            {i18next.t('action.refresh')}
          </Button>
        ),
        ...(this.props.accessControl('userGroup', 'update')
          ? {
              actionProps: {
                actionTitle: 'action.attachUsers',
                onAction: () => this.props.openModal(this.props.match.params.groupName, this.reload),
              },
            }
          : {}),
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (groupName: string, callback: () => void) => () =>
    dispatch(storeOpenModal(ModalVariants.AddUsersToUserGroupMC, { groupName, callback })),
});

export default withRouter(connect(null, mapDispatchToProps)(UsersTab));
