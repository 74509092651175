export enum ModalVariants {
  AddDeviceGroupsToDeviceMC = 'AddDeviceGroupsToDeviceMC',
  AddDevicesToDeviceGroupMC = 'AddDevicesToDeviceGroupMC',
  AddUserGroupsToUserMC = 'AddUserGroupsToUserMC',
  AddUsersToUserGroupMC = 'AddUsersToUserGroupMC',
  ApplyShadowTemplateMC = 'ApplyShadowTemplateMC',
  ChangePasswordMC = 'ChangePasswordMC',
  ConfirmFirmwarePackageMC = 'ConfirmFirmwarePackageMC',
  CreateDeviceApiGwMC = 'CreateDeviceApiGwMC',
  CreateTrustMC = 'CreateTrustMC',
  CreateDeviceMC = 'CreateDeviceMC',
  AuthorizeDeviceMC = 'AuthorizeDeviceMC',
  CreateDeviceGroupMC = 'CreateDeviceGroupMC',
  CreateDeviceGroupCloneMC = 'CreateDeviceGroupCloneMC',
  CreateFirmwarePackageMC = 'CreateFirmwarePackageMC',
  CreateFirmwareProductMC = 'CreateFirmwareProductMC',
  CreateFirmwareReleaseMC = 'CreateFirmwareReleaseMC',
  CreateShadowAliasMC = 'CreateShadowAliasMC',
  CreateShadowTemplateMC = 'CreateShadowTemplateMC',
  CreateKeyMC = 'CreateKeyMC',
  CreateSubscriptionMC = 'CreateSubscriptionMC',
  CreateUserGroupMC = 'CreateUserGroupMC',
  DialogMC = 'DialogMC',
  EditReleaseMappingMC = 'EditReleaseMappingMC',
  FullScreenImageMC = 'FullScreenImageMC',
  InviteUserMC = 'InviteUserMC',
  OpenSourceMC = 'OpenSourceMC',
  PublishReleaseMC = 'PublishReleaseMC',
  UpdateEntryPackageMC = 'UpdateEntryPackageMC',
  ChangeProductMC = 'ChangeProductMC',
}
