import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import { DeviceService, FilterService } from '../../../services';
import { SubdomainRoutePath, ModalVariants, DeviceGroup } from '../../../common/types';
import { storeOpenModal } from '../../../store/actions/modal';
import { tableProperties, listFilter, itemIcons, TableNames } from '../../../common/constants';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';
import { SnackbarUtils } from '../../../components';

type _Props = {
  openModal: (modalVariant: ModalVariants, callback: (data?: any) => any) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class DeviceGroupView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
  };

  subservice!: string;

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.filterService = new FilterService(this, 'deviceGroup');
    this.accessControlSection = 'deviceGroup';
    this.title = i18next.t('deviceGroups');
    this.icon = itemIcons.deviceGroup;
    this.idKey = 'groupId';
    this.subservice = 'deviceGroup';
    this.filterConfig = {
      listFilter: listFilter.deviceGroup,
      availableProperties: Object.keys(tableProperties(TableNames.deviceGroup)),
    };
    this.tableConfig = {
      tableProperties: tableProperties(TableNames.deviceGroup),
      emptyTitle: 'form.empty.deviceGroups',
      rowActions: [
        {
          title: 'action.delete',
          action: async (deviceGroup: DeviceGroup) => this.setState({ dialogOpen: deviceGroup.groupId }),
        },
        {
          title: 'action.clone',
          action: (dg: DeviceGroup) => {
            this.props.openModal(ModalVariants.CreateDeviceGroupCloneMC, (newDg: DeviceGroup) => {
              if (newDg) {
                const items = this.state.items.concat(newDg);
                this.setState({ items });
              } else {
                return dg;
              }
            });
          },
        },
      ],
    };
    this.dialogConfig = {
      title: i18next.t('action.delete'),
      description: i18next.t('description.delete.deviceGroup'),
      continueTitle: i18next.t('action.delete'),
      onClose: () => this.setState({ dialogOpen: undefined }),
      onContinue: () => this.state.dialogOpen && this.removeDeviceGroup(this.state.dialogOpen),
    };
    this.fabConfig = [
      {
        title: i18next.t('action.createDeviceGroup'),
        action: () => {
          this.props.openModal(ModalVariants.CreateDeviceGroupMC, (newDg: DeviceGroup) => {
            const items = this.state.items.concat(newDg);
            this.setState({ items });
          });
        },
        icon: itemIcons.deviceGroup,
        tryAccessRight: ['deviceGroup', 'create'],
      },
    ];

    // Functions
    this.getUrlPathForItem = SubdomainRoutePath.deviceGroup;
  }

  componentDidMount = async () => {
    this.service = await DeviceService.create();
    this.list = this.service!.deviceGroup.list;

    const predefinedFilters = this.filterService!.get();
    const index = this.filterService!.getIndex();

    this.setState({ initLoaded: true, predefinedFilters, activeTabIndex: index });
  };

  removeDeviceGroup = async (deviceGroup: string): Promise<void> => {
    try {
      await this.service.deviceGroup.del(deviceGroup);
      SnackbarUtils.success(i18next.t('success.delete.deviceGroup'));
      if (!this.isUnmounted) {
        const items = this.state.items.filter((d: DeviceGroup) => d.groupId !== deviceGroup);
        this.setState({ items });
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (modalVariant: ModalVariants, callback: (data?: any) => any) =>
    dispatch(storeOpenModal(modalVariant, { callback })),
});

export default withStyles(ListBaseStyles)(withRouter(connect(null, mapDispatchToProps)(DeviceGroupView)));
