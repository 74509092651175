import React, { Component } from 'react';
//@ts-ignore
import SwaggerUI, { presets } from 'swagger-ui';
import { AuthenticationServiceProvider } from '../../../services';
import { APIPath } from '../../../common/types';
import { Grid, Box, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from 'react-i18next';
import { PageLoader } from '../../../components';
import 'swagger-ui/dist/swagger-ui.css';
import { getTheme } from '../../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    container: {
      padding: theme.spacing(2),
      width: '100%',
      '& div.scheme-container': {
        boxShadow: 'none',
      },
    },
  };
};

type Props = WithStyles<any> & { type: 'fleet' | 'fleet-api-gw' | 'user' | 'data' | 'firmware' };

type State = {
  loading: boolean;
  spec: any;
};

class ApiSpecView extends Component<Props, State> {
  state: State = {
    loading: false,
    spec: undefined,
  };
  authenticationServiceProvider?: AuthenticationServiceProvider;

  componentDidMount = async (): Promise<void> => {
    this.authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (!this.authenticationServiceProvider) throw new Error('Missing cogniot service in SwaggerView');
    this.setState({ loading: true });
    try {
      const { data: spec }: any = await this.authenticationServiceProvider.Get<any>({
        path: APIPath.spec[this.props.type](),
      });
      this.setState({ loading: false, spec }, () => {
        SwaggerUI({
          layout: 'BaseLayout',
          domNode: document.getElementById('swagger-specs'),
          presets: [presets.apis],
          spec,
        });
      });
    } catch {
      this.setState({ loading: false });
    }
  };

  render = (): React.ReactNode => {
    if (this.state.loading) {
      return (
        <Box p={4}>
          <PageLoader />
        </Box>
      );
    }
    if (!this.state.spec) {
      return (
        <Box p={4} display="flex" justifyContent="center" alignItems="center">
          <Trans>error.fetch.specs</Trans>
        </Box>
      );
    }
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10} xl={6}>
          <Box mt={2} mb={2}>
            <Paper elevation={2}>
              <div id="swagger-specs" className={this.props.classes.container} />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    );
  };
}

export default withStyles(styles)(ApiSpecView);
