import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { DataService } from '../../../services';
import { Dialog, TabsHeader } from '../../../components';
import { PropertiesTab } from '../../baseClasses/PropertiesTab';
import { itemIcons } from '../../../common/constants';
import { SubdomainRoutePath } from '../../../common/types';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../baseClasses/ViewBase';
import dataSchema from '../../../assets/schemas/data.schema.parsed.json';

type _Props = {
  openModal: (subscriptionId: string, callback: () => void) => () => void;
};
type Props = ViewBaseProps<_Props, { subscriptionId: string }>;

type _State = unknown;
type State = ViewBaseState<_State>;

class SubscriptionDetailedView extends ViewBase<_Props, _State, { subscriptionId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.properties'];
    DataService.create().then((service) => (this.service = service));
    this.subservice = 'subscription';
    this.messages = {
      load: { error: 'error.fetch.subscription' },
      update: { success: 'success.update.subscription', error: 'error.tryAgain' },
      delete: { success: 'success.delete.subscription', error: 'error.tryAgain' },
    };
  }

  toggleBetweenEnabledDisabled = async (): Promise<void> => {
    this.setState({ loading: true });
    await this.update({ enabled: !this.state.item!.enabled });
    this.setState({ loading: false });
  };

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('subscription', 'update'))
      list.push(
        <MenuItem key="1" disabled={!this.state.item} onClick={this.toggleBetweenEnabledDisabled}>
          <Trans>{this.state.item && !this.state.item!.enabled ? 'action.enable' : 'action.disable'}</Trans>
        </MenuItem>,
      );
    if (this.accessControl('subscription', 'delete'))
      list.push(
        <MenuItem key="2" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <PropertiesTab
        tab={0}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="subscription"
        parentLoading={this.state.loading}
        schema={dataSchema}
        schemaKey={this.state.item && this.state.item.mode === 'PUSH' ? 'subscriptionPush' : 'subscriptionPull'}
        schemaFormInputProps={[
          {
            service: 'DeviceService',
            subservice: 'deviceGroup',
            type: 'options',
            key: 'deviceGroups',
            nameKey: 'groupId',
            filterFunc: (dg) => dg.policyType !== 'NONE',
          },
          {
            service: 'DataService',
            subservice: 'trust',
            type: 'options',
            key: 'trustId',
            extraData: ['NONE'],
            navigate: (id: string) => SubdomainRoutePath.trust(id),
          },
        ]}
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <Dialog
      open={this.state.deleteDialogOpen}
      title={<Trans>action.delete</Trans>}
      description={<Trans>description.delete.subscription</Trans>}
      continueTitle={<Trans>action.delete</Trans>}
      onClose={() => this.setState({ deleteDialogOpen: false })}
      onContinue={this.delete}
    />
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={this.props.match.params.subscriptionId}
        icon={itemIcons.subscription}
        subtitle={i18next.t('form.subscriptionId')}
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        buttons={this.renderTabsHeaderButtons()}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

export default withStyles(ViewBaseStyles)(
  withRouter(connect(null, ViewBaseMapDispatchToProps)(SubscriptionDetailedView)),
);
