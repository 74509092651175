import React, { Component } from 'react';
import {
  Paper,
  IconButton,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import { ReleaseDetailed, DeviceGroup } from '../../common/types';
import { FirmwareService, DeviceService } from '../../services';
import { store } from '../../store';
import { Dialog } from '..';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  addDeviceGroups: DeviceGroup[];
  groupsRule: 'ANY_OF' | 'ALL_OFF';
  rollout: string;
  deviceGroups: DeviceGroup[];
  defaultDeviceGroups: DeviceGroup[];
  foceDialogOpen: boolean;
  force: boolean;
};

export class PublishReleaseMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    addDeviceGroups: [],
    groupsRule: 'ANY_OF',
    rollout: '',
    deviceGroups: [],
    defaultDeviceGroups: [],
    foceDialogOpen: false,
    force: false,
  };
  firmwareService!: FirmwareService;
  deviceService!: DeviceService;
  deviceGroupDebouncer: any;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    this.deviceService = await DeviceService.create();
    const { data } = await this.deviceService.deviceGroup.list({ params: { parent: '*', limit: 50 } });
    this.setState({
      addDeviceGroups: data.data.filter((dg) =>
        ((this.store.modalStore.data.release as ReleaseDetailed)!.groupId || []).includes(dg.groupId),
      ),
      groupsRule: ((this.store.modalStore.data.release as ReleaseDetailed)!.groupsRule || 'ANY_OF') as any,
      rollout: ((this.store.modalStore.data.release as ReleaseDetailed)!.rollout || '100') as any,
      deviceGroups: data.data,
      defaultDeviceGroups: data.data,
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  publish = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.firmwareService.release.put(
        (this.store.modalStore.data.release as ReleaseDetailed)!.releaseId,
        {
          groupId: this.state.addDeviceGroups.map((dg) => dg.groupId),
          groupsRule: this.state.groupsRule,
          rollout: this.state.rollout,
          enabled: true,
        },
        { force: this.state.force },
      );
      SnackbarUtils.success(i18next.t('success.publish.release'));
      if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      if (error.response && error.response.data && error.response.data.errorCode === 'FORCE_REQUIRED') {
        this.setState({ foceDialogOpen: true });
      } else {
        SnackbarUtils.error(
          (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
        );
        this.setState({ loading: false });
      }
    }
  };

  get isValid(): boolean {
    return !this.state.loading;
  }

  getDeviceGroups = async (value: string): Promise<void> => {
    try {
      const { data } = await this.deviceService.deviceGroup.list({
        params: { parent: '*', limit: 50, groupId: value.endsWith('*') ? value : `${value}*` },
      });
      if (!!data && !!data.data && this._isMounted) this.setState({ deviceGroups: data.data });
    } catch {
      SnackbarUtils.error(i18next.t('error.fetch.deviceGroups'));
    }
  };

  handleGetDeviceGroups = (_: React.ChangeEvent<unknown>, value: any, reason: AutocompleteInputChangeReason): void => {
    if (this.deviceGroupDebouncer) clearTimeout(this.deviceGroupDebouncer);
    if (reason === 'reset' || value === '') {
      this.setState({ addDeviceGroups: this.state.defaultDeviceGroups });
    } else {
      this.deviceGroupDebouncer = setTimeout(() => this.getDeviceGroups(value), 1000);
    }
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.publishFirmwareRelease.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.publishFirmwareRelease.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="prm"
              multiple
              filterSelectedOptions
              ChipProps={{ size: 'small' }}
              options={this.state.deviceGroups}
              isOptionEqualToValue={(option: DeviceGroup, value: DeviceGroup) => option.groupId === value.groupId}
              getOptionLabel={(option: DeviceGroup) => option.groupId}
              value={this.state.addDeviceGroups}
              onChange={(_: any, addDeviceGroups: DeviceGroup[]) => this.setState({ addDeviceGroups })}
              onInputChange={this.handleGetDeviceGroups}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'prm' }}
                  label={i18next.t('form.deviceGroups')}
                  placeholder={i18next.t('form.placeholder.selectDeviceGroups')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                shrink
                id="groups-rule-label"
                style={{ backgroundColor: 'white', paddingLeft: 4, paddingRight: 4, marginLeft: -4 }}
              >
                <Trans>form.groupsRule</Trans>
              </InputLabel>
              <Select
                labelId="groups-rule-label"
                id="groups-rule"
                value={this.state.groupsRule}
                onChange={(e) => this.setState({ groupsRule: e.target.value as any })}
              >
                <MenuItem key="ANY_OF" value="ANY_OF">
                  ANY_OF
                </MenuItem>
                <MenuItem key="ALL_OF" value="ALL_OF">
                  ALL_OF
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={i18next.t('form.rollout')}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.rollout}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ rollout: e.target.value })}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={!this.isValid} onClick={this.publish}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.publish</Trans>}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.foceDialogOpen}
          title={<Trans>action.forceUpdateRelease</Trans>}
          description={<Trans>description.forceUpdateRelease</Trans>}
          continueTitle={<Trans>action.continue</Trans>}
          onClose={() => this.setState({ loading: false, foceDialogOpen: false })}
          onContinue={() => {
            this.setState({ foceDialogOpen: false, force: true }, this.publish);
          }}
        />
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(PublishReleaseMC);
