import { Dispatch, Action } from 'redux';
import * as types from '../../common/types';

// action types
export enum actions {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

const openModal = (variant: types.ModalVariants, data?: any) => ({
  store: types.StoreName.MODAL,
  type: actions.OPEN_MODAL,
  variant,
  data,
});

// action creators
export const storeCloseModal = (): any => ({
  store: types.StoreName.MODAL,
  type: actions.CLOSE_MODAL,
});

// conditional action creators
export const storeOpenModal: any = (variant: types.ModalVariants, data?: any) => async (
  dispatch: Dispatch<Action>,
  getState: () => types.Store,
): Promise<Action<any>> => {
  if (getState().modalStore.open) {
    dispatch(storeCloseModal());
    await new Promise((resolve) => setTimeout(resolve, 300));
  }
  return dispatch(openModal(variant, data));
};
