import { UserStoreAction, StoreName } from '../../common/types';
import { localStorageNameCustomTableProperties } from '../../common/constants';
import { actions } from '../actions/user';
import UserInitialState from '../init/user';

export default (state = UserInitialState, { store, type, ...data }: UserStoreAction) => {
  if (store !== StoreName.USER) return state;
  switch (type) {
    case actions.SET_LOCALE:
      return { ...state, ...data };
    case actions.SET_CONFIG:
      if (data.config && data.config.tables)
        localStorage.setItem(localStorageNameCustomTableProperties, JSON.stringify(data.config.tables));
      return { ...state, ...data };
    default:
      return state;
  }
};
