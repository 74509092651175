import React, { Component } from 'react';
import { List, ListItemButton, ListItemText, ListSubheader, Grid, Box, Collapse, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ExpandMore } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import clsx from 'clsx';
import { RouteType, RouteParentType, Role, MenuToggle, Store, CustomerConfiguration } from '../../common/types';
import { LastVisistedList } from '.';
import { storeSetMenuToggleList, storeSetLeftNavOpen } from '../../store/actions/common';
import { getTheme } from '../../common/theme';

export const styles = () => {
  const theme = getTheme();
  return {
    gridContainer: {
      height: '100%',
    },
    gridContainerPadding: {
      paddingLeft: 20,
    },
    linkRoot: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    linkFocus: {
      color: theme.palette.secondary.main,
    },
    linkRootPadding: {
      paddingLeft: 16,
    },
    linkText: {
      marginTop: 1,
      marginBottom: 1,
    },
    linkSelected: {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent !important',
      '& * span': {
        fontWeight: 'bold !important' as 'bold',
      },
    },
    menuSubHeader: {
      lineHeight: '36px',
      color: theme.palette.primary.main,
    },
    listItemIconContainer: {
      marginLeft: '-20px',
      marginRight: '-4px',
    },
    listItemIcon: {
      fontSize: '1rem',
      transform: 'rotate(-90deg)',
      transition: 'transform .2s ease-in-out',
    },
    listItemIconOpen: {
      transform: 'rotate(0deg)',
    },
    link: {
      textDecoration: 'none !important' as 'none',
      color: theme.palette.secondary.main,
    },
    copyright: {
      textAlign: 'center' as const,
      '& > span': {
        fontSize: 10,
        margin: '0 auto',
      },
    },
  };
};

type Props = WithStyles<any> &
  RouteComponentProps & {
    className?: any;
    isDrawer?: boolean;
    role: Role;
    routes: RouteType[];
    menuListToggle: { [key: string]: MenuToggle };
    activeTab: string;
    config?: CustomerConfiguration;
    setMenuListToggle: (menuListToggle: { [key: string]: MenuToggle }) => void;
    setLeftNavOpen: (setLeftNavOpen: boolean) => void;
  };

type State = {
  historyKey: string;
};

class Nav extends Component<Props, State> {
  state: State = {
    historyKey: 'this-is-just-a-start-key',
  };
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    const { routes, setMenuListToggle } = this.props;
    if (this._isMounted) setMenuListToggle(this.buildMenuListToggleState(routes));
  };

  componentDidUpdate = (prevProps: Props): void => {
    const { history, routes, setMenuListToggle, menuListToggle, activeTab } = this.props;
    const { historyKey } = this.state;

    if (
      ((history.location.key || '') !== historyKey || routes !== prevProps.routes) &&
      this._isMounted &&
      !!history.location.key &&
      !!historyKey
    ) {
      this.setState(
        {
          historyKey: history.location.key + '',
        },
        () => setMenuListToggle(this.buildMenuListToggleState(routes, menuListToggle)),
      );
    }
    if (activeTab !== prevProps.activeTab) setMenuListToggle(this.buildMenuListToggleState(routes));
  };

  /**
   * Creates a object containing all parent routes and if they should be open.
   * @param routes Routes which will be checked
   * @param oldMenuListToggle Previous version of menuListToggle. Pass if you want to keep open menus open.
   */
  buildMenuListToggleState = (
    routes: (RouteType | RouteParentType)[],
    oldMenuListToggle?: { [key: string]: MenuToggle },
  ): { [key: string]: MenuToggle } =>
    routes.reduce((menuListToggle: { [key: string]: MenuToggle }, route: RouteType | RouteParentType) => {
      if (!{}.hasOwnProperty.call(route, 'component') && !{}.hasOwnProperty.call(route, 'render')) {
        const path = Array.isArray(route.path) ? route.path[0] : route.path;
        menuListToggle[path] = {
          // True if is current path or if found true in oldMenuListToggle
          open:
            this.checkForActivePathInChildren(window.location.pathname, route.routes) ||
            (!!oldMenuListToggle && {}.hasOwnProperty.call(oldMenuListToggle, path) && oldMenuListToggle[path].open),
          children: this.buildMenuListToggleState(
            route.routes!,
            (!!oldMenuListToggle &&
              {}.hasOwnProperty.call(oldMenuListToggle, path) &&
              (oldMenuListToggle[path].children as { [key: string]: MenuToggle })) ||
              undefined,
          ),
        };
      }
      return menuListToggle;
    }, {});

  /**
   * Checks if active path can be found in routes children. If so return true else false.
   */
  checkForActivePathInChildren = (urlPath: string, routes?: (RouteType | RouteParentType)[]): boolean => {
    if (!routes) return false;
    if (
      routes.find((route) => {
        const routePath = Array.isArray(route.path) ? route.path[0] : route.path;
        return (
          routePath.toLowerCase() === urlPath.toLowerCase() ||
          urlPath.includes(routePath.split(':')[0]) ||
          routePath.toLowerCase() === urlPath.toLowerCase().substring(0, urlPath.length - 1) ||
          urlPath
            .toLowerCase()
            .substring(0, urlPath.length - 1)
            .includes(routePath.toLowerCase().split(':')[0])
        );
      })
    ) {
      return true;
    }
    for (const route of routes) if (this.checkForActivePathInChildren(urlPath, route.routes)) return true;
    return false;
  };

  findAndUpdateMenuItem = (
    menuListToggle: { [key: string]: MenuToggle },
    paths: string[],
  ): { [key: string]: MenuToggle } => {
    if (paths.length === 1 && {}.hasOwnProperty.call(menuListToggle, paths[0])) {
      menuListToggle[paths[0]].open = !menuListToggle[paths[0]].open;
      return menuListToggle;
    }
    menuListToggle[paths[0]].children = this.findAndUpdateMenuItem(menuListToggle[paths[0]].children, paths.slice(1));
    return menuListToggle;
  };

  toggleMenuItem = (paths: string[]) => {
    const menuListToggle = JSON.parse(JSON.stringify(this.props.menuListToggle));
    this.props.setMenuListToggle(this.findAndUpdateMenuItem(menuListToggle, paths));
  };

  getMenuItemOpen = (menuListToggle: { [key: string]: MenuToggle }, paths: string[]): boolean => {
    if (paths.length === 1 && {}.hasOwnProperty.call(menuListToggle, paths[0])) {
      return {}.hasOwnProperty.call(menuListToggle, paths[0]) ? menuListToggle[paths[0]].open : false;
    }
    return {}.hasOwnProperty.call(menuListToggle, paths[0])
      ? this.getMenuItemOpen(menuListToggle[paths[0]].children, paths.slice(1))
      : false;
  };

  drawerLinkClick = (path: string): void => {
    if (this.props.isDrawer) this.props.setLeftNavOpen(false);
    this.props.history.push(path);
  };

  renderRoutes = (routes: (RouteType | RouteParentType)[], paths: string[] = [], indent = 0): React.ReactNode => {
    if (!routes) return null;
    const activePath = '/' + window.location.pathname.split('/')[1];
    const { role, classes, menuListToggle } = this.props;
    return routes.map((route, i) => {
      if (
        ({}.hasOwnProperty.call(route, 'hideInMenu') && (route as RouteType).hideInMenu) ||
        (!!route.roles && !route.roles.includes(role))
      )
        return null;
      if ({}.hasOwnProperty.call(route, 'component') || {}.hasOwnProperty.call(route, 'render')) {
        const path = (Array.isArray(route.path) ? route.path[0] : route.path).toLowerCase();
        const routePaths = Array.isArray(route.path)
          ? route.path.map((p) => p.toLowerCase())
          : route.path.toLowerCase();
        const activeLocation = activePath.toLowerCase();
        const windowLocation = window.location.pathname.toLowerCase();
        return (
          <ListItemButton
            dense
            key={`${route.path}-${i}`}
            autoFocus={
              Array.isArray(route.path)
                ? routePaths.includes(activeLocation) || routePaths.includes(windowLocation)
                : activeLocation === path ||
                  windowLocation === path ||
                  activeLocation.substring(0, activeLocation.length - 1) === path ||
                  windowLocation.substring(0, windowLocation.length - 1) === path
            }
            selected={
              Array.isArray(route.path)
                ? routePaths.includes(activeLocation) || routePaths.includes(windowLocation)
                : activeLocation === path ||
                  windowLocation === path ||
                  activeLocation.substring(0, activeLocation.length - 1) === path ||
                  windowLocation.substring(0, windowLocation.length - 1) === path
            }
            focusVisibleClassName={classes.linkFocus}
            classes={{
              root: clsx(classes.linkRoot, classes.linkRootPadding),
              selected: classes.linkSelected,
            }}
            onClick={() => this.drawerLinkClick(route.staticPath ? route.staticPath : path)}
          >
            <ListItemText
              className={classes.linkText}
              primary={<Trans>{route.name ? route.name : path}</Trans>}
            ></ListItemText>
          </ListItemButton>
        );
      }

      const showCollapsable = route.routes
        ? (route.routes as any).reduce((bool: boolean, route: RouteType | RouteParentType) => {
            if (bool) return bool;
            return !(
              ({}.hasOwnProperty.call(route, 'hideInMenu') && (route as RouteType).hideInMenu) ||
              (!!route.roles && !route.roles.includes(role))
            );
          }, false)
        : false;

      if (showCollapsable) {
        const routePaths = paths.concat([Array.isArray(route.path) ? route.path[0] : route.path]);
        const open = this.getMenuItemOpen(menuListToggle, routePaths);
        return (
          <React.Fragment key={`parent-route-${route.path}`}>
            <ListItemButton
              dense
              key={`${route.path}-${i}-parent`}
              classes={{ root: classes.linkRoot }}
              onClick={() => this.toggleMenuItem(routePaths)}
            >
              <ListItemText className={classes.linkText}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item className={classes.listItemIconContainer}>
                    <Box display="flex" alignContent="center">
                      <ExpandMore
                        className={clsx({
                          [classes.listItemIcon]: true,
                          [classes.listItemIconOpen]: open,
                        })}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Trans>{route.name ? route.name : route.path}</Trans>
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItemButton>
            <Collapse in={open} key={`${route.path}-${i}-child`} timeout="auto" unmountOnExit>
              <Box ml="6px">
                <List disablePadding component="div">
                  {this.renderRoutes(route.routes!, routePaths, indent + 2)}
                </List>
              </Box>
            </Collapse>
          </React.Fragment>
        );
      }
      return null;
    });
  };

  render = (): React.ReactNode => (
    <div className={this.props.className}>
      <Grid
        container
        alignContent="space-between"
        className={clsx({
          [this.props.classes.gridContainer]: true,
          [this.props.classes.gridContainerPadding]: !!this.props.isDrawer,
        })}
      >
        <Grid item xs={12}>
          <List
            role="list"
            disablePadding
            component="nav"
            subheader={
              !this.props.isDrawer ? (
                <ListSubheader className={this.props.classes.menuSubHeader} disableSticky component="div" id="name">
                  {(this.props.config && this.props.config.alias) || ''}
                </ListSubheader>
              ) : null
            }
          >
            {this.renderRoutes(this.props.routes)}
            <LastVisistedList />
          </List>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} display="flex" justifyContent="center">
            <Grid container justifyContent="center" spacing={1}>
              <Grid item>
                <a href="https://www.sonynetworkcom.com/legal" className={this.props.classes.link}>
                  <Typography variant="caption">Legal</Typography>
                </a>
              </Grid>
              <Grid item>
                <a href="https://www.sonynetworkcom.com/legal/privacy" className={this.props.classes.link}>
                  <Typography variant="caption">Privacy policy</Typography>
                </a>
              </Grid>
              <Grid item>
                <a href="https://www.sonynetworkcom.com/legal/cookies" className={this.props.classes.link}>
                  <Typography variant="caption">Cookies</Typography>
                </a>
              </Grid>
              <Grid item xs={12} className={this.props.classes.copyright}>
                <Typography variant="caption">
                  Copyright © 2020 Sony Network Communications Europe. All rights reserved
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = ({ commonStore, userStore }: Store) => ({
  menuListToggle: commonStore.menuListToggle,
  activeTab: commonStore.activeTab,
  config: userStore.config,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setMenuListToggle: (menuListToggle: { [key: string]: MenuToggle }) =>
    dispatch(storeSetMenuToggleList(menuListToggle)),
  setLeftNavOpen: (leftNavOpen: boolean) => dispatch(storeSetLeftNavOpen(leftNavOpen)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav)));
