import { SubdomainRoutePath, ListTableProperty } from '../types';

export const localStorageNameCustomTableProperties = '26a6c566-f523-44bc-ba89-6dab94ab82d6';

export type DefaultProperty = string[];
export type DefaultProperties = { [key: string]: DefaultProperty };

export type CustomTableProperty = string[];
export type CustomTableProperties = { [key: string]: CustomTableProperty };

export type TableProperty = { [key: string]: ListTableProperty };
export type TableProperties = { [key: string]: TableProperty };

const BASE_TABLES: TableProperties = {
  // fleet
  device: {
    state: { defaultActive: true, displayName: 'form.state', displayType: 'text' },
    deviceId: {
      defaultActive: true,
      displayName: 'form.deviceId',
      displayType: 'text',
      path: SubdomainRoutePath.device,
    },
    type: { defaultActive: true, displayName: 'form.type', displayType: 'text' },
    name: { defaultActive: true, displayName: 'form.name', displayType: 'text' },
    imei: { defaultActive: true, displayName: 'form.imei', displayType: 'text' },
    imsi: { defaultActive: false, displayName: 'form.imsi', displayType: 'text' },
    productId: {
      defaultActive: true,
      displayName: 'form.productId',
      displayType: 'text',
      path: SubdomainRoutePath.firmwareProduct,
    },
    groups: { defaultActive: false, displayName: 'form.groups', displayType: 'text' },
    description: { defaultActive: false, displayName: 'form.description', displayType: 'text' },
    certificateCN: { defaultActive: false, displayName: 'form.certificateCN', displayType: 'text' },
    certificateId: { defaultActive: false, displayName: 'form.certificateId', displayType: 'text' },
    lastSeen: { defaultActive: false, displayName: 'form.lastSeen', displayType: 'date' },
    activated: { defaultActive: false, displayName: 'form.activated', displayType: 'date' },
    created: { defaultActive: false, displayName: 'form.created', displayType: 'date' },
    firmwareUpdated: { defaultActive: false, displayName: 'form.firmwareUpdated', displayType: 'text' },
    firmwareVersion: { defaultActive: true, displayName: 'form.firmwareVersion', displayType: 'text' },
    generation: { defaultActive: false, displayName: 'form.generation', displayType: 'text' },
  },

  deviceGroup: {
    groupId: {
      defaultActive: true,
      displayName: 'form.groupId',
      displayType: 'text',
      path: SubdomainRoutePath.deviceGroup,
    },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    parent: {
      defaultActive: true,
      displayName: 'form.parent',
      displayType: 'text',
      path: SubdomainRoutePath.deviceGroup,
    },
    groupType: { defaultActive: true, displayName: 'form.groupType', displayType: 'text' },
    policyType: { defaultActive: true, displayName: 'form.policyType', displayType: 'text' },
  },

  connection: {
    status: { defaultActive: true, displayName: 'form.status', displayType: 'text' },
    duration: { defaultActive: true, displayName: 'form.duration', displayType: 'text' },
    when: { defaultActive: true, displayName: 'form.when', displayType: 'text' },
    connected: { defaultActive: true, displayName: 'form.connected', displayType: 'dateTime' },
    disconnected: { defaultActive: true, displayName: 'form.disconnected', displayType: 'dateTime' },
    disconnectReason: { defaultActive: true, displayName: 'form.disconnectReason', displayType: 'text' },
    clientInitiatedDisconnect: {
      defaultActive: true,
      displayName: 'form.clientInitiatedDisconnect',
      displayType: 'boolean',
    },
  },
  shadowAlias: {
    alias: { defaultActive: true, displayName: 'form.alias', displayType: 'text' },
    property: { defaultActive: true, displayName: 'form.property', displayType: 'text' },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    isTimestamp: { defaultActive: true, displayName: 'form.isTimestamp', displayType: 'boolean' },
    modified: { defaultActive: true, displayName: 'form.modified', displayType: 'dateTime' },
    created: { defaultActive: true, displayName: 'form.created', displayType: 'dateTime' },
  },
  shadowTemplate: {
    default: { defaultActive: true, displayName: 'form.default', displayType: 'boolean' },
    templateId: { defaultActive: true, displayName: 'form.templateId', displayType: 'text' },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    modified: { defaultActive: true, displayName: 'form.modified', displayType: 'dateTime' },
    created: { defaultActive: true, displayName: 'form.created', displayType: 'dateTime' },
  },

  // fleet-api-gw
  deviceApiGw: {
    enabled: { defaultActive: true, displayName: 'form.status', displayType: 'boolean' },
    apiId: { defaultActive: true, displayName: 'form.apiId', displayType: 'text' },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    type: { defaultActive: true, displayName: 'form.type', displayType: 'text' },
  },

  trust: {
    trustId: { defaultActive: true, displayName: 'Trust id', displayType: 'text' },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    type: { defaultActive: true, displayName: 'form.type', displayType: 'text' },
    modified: { defaultActive: true, displayName: 'form.modified', displayType: 'dateTime' },
  },

  // user
  user: {
    enabled: { defaultActive: true, displayName: 'form.enabled', displayType: 'boolean' },
    email: {
      defaultActive: true,
      displayName: 'form.email',
      displayType: 'text',
      pathProperty: 'userId',
      path: SubdomainRoutePath.user,
    },
    name: {
      defaultActive: true,
      displayName: 'form.name',
      displayType: 'text',
      pathProperty: 'userId',
      path: SubdomainRoutePath.user,
    },
    status: { defaultActive: true, displayName: 'form.status', displayType: 'text' },
    userId: {
      defaultActive: false,
      displayName: 'form.userId',
      displayType: 'text',
      path: SubdomainRoutePath.user,
    },
  },

  userGroup: {
    groupName: {
      defaultActive: true,
      displayName: 'form.groupName',
      displayType: 'text',
      path: SubdomainRoutePath.userGroup,
    },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    region: { defaultActive: true, displayName: 'form.region', displayType: 'text' },
    deviceGroupId: {
      defaultActive: true,
      displayName: 'form.deviceGroupId',
      displayType: 'text',
      path: SubdomainRoutePath.deviceGroup,
    },
    createdBy: { defaultActive: true, displayName: 'form.createdBy', displayType: 'text' },
    modified: { defaultActive: true, displayName: 'form.modified', displayType: 'date' },
    created: { defaultActive: true, displayName: 'form.created', displayType: 'date' },
  },

  // data
  subscription: {
    enabled: { defaultActive: false, displayName: 'form.enabled', displayType: 'boolean' },
    status: { defaultActive: true, displayName: 'form.status', displayType: 'text' },
    subscriptionId: {
      defaultActive: true,
      displayName: 'form.subscriptionId',
      displayType: 'text',
      path: SubdomainRoutePath.subscription,
    },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    events: { defaultActive: true, displayName: 'form.events', displayType: 'text' },
    mode: { defaultActive: true, displayName: 'form.mode', displayType: 'text' },
    deviceGroups: { defaultActive: true, displayName: 'form.deviceGroups', displayType: 'text' },
    modified: { defaultActive: true, displayName: 'form.modified', displayType: 'date' },
    created: { defaultActive: true, displayName: 'form.created', displayType: 'date' },
  },

  // firmware
  product: {
    productId: {
      defaultActive: true,
      displayName: 'form.productId',
      displayType: 'text',
      path: SubdomainRoutePath.firmwareProduct,
    },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    updateTypes: { defaultActive: true, displayName: 'form.updateTypes', displayType: 'text' },
    default: { defaultActive: true, displayName: 'form.default', displayType: 'boolean' },
    modified: { defaultActive: false, displayName: 'form.modified', displayType: 'date' },
    created: { defaultActive: false, displayName: 'form.created', displayType: 'date' },
  },

  package: {
    state: { defaultActive: true, displayName: 'form.state', displayType: 'text' },
    version: { defaultActive: true, displayName: 'form.version', displayType: 'text' },
    name: {
      defaultActive: true,
      displayName: 'form.name',
      displayType: 'text',
      pathProperty: 'packageId',
      path: SubdomainRoutePath.firmwarePackage,
    },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    size: { defaultActive: true, displayName: 'form.size', displayType: 'text' },
    productId: {
      defaultActive: true,
      displayName: 'form.productId',
      displayType: 'text',
      path: SubdomainRoutePath.firmwareProduct,
    },
    type: { defaultActive: false, displayName: 'form.type', displayType: 'text' },
    buildType: { defaultActive: true, displayName: 'form.buildType', displayType: 'text' },
    packageId: {
      defaultActive: false,
      displayName: 'form.packageId',
      displayType: 'text',
      path: SubdomainRoutePath.firmwarePackage,
    },
    statusMessage: { defaultActive: false, displayName: 'form.statusMessage', displayType: 'text' },
    url: { defaultActive: false, displayName: 'form.url', displayType: 'text' },
    modified: { defaultActive: false, displayName: 'form.modified', displayType: 'date' },
    created: { defaultActive: true, displayName: 'form.created', displayType: 'date' },
    fromVersions: { defaultActive: true, displayName: 'form.fromVersions', displayType: 'text' },
  },

  release: {
    enabled: { defaultActive: true, displayName: 'form.status', displayType: 'boolean' },
    toVersion: {
      defaultActive: true,
      displayName: 'form.version',
      displayType: 'text',
      pathProperty: 'releaseId',
      path: SubdomainRoutePath.firmwareRelease,
    },
    name: {
      defaultActive: true,
      displayName: 'form.name',
      displayType: 'text',
    },
    productId: {
      defaultActive: true,
      displayName: 'form.productId',
      displayType: 'text',
      path: SubdomainRoutePath.firmwareProduct,
    },
    type: { defaultActive: true, displayName: 'form.type', displayType: 'text' },
    fromVersion: { defaultActive: true, displayName: 'form.fromVersions', displayType: 'text' },
    releaseId: {
      defaultActive: false,
      displayName: 'form.releaseId',
      displayType: 'text',
      path: SubdomainRoutePath.firmwareRelease,
    },
    groupId: { defaultActive: false, displayName: 'form.groupId', displayType: 'text' },
    groupsRule: { defaultActive: false, displayName: 'form.groupsRule', displayType: 'text' },
    modified: { defaultActive: false, displayName: 'form.modified', displayType: 'date' },
    created: { defaultActive: true, displayName: 'form.created', displayType: 'date' },
  },

  key: {
    locked: { defaultActive: true, displayName: 'form.locked', displayType: 'boolean' },
    keyId: { defaultActive: true, displayName: 'form.keyId', displayType: 'text', path: SubdomainRoutePath.key },
    description: { defaultActive: true, displayName: 'form.description', displayType: 'text' },
    type: { defaultActive: true, displayName: 'form.type', displayType: 'text' },
    reference: { defaultActive: true, displayName: 'form.reference', displayType: 'text' },
    modified: { defaultActive: true, displayName: 'form.modified', displayType: 'date' },
    created: { defaultActive: true, displayName: 'form.created', displayType: 'date' },
  },
};

function getBaseTable(baseTableName: string, tableName?: string): { table: TableProperty; ignoreProperties: boolean } {
  let table = BASE_TABLES[baseTableName];
  let custom: any = localStorage.getItem(localStorageNameCustomTableProperties);
  if (custom) {
    custom = JSON.parse(custom);
    if ({}.hasOwnProperty.call(custom, tableName || baseTableName)) {
      const defaultValues: string[] = custom[tableName || baseTableName];
      table = Object.entries(table).reduce((acc: TableProperty, [key, value]: [string, ListTableProperty]) => {
        acc[key] = value;
        acc[key].defaultActive = defaultValues.includes(key);
        return acc;
      }, {});
      return { table, ignoreProperties: true };
    }
  }
  return { table, ignoreProperties: false };
}

const parseTableProperties = (
  baseTableName: string,
  tableName?: string,
  properties?: CustomTableProperty,
  allActive = false,
): TableProperty => {
  let { table } = getBaseTable(baseTableName, tableName);
  const { ignoreProperties } = getBaseTable(baseTableName, tableName);
  if (properties) {
    table = (ignoreProperties
      ? Object.entries(table)
          .filter(([, value]) => value.defaultActive)
          .map(([key]) => key)
      : properties
    ).reduce((_table: any, value: [string, boolean?] | string) => {
      const key = typeof value === 'string' ? value : value[0];
      const defaultActive = typeof value === 'string' ? allActive : value[0] || allActive;

      if ({}.hasOwnProperty.call(table, key)) {
        _table[key] = table[key];
        if (defaultActive) _table[key].defaultActive = defaultActive;
      } else {
        throw new Error(`Missing key "${key}" in table properties`);
      }

      return _table;
    }, {} as TableProperty);
  } else if (allActive) {
    table = Object.keys(table).reduce((_table: any, key: string) => {
      if (allActive) _table[key].defaultActive = true;
      return _table;
    }, {} as TableProperty);
  }
  return table;
};

export enum TableNames {
  device = 'device',
  deviceTabProduct = 'deviceTabProduct',
  deviceTabRelease = 'deviceTabRelease',
  deviceGroup = 'deviceGroup',
  deviceGroupChildren = 'deviceGroupChildren',
  deviceDeviceGroupIds = 'deviceDeviceGroupIds',
  deviceConnections = 'deviceConnections',
  shadowAliases = 'shadowAliases',
  shadowTemplate = 'shadowTemplate',
  deviceApiGw = 'deviceApiGw',
  user = 'user',
  userGroup = 'userGroup',
  subscription = 'subscription',
  product = 'product',
  package = 'package',
  packageTab = 'packageTab',
  packageTabMapped = 'packageTabMapped',
  release = 'release',
  releaseTab = 'releaseTab',
  key = 'key',
  trust = 'trust',
}

const TABLES: Record<TableNames, () => TableProperty> = {
  // fleet
  device: () => parseTableProperties('device'),
  deviceTabProduct: () =>
    parseTableProperties(
      'device',
      'deviceTabProduct',
      ['state', 'deviceId', 'name', 'imei', 'groups', 'firmwareVersion', 'firmwareUpdated'],
      true,
    ),
  deviceTabRelease: () =>
    parseTableProperties(
      'device',
      'deviceTabRelease',
      ['state', 'deviceId', 'name', 'imei', 'groups', 'firmwareUpdated'],
      true,
    ),
  deviceGroup: () => parseTableProperties('deviceGroup'),
  deviceGroupChildren: () =>
    parseTableProperties(
      'deviceGroup',
      'deviceGroupChildren',
      ['groupId', 'description', 'groupType', 'policyType'],
      true,
    ),
  deviceDeviceGroupIds: () => parseTableProperties('deviceGroup', 'deviceDeviceGroupIds', ['groupId'], true),
  deviceConnections: () => parseTableProperties('connection'),
  shadowAliases: () => parseTableProperties('shadowAlias'),
  shadowTemplate: () => parseTableProperties('shadowTemplate'),

  // fleet-api-gw
  deviceApiGw: () => parseTableProperties('deviceApiGw'),

  // trust
  trust: () => parseTableProperties('trust'),

  // user
  user: () => parseTableProperties('user'),
  userGroup: () => parseTableProperties('userGroup'),

  // data
  subscription: () => parseTableProperties('subscription'),

  // firmware
  product: () => parseTableProperties('product'),
  package: () =>
    parseTableProperties('package', undefined, [
      'state',
      'version',
      'name',
      'description',
      'size',
      'productId',
      'type',
      'buildType',
      'packageId',
      'statusMessage',
      'url',
      'modified',
      'created',
    ]),
  packageTab: () =>
    parseTableProperties(
      'package',
      'packageTab',
      ['state', 'version', 'name', 'size', 'type', 'buildType', 'created'],
      true,
    ),
  packageTabMapped: () =>
    parseTableProperties(
      'package',
      'packageTabMapped',
      ['state', 'fromVersions', 'name', 'size', 'buildType', 'created'],
      true,
    ),
  release: () => parseTableProperties('release'),
  releaseTab: () =>
    parseTableProperties(
      'release',
      'releaseTab',
      ['enabled', 'toVersion', 'name', 'type', 'fromVersion', 'created'],
      true,
    ),
  key: () => parseTableProperties('key'),
};

// Used for keeping tables equal in all views and to list what properties and which ordet they should be shown in
export function tableProperties(tableName: TableNames): TableProperty {
  return TABLES[tableName]();
}
