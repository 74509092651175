import { AxiosRequestConfig } from 'axios';

export * from '../constants/api';
export * from './customer';
export * from './data';
export * from './device';
export * from './firmware';
export * from './modal';
export * from '../constants/route';
export * from './store';
export * from './user';
export * from './triggers';

export enum StoreName {
  COMMON = 'COMMON',
  DEVICE = 'DEVICE',
  MODAL = 'MODAL',
  USER = 'USER',
}

export enum Role {
  ADMIN = 'admin',
  DEVELOPER = 'developer',
  POWER_USER = 'poweruser',
  USER = 'user',
  DOCUMENTATION = 'docs',
}

export interface Locale {
  locale: string;
  fields?: { [key: string]: string };
}

export interface AuthBody {
  email: string;
  passowrd: string;
}

export interface AwsRequestConfig extends AxiosRequestConfig {
  path: string;
}

export type BaseFilterCombination = {
  label: string;
  value: string;
  properties?: string;
};

export type FilterCombination = Omit<BaseFilterCombination, 'properties'> & {
  id: string;
  properties: string;
  temporary?: boolean;
};

export type PropertyType = 'string' | 'date' | 'selector' | 'boolean';

export type ListFilterOperator = 'equal' | 'between' | 'startsWith' | 'betweenAbsolute';

export type ListFilterProperty = {
  property: string;
  displayName: string;
  type: PropertyType;
  operators: ListFilterOperator[];
  options?: string[];
};

export type ListFilterProps = {
  type: PropertyType;
  property: string;
  operator: ListFilterOperator;
  value1: string | number;
  value2: string | number;
};

export type ListTableAction = {
  title: string;
  dynamicTitle?: (row: any) => string;
  dynamicDisabled?: (row: any) => boolean;
  disabled?: boolean;
  action: (row: any) => Promise<void> | void;
  filterAction?: (item: any) => boolean;
};

export type TableDisplayType = 'text' | 'date' | 'dateTime' | 'dateFromNow' | 'boolean';

export type ListTableProperty = {
  defaultActive: boolean;
  displayName: string;
  displayType: TableDisplayType;
  align?: 'left' | 'center' | 'right';
  pathProperty?: string;
  path?: () => string;
};

export type MenuToggle = {
  open: boolean;
  children: { [key: string]: MenuToggle };
};

export interface MarkdownDocument {
  type: 'docs' | 'download';
  name: string;
  path: string;
}

export type ServiceType = 'cognito';

export enum ServiceTypes {
  COGNITO = 'cognito',
}
