import React, { Component } from 'react';

type IfProps = {
  statement: boolean;
  children?: React.ReactNode;
};

export class If extends Component<IfProps> {
  render = (): React.ReactNode => {
    const { statement, children } = this.props;
    if (statement && children) return children;
    return null;
  };
}
