import React, { Component } from 'react';
import { AppBar as MateriaAppBar, Toolbar, Grid, IconButton, Box, Tabs, Tab, Hidden } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { MenuOutlined, AccountCircle } from '@mui/icons-material';
import { SearchField, NavRight, If } from '..';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import { storeSetLeftNavOpen, storeSetRightNavOpen, storeSetActiveTab } from '../../store/actions/common';
import { Role, RouteType, RouteParentType, Store } from '../../common/types';
import { appBarHeight } from '../../common/constants';
import { AccessControl } from '../../common/permissions';
import { getLogo, getTheme } from '../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    appBar: {
      zIndex: 2,
    },
    toolbar: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    logo: {
      height: 24,
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(2),
      },
    },
    rootTabs: {
      height: appBarHeight,
    },
    tabIndicator: {
      backgroundColor: '#fff',
    },
    menuButtonLeft: {
      [theme.breakpoints.up('lg')]: {
        display: 'none' as const,
      },
    },
  };
};

type Props = WithStyles<any> & {
  role: Role;
  routes: (RouteType | RouteParentType)[];
  activeTab: string;
  hideMenu: boolean;
  setActiveTab: (activeTab: string) => void;
  setLeftNavOpen: () => void;
  setRightNavOpen: () => void;
};

class AppBar extends Component<Props> {
  rightNavRef!: React.RefObject<HTMLButtonElement>;
  accessControl: AccessControl;

  constructor(props: Props) {
    super(props);
    this.rightNavRef = React.createRef();
    this.accessControl = new AccessControl(props.role);
  }

  componentDidUpdate = (prevProps: Props): void => {
    if (prevProps.role !== this.props.role) this.accessControl = new AccessControl(this.props.role);
  };

  get showSearchField(): boolean {
    return (
      this.accessControl.access('device').list() ||
      this.accessControl.access('deviceGroup').list() ||
      this.accessControl.access('user').list()
    );
  }

  renderTabs = (): React.ReactNode => (
    <Tabs
      value={this.props.activeTab}
      onChange={(_: any, value: any) => this.props.setActiveTab(value)}
      aria-label="simple tabs example"
      textColor="inherit"
      classes={{
        flexContainer: this.props.classes.rootTabs,
        indicator: this.props.classes.tabIndicator,
      }}
    >
      {this.props.routes.map((route: RouteType | RouteParentType, i) => {
        if (route.hideInMenu || (route.roles && !route.roles.includes(this.props.role))) return null;
        return (
          <Tab
            key={i}
            style={{ fontSize: '0.8rem' }}
            label={i18next.t(route.name || (Array.isArray(route.path) && route.path[0]) || route.path)}
            value={Array.isArray(route.path) ? route.path[0] : route.path}
          />
        );
      })}
    </Tabs>
  );

  render = (): React.ReactNode => (
    <MateriaAppBar position="fixed" className={this.props.classes.appBar}>
      <Toolbar variant={appBarHeight === 64 ? 'regular' : 'dense'} className={this.props.classes.toolbar}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid container alignItems="center" spacing={3}>
              <If statement={this.props.hideMenu}>
                <Hidden lgUp>
                  <Grid item className={this.props.classes.menuButtonLeft}>
                    <IconButton
                      color="inherit"
                      aria-label="open navigation drawer"
                      onClick={this.props.setLeftNavOpen}
                      size="large"
                    >
                      <MenuOutlined />
                    </IconButton>
                  </Grid>
                </Hidden>
              </If>
              <Hidden lgDown>
                <Grid item>
                  <Box display="flex" alignContent="center">
                    <img className={this.props.classes.logo} src={getLogo('white')} alt="logo" />
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={1} style={{ flexWrap: 'nowrap' }}>
              <Hidden mdDown>
                {!!this.props.activeTab && !!this.props.routes && this.props.routes.length > 1 ? (
                  <Grid item>{this.renderTabs()}</Grid>
                ) : null}
              </Hidden>
              <If statement={this.showSearchField}>
                <Grid item>
                  <SearchField accessControl={this.accessControl} />
                </Grid>
              </If>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open information drawer"
                  onClick={this.props.setRightNavOpen}
                  ref={this.rightNavRef}
                  size="large"
                >
                  <AccountCircle />
                </IconButton>
                <NavRight buttonRef={this.rightNavRef} role={this.props.role} />
              </Grid>
            </Grid>
          </Grid>
          <Hidden mdUp>
            {!!this.props.activeTab && !!this.props.routes && this.props.routes.length > 1 ? (
              <Grid item xs={12}>
                {this.renderTabs()}
              </Grid>
            ) : null}
          </Hidden>
        </Grid>
      </Toolbar>
    </MateriaAppBar>
  );
}

const mapStateToProps = ({ commonStore }: Store) => ({
  activeTab: commonStore.activeTab,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setActiveTab: (activeTab: string) => dispatch(storeSetActiveTab(activeTab)),
  setLeftNavOpen: () => dispatch(storeSetLeftNavOpen(true)),
  setRightNavOpen: () => dispatch(storeSetRightNavOpen(true)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AppBar));
