import { AuthenticationServiceProvider } from '.';
import { AxiosResponse } from 'axios';
import { APIPath, RoutePathPrefix, User, UserDetailed, UserGroup, UserGroupDetailed } from '../common/types';
import { BaseCRUDService } from './common/BaseCRUDService';

class UserDataService extends BaseCRUDService<User, User, UserDetailed, User, User> {
  userSelfCheck = (): Promise<AxiosResponse> => {
    return this.authenticationServiceProvider.Post({ path: APIPath.user.userSelfCheck() });
  };
}

class UserGroupDataService extends BaseCRUDService<UserGroup, UserGroup, UserGroupDetailed, UserGroup, UserGroup> {
  getUsers = (groupName: string, params: any = undefined): Promise<AxiosResponse> =>
    this.authenticationServiceProvider.Get({ path: APIPath.user.userGroupUsers(groupName), params });

  postUserToUserGroup = (
    groupName: string,
    userId: string,
  ): Promise<AxiosResponse<{ groupName: string; userId: string }>> =>
    this.authenticationServiceProvider.Post<{ groupName: string; userId: string }>({
      data: { userId },
      path: APIPath.user.userGroupUsers(groupName),
    });

  delUserFromUserGroup = (
    groupName: string,
    deviceId: string,
  ): Promise<AxiosResponse<{ groupName: string; userId: string }>> =>
    this.authenticationServiceProvider.Delete<{ groupName: string; userId: string }>({
      path: APIPath.user.UserGroupRemoveUser(groupName, deviceId),
    });
}

export class UserService {
  user: UserDataService;
  userGroup: UserGroupDataService;

  constructor(public authenticationServiceProvider: AuthenticationServiceProvider) {
    this.user = new UserDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.user.users,
      get: APIPath.user.userDetailed,
    });
    this.userGroup = new UserGroupDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.user.userGroups,
      get: APIPath.user.userGroupDetailed,
    });
  }

  static create = async (): Promise<UserService> => {
    const authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (!authenticationServiceProvider) throw new Error('No cached AuthenticationService available in UserService');
    return new UserService(authenticationServiceProvider);
  };
}
