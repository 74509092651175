import React from 'react';
import { DataService, DeviceService, FirmwareService, UserService } from '../../services';
import { AccessControlSectionAction } from '../../common/permissions';

type _TabViewBaseProps = {
  tab: number;
  activeTab: number;
  accessControl: (section: string, action: AccessControlSectionAction) => boolean;
  parentLoading: boolean;
  item?: any;
  incrementToReload?: number;
};
export type TabViewBaseProps<P = unknown> = _TabViewBaseProps & Omit<P, keyof _TabViewBaseProps>;

type _TabViewBaseState = {
  loading: boolean;
  editing: boolean;
};
export type TabViewBaseState<S = unknown> = _TabViewBaseState & Omit<S, keyof _TabViewBaseState>;

export class TabViewBase<P = unknown, S = unknown> extends React.Component<TabViewBaseProps<P>, TabViewBaseState<S>> {
  state!: TabViewBaseState<S>;
  isUnmounted = false;
  lastReload?: number;
  service: any;

  time = (): number => Math.round(new Date().getTime() / 1000);

  _reload = (retries = 0): void => {
    if (!this.reload) return;
    const isinstanceOfService: boolean =
      this.service instanceof DataService ||
      this.service instanceof DeviceService ||
      this.service instanceof FirmwareService ||
      this.service instanceof UserService;

    if ((!this.service || !isinstanceOfService) && retries < 20) {
      // set lastReload this to prevent new call in componentDidUpdate
      if (!this.lastReload) this.lastReload = this.time();
      if (!this.state.loading) this.setState({ loading: true } as TabViewBaseState<S>);
      setTimeout(() => this._reload(retries++), 100);
    } else if (isinstanceOfService) {
      this.lastReload = this.time(); // set lastReload to correct time
      this.reload();
    } else {
      this.lastReload = undefined; // remove lastReload if it failed so changing tab can reload
      if (this.state.loading) this.setState({ loading: false } as TabViewBaseState<S>);
    }
  };

  componentDidUpdate = async (prevProps: TabViewBaseProps<P>, prevState: TabViewBaseState<S>): Promise<void> => {
    if (!this.reload && prevProps.parentLoading && !this.props.parentLoading) this.lastReload = this.time();
    if (this.reload) {
      if (prevState.loading && !this.state.loading) this.lastReload = this.time();
      if (prevProps.incrementToReload !== this.props.incrementToReload) this._reload();
      else if (!this.lastReload && (this.props.tab as number) === this.props.activeTab) this._reload();
      else if (
        this.lastReload &&
        this.props.activeTab !== prevProps.activeTab &&
        (this.props.tab as number) === this.props.activeTab &&
        this.time() - this.lastReload! > 300
      )
        this._reload();
    }
  };

  componentWillUnmount = (): void => {
    this.isUnmounted = true;
  };

  // Override
  reload?: () => void;
}
