import React, { Component } from 'react';
import {
  Paper,
  IconButton,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stepper,
  Step,
  StepLabel,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import {
  CloseOutlined,
  EditOutlined,
  ClearOutlined,
} from '@mui/icons-material';
import { Trans } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import i18next from 'i18next';
import CryptoJS from 'crypto-js';
import CryptoLatin1Encoder from 'crypto-js/enc-latin1';
import { PostPackage, Package, Product, Release } from '../../common/types';
import { FirmwareService } from '../../services';
import { store } from '../../store';
import { Dialog } from '..';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import { getTheme } from '../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    ...BaseModalStyles(),
    stepper: {
      padding: 0,
    },
    fileInput: {
      display: 'none',
    },
    warning: {
      color: theme.palette.error.main,
      textAlign: 'center' as const,
    },
  };
};

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  activeStep: number;
  package: PostPackage;
  metadataVersion: string;
  metadataProductId?: string;
  editingVersionInConfirm: boolean;
  completePackage?: Package;
  packageId?: string;
  file?: {
    data: any;
    md5: string;
  };
  product?: Product;
  products: Product[];
  addFromVersions: string[];
  fromVersions: string[];
  releasesAvailable: boolean;
  releaseId: string;
  releaseName: string;
  releaseDescription: string;
  forceMapping: boolean;
  foceDialogOpen: boolean;
  setEntrypoint: boolean;
};

export class CreateFirmwarePackageMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    activeStep: 0,
    package: {
      name: '',
      description: '',
      productId: '',
      type: '',
      version: '',
      buildType: 'DELTA',
    },
    metadataVersion: '',
    metadataProductId: undefined,
    editingVersionInConfirm: true,
    completePackage: undefined,
    packageId: undefined,
    file: undefined,
    product: undefined,
    products: [],
    addFromVersions: [],
    fromVersions: [],
    releasesAvailable: false,
    releaseId: '',
    releaseName: '',
    releaseDescription: '',
    forceMapping: false,
    foceDialogOpen: false,
    setEntrypoint: false,
  };
  fileUploadRef: React.RefObject<HTMLInputElement>;
  firmwareService!: FirmwareService;
  productDebouncer: any;
  _isMounted = true;

  constructor(props: Props, state: State) {
    super(props, state);
    this.fileUploadRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    const { data } = await this.firmwareService.product.list({
      params: { limit: 500 },
    });
    let product: Product | undefined;

    if (!!this.store.modalStore.data && !!this.store.modalStore.data.product) {
      product = this.store.modalStore.data.product;
    } else if (
      !!this.store.modalStore.data &&
      !!this.store.modalStore.data.release
    ) {
      product = data.data.find(
        (p) => p.productId === this.store.modalStore.data.release.productId,
      );
    }

    if (!product) product = data.data[0];

    if (this._isMounted)
      this.setState({
        products: data.data,
        product,
        package: {
          ...this.state.package,
          productId: product.productId,
          type:
            (this.store.modalStore.data &&
              !!this.store.modalStore.data.release &&
              this.store.modalStore.data.release.type) ||
            product.updateTypes[0],
        },
      });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  prepare = async (): Promise<void> => {
    this.setState({ loading: true });

    try {
      const {
        data: { bucket, key, token, packageId },
      } = await this.firmwareService.package.post({
        productId: this.state.package.productId,
        type: this.state.package.type,
        md5: this.state.file!.md5,
      });
      await this.firmwareService.authenticationServiceProvider
        .uploadFile({
          Bucket: bucket,
          Key: key,
          Body: this.state.file!.data,
          Metadata: { token },
        })
        .promise();

      let response: any;
      let fails = 0;
      do {
        fails++;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        response = await this.firmwareService.package.get(packageId);
      } while (
        !!response &&
        !!response.data &&
        response.data.state !== 'AWAITING_CONFIRM' &&
        fails < 6
      );

      if (
        !!response &&
        !!response.data &&
        response.data.state !== 'AWAITING_CONFIRM'
      ) {
        await this.firmwareService.package.del(packageId);
        throw new Error('Failed to upload file');
      }

      SnackbarUtils.success(i18next.t('success.create.packageCreate'));

      if (
        !!this.store.modalStore.data &&
        !!this.store.modalStore.data.callback
      ) {
        this.store.modalStore.data.callback();
      }

      this.setState({
        packageId,
        activeStep: this.state.activeStep + 1,
        package: {
          ...this.state.package,
          name: response.data.name,
          version: response.data.version,
          description: response.data.description,
          buildType: response.data.buildType || 'DELTA',
        },
        metadataVersion: response.data.version,
        metadataProductId:
          (!!response.data.metadata &&
            !!response.data.metadata.manifest &&
            response.data.metadata.manifest.product_id) ||
          undefined,
        editingVersionInConfirm: !response.data.version,
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  confirm = async (): Promise<void> => {
    this.setState({ loading: true });

    try {
      const {
        data: completePackage,
      } = await this.firmwareService.package.confirm({
        packageId: this.state.packageId!,
        name: this.state.package.name,
        description: this.state.package.description,
        version: this.state.package.version,
        buildType: this.state.package.buildType,
      });

      let response: any;
      let fails = 0;
      do {
        fails++;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        response = await this.firmwareService.package.get(
          this.state.packageId!,
        );
      } while (
        !!response &&
        !!response.data &&
        response.data.state !== 'READY' &&
        fails < 6
      );

      if (!!response && !!response.data && response.data.state !== 'READY') {
        await this.firmwareService.package.del(this.state.packageId!);
        throw new Error('Failed to confirm package');
      }

      SnackbarUtils.success(i18next.t('success.create.packageConfirm'));
      if (
        !!this.store.modalStore.data &&
        !!this.store.modalStore.data.callback
      ) {
        this.store.modalStore.data.callback();
      }

      this.setState(
        { completePackage, activeStep: this.state.activeStep + 1 },
        this.getRelatedRelease,
      );
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  attach = async (): Promise<void> => {
    this.setState({ loading: true });
    if (!this.state.releasesAvailable) {
      try {
        const { data } = await this.firmwareService.release.post({
          productId: this.state.package.productId,
          type: this.state.package.type,
          name: this.state.releaseName,
          description: this.state.releaseDescription,
          toVersion: this.state.package.version,
        });
        this.setState({ releaseId: data.releaseId });
      } catch (e) {
        const error: any = e;
        SnackbarUtils.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          i18next.t('error.tryAgain'),
        );
        return this.setState({ loading: false });
      }
    }

    const result = await Promise.all(
      this.state.addFromVersions.map((fromVersion) =>
        this.firmwareService.release
          .postMapping(
            {
              packageId: this.state.packageId!,
              fromVersion,
              releaseId: this.state.releaseId,
            },
            { force: this.state.forceMapping },
          )
          .catch((e) => e),
      ),
    );

    if (
      result &&
      result.length &&
      !result.map((a) => a instanceof Error).includes(false)
    ) {
      if (
        !!result[0].request &&
        !!result[0].request.response &&
        result[0].request.response.search('force') > -1
      ) {
        this.setState({ foceDialogOpen: true });
      } else {
        SnackbarUtils.error(
          (result[0].response &&
            result[0].response.data &&
            result[0].response.data.message) ||
          i18next.t('error.tryAgain'),
        );
      }
    } else {
      let text =
        result && result.length
          ? 'success.attach.releaseMappings'
          : 'success.create.packageAttach';

      if (
        result &&
        result.length &&
        result.map((a) => a instanceof Error).includes(true)
      )
        text = 'warning.attach.partiallyReleaseMappings';

      if (this.state.setEntrypoint) {
        try {
          await this.firmwareService.release.setEntrypoint(
            this.state.releaseId,
            { packageId: this.state.packageId! },
            { force: this.state.forceMapping },
          );

          SnackbarUtils.success(i18next.t(text));
          if (this.store.modalStore.data.callback) {
            this.store.modalStore.data.callback();
          }

          if (this.store.modalStore.data.refreshRelease) {
            this.store.modalStore.data.refreshRelease();
          }

          this.props.closeModal();
        } catch (e) {
          const error: any = e;
          if (
            error.response &&
            error.response.data &&
            error.response.data.errorCode === 'FORCE_REQUIRED'
          ) {
            this.setState({ foceDialogOpen: true });
          } else {
            SnackbarUtils.success(
              `${i18next.t(text)} - failed to set entrypoint`,
            );

            if (this.store.modalStore.data.callback) {
              this.store.modalStore.data.callback();
            }

            this.props.closeModal();
          }
        }
      } else {
        SnackbarUtils.success(i18next.t(text));
        if (this.store.modalStore.data.callback) {
          this.store.modalStore.data.callback();
        }

        this.props.closeModal();
      }
    }
    this.setState({ loading: false });
  };

  get isValid(): boolean {
    switch (this.state.activeStep) {
      case 0:
        return (
          !this.state.loading &&
          !!this.state.package.productId &&
          !!this.state.package.type &&
          !!this.state.file
        );
      case 1:
        return (
          !this.state.loading &&
          !!this.state.packageId &&
          !!this.state.package.name &&
          !!this.state.package.version
        );
      case 2:
        return (
          !this.state.loading &&
          (!!this.state.addFromVersions.length || this.state.setEntrypoint) &&
          (!!this.state.releaseId || !!this.state.releaseName)
        );
      default:
        return false;
    }
  }

  handleFileSelect = (e: any): void => {
    e.preventDefault();
    const data = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const binary = CryptoLatin1Encoder.parse(e.target!.result);
      this.setState({ file: { data, md5: CryptoJS.MD5(binary).toString() } });
    };
    reader.readAsBinaryString(data);
  };

  isVersionLess = (from: string, to: string): boolean => {
    const [fMajor, fMinor, fPatch] = from.split('.') as [
      string,
      string,
      string,
    ];
    const [tMajor, tMinor, tPatch] = to.split('.') as [string, string, string];
    return (
      parseInt(fMajor) < parseInt(tMajor) ||
      (parseInt(fMajor) === parseInt(tMajor) &&
        parseInt(fMinor) < parseInt(tMinor)) ||
      (parseInt(fMajor) === parseInt(tMajor) &&
        parseInt(fMinor) === parseInt(tMinor) &&
        parseInt(fPatch) < parseInt(tPatch))
    );
  };

  getRelatedRelease = async (): Promise<void> => {
    if (!this.firmwareService) return;

    try {
      const list = await this.firmwareService.release.list({
        params: {
          limit: 500,
          toVersion: this.state.completePackage!.version,
          productId: this.state.completePackage!.productId,
        },
      });
      if (!!list.data && !!list.data.data && !!list.data.data.length) {
        this.setState({
          releaseId: list.data.data[0].releaseId,
          releaseName: list.data.data[0].name || '',
          releaseDescription: list.data.data[0].description || '',
          releasesAvailable: true,
        });
        const { data } = await this.firmwareService.release.get(
          list.data.data[0].releaseId,
        );
        this.getVersions(data.fromVersion || []);
      } else {
        this.getVersions([]);
      }
    } catch {
      SnackbarUtils.error(i18next.t('error.fetch.releases'));
    }
  };

  getVersions = async (existingFromVersions: string[]): Promise<void> => {
    if (!this.firmwareService) return;

    let list: Release[] = [];
    let nextToken: string | undefined;
    do {
      try {
        const response: any = await this.firmwareService.release.list({
          params: {
            limit: 500,
            productId: this.state.completePackage!.productId,
            ...(nextToken ? { nextToken } : {}),
          },
        });
        list = list.concat(response.data.data);
        nextToken = response.data.nextToken;
      } catch {
        SnackbarUtils.error(i18next.t('error.fetch.releases'));
        list = [];
        nextToken = undefined;
      }
    } while (nextToken);

    if (!!list && !!list.length && this._isMounted) {
      this.setState({
        fromVersions: list
          .filter(
            (f) =>
              !existingFromVersions.includes(f.toVersion) &&
              this.isVersionLess(
                f.toVersion,
                this.state.completePackage!.version,
              ),
          )
          .map((release) => release.toVersion),
      });
    }
  };

  stepAction = (): void => {
    switch (this.state.activeStep) {
      case 0:
        this.prepare();
        break;
      case 1:
        this.confirm();
        break;
      case 2:
        this.attach();
        break;
    }
  };

  getText(type: 'button' | 'title' | 'description'): string {
    switch (type) {
      case 'button':
        switch (this.state.activeStep) {
          case 0:
            return 'action.upload';
          case 1:
            return 'action.confirm';
          case 2:
            return this.state.releasesAvailable
              ? 'action.attach'
              : 'action.createAndRelease';
          default:
            return '';
        }
      case 'title':
        switch (this.state.activeStep) {
          case 0:
            return 'modals.createFirmwarePackage.create.title';
          case 1:
            return 'modals.createFirmwarePackage.confirm.title';
          case 2:
            return 'modals.createFirmwarePackage.attach.title';
          default:
            return '';
        }
      case 'description':
        switch (this.state.activeStep) {
          case 0:
            return 'modals.createFirmwarePackage.create.description';
          case 1:
            return 'modals.createFirmwarePackage.confirm.description';
          case 2:
            return 'modals.createFirmwarePackage.attach.description';
          default:
            return '';
        }
    }
  }

  renderStep = (): React.ReactNode => {
    switch (this.state.activeStep) {
      case 0:
        return this.renderPrepare();
      case 1:
        return this.renderConfirm();
      case 2:
        return this.renderAttach();
      default:
        return null;
    }
  };

  renderPrepare = (): React.ReactNode => {
    return (
      <>
        {this.state.product && (
          <Grid item xs={6}>
            <Autocomplete
              id="crfp"
              disableClearable
              options={this.state.products}
              getOptionLabel={(option: Product) =>
                (!!option && option.productId) || ''
              }
              isOptionEqualToValue={(option: Product, value: Product) =>
                option.productId === value.productId
              }
              value={this.state.product}
              disabled={
                !!this.store.modalStore.data &&
                (!!this.store.modalStore.data.product ||
                  !!this.store.modalStore.data.release)
              }
              onChange={(_: any, product: Product) =>
                this.setState({
                  product,
                  package: {
                    ...this.state.package,
                    productId: product.productId,
                  },
                })
              }
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  InputLabelProps={{ shrink: true, htmlFor: 'crfp' }}
                  label={i18next.t('form.product')}
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              shrink
              id="type-label"
              style={{
                backgroundColor: 'white',
                paddingLeft: 4,
                paddingRight: 4,
                marginLeft: -4,
              }}
            >
              <Trans>form.type</Trans>*
            </InputLabel>
            <Select
              labelId="type-label"
              id="type"
              value={this.state.package.type}
              onChange={(e) =>
                this.setState({
                  package: {
                    ...this.state.package,
                    type: e.target.value as string,
                  },
                })
              }
              disabled={
                !!this.store.modalStore.data &&
                !!this.store.modalStore.data.release
              }
            >
              {(
                (this.state.product && this.state.product.updateTypes) ||
                []
              ).map((updateType) => (
                <MenuItem key={updateType} value={updateType}>
                  {updateType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => this.fileUploadRef.current!.click()}
          >
            <input
              id="file-upload"
              ref={this.fileUploadRef}
              type="file"
              accept=".ota"
              className={this.props.classes.fileInput}
              onChange={this.handleFileSelect}
            />
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Trans>
                  {this.state.file ? 'form.changeFile' : 'form.selectFile'}
                </Trans>
              </Grid>
              <Grid item xs={12}>
                <pre style={{ display: 'inline-block', margin: 0 }}>
                  {this.state.file
                    ? this.state.file.data.name
                    : i18next.t('description.noFileSelected')}
                </pre>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </>
    );
  };

  renderConfirm = (): React.ReactNode => {
    return (
      <>
        {this.state.metadataProductId !== undefined &&
          this.state.metadataProductId !== this.state.package.productId ? (
          <Grid item xs={12}>
            <Typography className={this.props.classes.warning}>
              {i18next
                .t('description.selectedProductIdDoesNotMatchPackageId')
                .replace('%reportedProduct%', this.state.metadataProductId)
                .replace('%selectedProduct%', this.state.package.productId)}
            </Typography>
          </Grid>
        ) : null}
        {this.state.metadataVersion !== undefined &&
          !!this.store.modalStore.data.release &&
          this.state.metadataVersion !==
          this.store.modalStore.data.release.toVersion ? (
          <Grid item xs={12}>
            <Typography className={this.props.classes.warning}>
              {i18next
                .t(
                  'description.selectedPackageVersionDoesNotMatchReleaseVersion',
                )
                .replace('%packageVersion%', this.state.metadataVersion)
                .replace(
                  '%releaseVersion%',
                  this.store.modalStore.data.release.toVersion,
                )}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            required
            label={i18next.t('form.version')}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!this.state.editingVersionInConfirm}
            value={this.state.package.version}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.setState({
                package: { ...this.state.package, version: e.target.value },
              })
            }
            InputProps={
              this.state.metadataVersion
                ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={
                          this.state.editingVersionInConfirm
                            ? 'edit version'
                            : 'reset version'
                        }
                        size="small"
                        onClick={() =>
                          this.setState({
                            editingVersionInConfirm: !this.state
                              .editingVersionInConfirm,
                            package: {
                              ...this.state.package,
                              version: `${this.state.metadataVersion}`,
                            },
                          })
                        }
                      >
                        {this.state.editingVersionInConfirm ? (
                          <ClearOutlined />
                        ) : (
                          <EditOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            required
            label={i18next.t('form.name')}
            InputLabelProps={{
              shrink: true,
            }}
            value={this.state.package.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.setState({
                package: { ...this.state.package, name: e.target.value },
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label={i18next.t('form.description')}
            InputLabelProps={{
              shrink: true,
            }}
            value={this.state.package.description || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.setState({
                package: { ...this.state.package, description: e.target.value },
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              shrink
              id="type-label"
              style={{
                backgroundColor: 'white',
                paddingLeft: 4,
                paddingRight: 4,
                marginLeft: -4,
              }}
            >
              <Trans>form.buildType</Trans>*
            </InputLabel>
            <Select
              labelId="type-label"
              id="type"
              value={this.state.package.buildType}
              onChange={(e) =>
                this.setState({
                  package: {
                    ...this.state.package,
                    buildType: e.target.value as any,
                  },
                })
              }
            >
              <MenuItem key="DELTA" value="DELTA">
                DELTA
              </MenuItem>
              <MenuItem key="FULL" value="FULL">
                FULL
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  };

  renderAttach = (): React.ReactNode => {
    return (
      <>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            required
            label={i18next.t('form.releaseVersion')}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            value={this.state.package.version}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            required
            label={i18next.t('form.releaseName')}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={this.state.releasesAvailable}
            value={this.state.releaseName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.setState({ releaseName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label={i18next.t('form.releaseDescription')}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={this.state.releasesAvailable}
            value={this.state.releaseDescription}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.setState({ releaseDescription: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="crfp2"
            multiple
            filterSelectedOptions
            ChipProps={{ size: 'small' }}
            options={this.state.fromVersions}
            getOptionLabel={(option: string) => option}
            isOptionEqualToValue={(option, value) => option === value}
            value={this.state.addFromVersions}
            onChange={(_: any, addFromVersions: string[]) =>
              this.setState({ addFromVersions })
            }
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="outlined"
                required
                InputLabelProps={{ shrink: true, htmlFor: 'crfp2' }}
                label={i18next.t('form.fromVersions')}
                placeholder={i18next.t('form.placeholder.selectFromVersions')}
                fullWidth
              />
            )}
          />
        </Grid>
        {this.state.package.buildType === 'FULL' ? (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.setEntrypoint}
                  onChange={(e: any, checked: boolean) =>
                    this.setState({ setEntrypoint: checked })
                  }
                />
              }
              label={<Trans>description.setPackageAsReleaseEntry</Trans>}
            />
          </Grid>
        ) : null}
      </>
    );
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>{this.getText('title')}</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>{this.getText('description')}</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stepper
              alternativeLabel
              activeStep={this.state.activeStep}
              className={this.props.classes.stepper}
            >
              <Step key={0}>
                <StepLabel>
                  <Trans>tab.uploadPackage</Trans>
                </StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>
                  <Trans>tab.confirmPackage</Trans>
                </StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel>
                  <Trans>tab.attachToRelease</Trans>
                </StepLabel>
              </Step>
            </Stepper>
          </Grid>
          {this.renderStep()}
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              disabled={!this.isValid}
              onClick={this.stepAction}
            >
              {this.state.loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Trans>{this.getText('button')}</Trans>
              )}
            </Button>
          </Grid>
          {this.state.activeStep === 2 ? (
            <Grid item>
              <Button color="secondary" onClick={this.props.closeModal}>
                <Trans>action.skip</Trans>
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Dialog
          open={this.state.foceDialogOpen}
          title={
            <Trans>
              {this.state.addFromVersions.length
                ? 'action.forceMapping'
                : 'action.forceEntrypoint'}
            </Trans>
          }
          description={
            <Trans>
              {this.state.addFromVersions.length
                ? 'description.forceMapping'
                : 'description.forceEntrypoint'}
            </Trans>
          }
          continueTitle={<Trans>action.continue</Trans>}
          onClose={() =>
            this.setState({ loading: false, foceDialogOpen: false })
          }
          onContinue={() => {
            this.setState(
              { foceDialogOpen: false, forceMapping: true },
              this.attach,
            );
                      

          }}
        />
      </Paper>
    );
  };
}

export default withStyles(styles)(CreateFirmwarePackageMC as any);
