/* eslint-disable react/no-children-prop */
import React from 'react';
import { Grid, Box, List, ListItem, ListItemText, ListSubheader, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { AuthenticationServiceProvider } from '../../../services';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import {
  ModalVariants,
  SubdomainRoutePath,
  LastVisited,
  RoutePathPrefix,
  Store,
  CustomerConfiguration,
} from '../../../common/types';
import { drawerWidth, appBarHeight } from '../../../common/constants';
import { storeOpenModal } from '../../../store/actions/modal';
import { PageLoader, SnackbarUtils } from '../../../components';
import { storeAddLastVisited } from '../../../store/actions/common';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { AWSError } from 'aws-sdk';
import { getTheme } from '../../../common/theme';

type TableOfContent = [Element, any[]];

const mdTypography = {
  h1: {
    fontSize: '2.5rem',
    fontWeight: '300 !important',
    borderBottom: '0 !important',
    marginTop: '24px',
    marginBottom: '16px',
  },
  h2: {
    fontSize: '2rem',
    fontWeight: '350 !important',
    borderBottom: '0 !important',
    marginTop: '24px',
    marginBottom: '16px',
  },
  h3: {
    fontSize: '1.75rem',
    fontWeight: '350 !important',
    marginTop: '24px',
    marginBottom: '16px',
  },
  h4: {
    fontSize: '1.20rem',
    fontWeight: '400 !important',
    marginTop: '24px',
    marginBottom: '12px',
  },
  h5: {
    fontSize: '1rem',
    fontWeight: '500 !important',
    marginTop: '24px',
    marginBottom: '12px',
  },
  h6: {
    fontSize: '1rem',
    fontWeight: '600 !important',
    color: '#6a737d',
    marginTop: '24px',
    marginBottom: '12px',
  },
};

const styles = () => {
  const theme = getTheme();
  return {
    container: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth - 100}px)`,
        padding: theme.spacing(2),
      },
      maxWidth: 1000,
      fontFamily: 'Roboto !important',
      outline: 'none',
      overflow: 'hidden',
      '& h1': mdTypography.h1,
      '& h2': mdTypography.h2,
      '& h3': mdTypography.h3,
      '& h4': mdTypography.h4,
      '& h5': mdTypography.h5,
      '& h6': mdTypography.h6,
      '& p': theme.typography.body1,
      '& a': {
        color: theme.palette.secondary.dark,
        textDecoration: 'underline',
      },
      '& a:hover': {
        color: theme.palette.secondary.light,
      },
      '& p .docs-button': {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        padding: '12px 16px',
        textDecoration: 'none',
        fontWeight: 500,
        fontSize: '0.875rem',
        borderRadius: '4px',
      },
      '& p .docs-button:hover': {
        backgroundColor: theme.palette.secondary.dark,
        textDecoration: 'none',
      },
      '& p .docs-link-icon': {
        display: 'inline-flex',
      },
      '& p .docs-link-icon img': {
        maxWidth: '24px',
        maxHeight: '24px',
      },
      '& > div ': {
        padding: theme.spacing(4),
        '&> *': {
          '&:first-child': {
            marginTop: 0,
          },
          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
      '& strong': {
        fontWeight: '500 !important',
      },
      '& ul': {
        marginTop: '12px',
        paddingInlineStart: '18px',
        marginBottom: '36px',
      },
      '& pre': {
        'overflow-y': 'auto' as const,
        padding: theme.spacing(2),
        backgroundColor: 'rgb(246, 248, 250)',
      },
      '& blockquote': {
        margin: 0,
        padding: '14px 40px 14px 36px',
        borderLeft: '4px solid #DDD',
        '& > p': {
          margin: 0,
          color: 'darkslategrey',
        },
      },
      '& table': {
        borderCollapse: 'collapse' as const,
        border: '1px solid black',
        '& td, & th, & tr': {
          border: '1px solid black',
        },
        '& td, & th': {
          padding: 4,
        },
      },
    },
    tableOfContentGrid: {
      width: drawerWidth - 100,
      [theme.breakpoints.down('md')]: {
        display: 'none' as const,
      },
    },
    tableOfContentContainer: {
      top: appBarHeight,
      position: 'fixed' as const,
      width: drawerWidth - 100,
      maxHeight: `calc(100vh - ${appBarHeight}px)`,
      padding: theme.spacing(2, 0),
      'overflow-y': 'hidden' as const,
      '&:hover': {
        'overflow-y': 'auto' as const,
      },
      '& > ul': {
        borderLeft: `2px solid ${theme.palette.secondary.main}`,
      },
    },
    contentHeaderRoot: {
      lineHeight: '36px',
      cursor: 'pointer' as const,
    },
    contentLink: {
      color: 'inherit' as const,
      '&:hover': {
        cursor: 'pointer' as const,
        color: theme.palette.secondary.main,
      },
    },
    contentLinkText: {
      fontSize: '12px',
      marginTop: 0,
      marginBottom: 0,
    },
    anchor: {
      top: -56,
      position: 'relative' as const,
      display: 'block' as const,
      visibility: 'hidden' as const,
      zIndex: -1,
    },
  };
};

type Props = WithStyles<any> &
  RouteComponentProps & {
    document: string;
    path: string;
    name: string;
    config?: CustomerConfiguration;
    openModal: (url: string) => void;
    addToLastVisited: (lastVisited: LastVisited, origin?: string) => void;
  };

type State = {
  loading: boolean;
  spec?: any;
  tableOfContent?: any;
};

class MarkdownDocsView extends React.Component<Props, State> {
  state: State = {
    loading: false,
    spec: undefined,
    tableOfContent: undefined,
  };
  authenticationServiceProvider?: AuthenticationServiceProvider;
  markdownRef: React.RefObject<HTMLDivElement>;
  _isMounted = true;

  constructor(props: Props) {
    super(props);
    this.markdownRef = React.createRef();
  }

  componentDidMount = async (): Promise<void> => {
    this.setState({ loading: true });
    this.authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (!this.authenticationServiceProvider) {
      throw new Error('No authentication service');
    }
    const { userSession, refresh } = this.authenticationServiceProvider.service;
    if (userSession && !userSession?.isValid()) {
      await refresh();
    }

    this.props.addToLastVisited(
      { type: 'docs', path: this.props.path, name: this.props.name },
      this.authenticationServiceProvider?.service.userId(),
    );

    let spec = await this.authenticationServiceProvider.downloadFileAsString({
      params: { Bucket: this.props.config!.bucket, Key: this.props.document },
    });

    if (spec.search('%username%') > -1)
      spec = spec.replace(/%username%/, await this.authenticationServiceProvider!.service.username());

    if (spec.search('%userPoolId%') > -1)
      spec = spec.replace(/%userPoolId%/, (this.props.config && this.props.config.userPoolId) || 'undefined');
    if (spec.search('%clientId%') > -1)
      spec = spec.replace(/%clientId%/, (this.props.config && this.props.config.clientId) || 'undefined');

    if (this.authenticationServiceProvider.iris) {
      if (spec.search('%milieu%') > -1)
        spec = spec.replace(/%milieu%/g, this.authenticationServiceProvider.iris.milieu);
      if (spec.search('%domain%') > -1)
        spec = spec.replace(/%domain%/g, this.authenticationServiceProvider.iris.domain || '');
      if (spec.search('%awsAccount%') > -1)
        spec = spec.replace(/%awsAccount%/g, this.authenticationServiceProvider.iris.account);
      if (spec.search('%roleArn%') > -1)
        spec = spec.replace(/%roleArn%/g, this.authenticationServiceProvider.iris.roleArn);
    }
    if (this._isMounted) this.setState({ loading: false, spec }, this.updateDocuments);
  };

  componentWillUnmount = (): void => {
    this._isMounted = false;
  };

  scrollTo = (offset: number): void => {
    window.scrollTo({
      left: 0,
      top: offset - appBarHeight - 16,
    });
  };

  updateDocuments = async (): Promise<void> => {
    await Promise.all([this.updateTableOfContent(), this.updateDocumentImages(), this.updateDocumentLinks()]);
    if (window.location.hash) {
      setTimeout(() => {
        const element = document.getElementById(decodeURIComponent(window.location.hash.substring(1)));
        if (element) this.scrollTo(element!.offsetTop);
      }, 500);
    }
  };

  updateTableOfContent = (): Promise<void> => {
    const mkEl = document.getElementsByClassName('markdown-body');
    if (!mkEl || !mkEl.length) return Promise.resolve();
    const data = mkEl[0].querySelectorAll('h2, h3');
    const { list } = this.buildTreeOfElements(data, 0);
    return new Promise((resolve) =>
      this.setState(
        {
          tableOfContent: ([
            <ListSubheader
              key="content-header"
              disableSticky
              classes={{ root: this.props.classes.contentHeaderRoot }}
              onClick={() => this.scrollTo(0)}
            >
              Table of content
            </ListSubheader>,
          ] as React.ReactNode[]).concat(list.map((element) => this.createTableOfContent(element))),
        },
        resolve,
      ),
    );
  };

  buildTreeOfElements = (elements: NodeListOf<Element>, index: number): { list: TableOfContent[]; index: number } => {
    const list: TableOfContent[] = [];
    let i = index;
    while (i < elements.length && !!elements[i]) {
      // Equal size
      if (list.length === 0 || list[list.length - 1][0].tagName === elements[i].tagName) {
        list.push([elements[i], []]);
        i++;
        continue;
      }

      // Prev is smaller than current (h2 is smaller than h1 in size but logic operator says h2 is bigger than h1)
      if (list[list.length - 1][0].tagName > elements[i].tagName) break;

      // Prev is bigger than current (h1 is biggern than h2 but logic operator says h1 is smaller than h2)
      if (list[list.length - 1][0].tagName < elements[i].tagName) {
        const data = this.buildTreeOfElements(elements, i);
        list[list.length - 1][1] = list[list.length - 1][1].concat(data.list);
        i = data.index;
      }
    }
    return { list, index: i };
  };

  createTableOfContent = ([node, children]: TableOfContent): React.ReactNode => {
    node.id = (node.textContent || '').toLowerCase();
    node.id = node.id.replace(/[^a-zA-Z0-9 ]/g, '');
    node.id = node.id.replace(/ /g, '-');
    return [
      <ListItem
        dense
        key={`table-of-content-link-${node.id}`}
        className={this.props.classes.contentLink}
        onClick={() => {
          // @ts-ignore
          if (window.history.replaceState) window.history.replaceState(null, null, `#${node.id}`);
          else window.location.hash = `#${node.id}`;
          this.scrollTo(document.getElementById(node.id)!.offsetTop);
        }}
      >
        <ListItemText disableTypography className={this.props.classes.contentLinkText}>
          {node.textContent}
        </ListItemText>
      </ListItem>,
      !!children && children.length ? (
        <Box ml="6px" key={`table-of-content-list-${node.id}`}>
          <List disablePadding>{children.map((child) => this.createTableOfContent(child as TableOfContent))}</List>
        </Box>
      ) : null,
    ];
  };

  getKeyFromLocalPath = (path: string): string => {
    const documentSplit = this.props.document.split('/');
    const pathSplit = path.split('/');
    return documentSplit.slice(0, -1).concat(pathSplit).join('/');
  };

  getKeyFromPath = (path: string): string => {
    const documentSplit = this.props.document.split('/');
    const pathSplit = path.split('/');
    let newPath = '';
    for (let i = 0; i < documentSplit.length; i += 1) {
      if (documentSplit[i].toLowerCase() === pathSplit[i].toLowerCase()) {
        newPath = newPath.concat(documentSplit[i] + '/');
      } else {
        for (let j = i; j < pathSplit.length; j += 1) {
          newPath = newPath.concat(pathSplit[j]);
          if (j !== pathSplit.length - 1) {
            newPath = newPath.concat('/');
          }
        }
        break;
      }
    }
    return newPath;
  };

  updateDocumentImages = async (): Promise<void[]> => {
    if (!this.authenticationServiceProvider) throw new Error('No cognito service');
    const localPath = window.location.pathname.split('/').slice(1, -1).join('/');
    const setUpImg = (img: HTMLImageElement, Body: Uint8Array, ContentType: string) => {
      const url = URL.createObjectURL(new Blob([Body as Uint8Array], { type: ContentType }));
      img.src = url;
      img.onclick = () => this.props.openModal(url);
    };
    return Promise.all(
      Array.from(document.images).map((img) => {
        img.style.maxWidth = '100%';
        if (img.src.search('static') > -1) return Promise.resolve();
        let path = img.src.split('//')[1].split('/').slice(1).join('/').replace(localPath, '');
        if (path.startsWith('/')) path = path.slice(1);
        const key = path.startsWith('images') ? this.getKeyFromLocalPath(path) : this.getKeyFromPath(path);
        return this.authenticationServiceProvider!.downloadFile({
          params: { Bucket: this.props.config!.bucket, Key: key },
        })
          .then(({ Body, ContentType }) => {
            const body = Body as Uint8Array;
            if (Body && ContentType) setUpImg(img, body, ContentType);
          })
          .catch((e: AWSError) => {
            console.error(e);
          });
      }),
    );
  };

  updateDocumentLinks = (): Promise<void[]> => {
    return Promise.all(
      Array.from(document.links).map((link) => {
        console.log('link', link.href);
        if (!link.href || link.href === 'javascript:void(0)') return Promise.resolve(); // eslint-disable-line
        if (link.href.search('mailto:|tel:') > -1) return Promise.resolve();

        const path = link.href.split('//')[1].split('/').slice(1).join('/').replace('.md', '');
        if (link.href.search('api-spec') > -1) {
          if (path.split('/')[1]) {
            link.href = 'javascript:void(0)'; // eslint-disable-line
            link.onclick = (): void => this.props.history.push(SubdomainRoutePath.apiSpec(path.split('/')[1] as any));
          } else {
            link.href = 'https://team-docs.dev.iris.incubation.io/' + path;
            link.target = '_blank';
          }
          return Promise.resolve();
        }
        console.log('path', path);

        if (path.search('downloads/') > -1) {
          const fileName = path.split('/').slice(-1);
          console.log('fileName', fileName);
          let snackId;
          link.onclick = async () => {
            if (!this.authenticationServiceProvider) return alert('NO COGNITO');
            snackId = SnackbarUtils.download(`Downloading ${fileName}`);
            try {
              const key = path.replace('docs/downloads/', '');
              console.log('key', key);
              const { Body, ContentType } = await this.authenticationServiceProvider.downloadFile({
                params: { Bucket: this.props.config!.bucket, Key: key },
              });
              const blob = new Blob([Body as Uint8Array], { type: ContentType });

              const a = document.createElement('a');
              document.body.appendChild(a);
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              const splitKey = path.split('/');
              a.download = splitKey[splitKey.length - 1];
              a.click();
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);

              SnackbarUtils.close(snackId);
              SnackbarUtils.success(`Successfully downloaded ${fileName}`);
            } catch {
              SnackbarUtils.close(snackId);
              SnackbarUtils.error(`Failed to download ${fileName}`);
            }
          };
          link.href = 'javascript:void(0)'; // eslint-disable-line
          return Promise.resolve();
        }

        if (
          path.search('#') > -1 &&
          (window.location.pathname.search(`/${path.split('#')[0]}`) > -1,
          window.location.pathname.search(`/${path.split('#')[0].substring(0, path.split('#')[0].length - 1)}`) > -1)
        ) {
          link.href = 'javascript:void(0)'; // eslint-disable-line
          link.onclick = (): void => {
            this.scrollTo(document.getElementById(path.split('#')[1])!.offsetTop);
            window.history.replaceState({}, '', `${window.location.pathname}#${path.split('#')[1]}`);
          };
          return Promise.resolve();
        }

        if (link.href.search(window.location.hostname) > -1) {
          const finalPath = path.startsWith('/') ? path : '/' + path;
          link.href = 'javascript:void(0)'; // eslint-disable-line
          if (window.location.pathname.split('/').slice(-1)[0] !== path.split('/')[0]) {
            link.onclick = (): void => this.props.history.push(finalPath);
          }
          return Promise.resolve();
        }

        link.target = '_blank';
        return Promise.resolve();
      }),
    );
  };

  render = (): React.ReactNode => {
    if (this.state.loading) {
      return (
        <Box p={4}>
          <PageLoader />
        </Box>
      );
    }
    if (!this.state.spec) {
      return (
        <Box p={4} display="flex" justifyContent="center" alignItems="center">
          <Trans>error.fetch.docs</Trans>
        </Box>
      );
    }
    return (
      <Grid container justifyContent="center">
        <Grid item ref={this.markdownRef} className={clsx(this.props.classes.container, 'markdown-body')}>
          <Paper elevation={2}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={this.state.spec.trim()} />
          </Paper>
        </Grid>
        <Grid item className={this.props.classes.tableOfContentGrid}>
          <div className={this.props.classes.tableOfContentContainer}>
            <List disablePadding>{this.state.tableOfContent}</List>
          </div>
        </Grid>
      </Grid>
    );
  };
}

const mapStateToProps = ({ userStore }: Store) => ({
  config: userStore.config,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (url: string) => dispatch(storeOpenModal(ModalVariants.FullScreenImageMC, { url })),
  addToLastVisited: (lastVisited: LastVisited, origin?: string) =>
    dispatch(storeAddLastVisited({ ...lastVisited, origin }, RoutePathPrefix.docsAndDownloads)),
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MarkdownDocsView)));
