import { CommonStore } from '../../common/types';
// import { LAST_VISITED_CACHE } from '../../common/constants';

const CommonInitialState: CommonStore = (() => {
  const lastVisited = localStorage.getItem('4295c528-a246-4acc-aef7-dbf1cf2e3747');
  return {
    lastVisited: lastVisited ? JSON.parse(lastVisited) : {},
    menuListToggle: {},
    activeTab: '',
    leftNavOpen: false,
    rightNavOpen: false,
  };
})();

export default CommonInitialState;
