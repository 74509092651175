import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Action, Dispatch } from 'redux';
import i18next from 'i18next';
import { FirmwareService } from '../../../services';
import { Dialog, TabsHeader } from '../../../components';
import { PropertiesTab } from '../../baseClasses/PropertiesTab';
import { itemIcons } from '../../../common/constants';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../baseClasses/ViewBase';
import firmwareSchema from '../../../assets/schemas/firmware.schema.parsed.json';

type _Props = {
  openModal: (productId: string, callback: () => void) => () => void;
};
type Props = ViewBaseProps<_Props, { keyId: string }>;

type _State = unknown;
type State = ViewBaseState<_State>;

class KeyDetailedView extends ViewBase<Props, State, { keyId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.properties'];
    FirmwareService.create().then((service) => (this.service = service));
    this.subservice = 'key';
    this.messages = {
      load: { error: 'error.fetch.key' },
      update: { success: 'success.update.key', error: 'error.tryAgain' },
      delete: { success: 'success.delete.key', error: 'error.tryAgain' },
    };
  }

  downloadKey = (): void => {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(this.state.item.key)}`);
    element.setAttribute('download', `${this.state.item.keyId}.pem`);
    element.click();
  };

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('key', 'delete') && !!this.state.item && !this.state.item.locked)
      list.push(
        <MenuItem key="1" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <PropertiesTab
        disableEdit={!this.state.item || !!this.state.item.locked}
        tab={0}
        customAction={
          <Button color="secondary" size="small" variant="text" onClick={this.downloadKey}>
            {i18next.t('action.downloadKey')}
          </Button>
        }
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="key"
        parentLoading={this.state.loading}
        schema={firmwareSchema}
        schemaKey="key"
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <Dialog
      open={this.state.deleteDialogOpen}
      title={<Trans>action.delete</Trans>}
      description={<Trans>description.delete.key</Trans>}
      continueTitle={<Trans>action.delete</Trans>}
      onClose={() => this.setState({ deleteDialogOpen: false })}
      onContinue={this.delete}
    />
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={this.props.match.params.keyId}
        icon={itemIcons.key}
        subtitle={i18next.t('form.keyId')}
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  ...ViewBaseMapDispatchToProps(dispatch),
});

export default withStyles(ViewBaseStyles)(withRouter(connect(null, mapDispatchToProps)(KeyDetailedView)));
