/* eslint-disable max-len */

export default {
  '/docs': 'Documentation',
  '/download': 'Downloads',

  routes: {
    // Public
    home: 'Home',
    deviceLicenses: 'Device licenses',

    // Subdomain
    operations: 'Management',
    dashboard: 'Dashboard',
    customerConfiguration: 'Account information',
    deviceManagement: 'Device management',
    devices: 'Devices',
    deviceGroups: 'Device groups',
    deviceApiGw: 'Device API GW',
    firmwareManagement: 'Firmware management',
    firmwarePackages: 'Packages',
    firmwareProducts: 'Products',
    firmwareReleases: 'Releases',
    dataManagement: 'Data management',
    subscriptions: 'Subscriptions',
    shadowAliases: 'Shadow aliases',
    shadowTemplates: 'Shadow templates',
    userManagement: 'User management',
    settings: 'My settings',
    users: 'Users',
    userGroups: 'User groups',
    docs: 'Docs & Download',
    apiSpec: 'Api specifications',
    apiFleet: 'Fleet API',
    apiFleetApiGw: 'Fleet API GW',
    apiUser: 'User API',
    keys: 'Keys',
    key: 'Key',
    trusts: 'Trusts',
    trust: 'Trust',
  },

  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',

  filter: 'Filter',
  devices: 'Devices',
  device: 'Device',
  deviceGroups: 'Device groups',
  deviceGroup: 'Device group',
  deviceApiGw: 'API Endpoint',
  deviceApiGws: 'API Endpoints',
  shadowAliases: 'Shadow aliases',
  shadowAlias: 'Shadow alias',
  shadowTemplates: 'Shadow templates',
  shadowTemplate: 'Shadow template',
  users: 'Users',
  user: 'User',
  userGroups: 'User groups',
  userGroup: 'User group',
  subscriptions: 'Subscriptions',
  subscription: 'Subscription',
  document: 'Document',
  download: 'Download',
  packages: 'Packages',
  package: 'Package',
  products: 'Products',
  product: 'Product',
  releases: 'Releases',
  release: 'Release',
  keys: 'Keys',
  key: 'Key',
  trusts: 'Trusts',
  trust: 'Trust',

  config: {
    customer: 'Customer',
    iot: 'IoT',
    cognito: 'Cognito',
    api: 'Api',
    role: 'Role',
  },

  success: {
    attach: {
      deviceGroupToDevice: 'Device group has been attached to device',
      deviceGroupsToDevice: 'Device groups has been attached to device',
      deviceToDeviceGroup: 'Device has been attached to device group',
      devicesToDeviceGroup: 'Devices has been attached to device group',
      userGroupToUser: 'User group has been attached to user',
      userGroupsToUser: 'User groups has been attached to user',
      userToUserGroup: 'User has been attached to user group',
      usersToUserGroup: 'Users has been attached to user group',
      releaseMapping: 'Package has been attached to release',
      releaseMappingVersion: 'Version has been attached to release',
    },
    detach: {
      releaseMappingVersion: 'Version has been detached from release',
      deviceFromDeviceGroup: 'Device has been detached from device group',
      userFromUserGroup: 'User has been detached from user group',
    },
    publish: {
      release: 'Release has been published',
    },
    create: {
      filter: 'Query has been created',
      filterDuplicate: 'Query has been duplicated',
      subscription: 'Subscription has been created',
      product: 'Product has been created',
      packageCreate: 'Package has been created',
      packageConfirm: 'Package has been confirmed',
      packageAttach: 'Package has been attached',
      release: 'Release has been created',
      device: 'Device has been created',
      deviceGroup: 'Device group has been created',
      deviceApiGw: 'Device API GW has been created',
      shadowAlias: 'Shadow alias has been created',
      shadowTemplate: 'Shadow template has been created',
      userGroup: 'User group has been created',
      user: 'Invitation has been sent',
      key: 'Key has been created',
      trust: 'Trust has been created',
      clone: 'Clone has been created',
    },
    update: {
      releaseMappingVersions: 'Release version mappings has been updated',
      releaseMappings: 'Package mappings has been updated',
      releasePackageEntry: 'Package entry has been updated',
      filter: 'Query has been updated',
      product: 'Product has been updated',
      package: 'Package has been updated',
      release: 'Release has been updated',
      password: 'Password has been updated',
      device: 'Device has been updated',
      deviceShadow: 'Device shadow has been updated',
      deviceGroup: 'Device group has been updated',
      deviceApiGw: 'Device API GW has been updated',
      shadowAlias: 'Shadow alias has been updated',
      shadowTemplate: 'Shadow template has been updated',
      subscription: 'Subscription has been updated',
      user: 'User has been updated',
      userGroup: 'User group has been updated',
      key: 'Key has been updated',
      trust: 'Trust has been updated',
      shadowTemplateDefault: 'Default shadow template has been updated',
      resendInvite: 'New invite sent',
      triggers: {
        attached: 'Attached trigger has been updated',
        detached: 'Detached trigger has been updated',
        triggers: 'Trigger has been updated',
      },
    },
    delete: {
      releaseEntrypoint: 'Entrypoint has been deleted',
      filter: 'Query has been deleted',
      product: 'Product has been deleted',
      package: 'Package has been deleted',
      release: 'Release has been deleted',
      releaseMapping: 'Release mapping has been deleted',
      device: 'Device has been deleted',
      deviceGroup: 'Device group has been deleted',
      deviceApiGw: 'Device API GW has been deleted',
      shadowAlias: 'Shadow alias has been deleted',
      shadowTemplate: 'Shadow template has been deleted',
      subscription: 'Subscription has been deleted',
      user: 'User has been deleted',
      userGroup: 'User group has been deleted',
      key: 'Key has been deleted',
      trust: 'Trust has been deleted',
      shadowTemplateDefault: 'Default shadow template has been deleted',
    },
    disable: {
      release: 'Release has been disabled',
      subscription: 'Subscription has been disabled',
    },
    mfa: {
      enable: 'Two-factor (mfa) authentication succesfully enabled',
      disable: 'Two-factor (mfa) authentication succesfully disabled',
      info: 'Current mfa disabled',
    },
  },

  warning: {
    trigger: {
      key: 'Group with policyType NONE should not be used with shadow template having groupId!',
    },
    form: {
      array: 'press plus or tap enter to keep your changes',
    },
    create: {
      deviceCreatedPartiallyAddedGroups: 'Device was created, partially succeeded to attach device groups',
    },
    attach: {
      partiallyDeviceGroupsToDevice: 'Partially succeeded to attach device groups to device',
      partiallyDevicesToDeviceGroup: 'Partially succeeded to attach devices to device group',
      partiallyUsersToUserGroup: 'Partially succeeded to attach users to user group',
      partiallyUserGroupsToUser: 'Partially succeeded to attach user groups to user',
      userCreatedPartiallyAddedToAllGroups: 'Invitation has been sent but user was not attached to all groups',
    },
    update: {
      partiallyReleaseMappings: 'Partially succeeded to update package mappings',
    },
    changeProduct: 'Product has been updated',
  },
  error: {
    triggers: 'Failed to update triggers',
    loadList: 'Failed to load list data',
    tryAgain: 'Something went wrong, please try again',
    deltaNotEditable: 'Delta is not allowed to be modified',
    parse: 'Cannot parse json',
    deletePermisson: 'Your user group do not have permission to perform this task',
    create: {
      deviceCreatedFailedToAddGroups: 'Device was created but failed to add device groups',
      createUserFailedToAddUserGroup: 'User was created but failed to add user groups',
      permission: 'Your user group do not have permission to perform this task',
    },
    match: {
      json: 'Invalid json',
      passwordRequirements: 'New password not matching requirments',
    },
    missing: {
      deviceId: 'Missing device id',
      deviceGroupId: 'Missing device group id',
      userId: 'Missing user id',
      userGroupName: 'Missing user group name',
    },
    mfa: {
      credentials: 'Could not confirm your credentials, please try again.',
      code: 'Invalid code, please try again.',
      enable: 'Could not enable Two-factor (mfa) authentication, please try again.',
      disable: 'Could not disable Two-factor (mfa) authentication, please try again.',
      proceed: 'Could not proceed, please try again',
    },
    mqtt: {
      start: 'Failed to start device traffic listener',
      stop: 'Failed to close connection with device traffic listener',
      closed: 'Traffic connection closed due to inactivity or error, press LISTEN to start again',
      tryingToRestart: 'Error occured, trying to reconnect device traffic listener',
    },
    attach: {
      releaseMappingVersion: 'Failed to attach version to release',
      deviceGroupToDevice: 'Failed to attach device group to device',
      deviceGroupsToDevice: 'Failed to attach device groups to device',
      deviceToDeviceGroup: 'Failed to attach device to device group',
      devicesToDeviceGroup: 'Failed to attach devices to device groups',
      userGroupToUser: 'Failed to attach user group to user',
      userGroupsToUser: 'Failed to attach user groups to user',
      userToUserGroup: 'Failed to attach user to user group',
      usersToUserGroup: 'Failed to attach users to user group',
    },
    detach: {
      releaseMappingVersion: 'Failed to detach version from release',
    },
    fetch: {
      key: 'Failed to fetch key',
      keys: 'Failed to fetch keys',
      device: 'Failed to fetch device',
      devices: 'Failed to fetch devices',
      deviceGroup: 'Failed to fetch device group',
      deviceGroups: 'Failed to fetch device groups',
      deviceChildGroups: 'Failed to fetch child groups',
      connections: 'Failed to fetch connections',
      deviceApi: 'Failed to fetch device API',
      deviceApis: 'Failed to fetch device APIs',
      shadowAliases: 'Failed to fetch shadow aliases',
      shadowTemplate: 'Failed to fetch shadow template',
      subscription: 'Failed to fetch subscription',
      user: 'Failed to fetch user',
      users: 'Failed to fetch users',
      userGroup: 'Failed to fetch user group',
      userGroups: 'Failed to fetch user groups',
      docs: 'Failed to fetch documentation',
      specs: 'Failed to fetch api specification',
      product: 'Failed to fetch product',
      package: 'Failed to fetch package',
      packages: 'Failed to fetch packages',
      release: 'Failed to fetch release',
      releases: 'Failed to fetch releases',
      triggers: 'Failed to fetch triggers',
    },
    update: {
      releaseMappingVersions: 'Failed to update version mappings',
      password: 'Failed to update password',
    },
    validator: {
      required: 'This is required',
      minLength: 'Min length is %minLength%',
      maxLength: 'Max length is %maxLength%',
      minLengthArray: 'Minimum of %minLength% items',
      maxLengthArray: 'Maximum of %maxLength% items',
      minimum: 'Minimum value is %minimum%',
      maximum: 'Maximum value is %maximum%',
      pattern: '"%chars%" not matching allowed pattern',
      onlyInteger: 'Only integer allowed',
      invalidJson: 'Invalid json',
      doesNotMatchJsonSchema: 'Does not match json schema',
    },
  },

  description: {
    attached: 'When device is attached to group',
    detached: 'When device is detached to group',
    show: 'show',
    hide: 'hide',
    bytes: ' bytes',
    optional: 'optional',
    isDefaultTemplate: 'Is default template',
    isNotDefaultTemplate: 'Is not default template',
    selectedProductIdDoesNotMatchPackageId:
      "Product in package ('%reportedProduct%') does not match currently selected product ('%selectedProduct%')! Only confirm this package if you are sure.",
    selectedPackageVersionDoesNotMatchReleaseVersion:
      "Package version ('%packageVersion%') does not match currently selected release version ('%releaseVersion%')! Only confirm this package if you are sure.",
    unpublishRelease: 'Unpublish release',
    setPackageAsReleaseEntry: 'Set package as release entrypoint',
    apiIsDisabled: 'Api is disabled',
    apiIsEnabled: 'Api is enabled',
    deleteEntrypoint: 'Delete entrypoint',
    deviceIsConnected: 'Device is connected',
    deviceIsNotConnected: 'Device is not connected',
    deviceWasDisconnected: 'Device was disconnected',
    subscriptionStatusIs: 'Subscription status is',
    deviceIsActivated: 'Device is activated',
    deviceHasBeenCreated: 'Device has been created',
    deviceIsDeactivated: 'Device is deactivated',
    deviceHasBeenRevoked: 'Device has been revoked',
    packageIsReadyToUse: 'Package is ready to use',
    awaitingPackageConfirmation: 'Awaiting package confirmation',
    awaitingPackageUpload: 'Awaiting package upload',
    failedToParseUpload: 'Failed to parse upload',
    userIsDisabled: 'User is disabled',
    userIsEnabled: 'User is enabled',
    userIsEnabledWithStatus: 'User is enabled with status %status%',
    releaseIsDisabled: 'Release is disabled',
    enabledWithRollout: 'Enabled with rollout %rollout%',
    enabledForGroupsWithRollout: 'Enabled for %groupsRule% %groups% with rollout %rollout%',
    noMetadataAvailable: 'There is not metadata available for this package.',
    forceMapping:
      'The release is enabled which will make the changes available instantly. Are you sure you want to continue?',
    forceEntrypoint:
      'The release is enabled which will make the entrypoint available instantly. Are you sure you want to continue?',
    forceUpdateRelease:
      'The release is enabled and the effects of the update will take action immediately. Are you sure you want to continue',
    noFileSelected: 'No file selected',
    noKeyFileSelected: 'No file selected (.pem, .key)',
    newFilter: 'New query',
    update: {
      product: '                                         ',
    },
    delete: {
      entrypoint:
        'This will remove the entrypoint and disable factory from download the package. Are you sure you want to continue?',
      device: 'The device will be deleted and can not be undone. Are you sure you want to continue?',
      deviceGroup: 'The device group will be deleted and can not be undone. Are you sure you want to continue?',
      deviceApiGw: 'The device API GW will be deleted and can not be undone. Are you sure you want to continue?',
      shadowTemplate: 'The shadow template will be deleted and can not be undone. Are you sure you want to continue?',
      user: 'The user will be deleted and can not be undone. Are you sure you want to continue?',
      userGroup: 'The user group will be deleted and can not be undone. Are you sure you want to continue?',
      subscription: 'The subscription will be deleted and can not be undone. Are you sure you want to continue?',
      package: 'The package will be deleted and can not be undone. Are you sure you want to continue?',
      product: 'The product will be deleted and can not be undone. Are you sure you want to continue?',
      release: 'The release will be deleted and can not be undone. Are you sure you want to continue?',
      key: 'The key will be deleted and can not be undone. Are you sure you want to continue?',
      defaultShadowTemplate:
        'The template will be removed as default and will affect current factory processes instantly. Are you sure you want to continue?',
    },
    disable: {
      release:
        'The release will be disabled and no devices will be able to download the release. Are you sure you want to continue?',
    },
  },

  action: {
    changeProduct: 'Change product',
    authorize: 'Authorize',
    listen: 'Listen',
    clear: 'Clear',
    close: 'Close',
    moreInfo: 'More info',
    stop: 'Stop',
    downloadKey: 'Download key',
    createKey: 'Create key',
    desired: 'Desired',
    reported: 'Reported',
    json: 'Json',
    file: 'File',
    setAsDefault: 'Set as default',
    unsetAsDefault: 'Unset as default',
    apply: 'Apply',
    download: 'Download',
    createFirmwareProduct: 'Create product',
    createFirmwareRelease: 'Create release',
    createFirmwarePackage: 'Upload package',
    createSubscription: 'Create subscription',
    createUserGroup: 'Create user group',
    createUser: 'Create user',
    createDeviceApiGw: 'Create API endpoint',
    createDeviceGroup: 'Create device group',
    createDevice: 'Authorize device',
    createShadowTemplate: 'Create shadow template',
    createShadowAlias: 'Create shadow alias',
    createTrust: 'Create trust',
    saveAs: 'Save as',
    unset: 'Unset',
    setEntrypoint: 'Set entrypoint',
    unsetEntrypoint: 'Unset entrypoint',
    editMapping: 'Edit mappings',
    forceUpdateRelease: 'Force update release',
    forceEntrypoint: 'Force entrypoint',
    updateEntry: 'Update entrypoint',
    deleteEntry: 'Delete entrypoint',
    runTest: 'Run test',
    addNew: 'Add new',
    query: 'Query',
    confirmPackage: 'Confirm package',
    uploadPackage: 'Upload package',
    upload: 'Upload',
    publish: 'Publish',
    unpublish: 'Unpublish',
    editTarget: 'Edit target',
    forceMapping: 'Force update release mapping',
    enable: 'Enable',
    disable: 'Disable',
    toggleEnable: 'Toggle enable',
    skip: 'Skip',
    createRelease: 'Create release',
    createAndRelease: 'Create & attach',
    signIn: 'Sign in',
    signOut: 'Sign out',
    attachDevices: 'Attach devices',
    attachDeviceGroups: 'Attach to group',
    attachUsers: 'Attach users',
    attachUserGroups: 'Attach to user groups',
    attach: 'Attach',
    detach: 'Detach',
    activate: 'Activate',
    deactivate: 'Deactivate',
    refresh: 'Refresh',
    add: 'Add',
    finish: 'Finish',
    addCombination: 'Add combination',
    update: 'Update',
    delete: 'Delete',
    clone: 'Clone',
    edit: 'Edit',
    save: 'Save',
    saveAsNew: 'Save as new',
    cancel: 'Cancel',
    continue: 'Continue',
    confirm: 'Confirm',
    customize: 'Customize',
    change: 'Change',
    create: 'Create',
    invite: 'Invite',
    changePassword: 'Change password',
    forgotPassword: 'Forgot password',
    openSourceLicenses: 'Open source licenses',
    actions: 'Actions',
    editProperties: 'Edit properties',
    editShadow: 'Edit shadow',
    createNewFilter: 'Create query',
    editFilter: 'Edit query',
    duplicateFilter: 'Duplicate query',
    deleteFilter: 'Delete query',
    changeType: 'Change type',
  },

  label: {
    deviceId: 'deviceId',
    type: 'type',
    state: 'state',
    lastSeen: 'lastSeen',
  },

  form: {
    clientId: 'Client id',
    unlocked: 'Unlocked',
    locked: 'Locked',
    reference: 'Reference',
    keyId: 'Key id',
    generation: 'Generation',
    mode: 'Mode',
    templateId: 'Template id',
    entrypointPackageId: 'Entrypoint',
    entrypoint: 'Entrypoint',
    buildType: 'Build type',
    firmwareUpdated: 'Firmware updated',
    firmwareVersion: 'Firmware version',
    disconnectReason: 'Disconnect reason',
    clientInitiatedDisconnect: 'Client initiated disconnect',
    headers: 'Headers',
    apiId: 'Api id',
    trustId: 'Trust id',
    alias: 'Alias',
    isTimestamp: 'Is timestamp',
    releaseVersion: 'Release version',
    releaseName: 'Release name',
    releaseDescription: 'Release description',
    selectFile: 'Select file',
    changeFile: 'Change file',
    selectKey: 'Select key file',
    changeKey: 'Change key file',
    deviceGroups: 'Device groups',
    package: 'Package',
    product: 'Product',
    release: 'Release',
    customVersion: 'Custom version',
    rollout: 'Rollout',
    fromVersion: 'From version',
    fromVersions: 'From versions',
    toVersion: 'To version',
    productId: 'Product id',
    packageId: 'Package id',
    releaseId: 'Release id',
    updateTypes: 'Update types',
    updateType: 'Update type',
    default: 'Default',
    type: 'Type',
    size: 'Size',
    version: 'Version',
    statusMessage: 'Status message',
    groupsRule: 'Groups rule',
    packageMapping: 'Package mapping',
    subscriptionId: 'Subscription id',
    events: 'Event filter',
    url: 'Url',
    fingerprints: 'Fingerprints',
    kinesisStreamArn: 'Kinesis stream arn',
    kinesisRoleArn: 'Kinesis role arn',
    deliveryStreamArn: 'Kinesis Firehose stream arn',
    publishRoleArn: 'Kinesis Firehose role arn',
    sqsRoleArn: 'SQS role arn',
    sqsQueueUrl: 'SQS queue url',
    endpointType: 'Endspoint type',
    query: 'Query',
    none: 'None',
    email: 'Email',
    password: 'Password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    signIn: 'sign in',
    forgotPassword: 'Forgot password',
    verificationCode: 'Verification code',
    name: 'Name',
    state: 'State',
    groupId: 'Group id',
    groupName: 'Group name',
    description: 'Description',
    parent: 'Parent',
    userGroups: 'User groups',
    deviceGroup: 'Device group',
    value: 'Value',
    startDate: 'Start date',
    endDate: 'End date',
    property: 'Property',
    filterProperty: 'Filter property',
    selectFilterCombination: 'Filter combination',
    groupType: 'Group type',
    policyType: 'Policy type',
    deviceId: 'Device id',
    certificateId: 'Certificate id',
    certificateCN: 'Certificate cn',
    thingName: 'Thing name',
    deviceGroupId: 'Device group',
    imei: 'IMEI',
    imsi: 'IMSI',
    created: 'Created',
    activated: 'Activated',
    modified: 'Modified',
    updated: 'Updated',
    lastSeen: 'Last seen',
    operator: 'Operator',
    groups: 'Groups',
    roleArn: 'Role arn',
    region: 'Region',
    createdBy: 'Created by',
    userId: 'User id',
    status: 'Status',
    enabled: 'Enabled',
    disconnected: 'Disconnected',
    connected: 'Connected',
    when: 'When',
    duration: 'Duration',
    displayProperties: 'Display properties',
    empty: {
      devices: 'No devices found',
      deviceGroups: 'No device groups found',
      deviceApiGw: 'No device APIs found',
      trust: 'No trusts found',
      shadowAliases: 'No shadow aliases found',
      shadowTemplates: 'No shadow templates found',
      users: 'No users found',
      userGroups: 'No user groups found',
      childGroups: 'No child groups found',
      connections: 'No connections found',
      items: 'No items found',
      minLength: 'Write 2 chars to search',
      subscriptions: 'No subscriptions found',
      packages: 'No packages found',
      products: 'No products found',
      releases: 'No releases found',
      keys: 'No keys found',
      events: 'You must start to listen before you see any traffic',
      noEvents: 'No traffic, yet..',
    },
    operators: {
      equal: 'Equal',
      startsWith: 'Starts with',
      between: 'Between',
      betweenAbsolute: 'Between absolute',
    },
    placeholder: {
      startsWith: 'Starts with...',
      searchForItems: 'Search...',
      selectFilterCombination: 'Select filter combination',
      selectDeviceGroup: 'Select device group',
      selectDeviceGroups: 'Select device groups',
      selectDevices: 'Select devices',
      selectUserGroups: 'Select user groups',
      selectUsers: 'Select users',
      selectFromVersions: 'Select from versions',
      selectPackage: 'Select package',
      selectShadowTemplate: 'Select shadow template',
    },
  },

  tab: {
    test: 'Test',
    packages: 'Packages',
    releases: 'Releases',
    properties: 'Properties',
    shadow: 'Shadow',
    userGroups: 'User groups',
    users: 'Users',
    deviceGroups: 'Device groups',
    connections: 'Connections',
    childGroups: 'Child groups',
    triggers: 'Triggers',
    devices: 'Devices',
    mappedPackages: 'Packages',
    uploadPackage: 'Upload package',
    confirmPackage: 'Confirm package info',
    attachToRelease: 'Attach to release',
    metadata: 'Metadata',
    traffic: 'Traffic',
    security: 'Security',
    firmware: 'Firmware',
  },

  modals: {
    changePassword: {
      changePassword: 'Change password',
      updatePassword:
        'You need to update your password. Need to contain uppercased, lowercased, digit and symbol with min length 8',
    },
    changeProduct: {
      changeProduct: 'Change product',
      subheader: 'Change to ProductId:',
      description:
        'By changing product you instruct the device to crossover to a completely other product/application at next firmware update check. The product you are changing to, must have a release with an entry point package.',
    },
    addDeviceGroupToDevice: {
      title: 'Attach device groups to device',
      description:
        'Attaching device groups to a device will make it visible for users having access to the device group. I may take some time for the device to appear.',
    },
    addDevicesToDeviceGroup: {
      title: 'Attach devices to device group',
      description:
        'Attaching a devices to the device group will make it visible for users having access to the device group. I may take some time for the device to appear.',
    },
    addUserGroupsToUser: {
      title: 'Attach user groups to user',
      description:
        'Attaching user groups to a user gives the user access to view, and if user group allows, edit the device.',
    },
    addUsersToUserGroup: {
      title: 'Attach users to user group',
      description:
        'Attaching users to a user groups gives the users access to view, and if user group allows, edit the device.',
    },
    applyShadowTemplate: {
      title: 'Apply shadow template',
      description:
        'Applying a shadow template to a device will put template in desired state for shadow. Only attributes mentioned in the template will be affected.',
    },
    createDevice: {
      title: 'Authorize device',
      description:
        'The device will be available and connected after being added. I may take some time for the device to appear.',
    },
    createDeviceGroup: {
      title: 'Create device group',
      description:
        'Attaching devices to a group will make it easier to manage the groups. Group type is only for easier management but policies will give the devices access to new functionality. I may take some time for the device to appear.',
    },
    createDeviceGroupClone: {
      title: 'Clone device group',
      description: 'All properties, triggers etcetera will be cloned, please select a unique group id.',
    },
    createDeviceApiGw: {
      title: 'Create API endpoint',
      description:
        'Create and configure mqtt endpoint providing bi-directional data communication with http apis for your devices.',
      activePageHeader: ['Create API endpoint', 'Define api', 'Configure endpoint', 'Review and create'],
      activePageDescription: [
        'By creating an API you provide bidirectional communication capabilities between devices and a specified endpoint. A device can send a request and the endpoint can respond with data to the requesting device. We will guide you through the process:',
        'An API is represented by an Api Id which also becomes the subtopic that the device uses in the communication. An Api Id should be short and descriptive for its usage and can be complemented with a description that can provide more details. Note that the Api Id can NOT be changed once created!',
        'A device api request will become a https request to the endpoint, and will contain metadata about the device and may include an optional payload. The endpoint must processes the request and return a relevant response to the device.',
        'You can review example usage of the API bellow. When you create the api it will become enabled and status will change once data is transmitted.',
      ],
      activePageDescriptionStatic:
        'A STATIC Api will return a preconfigured configured response. During device application development a STATIC api can be used to mock a real HTTPS API, e.g. before the endpoint is available.',
      reviewCommon: ['Type:', 'Api id:', 'Url:'],
      reviewHTTPS: {
        1: ['1. Request from device', 'Topic:', 'Payload:'],
        2: ['2. Request to endpoint​', 'Method:', 'URL:', 'Headers:', 'Body:'],
        3: ['3. Response from endpoint​', 'Headers:', 'Body:'],
        a: ['4a. Data response to device​', 'Topic:', 'Payload:'],
        b: [
          '4b. Error response to device​',
          'If endpoint returns HTTP status <> 200 or response time is over 5s',
          'Topic:',
          'Payload:',
        ],
      },
      reviewSTATIC: {
        1: ['1. Request from device', 'Topic:', 'Payload:'],
        2: ['2. Data response to device​', 'Topic:', 'Payload:'],
      },
    },
    createTrust: {
      title: 'Create trust',
      description: 'Create and configure trust for securing https integration.',
    },
    createFirmwarePackage: {
      create: {
        title: 'Upload package',
        description:
          'Several packages with the same end-state (version) can exist where each depend on a different base.',
      },
      confirm: {
        title: 'Confirm information',
        description:
          'Confirm the information below and fill out the missing fields. Version cannot be change after this step!',
      },
      attach: {
        title: 'Attach to release',
        description: 'Attach the package to a existing release or create a release for this package.',
      },
    },
    createFirmwareProduct: {
      title: 'Create product',
      description:
        'This is a top level software partition for customer devices. A device must be connected to a product which will be the decicive connection for new software updates. This can be used to seperate different hardwares or different applications for same hardware.',
    },
    createFirmwareRelease: {
      create: {
        title: 'Create release',
        description:
          'A release is for a product with a given end-state. It represents the rules that bind packages of same version to different starting points.',
      },
      attach: {
        title: 'Attach packages',
        description: 'Attach packages to the release. Each package should have a unique from version.',
      },
    },
    createKey: {
      title: 'Create key',
      description:
        'A TOOLCHAIN_KEY is used for accessing devices while SIGNING_KEY is used for product/package signing.',
    },
    createShadowAlias: {
      title: 'Create shadow alias',
      description:
        'Shadow alias will index a attribute in shadow to be visible and searchable in device list and detailed view.',
    },
    createShadowTemplate: {
      title: 'Create shadow template',
      description:
        'Shadow template is used when selecting default shadow for devices. It can also be used to update single devices for testing',
    },
    editFirmwareReleaseMapping: {
      title: 'Edit release mappings',
      description:
        'A release mapping will connect the packages needed for previus versions to get to this version. You can connect several packages with different from versions to make it easier for different versions update to the release version.',
    },
    updateShadowAlias: {
      title: 'Update shadow alias',
      description:
        'Shadow alias will index a attribute in shadow to be visible and searchable in device list and detailed view.',
    },
    createSubscription: {
      title: 'Create subscription',
      description: 'Adding a new subscription will enable you to retrive data posted from the devices.',
      activePageHeader: ['Create subscription', 'Filter Events', 'Define destination', 'Start subscription'],
      activePageDescription: [
        'Creating a subscription will enable you to consume data published by your devices and platform generated events, in your own backend endpoint. We will guide you through the process in 3 steps:',
        'Define what data that should be processed by this subscription. Filter events from subtopics used by your application and/or system events that you want to consume.',
        'Select your preferred integration type and the destination endpoint, where the data should be processed. Configure details as trust mechanism that should be used (e.g. Authorization).',
        'Add an informative description of this subscription. The subscription will become enabled directly and status will change once data is successfully transmitted.',
      ],
    },
    authorizeDevice: {
      title: 'Authorize Device',
      description:
        'The device will be available and connected after being added. I may take some time for the device to appear.',
      activePageHeader: ['Authorize device', 'Device type & identity', 'Optional properties', 'Authorize device'],
      activePageHeaderNoOptional: ['Authorize device', 'Device type & identity', 'Authorize device'],
      activePageDescription: [
        'Authorize a new physical device to become part of your managed fleet. An authorised device is permitted to provide data into your data streams. We will guide you through the process in 3 steps:',
        'Specify the device type and its identity.',
        'Optionally add additional properties and identifiers of the device.',
        'Optionally add name and description of the device and then press Authorize. The device will be added to your managed fleet.',
      ],
    },
    createUserGroup: {
      title: 'Create user group',
      description:
        'If you attach a device group to the user group the users in the group will only be able to manage units related with the device group.',
    },
    inviteUser: {
      title: 'Invite user',
      description:
        'Inviting a new user will send out a email to the specified email below. You can only invite a new user if you belong to user group admins or powerusers.',
    },
    publishFirmwareRelease: {
      title: 'Publish release',
      description:
        'Publishing the release will make it avaiable for devices. You can select to publish to specific groups or partitions or a combination of both.',
    },
    updateEntryPackageMC: {
      title: 'Update entrypoint',
      description: 'Select a package which will be set as entrypoint and available for factory to download.',
    },
  },

  view: {
    signInView: {
      updatePassword:
        'You need to update your password. Need to contain uppercased, lowercased, digit and symbol with min length 8',
      resetRequire: 'Password reset required, and email has been sent to you with the code',
      forgotPassword: 'Forgot password',
      verifyForgotPassword: 'Verify forgot password',
    },
    securityTab: {
      app: 'Authentication app',
      cancel: 'Cancel',
      confirmButton: 'Confirm',
      edit: 'Enable MFA',
      enable: 'Enable',
      confirm: 'Please confirm your credentials to proceed',
      continue: 'Continue',
      disable: 'Disable MFA',
      phone:
        'Use an application on your phone to get two-factor (mfa) authentication codes when prompted. You must use a cloud-based app such as Google or Microsoft Authenticator.',
      scan:
        'Scan the QR code from your two-factor (mfa) authentication app on your phone, an application such as Google or Microsoft Authenticator.',
      show: 'Show full code',
      twoFactor: 'Two-factor authentication (MFA)',
      help: 'Scan the QR code, the app will display a six-digit code',
    },
  },
  navigation: {
    right: {
      version: 'Version',
      region: 'Region',
      role: 'Role',
      email: 'Email',
    },
  },
};
