/*
 * Copyright (C) 2018 Sony Mobile Communications Inc.
 * All rights, including trade secret rights, reserved.
 */
const awsIot = require('aws-iot-device-sdk');

const MAX_WAIT = 15000;
const MAX_CLOSE_CONNECTIONS = 3;
const MAX_VISUAL_BINARY_LEN = 40;

export class IotMqttDevice {
  constructor(config) {
    // We only setup a deviceConfig for selected protocol that will be used later
    // The portal only supports wss
    const { host, region, clientId, debug = false, protocol = 'wss', expires } = config;
    this.setExpireTimer(expires);
    if (protocol === 'wss') {
      const { accessKeyId, secretKey, sessionToken } = config;
      this.deviceConfig = { protocol, host, region, clientId, debug, accessKeyId, secretKey, sessionToken };
    } else {
      throw new Error('protocol must be "wss"');
    }
    // As we create sdk device at connect (lazy) we must cache listeners user want to set-up prior to that...
    this.listeners = [];
  }

  createDevice(deviceConfig) {
    try {
      const device = new awsIot.thingShadow(deviceConfig); // May throw
      this.device = device;
      // Add any cached listners set prior to device is enabled.
      this.listeners.forEach((item) => device.on(item.event, item.handler));
      this.listeners = []; // Empty cache
      return Promise.resolve(device);
    } catch (error) {
      console.error('createDevice', error);
      return Promise.reject(error);
    }
  }

  connect() {
    return new Promise((resolve, reject) => {
      if (!this.device) {
        this.createDevice(this.deviceConfig)
          .then((device) => {
            const errorHandler = (error) => reject(error);
            let closed = 0;
            const closedtHandler = () => {
              if (++closed >= MAX_CLOSE_CONNECTIONS) {
                const msg = `Max reconnect attempts reached ${MAX_CLOSE_CONNECTIONS}`;
                try {
                  device.emit('error', msg);
                } catch (err) {
                  reject(msg);
                }
              }
            };
            const connectHandler = () => {
              closed = 0;
              this.device = device; // Indicator that we are connected...
              device.removeListener('connect', connectHandler);
              device.removeListener('error', errorHandler);
              resolve(this.device);
            };
            device.on('connect', connectHandler);
            device.on('close', closedtHandler); // Keep active....
            device.on('error', errorHandler);
          })
          .catch((err) => {
            console.error('failed to create device when connecting');
            return reject(err);
          });
      } else {
        resolve(this.device);
      }
    }); // Promise
  }

  disconnect() {
    return new Promise((resolve) => {
      if (this.device) {
        console.log('IotDevice disconnect this.device', this.device);
        this.device.end(true, () => {
          console.log('IotDevice disconnect device.end');
          this.device = null;
          resolve('Disconnected');
        });
      } else {
        resolve('Disconnected');
      }
    });
  }

  subscribe(topic, messageHandler, qos = 1) {
    qos = parseInt(qos);
    return new Promise((resolve, reject) => {
      this.connect()
        .then((device) => {
          if (messageHandler) messageHandler.listenOn(device); // Connect handle with sdk device
          device.subscribe(topic, { qos }, (err) => {
            const { protocol } = this.deviceConfig;
            err ? reject(err) : resolve({ state: 'SUBSCRIBED', protocol, qos, topic });
          });
        })
        .catch((err) => reject(err));
    });
  }

  unsubscribe(topic) {
    return new Promise((resolve, reject) => {
      this.connect()
        .then((device) => {
          device.unsubscribe(topic, (err) => {
            const { protocol } = this.deviceConfig;
            err ? reject(err) : resolve({ state: 'UNSUBSCRIBED', protocol, topic });
          });
        })
        .catch((err) => reject(err));
    });
  }

  addListener(event, handler) {
    // Add to device OR cache until sdk client is availible
    this.device ? this.device.on(event, handler) : this.listeners.push({ event, handler });
    return this;
  }

  removeListener(event, handler) {
    if (this.device) this.device.removeListener(event, handler);
    return this;
  }

  setExpireTimer(expires) {
    // See if we must setup expire
    if (expires && expires > new Date().getTime()) {
      const when = expires - new Date().getTime();
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.device) this.device.emit('expired', {});
        this.timer = null;
      }, when);
    }
  }

  updateConfig(config) {
    const { accessKeyId, secretKey, sessionToken, expires } = config;
    Object.assign(this.deviceConfig, { accessKeyId, secretKey, sessionToken });
    this.setExpireTimer(expires);
    if (this.device && this.deviceConfig.protocol === 'wss' && sessionToken) {
      this.device.updateWebSocketCredentials(accessKeyId, secretKey, sessionToken);
    }
  }
}

export class IotMqttMessageHandler {
  constructor() {
    this.messages = [];
    this.waited = 0;
    this.attempts = 0;
    this.handler = (topic, message) => {
      // IMPORTANT: Buffer may have trialing u0000 if message is sent with qos 0, trim it...
      while (message.length > 0 && message[message.length - 1] === 0) {
        message = message.slice(0, -1);
      }
      // Handle
      this.callback ? this.callback(topic, message) : this.messages.push({ message, topic });
    };
  }

  wait = (waitMS = MAX_WAIT) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(`Waited ${waitMS}`);
      }, waitMS);
    });
  };

  listenOn(device) {
    this.device = device;
    this.device.addListener('message', this.handler);
  }

  getMessage(outputJson = true) {
    const LOOP_MS = 100;
    return this.wait(LOOP_MS).then(() => {
      this.waited = ++this.attempts * LOOP_MS;
      if (this.messages.length > 0) {
        if (this.device) this.device.removeListener('message', this.handler);
        const message = this.messages.pop();
        try {
          message.message = JSON.parse(message.message);
        } catch (err) {
          if (outputJson) {
            const { length } = message.message;
            const buffer =
              length <= MAX_VISUAL_BINARY_LEN
                ? `0x ${message.message.toString('hex')}`
                : `0x ${message.message.slice(0, MAX_VISUAL_BINARY_LEN).toString('hex')}...`;
            message.message = {
              length,
              data: buffer,
            };
          }
        }
        return Promise.resolve(message);
      } else if (this.waited > MAX_WAIT) {
        return Promise.reject(`Max wait time reached ${MAX_WAIT} ms`);
      } else {
        return this.getMessage(outputJson);
      }
    });
  }

  addCallback(callback) {
    this.callback = callback;
    return this;
  }
}
