import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import { DeviceService, FilterService } from '../../../services';
import { SubdomainRoutePath, ModalVariants } from '../../../common/types';
import { storeOpenModal } from '../../../store/actions/modal';
import { tableProperties, listFilter, itemIcons, TableNames } from '../../../common/constants';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';

type _Props = {
  openModal: (callback: () => any) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class ShadowTemplateView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
  };

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.filterService = new FilterService(this, 'shadowTemplate');
    this.accessControlSection = 'shadowTemplate';
    this.title = i18next.t('shadowTemplates');
    this.icon = itemIcons.shadowTemplate;
    this.idKey = 'templateId';
    this.filterConfig = {
      listFilter: listFilter.shadowTemplate,
      availableProperties: Object.keys(tableProperties(TableNames.shadowTemplate)),
    };
    this.tableConfig = {
      tableProperties: tableProperties(TableNames.shadowTemplate),
      emptyTitle: 'form.empty.shadowTemplates',
    };
    this.fabConfig = [
      {
        title: i18next.t('action.createShadowTemplate'),
        action: () => this.props.openModal(this.reloadList),
        icon: itemIcons.shadowTemplate,
        tryAccessRight: ['shadowTemplate', 'create'],
      },
    ];

    // Functions
    this.getUrlPathForItem = SubdomainRoutePath.shadowTemplate;
  }

  componentDidMount = async () => {
    this.service = await DeviceService.create();
    this.list = this.service!.shadowTemplate.list;

    const predefinedFilters = this.filterService!.get();
    const index = this.filterService!.getIndex();

    this.setState({ initLoaded: true, predefinedFilters, activeTabIndex: index });
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any) => dispatch(storeOpenModal(ModalVariants.CreateShadowTemplateMC, { callback })),
});

export default withStyles(ListBaseStyles)(withRouter(connect(null, mapDispatchToProps)(ShadowTemplateView)));
