const hasExtension = (topic: string) => topic.match('^(.+)\\.[a-zA-Z0-9]+$');

const extensionMatch = (topic: string, extension: any) => topic.match('^(.+)\\.' + extension + '$') as any;

export const jsons = ['json', 'j', 'JSON', 'J'];

const expectJson = (topic: string) => {
  const ends = !topic.endsWith('/b');
  const ext = !hasExtension(topic);
  const match = jsons.reduce((res, next) => {
    return res || extensionMatch(topic, next);
  }, false);
  return ends && (ext || match);
};

export default expectJson;
