import { Role } from './types';

type ViewPermission = {
  generic?: Role[];
  list?: Role[];
  read?: Role[];
  create?: Role[];
  update?: Role[];
  delete?: Role[];
};

const PERMISSIONS: { [key: string]: ViewPermission } = {
  // Management
  configuration: {
    read: [Role.ADMIN, Role.DEVELOPER],
  },
  device: {
    list: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    read: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    create: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER],
    update: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER],
    delete: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER],
  },
  deviceGroup: {
    list: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    read: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    create: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER],
    update: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER],
    delete: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER],
  },
  deviceApiGw: {
    generic: [Role.ADMIN, Role.DEVELOPER],
  },
  shadow: {
    list: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    read: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    create: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    update: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    delete: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
  },
  shadowAlias: {
    generic: [Role.ADMIN, Role.DEVELOPER],
  },
  shadowTemplate: {
    generic: [Role.ADMIN, Role.DEVELOPER],
  },
  user: {
    generic: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER],
  },
  userGroup: {
    generic: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER],
  },
  subscription: {
    list: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    read: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    create: [Role.ADMIN, Role.DEVELOPER],
    update: [Role.ADMIN, Role.DEVELOPER],
    delete: [Role.ADMIN, Role.DEVELOPER],
  },
  trust: {
    list: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    read: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    create: [Role.ADMIN, Role.DEVELOPER],
    update: [Role.ADMIN, Role.DEVELOPER],
    delete: [Role.ADMIN, Role.DEVELOPER],
  },
  product: {
    list: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    read: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    create: [Role.ADMIN],
    update: [Role.ADMIN],
    delete: [Role.ADMIN],
  },
  package: {
    list: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    read: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    create: [Role.ADMIN, Role.DEVELOPER],
    update: [Role.ADMIN, Role.DEVELOPER],
    delete: [Role.ADMIN, Role.DEVELOPER],
  },
  release: {
    list: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    read: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER],
    create: [Role.ADMIN, Role.DEVELOPER],
    update: [Role.ADMIN, Role.DEVELOPER],
    delete: [Role.ADMIN, Role.DEVELOPER],
  },
  key: {
    generic: [Role.ADMIN],
    list: [Role.ADMIN, Role.DEVELOPER],
    read: [Role.ADMIN, Role.DEVELOPER],
  },
  // Documentation
  apiSpec: {
    generic: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER, Role.DOCUMENTATION],
  },
  documentation: {
    generic: [Role.ADMIN, Role.DEVELOPER, Role.POWER_USER, Role.USER, Role.DOCUMENTATION],
  },
};

export type AccessControlSectionAction = 'list' | 'read' | 'create' | 'update' | 'delete';

export type AccessControlSection = {
  list: () => boolean;
  read: () => boolean;
  create: () => boolean;
  update: () => boolean;
  delete: () => boolean;
};

export class AccessControl {
  constructor(public role: Role) {}

  static list = (section: string): Role[] => {
    if (!AccessControl.hasRequestedSection(section)) throw new Error(`Section (${section}) is not implemented`);
    return PERMISSIONS[section].list || (PERMISSIONS[section].generic as Role[]);
  };

  static read = (section: string): Role[] => {
    if (!AccessControl.hasRequestedSection(section)) throw new Error(`Section (${section}) is not implemented`);
    return PERMISSIONS[section].read || (PERMISSIONS[section].generic as Role[]);
  };

  static create = (section: string): Role[] => {
    if (!AccessControl.hasRequestedSection(section)) throw new Error(`Section (${section}) is not implemented`);
    return PERMISSIONS[section].create || (PERMISSIONS[section].generic as Role[]);
  };

  static update = (section: string): Role[] => {
    if (!AccessControl.hasRequestedSection(section)) throw new Error(`Section (${section}) is not implemented`);
    return PERMISSIONS[section].update || (PERMISSIONS[section].generic as Role[]);
  };

  static delete = (section: string): Role[] => {
    if (!AccessControl.hasRequestedSection(section)) throw new Error(`Section (${section}) is not implemented`);
    return PERMISSIONS[section].delete || (PERMISSIONS[section].generic as Role[]);
  };

  private hasPermission = (action: AccessControlSectionAction, section: string): boolean => {
    if (!AccessControl.hasRequestedSection(section)) throw new Error(`Section (${section}) is not implemented`);
    return (PERMISSIONS[section][action] || PERMISSIONS[section].generic)!.includes(this.role);
  };

  access = (section: string): AccessControlSection => ({
    list: () => this.hasPermission('list', section),
    read: () => this.hasPermission('read', section),
    create: () => this.hasPermission('create', section),
    update: () => this.hasPermission('update', section),
    delete: () => this.hasPermission('delete', section),
  });

  private static hasRequestedSection = (section: string): boolean => PERMISSIONS[section] !== undefined;
}
