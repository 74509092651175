import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { SubdomainRoutePath, Package, Release, ModalVariants } from '../../../../common/types';
import { ListTable, Dialog, TabPanel, ListTableActions } from '../../../../components';
import { tableProperties, TableNames } from '../../../../common/constants';
import { getDefaultTableProperties, FirmwareService } from '../../../../services';
import { storeOpenModal } from '../../../../store/actions/modal';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';

type Props = RouteComponentProps<{ releaseId: string }> & {
  release?: Release;
  reload: () => void;
  openMappingModal: (
    release: Release,
    cPackage: Package,
    existingFromVersions: string[],
    packageFromVersions: string[],
    callback: () => void,
  ) => () => void;
  openConfirmModal: (release: Release, cPackage: Package, callback: () => void) => () => void;
};

type State = {
  deletePackageId?: string;
  deleteEntrypointOpen: boolean;
};

class PackageTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    deletePackageId: undefined,
    deleteEntrypointOpen: false,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    FirmwareService.create().then((service) => (this.service = service));
  }

  reload = this.props.reload;

  get packages(): (Package & { fromVersions: string[]; entrypoint: boolean })[] {
    if (!this.props.release || !this.props.release!.packages) return [];
    const mappings = Object.entries(this.props.release.packageMapping || []) as [string, string][];
    return this.props.release!.packages!.map((p: any) => ({
      ...p,
      fromVersions: mappings.filter((m) => m[1] === p.packageId).map((m) => m[0]),
      entrypoint: p.packageId === this.props.release!.entrypointPackageId,
    }));
  }

  setEntrypoint = async (packageId: string): Promise<void> => {
    try {
      await this.service.release.setEntrypoint(this.props.match.params.releaseId, { packageId }, { force: true });
      SnackbarUtils.success(i18next.t('success.update.releasePackageEntry'));
      this.props.reload();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
  };

  unsetEntrypoint = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.service.release.deleteEntrypoint(this.props.match.params.releaseId, { force: true });
      SnackbarUtils.success(i18next.t('success.delete.releaseEntrypoint'));
      this.props.reload();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      this.setState({ loading: true });
    }
  };

  deleteEntrypoint = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.service.release.deleteEntrypoint(this.props.match.params.releaseId, { force: true });
      SnackbarUtils.success(i18next.t('success.delete.releaseEntrypoint'));
      this.props.reload();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      this.setState({ loading: true });
    }
  };

  delete = async (): Promise<void> => {
    try {
      await this.service.package.del(this.state.deletePackageId!);
      SnackbarUtils.success(i18next.t('success.delete.package'));
      this.props.reload();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    if (!this.isUnmounted) this.setState({ deletePackageId: undefined });
  };

  download = async (cPackage: Package): Promise<void> => {
    const fileName = cPackage.name;
    const snackId = SnackbarUtils.download(`Downloading ${fileName}`);
    try {
      const { data } = await this.service!.authenticationServiceProvider.axios.get(cPackage.url!, {
        responseType: 'blob',
      });
      const blob = new Blob([data as Uint8Array]);
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${cPackage.name.replace(/ /g, '_').replace(/\./g, '-')}.ota`;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      SnackbarUtils.close(snackId);
      SnackbarUtils.success(`Successfully downloaded ${fileName}`);
    } catch {
      SnackbarUtils.close(snackId);
      SnackbarUtils.error(`Failed to download ${fileName}`);
    }
  };

  clickRow = (cPackage: Package): (() => Promise<void>) => async (): Promise<void> => {
    this.props.history.push(SubdomainRoutePath.firmwarePackage(cPackage.packageId));
  };

  renderContent = (): React.ReactNode => (
    <>
      <ListTable
        TableProps={{ disableBackgroundPaper: true }}
        loading={this.props.parentLoading}
        emptyTitle="form.empty.packages"
        properties={getDefaultTableProperties(tableProperties(TableNames.packageTabMapped))}
        rows={this.packages.map((cPackage) => {
          if (
            ((!!cPackage.fromVersions && !cPackage.fromVersions.includes('entrypoint')) ||
              !cPackage.fromVersions ||
              (!!cPackage.fromVersions && !cPackage.fromVersions.length)) &&
            cPackage.entrypoint
          ) {
            if (Array.isArray(cPackage.fromVersions)) {
              cPackage.fromVersions.push('entrypoint');
            } else {
              cPackage.fromVersions = ['entrypoint'];
            }
          }
          return cPackage;
        })}
        filterActions
        rowActions={([
          {
            title: 'action.download',
            action: (cPackage: Package) => this.download(cPackage),
            filterAction: (cPackage: Package) => !!cPackage.url,
          },
        ] as ListTableActions)
          .concat(
            this.props.accessControl('release', 'update')
              ? ([
                  {
                    title: 'action.setEntrypoint',
                    action: (cPackage: Package) => this.setEntrypoint(cPackage.packageId),
                    filterAction: (cPackage: Package) =>
                      !!this.props.release &&
                      cPackage.buildType === 'FULL' &&
                      cPackage.packageId !== this.props.release!.entrypointPackageId,
                  },
                  {
                    title: 'action.unsetEntrypoint',
                    action: () => this.unsetEntrypoint(),
                    filterAction: (cPackage: Package) =>
                      !!this.props.release && cPackage.packageId === this.props.release!.entrypointPackageId,
                  },
                  {
                    title: 'action.editMapping',
                    action: (cPackage: Package & { fromVersions: string[] }) => {
                      this.props.openMappingModal(
                        this.props.release!,
                        cPackage,
                        this.props.release!.fromVersion || [],
                        cPackage.fromVersions.filter((version) => version !== 'entrypoint'),
                        this.props.reload,
                      )();
                    },
                    filterAction: (cPackage: Package) => cPackage.state === 'READY',
                  },
                ] as ListTableActions)
              : [],
          )
          .concat(
            this.props.accessControl('package', 'update')
              ? ([
                  {
                    title: 'action.confirmPackage',
                    action: (cPackage: Package) => {
                      this.props.openConfirmModal(this.props.release!, cPackage, this.props.reload)();
                      return Promise.resolve();
                    },
                    filterAction: (cPackage: Package) => cPackage.state === 'AWAITING_CONFIRM',
                  },
                ] as ListTableActions)
              : [],
          )
          .concat(
            this.props.accessControl('package', 'delete')
              ? ([
                  {
                    title: 'action.delete',
                    action: (cPackage: Package) => {
                      this.setState({ deletePackageId: cPackage.packageId });
                      return Promise.resolve();
                    },
                  },
                ] as ListTableActions)
              : [],
          )}
        clickRow={this.clickRow}
      />
      <Dialog
        open={!!this.state.deletePackageId}
        title={<Trans>action.delete</Trans>}
        description={<Trans>description.delete.package</Trans>}
        continueTitle={<Trans>action.delete</Trans>}
        onClose={() => this.setState({ deletePackageId: undefined })}
        onContinue={() => this.delete()}
      />
      <Dialog
        open={this.state.deleteEntrypointOpen}
        title={<Trans>description.deleteEntrypoint</Trans>}
        description={<Trans>description.delete.entrypoint</Trans>}
        continueTitle={<Trans>action.delete</Trans>}
        onClose={() => this.setState({ deleteEntrypointOpen: false })}
        onContinue={this.deleteEntrypoint}
      />
    </>
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.props.parentLoading,
        },
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openMappingModal: (
    release: Release,
    cPackage: Package,
    existingFromVersions: string[],
    packageFromVersions: string[],
    callback: () => void,
  ) => () =>
    dispatch(
      storeOpenModal(ModalVariants.EditReleaseMappingMC, {
        release,
        package: cPackage,
        existingFromVersions,
        packageFromVersions,
        callback,
      }),
    ),
  openConfirmModal: (release: Release, cPackage: Package, callback: () => void) => () =>
    dispatch(storeOpenModal(ModalVariants.ConfirmFirmwarePackageMC, { release, package: cPackage, callback })),
});

export default withRouter(connect(null, mapDispatchToProps)(PackageTab));
