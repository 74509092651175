import moment from 'moment';
import { ListFilterProps } from './types';
import { listFilter } from './constants';

export function createFilterAndMetaDataFromUrl(
  search: string,
): { filters: ListFilterProps[]; label: string; properties: string } {
  const allProperties = Object.values(listFilter).reduce((acc, val) => acc.concat(val), []);
  const allDateProperties = allProperties.filter((p) => p.type === 'date').map((p) => p.property);
  const allSelectorProperties = allProperties.filter((p) => p.type === 'selector').map((p) => p.property);
  let filters = [] as ListFilterProps[];
  if (search)
    filters = Array.from(new URLSearchParams(search).entries()).reduce(
      (acc: any[], [property, value]: [string, string]) => {
        if (['queryLabel', 'queryTableProperties'].includes(property)) return acc;
        let type: any = '';
        let operator: any = '';
        let value1: any = '';
        let value2: any = '';
        if (allDateProperties.includes(property)) {
          type = 'date';
          if (
            ((value.match(/-/g) || []).length === 2 && value.search(' TO ') === -1) ||
            ((value.match(/-/g) || []).length === 4 && value.search(' TO ') > -1)
          ) {
            operator = 'betweenAbsolute';
            const tmp = value.slice(1, -1).split(' TO ');
            value1 = tmp[0];
            value2 = tmp[1];
          } else {
            operator = 'between';
            const tmp = value.slice(1, -1).split(' TO ');
            value1 = parseInt(tmp[0]);
            value2 = parseInt(tmp[1]);
          }
        } else if (allSelectorProperties.includes(property)) {
          type = 'selector';
          operator = 'equal';
          value1 = value;
        } else {
          type = 'string';
          operator = value.endsWith('*') ? 'startsWith' : 'equal';
          value1 = value.endsWith('*') ? value.slice(0, value.length - 1) : value;
        }
        return [...acc, { type, property, operator, value1, value2 }];
      },
      [],
    ) as ListFilterProps[];
  return { filters, ...createLabelAndTablePropertiesFromUrl(search) };
}

export function createLabelAndTablePropertiesFromUrl(search: string): { label: string; properties: string } {
  const data = new URLSearchParams(search);
  return {
    label: data.get('queryLabel') || '',
    properties: data.get('queryTableProperties') || '',
  };
}

export function createParamsFromFilter(filters: ListFilterProps[]): { [key: string]: string } {
  return filters.reduce((acc, val) => {
    (acc as any)[val.property] = createParamValueFromFilter(val);
    return acc;
  }, {});
}

export function createParamValueFromFilter(filter: ListFilterProps): string {
  if (filter.type === 'string') {
    return filter.value1 + (filter.operator === 'equal' ? '' : '*');
  }
  if (filter.type === 'date') {
    if (filter.operator === 'betweenAbsolute') {
      return `[${moment(filter.value1).format('YYYY-MM-DD')} TO ${moment(filter.value2).format('YYYY-MM-DD')}]`;
    }
    return `[${moment().add(filter.value1, 'days').format('YYYY-MM-DD')} TO ${moment()
      .add(filter.value2, 'days')
      .format('YYYY-MM-DD')}]`;
  }
  if (filter.type === 'selector') {
    return filter.value1 + (filter.operator === 'equal' ? '' : '*');
  }
  return '';
}

export function createUrlFromFilter(
  filters: ListFilterProps[],
  queryLabel: string,
  queryTableProperties: string,
): string {
  const test = Object.entries({
    queryLabel,
    queryTableProperties,
    ...filters.reduce((acc: any, val: ListFilterProps) => {
      acc[val.property] = createUrlValueFromFilter(val);
      return acc;
    }, {}),
  })
    .reduce((acc: any, [key, val]: [string, any]) => {
      acc.append(key, val);
      return acc;
    }, new URLSearchParams())
    .toString();
  return `?${test}`;
}

export function createUrlValueFromFilter(filter: ListFilterProps): string {
  if (filter.type === 'string') {
    return filter.value1 + (filter.operator === 'equal' ? '' : '*');
  }
  if (filter.type === 'date') {
    return `[${filter.value1} TO ${filter.value2}]`;
  }
  if (filter.type === 'selector') {
    return filter.value1 + (filter.operator === 'equal' ? '' : '*');
  }
  return '';
}

export function createChipValueFromFilter(filter: ListFilterProps): string {
  if (filter.type === 'string') {
    return filter.value1 + (filter.operator === 'equal' ? '' : '*');
  }
  if (filter.type === 'date') {
    if (filter.operator === 'betweenAbsolute') {
      return `${filter.value1} to ${filter.value2}`;
    }
    return `${(filter.value1 as number) === 0 ? 'today' : moment().add(filter.value1, 'days').fromNow()} to ${
      (filter.value2 as number) === 0 ? 'today' : moment().add(filter.value2, 'days').fromNow()
    }`;
  }
  if (filter.type === 'selector') {
    return filter.value1 + (filter.operator === 'equal' ? '' : '*');
  }
  return '';
}
