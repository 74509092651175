import React, { ChangeEvent, Component } from 'react';
import { Paper, IconButton, Grid, Typography, Button, CircularProgress, TextField } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Autocomplete } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { DeviceService, FirmwareService } from '../../services';
import { store } from '../../store';
import { ShadowTemplate, ShadowTemplateDetailed } from '../../common/types';
import { SchemaForm } from '..';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import fleetSchema from '../../assets/schemas/fleet.schema.parsed.json';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  shadowTemplates: ShadowTemplate[];
  shadowTemplate?: ShadowTemplate;
  shadowTemplateDetailed?: ShadowTemplateDetailed;
};

class CreateShadowTemplateMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    shadowTemplates: [],
    shadowTemplate: undefined,
    shadowTemplateDetailed: undefined,
  };
  firmwareService!: FirmwareService;
  deviceService!: DeviceService;

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    this.deviceService = await DeviceService.create();
    this.loadShadowTemplates();
  };

  loadShadowTemplates = async (): Promise<void> => {
    let shadowTemplates: ShadowTemplate[] = [];
    let nextToken: string | undefined;
    do {
      const { data } = await this.deviceService.shadowTemplate.list();
      shadowTemplates = shadowTemplates.concat(data.data);
      nextToken = data.nextToken;
    } while (nextToken);
    this.setState({ shadowTemplates });
  };

  loadShadowTemplate = async (shadowTemplate?: ShadowTemplate): Promise<void> => {
    if (!shadowTemplate) {
      this.setState({ shadowTemplate: undefined, shadowTemplateDetailed: undefined });
    } else {
      this.setState({ loading: true, shadowTemplate });
      try {
        const { data } = await this.deviceService.shadowTemplate.get(shadowTemplate.templateId);
        this.setState({ loading: false, shadowTemplateDetailed: data });
      } catch (e) {
        const error: any = e;
        SnackbarUtils.error(
          (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
        );
        this.setState({ loading: false });
      }
    }
  };

  applyTemplate = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.deviceService.device.putShadow(
        this.store.modalStore.data.deviceId,
        { state: 'desired' },
        this.state.shadowTemplateDetailed!.template,
      );
      SnackbarUtils.success(i18next.t('success.update.deviceShadow'));
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.applyShadowTemplate.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.applyShadowTemplate.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="ast"
              ChipProps={{ size: 'small' }}
              options={this.state.shadowTemplates || {}}
              getOptionLabel={(option: ShadowTemplate) => option.templateId}
              value={this.state.shadowTemplate}
              isOptionEqualToValue={(option, value) => option.templateId === value.templateId}
              onChange={(_: ChangeEvent<unknown>, shadowTemplate: ShadowTemplate | null) => {
                shadowTemplate ? this.loadShadowTemplate(shadowTemplate) : this.loadShadowTemplate(undefined);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  required
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'ast' }}
                  label={i18next.t('shadowTemplate')}
                  placeholder={i18next.t('form.placeholder.selectShadowTemplate')}
                  fullWidth
                />
              )}
            />
          </Grid>
          {this.state.shadowTemplateDetailed ? (
            <Grid item xs={12}>
              <SchemaForm
                key={`shadow-template-${this.state.shadowTemplateDetailed.templateId}`}
                state="UPDATE"
                data={{
                  ...(fleetSchema as any).properties.applyShadowTemplate,
                }}
                disabled
                defaultValue={this.state.shadowTemplateDetailed}
              />
            </Grid>
          ) : null}
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              disabled={this.state.loading || !this.state.shadowTemplateDetailed}
              onClick={this.applyTemplate}
            >
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.apply</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateShadowTemplateMC);
