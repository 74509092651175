import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { UserService } from '../../../../services';
import { Dialog, TabsHeader, SnackbarUtils } from '../../../../components';
import { PropertiesTab } from '../../../baseClasses/PropertiesTab';
import UserGroupsTab from './UserGroupsTab';
import { itemIcons } from '../../../../common/constants';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../../baseClasses/ViewBase';
import userSchema from '../../../../assets/schemas/user.schema.parsed.json';
import { UserSecurityTab } from '../UserSettingsView';

type _Props = unknown;
type Props = ViewBaseProps<_Props, { userId: string }>;

type _State = unknown;
type State = ViewBaseState<_State>;

class UserDetailedView extends ViewBase<_Props, _State, { userId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.properties', 'tab.userGroups', 'tab.security'];
    UserService.create().then((service) => (this.service = service));
    this.subservice = 'user';
    this.messages = {
      load: { error: 'error.fetch.user' },
      update: { success: 'success.update.user', error: 'error.tryAgain' },
      delete: { success: 'success.delete.user', error: 'error.tryAgain' },
    };
  }

  toggleBetweenEnabledDisabled = async (): Promise<void> => {
    this.setState({ loading: true });
    await this.update({ enabled: !this.state.item!.enabled });
    this.setState({ loading: false });
  };

  deleteBeforeRecreate = async (): Promise<void> => {
    try {
      return await this.service[this.subservice].del(this.id);
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  };

  recreateUser = async () => {
    try {
      this.setState({ loading: true });
      await this.deleteBeforeRecreate();
      const { groups, email, ...data } = this.state.item;
      const { data: user } = await this.service.user.post({
        ...data,
        userEmail: email.toLowerCase(),
      });
      const result = await Promise.all(
        groups.map((group: Record<string, any>) =>
          this.service!.userGroup.postUserToUserGroup(group.groupName, user!.userId)
            .then(() => ({
              isError: false,
              title: group.groupName,
              subtitle: i18next.t('success.attach.userToUserGroup'),
            }))
            .catch((e: any) => {
              const error: any = e;
              return {
                isError: true,
                title: group.groupName,
                subtitle:
                  (error.response && error.response.data && error.response.data.message) ||
                  i18next.t('error.attach.userToUserGroup'),
              };
            }),
        ),
      );
      if (!result.map((res: any) => res.isError).includes(false)) {
        SnackbarUtils.error(
          `${i18next.t('error.create.createUserFailedToAddUserGroup')} (${groups.length})`,
          result as any,
        );
      } else if (!result.map((res: any) => res.isError).includes(true)) {
        setTimeout(this.props.history.goBack, 1000);
        SnackbarUtils.success(i18next.t('success.update.resendInvite'), result as any);
      } else {
        SnackbarUtils.warning(
          `${i18next.t('warning.attach.userCreatedPartiallyAddedToAllGroups')} (${groups.length})`,
          result as any,
        );
      }
    } catch (e) {
      this.setState({ loading: false });
      const error: any = e;
      if (error && error.response && error.response.status === 403) {
        SnackbarUtils.error(i18next.t('error.create.permission'));
        return;
      } else {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            i18next.t('error.tryAgain'),
        );
        return;
      }
    }
  };

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.state.item?.status === 'FORCE_CHANGE_PASSWORD')
      list.push(
        <MenuItem key="3" onClick={this.recreateUser}>
          Resend invite
        </MenuItem>,
      );
    if (this.accessControl('user', 'update'))
      list.push(
        <MenuItem key="1" disabled={!this.state.item} onClick={this.toggleBetweenEnabledDisabled}>
          <Trans>{!!this.state.item && !this.state.item!.enabled ? 'action.enable' : 'action.disable'}</Trans>
        </MenuItem>,
      );
    if (this.accessControl('user', 'update'))
      list.push(
        <MenuItem key="2" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <PropertiesTab
        tab={0}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="user"
        parentLoading={this.state.loading}
        schema={userSchema}
        schemaKey="user"
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
      <UserGroupsTab
        tab={1}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
        userGroups={this.state.item && this.state.item.groups}
        reload={this.load}
      />
      <UserSecurityTab
        tab={2}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
        reload={this.load}
        item={this.state.item}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <Dialog
      open={this.state.deleteDialogOpen}
      title={<Trans>action.delete</Trans>}
      description={<Trans>description.delete.user</Trans>}
      continueTitle={<Trans>action.delete</Trans>}
      onClose={() => this.setState({ deleteDialogOpen: false })}
      onContinue={() => this.delete()}
    />
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={!!this.state.item && this.state.item.email ? this.state.item.email : this.props.match.params.userId}
        icon={itemIcons.user}
        subtitle={!!this.state.item && this.state.item.email ? i18next.t('form.email') : i18next.t('form.userId')}
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

export default withStyles(ViewBaseStyles)(withRouter(connect(null, ViewBaseMapDispatchToProps)(UserDetailedView)));
