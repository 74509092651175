import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Box, Typography, TextField, Grid } from '@mui/material';
import { TabPanel, PageLoader } from '../../../../components';
import { TabViewBase, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';
import { AuthenticationServiceProvider } from '../../../../services';
import QRCode from 'react-qr-code';
import { connect } from 'react-redux';
import { CustomerConfiguration, Store } from '../../../../common/types';
import { MFA_SETUP, SecurityCallback } from '../../../../services/authentication/authenticationUtils';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

type Props = RouteComponentProps<{ userId: string }> & {
  config?: CustomerConfiguration;
  reload: () => Promise<void>;
  item: any;
};

export enum SECURITY_STATE {
  BASE = 'BASE',
  CREDENTIALS = 'CREDENTIALS',
  CREDENTIALS_DISABLE = 'CREDENTIALS_DISABLE',
  INFO = 'INFO',
  QR = 'QR',
}

type State = {
  state: SECURITY_STATE;
  mfaCode: string;
  qrString: undefined | string;
  qrCode: undefined | string;
  password: string;
  showQrText: boolean;
  username: string;
};

class UserSecurityTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    state: SECURITY_STATE.BASE,
    loading: false,
    editing: false,
    password: '',
    mfaCode: '',
    qrString: undefined,
    qrCode: undefined,
    showQrText: false,
    username: '',
  };
  authenticationServiceProvider?: AuthenticationServiceProvider;

  componentDidMount = async (): Promise<void> => {
    this.authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (this.authenticationServiceProvider) {
      const username = await this.authenticationServiceProvider?.service.username();
      this.setState({ username });
    }
  };

  setQR = (userName: string, code: string | undefined) => {
    if (code) {
      const regex = /\.webapp|\.incubation\.io/;
      const loc = window.location.host.replace(regex, '');
      const qr = 'otpauth://totp/' + loc + ':' + userName + '?secret=' + code + '&issuer=' + loc;
      this.setState({ qrString: qr, qrCode: code });
    } else {
      console.error('No code');
    }
  };

  callback: SecurityCallback = async (stage: MFA_SETUP, res: any, code?: string) => {
    switch (stage) {
      case MFA_SETUP.QR: {
        this.setQR(this.state.username, code);
        return;
      }
      case MFA_SETUP.CONFIRM: {
        if (res && this.state.state === SECURITY_STATE.CREDENTIALS_DISABLE) {
          this.authenticationServiceProvider!.service.setUpMfa(MFA_SETUP.DISABLE, this.callback);
        } else {
          SnackbarUtils.error(i18next.t('error.mfa.credentials'));
        }
        return;
      }
      case MFA_SETUP.VALIDATE: {
        if (res) {
          this.authenticationServiceProvider!.service.setUpMfa(MFA_SETUP.ENABLE, this.callback);
        } else {
          this.setState({ mfaCode: '' });
          SnackbarUtils.error(i18next.t('error.mfa.code'));
        }
        return;
      }
      case MFA_SETUP.ENABLE: {
        if (res) {
          this.setState({ loading: true });
          this.setState({ state: SECURITY_STATE.BASE, mfaCode: '' });
          SnackbarUtils.success(i18next.t('success.mfa.enable'));
          setTimeout(async () => {
            if (this.props.reload) {
              await this.props.reload();
              this.setState({ loading: false });
            }
          }, 500);
        } else {
          this.setState({ mfaCode: '' });
          SnackbarUtils.error(i18next.t('error.mfa.enable'));
        }
        return;
      }
      case MFA_SETUP.DISABLE: {
        if (res) {
          this.setState({ loading: true });
          this.setState({ state: SECURITY_STATE.BASE, mfaCode: '' });
          SnackbarUtils.success(i18next.t('success.mfa.disable'));
          if (this.props.reload) {
            setTimeout(async () => {
              if (this.props.reload) {
                await this.props.reload();
                this.setState({ loading: false });
              }
            }, 500);
          }
        } else {
          this.setState({ mfaCode: '' });
          SnackbarUtils.error(i18next.t('error.mfa.disable'));
        }
        return;
      }
      default: {
        return;
      }
    }
  };

  renderConfigureMfa = (): React.ReactNode => {
    if (this.state.state === SECURITY_STATE.CREDENTIALS || this.state.state === SECURITY_STATE.CREDENTIALS_DISABLE) {
      return (
        <>
          <Box>
            <Typography variant="subtitle1">
              <Trans>view.securityTab.confirm</Trans>
            </Typography>
          </Box>
          <Box>
            <br />
            <Box ml={3} mr={3} mb={0}>
              <TextField
                fullWidth
                label={i18next.t('form.email')}
                type="text"
                autoComplete="email"
                disabled
                margin="normal"
                value={this.state.username}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box ml={3} mr={3} mt={0} mb={0}>
              <TextField
                fullWidth
                label={i18next.t('form.password')}
                type="password"
                autoComplete="password"
                margin="normal"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter' && this.state.password && this.state.mfaCode.length === 6) {
                    this.authenticationServiceProvider!.service.setUpMfa(
                      MFA_SETUP.CONFIRM,
                      this.callback,
                      this.state.mfaCode,
                      this.state.username,
                      this.state.password,
                    );
                  }
                }}
              />
            </Box>
            <Box ml={3} mr={3} mt={0} mb={0}>
              <TextField
                fullWidth
                label="Authentication code"
                type="text"
                margin="normal"
                value={this.state.mfaCode}
                onChange={(e) => this.setState({ mfaCode: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter' && this.state.password && this.state.mfaCode.length === 6) {
                    this.authenticationServiceProvider!.service.setUpMfa(
                      MFA_SETUP.CONFIRM,
                      this.callback,
                      this.state.mfaCode,
                      this.state.username,
                      this.state.password,
                    );
                  }
                }}
              />
            </Box>
          </Box>
          <br />
          <br />
          <Box display="flex" justifyContent="space-between">
            <Button
              color="secondary"
              size="small"
              variant="text"
              disabled={this.props.parentLoading || this.state.loading}
              onClick={() => {
                this.setState({
                  state: SECURITY_STATE.BASE,
                  mfaCode: '',
                  qrString: undefined,
                  qrCode: undefined,
                  showQrText: false,
                });
              }}
            >
              <Trans>view.securityTab.cancel</Trans>
            </Button>
            <Button
              color="primary"
              size="small"
              variant="text"
              disabled={this.state.mfaCode.length !== 6}
              onClick={() => {
                this.authenticationServiceProvider!.service.setUpMfa(
                  MFA_SETUP.CONFIRM,
                  this.callback,
                  this.state.mfaCode,
                  this.state.username,
                  this.state.password,
                );
              }}
            >
              <Trans>view.securityTab.confirmButton</Trans>
            </Button>
          </Box>
        </>
      );
    }
    return (
      <Box>
        {this.state.state === SECURITY_STATE.QR ? (
          <>
            <Typography variant="subtitle1">
              <Trans>view.securityTab.scan</Trans>
            </Typography>
            <br />
            {this.state.qrString && (
              <Box display="flex" flexDirection="column" alignItems="center">
                {!this.state.showQrText && (
                  <Button
                    color="secondary"
                    size="small"
                    variant="text"
                    onClick={() => this.setState({ showQrText: true })}
                  >
                    {i18next.t('view.securityTab.show')}
                  </Button>
                )}
                {this.state.showQrText && <Typography variant="subtitle1">{this.state.qrCode}</Typography>}
                <br />
                <QRCode value={this.state.qrString} />
                <br />
                <br />
                <TextField
                  variant="outlined"
                  helperText={i18next.t('view.securityTab.help')}
                  value={this.state.mfaCode}
                  onChange={(e) => this.setState({ mfaCode: e.target.value })}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      this.authenticationServiceProvider!.service.setUpMfa(
                        MFA_SETUP.VALIDATE,
                        this.callback,
                        this.state.mfaCode,
                      );
                    }
                  }}
                />
              </Box>
            )}
            <br />
            <br />
            <Box display="flex" justifyContent="space-between">
              <Button
                color="secondary"
                size="small"
                variant="text"
                disabled={this.props.parentLoading || this.state.loading}
                onClick={() =>
                  this.setState({
                    state: SECURITY_STATE.BASE,
                    mfaCode: '',
                    qrString: undefined,
                    qrCode: undefined,
                    showQrText: false,
                  })
                }
              >
                <Trans>view.securityTab.cancel</Trans>
              </Button>
              <Button
                color="primary"
                size="small"
                variant="text"
                disabled={this.state.mfaCode.length !== 6}
                onClick={() => {
                  this.authenticationServiceProvider!.service.setUpMfa(
                    MFA_SETUP.VALIDATE,
                    this.callback,
                    this.state.mfaCode,
                  );
                }}
              >
                <Trans>view.securityTab.enable</Trans>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="subtitle1">
              <Trans>view.securityTab.phone</Trans>
            </Typography>
            <br />
            <Box display="flex" justifyContent="space-between">
              <Button
                color="secondary"
                size="small"
                variant="text"
                disabled={this.props.parentLoading || this.state.loading}
                onClick={() =>
                  this.setState({
                    state: SECURITY_STATE.BASE,
                    mfaCode: '',
                    qrString: undefined,
                    qrCode: undefined,
                    showQrText: false,
                  })
                }
              >
                <Trans>view.securityTab.cancel</Trans>
              </Button>
              <Button
                color="primary"
                size="small"
                variant="text"
                disabled={this.props.parentLoading || this.state.loading}
                onClick={() => {
                  if (this.props.item?.mfa === 'SOFTWARE_TOKEN_MFA') {
                    this.setState({ state: SECURITY_STATE.CREDENTIALS });
                  } else {
                    this.setState({ state: SECURITY_STATE.QR });
                    this.authenticationServiceProvider!.service.setUpMfa(MFA_SETUP.QR, this.callback);
                  }
                }}
              >
                <Trans>view.securityTab.continue</Trans>
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  };

  render = (): React.ReactNode => (
    <TabPanel tab={this.props.tab} activeTab={this.props.activeTab}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6">
              <Trans>view.securityTab.twoFactor</Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <br />
      {this.state.state === SECURITY_STATE.BASE &&
        (this.state.loading ? (
          <PageLoader />
        ) : (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">
              <Trans>view.securityTab.app</Trans>
            </Typography>
            <Typography variant="overline">{this.props.item?.mfa || ''}</Typography>
            {this.props.item?.mfa === 'SOFTWARE_TOKEN_MFA' ? (
              <Button
                color="secondary"
                size="small"
                variant="text"
                disabled={
                  this.props.parentLoading ||
                  this.state.loading ||
                  (!!this.state.username && this.props.item && this.props.item.email !== this.state.username)
                }
                onClick={() => {
                  this.setState({ state: SECURITY_STATE.CREDENTIALS_DISABLE });
                }}
              >
                <Trans>view.securityTab.disable</Trans>
              </Button>
            ) : (
              <Button
                color="secondary"
                size="small"
                variant="text"
                disabled={
                  this.props.parentLoading ||
                  this.state.loading ||
                  (!!this.state.username && this.props.item && this.props.item.email !== this.state.username)
                }
                onClick={() => {
                  this.setState({ state: SECURITY_STATE.INFO });
                }}
              >
                <Trans>view.securityTab.edit</Trans>
              </Button>
            )}
          </Box>
        ))}
      {!(this.state.state === SECURITY_STATE.BASE) && this.renderConfigureMfa()}
    </TabPanel>
  );
}

const mapStateToProps = ({ userStore }: Store) => ({
  config: userStore.config,
});

export default withRouter(connect(mapStateToProps, null)(UserSecurityTab));
