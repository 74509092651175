import React, { Component } from 'react';
import { Paper, IconButton, Grid, Typography, Button, CircularProgress } from '@mui/material';
import { SchemaForm } from '../../components';
import { DataService } from '../../services';
import { store } from '../../store';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { Store } from '../../common/types';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import dataSchema from '../../assets/schemas/data.schema.parsed.json';
import i18next from 'i18next';
import Ajv from 'ajv';
import { withStyles, WithStyles } from '@mui/styles';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

enum Variant {
  JWT_TOKEN = 'JWT_TOKEN',
  BASIC_AUTH = 'BASIC_AUTH',
  OAUTH2 = 'OAUTH2',
}

type State = {
  data: { [key: string]: any };
  isSaveDisabled: boolean;
  loading: boolean;
  type: Variant;
};

class CreateTrustMC extends Component<Props, State> {
  store: Store;
  state: State;
  dataService!: DataService;
  ajv: any;

  constructor(props: Props) {
    super(props);
    this.ajv = new Ajv({ allErrors: true, useDefaults: true });
    this.ajv.addSchema(dataSchema, 'dataSchema');
    this.store = store.getState();
    this.state = {
      data: {},
      isSaveDisabled: true,
      loading: false,
      type: Variant.BASIC_AUTH,
    };
  }

  componentDidMount = async (): Promise<void> => {
    this.dataService = await DataService.create();
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.dataService.trust.post({ ...this.state.data });
      SnackbarUtils.success(i18next.t('success.create.trust'));
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  setValue = (data: { [key: string]: any }, keys: string[], value: any): any => {
    if (keys.length === 0) {
      data = value;
    } else if (keys.length === 1) {
      data[keys[0]] = value;
    } else {
      data[keys[0]] = this.setValue(data[keys[0]] || {}, keys.slice(1), value);
    }
    return data;
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean) => {
    if (key === 'type') {
      const typeKeys = Object.keys((dataSchema as any).definitions[`trust${value}`]?.properties);
      //When user change type the data needs to be cleaned to not send incorrect parameters
      let cleanKeys;
      if (typeKeys) {
        cleanKeys = Object.keys(this.state.data).filter((item: any) => !typeKeys.includes(item));
      }
      if (cleanKeys) {
        const cleanData = this.state.data;
        cleanKeys.forEach((cKey) => {
          delete cleanData[cKey];
        });
        this.setState({ type: value, data: cleanData });
      } else {
        this.setState({ type: value });
      }
    }
    const data = this.setValue(this.state.data || {}, key === undefined ? [] : key.split('.'), value);
    const ajvIsValid = this.ajv.validate('dataSchema', {
      ['trust' + this.state.type]: { ...data },
    });
    this.setState({ data: data || {}, isSaveDisabled: !(!!Object.keys(data).length && ajvIsValid && isValid) });
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton style={{ ...BaseModalStyles().closeButton }} onClick={this.props.closeModal} aria-label="close">
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.createTrust.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.createTrust.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SchemaForm
              state="CREATE"
              data={{
                ...(dataSchema as any).properties['trust' + this.state.type],
              }}
              grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
              defaultValue={this.state.data}
              onChange={this.handleUpdate}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={this.state.isSaveDisabled} onClick={this.create}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.create</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateTrustMC);
