import React from 'react';
import { Routes } from './views';
import { LanguageProvider, StyledSnackbarProvider } from './components';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Provider as StoreProvider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { store } from './store';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { getTheme } from './common/theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: React.FC = () => (
  <StoreProvider store={store}>
    <CssBaseline />
    <LanguageProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={getTheme()}>
          <StyledSnackbarProvider>
            <Routes />
          </StyledSnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </LanguageProvider>
  </StoreProvider>
);

export default App;
