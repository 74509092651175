import { withStyles } from '@mui/styles';
import { getDefaultTableProperties, DataService } from '../../../services';
import { SubdomainRoutePath, ModalVariants } from '../../../common/types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import { storeOpenModal } from '../../../store/actions/modal';
import { tableProperties, itemIcons, TableNames } from '../../../common/constants';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';
import i18next from 'i18next';

type _Props = {
  openModal: (callback: () => any) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class TrustView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
  };

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.accessControlSection = 'trust';
    this.title = i18next.t('trusts');
    this.icon = itemIcons.trust;
    this.idKey = 'trustId';
    this.tableConfig = {
      tableProperties: getDefaultTableProperties(tableProperties(TableNames.trust)),
      emptyTitle: 'form.empty.trust',
    };
    this.fabConfig = [
      {
        title: i18next.t('action.createTrust'),
        action: () => this.props.openModal(this.reloadList),
        icon: itemIcons.trust,
        tryAccessRight: ['trust', 'create'],
      },
    ];

    // Functions
    this.getUrlPathForItem = SubdomainRoutePath.trust;
  }

  componentDidMount = async () => {
    this.service = await DataService.create();
    this.list = this.service!.trust.list;
    this.setState({ initLoaded: true });
  };
}
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any) => dispatch(storeOpenModal(ModalVariants.CreateTrustMC, { callback })),
});

export default withStyles(ListBaseStyles)(withRouter(connect(null, mapDispatchToProps)(TrustView)));
