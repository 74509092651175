import React, { Component } from 'react';
import { Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { AppBar, Toolbar, Grid, Tabs, Tab, Box } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import i18next from 'i18next';
import { RouteParentType, Role, RouteType } from '../common/types';
import { RoutesHierarchy } from '../common/routeUtils';
import { appBarHeight } from '../common/constants';
import logo from '../assets/images/mSafety_logo_white.png';

import { getTheme } from '../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    main: {
      paddingTop: appBarHeight,
      width: '100%',
      minHeight: `calc(100vh - ${appBarHeight}px)`,
      [theme.breakpoints.down('md')]: {
        minHeight: `calc(100vh - ${appBarHeight * 2}px)`,
      },
    },
    logo: {
      height: 24,
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(2),
      },
    },
    rootTabs: {
      height: appBarHeight,
    },
    tabIndicator: {
      backgroundColor:
        theme.palette.primary.main === theme.palette.secondary.main ? '#fff' : theme.palette.secondary.main,
    },
  };
};

type Props = WithStyles<any> &
  RouteParentType &
  RouteComponentProps & {
    role: Role;
  };

type State = {
  path: string;
};

class PublicView extends Component<Props, State> {
  state: State = {
    path: '/',
  };

  componentDidMount = (): void => {
    this.setActiveTabBaseOnURL();
    window.addEventListener('popstate', this.handleHistoryPopEvent, false);
  };

  componentDidUpdate = (_: Props, prevState: State) => {
    if (prevState.path !== this.state.path) {
      const route = this.props.routes.find((r) => (Array.isArray(r.path) ? r.path[0] : r.path) === this.state.path);
      if (route) this.setActiveTab(Array.isArray(route.path) ? route.path[0] : route.path);
    }
  };

  componentWillUnmount = (): void => {
    window.removeEventListener('popstate', this.handleHistoryPopEvent, false);
  };

  setActiveTabBaseOnURL = (): void => {
    const path = window.location.pathname.split('/')[1];
    const route = this.props.routes.find((route) => route.path === `/${path}`);
    if (route) {
      this.setState({ path: `/${path}` });
    } else {
      this.setActiveTab(
        Array.isArray(this.props.routes[0].path) ? this.props.routes[0].path[0] : this.props.routes[0].path,
      );
    }
  };

  handleHistoryPopEvent = (e: any): void => {
    if (e.currentTarget.location.pathname.search(this.state.path) === -1) this.setActiveTabBaseOnURL();
  };

  findFirstChildRoutePath = (route: RouteType | RouteParentType): string => {
    const routes = route.routes;
    if (!!route.routes && route.routes.length) {
      for (let i = 0; i < routes!.length; i++) {
        if ({}.hasOwnProperty.call(routes![i], 'component') || {}.hasOwnProperty.call(routes![i], 'render')) {
          return Array.isArray(routes![i].path) ? routes![i].path[0] : (routes![i].path as string);
        } else if (!!routes![i].routes && routes![i].routes!.length && !routes![i].hideInMenu) {
          return this.findFirstChildRoutePath(routes![i]);
        }
      }
    }
    return Array.isArray(route.path) ? route.path[0] : route.path;
  };

  setActiveTab = (path: string): void => {
    const route = this.props.routes.find((route) => route.path === path);
    if (!route) return;
    this.setState({ path: Array.isArray(route.path) ? route.path[0] : route.path });
    this.props.history.push(this.findFirstChildRoutePath(route));
  };

  render = (): React.ReactNode => {
    return (
      <>
        <AppBar>
          <Toolbar variant="dense">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Box display="flex" alignContent="center">
                  <img className={this.props.classes.logo} src={logo} alt="logo" />
                </Box>
              </Grid>
              <Grid item>
                <Tabs
                  value={this.state.path}
                  onChange={(_: any, path: any) => this.setActiveTab(path)}
                  textColor="inherit"
                  aria-label="simple tabs example"
                  classes={{
                    flexContainer: this.props.classes.rootTabs,
                    indicator: this.props.classes.tabIndicator,
                  }}
                >
                  {this.props.routes.map((route, i) => (
                    <Tab
                      key={i}
                      href={route.href || ''}
                      style={{ fontSize: '0.8rem' }}
                      label={i18next.t(route.name || (Array.isArray(route.path) && route.path[0]) || route.path)}
                      value={Array.isArray(route.path) ? route.path[0] : route.path}
                    />
                  ))}
                </Tabs>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <main className={this.props.classes.main}>
          <Switch>
            <RoutesHierarchy role={this.props.role} routes={this.props.routes} withoutSwitch />
          </Switch>
        </main>
      </>
    );
  };
}

export default withStyles(styles)(withRouter(PublicView));
