import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, TextField, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';
import { ShadowAlias } from '../../../common/types';
import { PageLoader, TabPanel } from '../../../components';
import { DeviceService } from '../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../baseClasses/TabViewBase';
import { InfoOutlined } from '@mui/icons-material';
import { getTheme } from '../../../common/theme';

type Data = {
  name: string;
  key: string;
  options?: string;
  data?: any;
  config: {
    pattern: string;
    description: string;
    mode: 'READ_WRITE' | 'READ_WRITEONCE' | 'READONLY';
    required: boolean;
  };
};

type Props = {
  shadowAliases?: ShadowAlias[];
  customAction?: React.ReactNode;
  accessControlSection: string;
  disableEdit?: boolean;
  update: (data: any) => void;
  reload: () => Promise<void>;
};

type State = {
  editedItem: { [key: string]: string };
  saveDisabled: boolean;
  schemaFormInputData: { [key: string]: any[] };
  groupedData: Record<string, Data[]> | null;
  commonData: Data[];
};
class DevicePropertiesTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    editedItem: {},
    saveDisabled: true,
    schemaFormInputData: {},
    groupedData: null,
    commonData: [
      {
        name: 'name',
        key: 'name',
        config: { pattern: '^[a-zA-Z0-9]+', description: 'Name', mode: 'READ_WRITE', required: true },
      },
      {
        name: 'description',
        key: 'description',
        config: { pattern: '^[a-zA-Z0-9]+', description: 'Description', mode: 'READ_WRITE', required: false },
      },
    ],
  };
  DeviceService!: DeviceService;

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    this.lastReload = this.time();
  }

  componentDidMount = async (): Promise<void> => {
    this.DeviceService = await DeviceService.create();
  };

  componentDidUpdate = async (prevProps: TabViewBaseProps<Props>): Promise<void> => {
    if (this.props.item && this.props.item?.type && this.props.item?.type !== prevProps.item?.type) {
      this.setFields(this.props.item);
    }
  };

  setFields = async (item: Record<string, any>): Promise<any> => {
    const deviceTypeParameters = await this.DeviceService.deviceType.deviceTypeParameters(item.type, {
      params: { parent: '*', limit: 500 },
    });

    const groupedData = deviceTypeParameters.data
      .filter((d) => d.category !== 'Firmware')
      .reduce((acc, obj) => {
        const category = obj.category || 'Rest';
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(obj);
        return acc;
      }, {});
    this.setState({
      groupedData,
    });
    return item;
  };

  reload = this.props.reload;

  update = async (): Promise<void> => {
    this.setState({ loading: true });
    await this.props.update(this.state.editedItem || {});
    this.setState({ loading: false, editing: false });
  };

  handleUpdate = (key: string, value: any): void => {
    const editedItem = this.state.editedItem;
    editedItem[key] = value;
    this.setState({ editedItem, saveDisabled: Object.keys(editedItem).length === 0 });
  };

  renderCommon = (): React.ReactNode =>
    this.state.commonData &&
    this.state.commonData.map(
      (d: Data, index) =>
        index % 2 === 0 && (
          <Grid item xs={12} key={index} style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
            <Grid item xs={12}>
              <div style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px' }} key={index}>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: 12,
                    position: 'relative',
                    marginTop: -10,
                    marginLeft: '14px',
                    color: getTheme().palette.text.disabled,
                  }}
                >
                  {(!this.state.editing || d.config.mode === 'READ_WRITEONCE' || d.config.mode === 'READONLY') && d.key}
                  <Tooltip title={d.config.description} placement="left">
                    <InfoOutlined
                      fontSize="small"
                      style={{
                        position: 'absolute' as const,
                        color: getTheme().palette.text.disabled,
                        cursor: 'pointer' as const,
                        top: 0,
                        right: 20,
                        backgroundColor: 'white',
                        display: 'block',
                        zIndex: 1,
                        borderRadius: '200%',
                      }}
                    />
                  </Tooltip>
                </Typography>
                {/* Left common edit */}
                {this.state.editing && d.config.mode !== 'READ_WRITEONCE' && d.config.mode !== 'READONLY' ? (
                  <TextField
                    key={`${d.key}edit`}
                    style={{ marginTop: '6px' }}
                    fullWidth
                    type="string"
                    variant="outlined"
                    required={d.config.required}
                    label={d.key}
                    error={false}
                    InputLabelProps={{
                      id: `label${d.key}`,
                      shrink: true,
                      htmlFor: d.key ? `input${d.key}` : undefined,
                    }}
                    value={
                      {}.hasOwnProperty.call(this.state.editedItem, d.key)
                        ? this.state.editedItem[d.key]
                        : this.props.item[d.key]
                    }
                    disabled={false}
                    onChange={(e) => this.handleUpdate(d.key, e.target.value)}
                    InputProps={{
                      id: `input${d.key}`,
                      'aria-describedby': `label${d.key}`,
                    }}
                    autoFocus={index === 0}
                  />
                ) : (
                  <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                    {this.props.item[d.key]}
                  </div>
                )}
              </div>
            </Grid>
            {this.state.commonData && this.state.commonData.length > 0 && this.state.commonData.length - 1 >= index && (
              <Grid item xs={12}>
                <div style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px' }}>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: 12,
                      position: 'relative',
                      marginTop: -8,
                      marginLeft: '14px',
                      color: getTheme().palette.text.disabled,
                    }}
                  >
                    {(!this.state.editing || d.config.mode === 'READ_WRITEONCE' || d.config.mode === 'READONLY') &&
                      this.state.commonData[index + 1].key}
                    <Tooltip title={this.state.commonData[index + 1].config.description} placement="left">
                      <InfoOutlined
                        fontSize="small"
                        style={{
                          position: 'absolute' as const,
                          color: getTheme().palette.text.disabled,
                          cursor: 'pointer' as const,
                          top: 0,
                          right: 6,
                          backgroundColor: 'white',
                          display: 'block',
                          zIndex: 1,
                          borderRadius: '200%',
                        }}
                      />
                    </Tooltip>
                  </Typography>
                  {/* Right common edit */}
                  {this.state.editing &&
                  this.state.commonData[index + 1].config.mode !== 'READ_WRITEONCE' &&
                  this.state.commonData[index + 1].config.mode !== 'READONLY' ? (
                    <TextField
                      key={`${this.state.commonData[index + 1].key}edit`}
                      style={{ marginTop: '6px' }}
                      fullWidth
                      type="string"
                      variant="outlined"
                      required={this.state.commonData[index + 1].config.required}
                      label={this.state.commonData[index + 1].key}
                      error={false}
                      InputLabelProps={{
                        id: `label${this.state.commonData[index + 1].key}`,
                        shrink: true,
                        htmlFor: this.state.commonData[index + 1].key
                          ? `input${this.state.commonData[index + 1].key}`
                          : undefined,
                      }}
                      value={
                        {}.hasOwnProperty.call(this.state.editedItem, this.state.commonData[index + 1].key)
                          ? this.state.editedItem[this.state.commonData[index + 1].key]
                          : this.props.item[this.state.commonData[index + 1].key]
                      }
                      disabled={false}
                      onChange={(e) => this.handleUpdate(this.state.commonData[index + 1].key, e.target.value)}
                      InputProps={{
                        id: `input${this.state.commonData[index + 1].key}`,
                        'aria-describedby': `label${this.state.commonData[index + 1].key}`,
                      }}
                      autoFocus={false}
                    />
                  ) : (
                    <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                      {this.props.item[this.state.commonData[index + 1].key]}
                    </div>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        ),
    );

  renderSpecific = (): React.ReactNode =>
    this.state.groupedData &&
    Object.keys(this.state.groupedData).map(
      (k: string, index) =>
        index % 2 === 0 && (
          <Grid item xs={12} key={index} style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
            <Grid item xs={12}>
              {this.state.groupedData &&
                this.state.groupedData[k] &&
                this.state.groupedData[k].map((ad, i) => (
                  <div style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px' }} key={i}>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 12,
                        position: 'relative',
                        marginTop: -8,
                        marginLeft: '14px',
                        color: getTheme().palette.text.disabled,
                      }}
                    >
                      {(!this.state.editing || ad.config.mode === 'READ_WRITEONCE' || ad.config.mode === 'READONLY') &&
                        ad.key}
                      <Tooltip title={ad.config.description} placement="left">
                        <InfoOutlined
                          fontSize="small"
                          style={{
                            position: 'absolute' as const,
                            color: getTheme().palette.text.disabled,
                            cursor: 'pointer' as const,
                            top: -6,
                            right: 20,
                            backgroundColor: 'white',
                            display: 'block',
                            zIndex: 1,
                            borderRadius: '200%',
                          }}
                        />
                      </Tooltip>
                    </Typography>
                    {/* Edit "rest" */}
                    {this.state.editing && ad.config.mode !== 'READ_WRITEONCE' && ad.config.mode !== 'READONLY' ? (
                      <TextField
                        key={`${ad.key}edit`}
                        style={{ marginTop: '6px' }}
                        fullWidth
                        type="string"
                        variant="outlined"
                        required={ad.config.required}
                        label={ad.key}
                        error={false}
                        InputLabelProps={{
                          id: `label${ad.key}`,
                          shrink: true,
                          htmlFor: ad.key ? `input${ad.key}` : undefined,
                        }}
                        value={
                          {}.hasOwnProperty.call(this.state.editedItem, ad.key)
                            ? this.state.editedItem[ad.key]
                            : this.props.item[ad.key]
                        }
                        disabled={false}
                        onChange={(e) => this.handleUpdate(ad.key, e.target.value)}
                        InputProps={{
                          id: `input${ad.key}`,
                          'aria-describedby': `label${ad.key}`,
                        }}
                        autoFocus={index === 0}
                      />
                    ) : (
                      <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                        {this.props.item[ad.key]}
                      </div>
                    )}
                  </div>
                ))}
            </Grid>
            {/* Right */}
            {this.state.groupedData &&
              Object.keys(this.state.groupedData).length > 0 &&
              Object.keys(this.state.groupedData).length - 1 >= index + 1 && (
                <Grid item xs={12}>
                  {this.state.groupedData &&
                    this.state.groupedData[Object.keys(this.state.groupedData)[index + 1]] &&
                    this.state.groupedData[Object.keys(this.state.groupedData)[index + 1]].map((ad, i) => (
                      <div style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px' }} key={i}>
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: 12,
                            position: 'relative',
                            marginTop: -8,
                            marginLeft: '14px',
                            color: getTheme().palette.text.disabled,
                          }}
                        >
                          {(!this.state.editing ||
                            ad.config.mode === 'READ_WRITEONCE' ||
                            ad.config.mode === 'READONLY') &&
                            ad.key}
                          <Tooltip title={ad.config.description} placement="left">
                            <InfoOutlined
                              fontSize="small"
                              style={{
                                position: 'absolute' as const,
                                color: getTheme().palette.text.disabled,
                                cursor: 'pointer' as const,
                                top: 0,
                                right: 6,
                                backgroundColor: 'white',
                                display: 'block',
                                zIndex: 1,
                                borderRadius: '200%',
                              }}
                            />
                          </Tooltip>
                        </Typography>
                        {/* Edit "rest" */}
                        {this.state.editing && ad.config.mode !== 'READ_WRITEONCE' && ad.config.mode !== 'READONLY' ? (
                          <TextField
                            key={`${ad.key}edit`}
                            style={{ marginTop: '6px' }}
                            fullWidth
                            type="string"
                            variant="outlined"
                            required={ad.config.required}
                            label={ad.key}
                            error={false}
                            InputLabelProps={{
                              id: `label${ad.key}`,
                              shrink: true,
                              htmlFor: ad.key ? `input${ad.key}` : undefined,
                            }}
                            value={
                              {}.hasOwnProperty.call(this.state.editedItem, ad.key)
                                ? this.state.editedItem[ad.key]
                                : this.props.item[ad.key]
                            }
                            disabled={false}
                            onChange={(e) => this.handleUpdate(ad.key, e.target.value)}
                            InputProps={{
                              id: `input${ad.key}`,
                              'aria-describedby': `label${ad.key}`,
                            }}
                            autoFocus={index === 0}
                          />
                        ) : (
                          <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                            {this.props.item[ad.key]}
                          </div>
                        )}
                      </div>
                    ))}
                </Grid>
              )}
          </Grid>
        ),
    );

  renderBeacon = (): React.ReactNode =>
    this.state.groupedData && (
      <Grid item xs={12} style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
        <Grid item xs={12}>
          <div style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px' }}>
            <Typography
              variant="body2"
              style={{
                fontSize: 12,
                position: 'relative',
                marginTop: -8,
                marginLeft: '14px',
                color: getTheme().palette.text.disabled,
              }}
            >
              {(!this.state.editing ||
                this.state.groupedData.Identifiers[0].config.mode === 'READ_WRITEONCE' ||
                this.state.groupedData.Identifiers[0].config.mode === 'READONLY') &&
                this.state.groupedData.Identifiers[0].key}
              <Tooltip title={this.state.groupedData.Identifiers[0].config.description} placement="left">
                <InfoOutlined
                  fontSize="small"
                  style={{
                    position: 'absolute' as const,
                    color: getTheme().palette.text.disabled,
                    cursor: 'pointer' as const,
                    top: 0,
                    right: 20,
                    backgroundColor: 'white',
                    display: 'block',
                    zIndex: 1,
                    borderRadius: '200%',
                  }}
                />
              </Tooltip>
            </Typography>
            {/* Edit "rest" */}
            {this.state.editing &&
            this.state.groupedData.Identifiers[0].config.mode !== 'READ_WRITEONCE' &&
            this.state.groupedData.Identifiers[0].config.mode !== 'READONLY' ? (
              <TextField
                key={`${this.state.groupedData.Identifiers[0].key}edit`}
                style={{ marginTop: '6px' }}
                fullWidth
                type="string"
                variant="outlined"
                required={this.state.groupedData.Identifiers[0].config.required}
                label={this.state.groupedData.Identifiers[0].key}
                error={false}
                InputLabelProps={{
                  id: `label${this.state.groupedData.Identifiers[0].key}`,
                  shrink: true,
                  htmlFor: this.state.groupedData.Identifiers[0].key
                    ? `input${this.state.groupedData.Identifiers[0].key}`
                    : undefined,
                }}
                value={
                  {}.hasOwnProperty.call(this.state.editedItem, this.state.groupedData.Identifiers[0].key)
                    ? this.state.editedItem[this.state.groupedData.Identifiers[0].key]
                    : this.props.item[this.state.groupedData.Identifiers[0].key]
                }
                disabled={false}
                onChange={(e) =>
                  this.state.groupedData && this.handleUpdate(this.state.groupedData.Identifiers[0].key, e.target.value)
                }
                InputProps={{
                  id: `input${this.state.groupedData.Identifiers[0].key}`,
                  'aria-describedby': `label${this.state.groupedData.Identifiers[0].key}`,
                }}
                autoFocus={false}
              />
            ) : (
              <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                {this.props.item[this.state.groupedData.Identifiers[0].key]}
              </div>
            )}
          </div>
        </Grid>
        {
          //right1}
          <Grid item xs={12} style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
            <div style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px' }}>
              <Typography
                variant="body2"
                style={{
                  fontSize: 12,
                  position: 'relative',
                  marginTop: -8,
                  marginLeft: '14px',
                  color: getTheme().palette.text.disabled,
                }}
              >
                {(!this.state.editing ||
                  this.state.groupedData.Position[0].config.mode === 'READ_WRITEONCE' ||
                  this.state.groupedData.Position[0].config.mode === 'READONLY') &&
                  this.state.groupedData.Position[0].key}
                <Tooltip title={this.state.groupedData.Position[0].config.description} placement="left">
                  <InfoOutlined
                    fontSize="small"
                    style={{
                      position: 'absolute' as const,
                      color: getTheme().palette.text.disabled,
                      cursor: 'pointer' as const,
                      top: 0,
                      right: 6,
                      backgroundColor: 'white',
                      display: 'block',
                      zIndex: 1,
                      borderRadius: '200%',
                    }}
                  />
                </Tooltip>
              </Typography>
              {/* Edit "rest" */}
              {this.state.editing &&
              this.state.groupedData.Position[0].config.mode !== 'READ_WRITEONCE' &&
              this.state.groupedData.Position[0].config.mode !== 'READONLY' ? (
                this.state.groupedData.Position[0].key !== 'productId' && (
                  <TextField
                    key={`${this.state.groupedData.Position[0].key}edit`}
                    style={{ marginTop: '6px' }}
                    fullWidth
                    type="string"
                    variant="outlined"
                    required={this.state.groupedData.Position[0].config.required}
                    label={this.state.groupedData.Position[0].key}
                    error={false}
                    InputLabelProps={{
                      id: `label${this.state.groupedData.Position[0].key}`,
                      shrink: true,
                      htmlFor: this.state.groupedData.Position[0].key
                        ? `input${this.state.groupedData.Position[0].key}`
                        : undefined,
                    }}
                    value={
                      {}.hasOwnProperty.call(this.state.editedItem, this.state.groupedData.Position[0].key)
                        ? this.state.editedItem[this.state.groupedData.Position[0].key]
                        : this.props.item[this.state.groupedData.Position[0].key]
                    }
                    disabled={false}
                    onChange={(e) =>
                      this.state.groupedData &&
                      this.handleUpdate(this.state.groupedData.Position[0].key, e.target.value)
                    }
                    InputProps={{
                      id: `input${this.state.groupedData.Position[0].key}`,
                      'aria-describedby': `label${this.state.groupedData.Position[0].key}`,
                    }}
                    autoFocus={false}
                  />
                )
              ) : (
                <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                  {this.props.item[this.state.groupedData.Position[0].key]}
                </div>
              )}
            </div>
            {
              //second}
              <div style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px' }}>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: 12,
                    position: 'relative',
                    marginTop: -8,
                    marginLeft: '14px',
                    color: getTheme().palette.text.disabled,
                  }}
                >
                  {(!this.state.editing ||
                    this.state.groupedData.Position[1].config.mode === 'READ_WRITEONCE' ||
                    this.state.groupedData.Position[1].config.mode === 'READONLY') &&
                    this.state.groupedData.Position[1].key}
                  <Tooltip title={this.state.groupedData.Position[1].config.description} placement="left">
                    <InfoOutlined
                      fontSize="small"
                      style={{
                        position: 'absolute' as const,
                        color: getTheme().palette.text.disabled,
                        cursor: 'pointer' as const,
                        top: 0,
                        right: 6,
                        backgroundColor: 'white',
                        display: 'block',
                        zIndex: 1,
                        borderRadius: '200%',
                      }}
                    />
                  </Tooltip>
                </Typography>
                {/* Edit "rest" */}
                {this.state.editing &&
                this.state.groupedData.Position[1].config.mode !== 'READ_WRITEONCE' &&
                this.state.groupedData.Position[1].config.mode !== 'READONLY' ? (
                  this.state.groupedData.Position[1].key !== 'productId' && (
                    <TextField
                      key={`${this.state.groupedData.Position[1].key}edit`}
                      style={{ marginTop: '6px' }}
                      fullWidth
                      type="string"
                      variant="outlined"
                      required={this.state.groupedData.Position[1].config.required}
                      label={this.state.groupedData.Position[1].key}
                      error={false}
                      InputLabelProps={{
                        id: `label${this.state.groupedData.Position[1].key}`,
                        shrink: true,
                        htmlFor: this.state.groupedData.Position[1].key
                          ? `input${this.state.groupedData.Position[1].key}`
                          : undefined,
                      }}
                      value={
                        {}.hasOwnProperty.call(this.state.editedItem, this.state.groupedData.Position[1].key)
                          ? this.state.editedItem[this.state.groupedData.Position[1].key]
                          : this.props.item[this.state.groupedData.Position[1].key]
                      }
                      disabled={false}
                      onChange={(e) =>
                        this.state.groupedData &&
                        this.handleUpdate(this.state.groupedData.Position[1].key, e.target.value)
                      }
                      InputProps={{
                        id: `input${this.state.groupedData.Position[1].key}`,
                        'aria-describedby': `label${this.state.groupedData.Position[1].key}`,
                      }}
                      autoFocus={false}
                    />
                  )
                ) : (
                  <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                    {this.props.item[this.state.groupedData.Position[1].key]}
                  </div>
                )}
              </div>
            }
          </Grid>
        }
        {
          //left2
          <Grid item xs={12} style={{ display: 'grid', gridTemplateColumns: '33% 33% 33%' }}>
            <div
              style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px', marginRight: '34px' }}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: 12,
                  position: 'relative',
                  marginTop: -8,
                  marginLeft: '14px',
                  color: getTheme().palette.text.disabled,
                }}
              >
                {(!this.state.editing ||
                  this.state.groupedData.Identifiers[1].config.mode === 'READ_WRITEONCE' ||
                  this.state.groupedData.Identifiers[1].config.mode === 'READONLY') &&
                  this.state.groupedData.Identifiers[1].key}
                <Tooltip title={this.state.groupedData.Identifiers[1].config.description} placement="left">
                  <InfoOutlined
                    fontSize="small"
                    style={{
                      position: 'absolute' as const,
                      color: getTheme().palette.text.disabled,
                      cursor: 'pointer' as const,
                      top: 0,
                      right: -20,
                      backgroundColor: 'white',
                      display: 'block',
                      zIndex: 1,
                      borderRadius: '200%',
                    }}
                  />
                </Tooltip>
              </Typography>
              {/* Edit "rest" */}
              {this.state.editing &&
              this.state.groupedData.Identifiers[1].config.mode !== 'READ_WRITEONCE' &&
              this.state.groupedData.Identifiers[1].config.mode !== 'READONLY' ? (
                this.state.groupedData.Identifiers[1].key !== 'productId' && (
                  <TextField
                    key={`${this.state.groupedData.Identifiers[1].key}edit`}
                    style={{ marginTop: '6px' }}
                    fullWidth
                    type="string"
                    variant="outlined"
                    required={this.state.groupedData.Identifiers[1].config.required}
                    label={this.state.groupedData.Identifiers[1].key}
                    error={false}
                    InputLabelProps={{
                      id: `label${this.state.groupedData.Identifiers[1].key}`,
                      shrink: true,
                      htmlFor: this.state.groupedData.Identifiers[1].key
                        ? `input${this.state.groupedData.Identifiers[1].key}`
                        : undefined,
                    }}
                    value={
                      {}.hasOwnProperty.call(this.state.editedItem, this.state.groupedData.Identifiers[1].key)
                        ? this.state.editedItem[this.state.groupedData.Identifiers[1].key]
                        : this.props.item[this.state.groupedData.Identifiers[1].key]
                    }
                    disabled={false}
                    onChange={(e) =>
                      this.state.groupedData &&
                      this.handleUpdate(this.state.groupedData.Identifiers[1].key, e.target.value)
                    }
                    InputProps={{
                      id: `input${this.state.groupedData.Identifiers[1].key}`,
                      'aria-describedby': `label${this.state.groupedData.Identifiers[1].key}`,
                    }}
                    autoFocus={false}
                  />
                )
              ) : (
                <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                  {this.props.item[this.state.groupedData.Identifiers[1].key]}
                </div>
              )}
            </div>
            <div
              style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px', marginRight: '34px' }}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: 12,
                  position: 'relative',
                  marginTop: -8,
                  marginLeft: '14px',
                  color: getTheme().palette.text.disabled,
                }}
              >
                {(!this.state.editing ||
                  this.state.groupedData.Identifiers[2].config.mode === 'READ_WRITEONCE' ||
                  this.state.groupedData.Identifiers[2].config.mode === 'READONLY') &&
                  this.state.groupedData.Identifiers[2].key}
                <Tooltip title={this.state.groupedData.Identifiers[2].config.description} placement="left">
                  <InfoOutlined
                    fontSize="small"
                    style={{
                      position: 'absolute' as const,
                      color: getTheme().palette.text.disabled,
                      cursor: 'pointer' as const,
                      top: 0,
                      right: -20,
                      backgroundColor: 'white',
                      display: 'block',
                      zIndex: 1,
                      borderRadius: '200%',
                    }}
                  />
                </Tooltip>
              </Typography>
              {/* Edit "rest" */}
              {this.state.editing &&
              this.state.groupedData.Identifiers[2].config.mode !== 'READ_WRITEONCE' &&
              this.state.groupedData.Identifiers[2].config.mode !== 'READONLY' ? (
                this.state.groupedData.Identifiers[2].key !== 'productId' && (
                  <TextField
                    key={`${this.state.groupedData.Identifiers[2].key}edit`}
                    style={{ marginTop: '6px' }}
                    fullWidth
                    type="string"
                    variant="outlined"
                    required={this.state.groupedData.Identifiers[2].config.required}
                    label={this.state.groupedData.Identifiers[2].key}
                    error={false}
                    InputLabelProps={{
                      id: `label${this.state.groupedData.Identifiers[2].key}`,
                      shrink: true,
                      htmlFor: this.state.groupedData.Identifiers[2].key
                        ? `input${this.state.groupedData.Identifiers[2].key}`
                        : undefined,
                    }}
                    value={
                      {}.hasOwnProperty.call(this.state.editedItem, this.state.groupedData.Identifiers[2].key)
                        ? this.state.editedItem[this.state.groupedData.Identifiers[2].key]
                        : this.props.item[this.state.groupedData.Identifiers[2].key]
                    }
                    disabled={false}
                    onChange={(e) =>
                      this.state.groupedData &&
                      this.handleUpdate(this.state.groupedData.Identifiers[2].key, e.target.value)
                    }
                    InputProps={{
                      id: `input${this.state.groupedData.Identifiers[2].key}`,
                      'aria-describedby': `label${this.state.groupedData.Identifiers[1].key}`,
                    }}
                    autoFocus={false}
                  />
                )
              ) : (
                <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                  {this.props.item[this.state.groupedData.Identifiers[2].key]}
                </div>
              )}
            </div>
            <div
              style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px', marginRight: '34px' }}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: 12,
                  position: 'relative',
                  marginTop: -8,
                  marginLeft: '14px',
                  color: getTheme().palette.text.disabled,
                }}
              >
                {(!this.state.editing ||
                  this.state.groupedData.Position[2].config.mode === 'READ_WRITEONCE' ||
                  this.state.groupedData.Position[2].config.mode === 'READONLY') &&
                  this.state.groupedData.Position[2].key}
                <Tooltip title={this.state.groupedData.Position[2].config.description} placement="left">
                  <InfoOutlined
                    fontSize="small"
                    style={{
                      position: 'absolute' as const,
                      color: getTheme().palette.text.disabled,
                      cursor: 'pointer' as const,
                      top: 0,
                      right: -20,
                      backgroundColor: 'white',
                      display: 'block',
                      zIndex: 1,
                      borderRadius: '200%',
                    }}
                  />
                </Tooltip>
              </Typography>
              {/* Edit "rest" */}
              {this.state.editing &&
              this.state.groupedData.Position[2].config.mode !== 'READ_WRITEONCE' &&
              this.state.groupedData.Position[2].config.mode !== 'READONLY' ? (
                this.state.groupedData.Position[2].key !== 'productId' && (
                  <TextField
                    key={`${this.state.groupedData.Position[2].key}edit`}
                    style={{ marginTop: '6px' }}
                    fullWidth
                    type="string"
                    variant="outlined"
                    required={this.state.groupedData.Position[2].config.required}
                    label={this.state.groupedData.Position[2].key}
                    error={false}
                    InputLabelProps={{
                      id: `label${this.state.groupedData.Position[2].key}`,
                      shrink: true,
                      htmlFor: this.state.groupedData.Position[2].key
                        ? `input${this.state.groupedData.Position[2].key}`
                        : undefined,
                    }}
                    value={
                      {}.hasOwnProperty.call(this.state.editedItem, this.state.groupedData.Position[2].key)
                        ? this.state.editedItem[this.state.groupedData.Position[2].key]
                        : this.props.item[this.state.groupedData.Position[2].key]
                    }
                    disabled={false}
                    onChange={(e) =>
                      this.state.groupedData &&
                      this.handleUpdate(this.state.groupedData.Position[2].key, e.target.value)
                    }
                    InputProps={{
                      id: `input${this.state.groupedData.Position[2].key}`,
                      'aria-describedby': `label${this.state.groupedData.Position[2].key}`,
                    }}
                    autoFocus={false}
                  />
                )
              ) : (
                <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                  {this.props.item[this.state.groupedData.Position[2].key]}
                </div>
              )}
            </div>
          </Grid>
        }
        {
          //left2
          <Grid item xs={12} style={{ display: 'grid', gridTemplateColumns: '33% 33% 33%' }}>
            <div
              style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px', marginRight: '34px' }}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: 12,
                  position: 'relative',
                  marginTop: -8,
                  marginLeft: '14px',
                  color: getTheme().palette.text.disabled,
                }}
              >
                {(!this.state.editing ||
                  this.state.groupedData.Position[3].config.mode === 'READ_WRITEONCE' ||
                  this.state.groupedData.Position[3].config.mode === 'READONLY') &&
                  this.state.groupedData.Position[3].key}
                <Tooltip title={this.state.groupedData.Position[3].config.description} placement="left">
                  <InfoOutlined
                    fontSize="small"
                    style={{
                      position: 'absolute' as const,
                      color: getTheme().palette.text.disabled,
                      cursor: 'pointer' as const,
                      top: 0,
                      right: -20,
                      backgroundColor: 'white',
                      display: 'block',
                      zIndex: 1,
                      borderRadius: '200%',
                    }}
                  />
                </Tooltip>
              </Typography>
              {/* Edit "rest" */}
              {this.state.editing &&
              this.state.groupedData.Position[3].config.mode !== 'READ_WRITEONCE' &&
              this.state.groupedData.Position[3].config.mode !== 'READONLY' ? (
                this.state.groupedData.Position[3].key !== 'productId' && (
                  <TextField
                    key={`${this.state.groupedData.Position[3].key}edit`}
                    style={{ marginTop: '6px' }}
                    fullWidth
                    type="string"
                    variant="outlined"
                    required={this.state.groupedData.Position[3].config.required}
                    label={this.state.groupedData.Position[3].key}
                    error={false}
                    InputLabelProps={{
                      id: `label${this.state.groupedData.Position[3].key}`,
                      shrink: true,
                      htmlFor: this.state.groupedData.Position[3].key
                        ? `input${this.state.groupedData.Position[3].key}`
                        : undefined,
                    }}
                    value={
                      {}.hasOwnProperty.call(this.state.editedItem, this.state.groupedData.Position[3].key)
                        ? this.state.editedItem[this.state.groupedData.Position[3].key]
                        : this.props.item[this.state.groupedData.Position[3].key]
                    }
                    disabled={false}
                    onChange={(e) =>
                      this.state.groupedData &&
                      this.handleUpdate(this.state.groupedData.Position[3].key, e.target.value)
                    }
                    InputProps={{
                      id: `input${this.state.groupedData.Position[3].key}`,
                      'aria-describedby': `label${this.state.groupedData.Position[3].key}`,
                    }}
                    autoFocus={false}
                  />
                )
              ) : (
                <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                  {this.props.item[this.state.groupedData.Position[3].key]}
                </div>
              )}
            </div>
            <div
              style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px', marginRight: '34px' }}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: 12,
                  position: 'relative',
                  marginTop: -8,
                  marginLeft: '14px',
                  color: getTheme().palette.text.disabled,
                }}
              >
                {(!this.state.editing ||
                  this.state.groupedData.Position[4].config.mode === 'READ_WRITEONCE' ||
                  this.state.groupedData.Position[4].config.mode === 'READONLY') &&
                  this.state.groupedData.Position[4].key}
                <Tooltip title={this.state.groupedData.Position[4].config.description} placement="left">
                  <InfoOutlined
                    fontSize="small"
                    style={{
                      position: 'absolute' as const,
                      color: getTheme().palette.text.disabled,
                      cursor: 'pointer' as const,
                      top: 0,
                      right: -20,
                      backgroundColor: 'white',
                      display: 'block',
                      zIndex: 1,
                      borderRadius: '200%',
                    }}
                  />
                </Tooltip>
              </Typography>
              {/* Edit "rest" */}
              {this.state.editing &&
              this.state.groupedData.Position[4].config.mode !== 'READ_WRITEONCE' &&
              this.state.groupedData.Position[4].config.mode !== 'READONLY' ? (
                this.state.groupedData.Position[4].key !== 'productId' && (
                  <TextField
                    key={`${this.state.groupedData.Position[4].key}edit`}
                    style={{ marginTop: '6px' }}
                    fullWidth
                    type="string"
                    variant="outlined"
                    required={this.state.groupedData.Position[4].config.required}
                    label={this.state.groupedData.Position[4].key}
                    error={false}
                    InputLabelProps={{
                      id: `label${this.state.groupedData.Position[4].key}`,
                      shrink: true,
                      htmlFor: this.state.groupedData.Position[4].key
                        ? `input${this.state.groupedData.Position[4].key}`
                        : undefined,
                    }}
                    value={
                      {}.hasOwnProperty.call(this.state.editedItem, this.state.groupedData.Position[4].key)
                        ? this.state.editedItem[this.state.groupedData.Position[4].key]
                        : this.props.item[this.state.groupedData.Position[4].key]
                    }
                    disabled={false}
                    onChange={(e) =>
                      this.state.groupedData &&
                      this.handleUpdate(this.state.groupedData.Position[4].key, e.target.value)
                    }
                    InputProps={{
                      id: `input${this.state.groupedData.Position[4].key}`,
                      'aria-describedby': `label${this.state.groupedData.Position[4].key}`,
                    }}
                    autoFocus={false}
                  />
                )
              ) : (
                <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                  {this.props.item[this.state.groupedData.Position[4].key]}
                </div>
              )}
            </div>
            <div
              style={{ minHeight: 64, paddingLeft: 14, position: 'relative', marginTop: '32px', marginRight: '20px' }}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: 12,
                  position: 'relative',
                  marginTop: -8,
                  marginLeft: '14px',
                  color: getTheme().palette.text.disabled,
                }}
              >
                {(!this.state.editing ||
                  this.state.groupedData.Position[5].config.mode === 'READ_WRITEONCE' ||
                  this.state.groupedData.Position[5].config.mode === 'READONLY') &&
                  this.state.groupedData.Position[5].key}
                <Tooltip title={this.state.groupedData.Position[5].config.description} placement="left">
                  <InfoOutlined
                    fontSize="small"
                    style={{
                      position: 'absolute' as const,
                      color: getTheme().palette.text.disabled,
                      cursor: 'pointer' as const,
                      top: 0,
                      right: -20,
                      backgroundColor: 'white',
                      display: 'block',
                      zIndex: 1,
                      borderRadius: '200%',
                    }}
                  />
                </Tooltip>
              </Typography>
              {/* Edit "rest" */}
              {this.state.editing &&
              this.state.groupedData.Position[5].config.mode !== 'READ_WRITEONCE' &&
              this.state.groupedData.Position[5].config.mode !== 'READONLY' ? (
                this.state.groupedData.Position[5].key !== 'productId' && (
                  <TextField
                    key={`${this.state.groupedData.Position[5].key}edit`}
                    style={{ marginTop: '6px' }}
                    fullWidth
                    type="string"
                    variant="outlined"
                    required={this.state.groupedData.Position[5].config.required}
                    label={this.state.groupedData.Position[5].key}
                    error={false}
                    InputLabelProps={{
                      id: `label${this.state.groupedData.Position[5].key}`,
                      shrink: true,
                      htmlFor: this.state.groupedData.Position[5].key
                        ? `input${this.state.groupedData.Position[5].key}`
                        : undefined,
                    }}
                    value={
                      {}.hasOwnProperty.call(this.state.editedItem, this.state.groupedData.Position[5].key)
                        ? this.state.editedItem[this.state.groupedData.Position[5].key]
                        : this.props.item[this.state.groupedData.Position[5].key]
                    }
                    disabled={false}
                    onChange={(e) =>
                      this.state.groupedData &&
                      this.handleUpdate(this.state.groupedData.Position[5].key, e.target.value)
                    }
                    InputProps={{
                      id: `input${this.state.groupedData.Position[5].key}`,
                      'aria-describedby': `label${this.state.groupedData.Position[5].key}`,
                    }}
                    autoFocus={false}
                  />
                )
              ) : (
                <div style={{ marginTop: '6px', marginLeft: '12px', wordBreak: 'break-all' }}>
                  {this.props.item[this.state.groupedData.Position[5].key]}
                </div>
              )}
            </div>
          </Grid>
        }
      </Grid>
    );

  renderShadowAliases = (): React.ReactNode => {
    if (!this.props.shadowAliases) return null;
    const shadowAliases = this.props.shadowAliases!.filter(
      (sa) => !['firmwareProductId', 'firmwareVersion', 'firmwareUpdated'].includes(sa.alias),
    );
    if (!this.props.item || !shadowAliases.length) return null;

    return (
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6">
              <Trans>shadowAliases</Trans>
            </Typography>
          </Grid>
          {shadowAliases.map((sa) => (
            <Grid item xs={12} key={sa.alias}>
              <TextField
                fullWidth
                disabled
                label={sa.alias}
                value={{}.hasOwnProperty.call(this.props.item, sa.alias) ? (this.props.item! as any)[sa.alias] : 'null'}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  renderContent = (): React.ReactNode => (
    <Grid container spacing={4}>
      {this.props.item && this.renderCommon()}
      {this.props.item && this.props.item.type !== 'iBeacon' && this.renderSpecific()}
      {this.props.item && this.state.groupedData && this.props.item.type === 'iBeacon' && this.renderBeacon()}
      {this.renderShadowAliases()}
    </Grid>
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.props.parentLoading || this.state.loading,
        },
        customAction: this.props.customAction,
        ...(this.props.accessControl(this.props.accessControlSection, 'update') && !this.props.disableEdit
          ? {
              editProps: {
                loading: this.state.loading,
                editing: this.state.editing,
                saveDisabled: this.state.saveDisabled,
                editDisabled: !this.props.item,
                onEdit: () => {
                  this.setState({ editing: true });
                },
                onSave: this.update,
                onCancel: () => this.setState({ editing: false }),
              },
            }
          : {}),
      }}
    >
      {this.props.parentLoading && <PageLoader />}
      {!this.props.parentLoading && this.renderContent()}
    </TabPanel>
  );
}

const mapStateToProps = (/*{ deviceStore }: Store*/) => ({
  shadowAliases: undefined, //deviceStore.shadowAliases,
});

export default connect(mapStateToProps)(DevicePropertiesTab);
