import React, { Component } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { Box } from '@mui/material';
import { Store } from '../../../common/types';
import { LOCALES } from '../../../assets/translations';
import { storeSetLocale } from '../../../store/actions/user';

type Props = DispatchProp & {
  locale: string;
};

class DashboardView extends Component<Props> {
  changeLanguage = (): void => {
    const { dispatch, locale } = this.props;
    const newLocale: string = LOCALES.filter((l) => l !== locale)[0];
    if (newLocale) dispatch<any>(storeSetLocale(newLocale));
  };

  render = (): React.ReactNode => (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <div>TBD</div>
      </Box>
    </>
  );
}

const mapStateToProps = ({ userStore }: Store) => ({
  locale: userStore.locale,
});

export default connect(mapStateToProps)(DashboardView);
