import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DataService } from '../../../services';
import { Dialog, TabsHeader } from '../../../components';
import { withRouter } from 'react-router-dom';
import { PropertiesTab } from '../../baseClasses/PropertiesTab';
import { TestTab } from './DeviceApiDetailedView';
import { Trans } from 'react-i18next';
import { ModalVariants, SubdomainRoutePath } from '../../../common/types';
import { itemIcons } from '../../../common/constants';
import { storeOpenModal } from '../../../store/actions/modal';
import { Dispatch, Action } from 'redux';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../baseClasses/ViewBase';
import i18next from 'i18next';
import fleetApiGwSchema from '../../../assets/schemas/fleet-api-gw.schema.parsed.json';

type _Props = {
  openModal: (callback: () => void) => () => void;
};
type Props = ViewBaseProps<_Props, { apiId: string }>;

type _State = unknown;
type State = ViewBaseState<_State>;

class DeviceApiGwDetailedView extends ViewBase<Props, State, { apiId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
  };
  propertyTab!: number;
  dataService!: DataService;

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.properties', 'tab.test'];
    DataService.create().then((service) => (this.service = service));
    this.subservice = 'deviceApiGw';
    this.messages = {
      load: { error: 'error.fetch.deviceApi' },
      update: { success: 'success.update.deviceApiGw', error: 'error.tryAgain' },
      delete: { success: 'success.delete.deviceApiGw', error: 'error.tryAgain' },
    };
  }

  toggleBetweenEnabledDisabled = async (): Promise<void> => {
    this.setState({ loading: true });
    await this.update({ enabled: !this.state.item!.enabled });
    this.setState({ loading: false });
  };

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('deviceApiGw', 'update'))
      list.push(
        <MenuItem key="1" disabled={!this.state.item} onClick={this.toggleBetweenEnabledDisabled}>
          <Trans>{this.state.item && !this.state.item!.enabled ? 'action.enable' : 'action.disable'}</Trans>
        </MenuItem>,
      );
    if (this.accessControl('deviceApiGw', 'delete'))
      list.push(
        <MenuItem key="2" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <PropertiesTab
        tab={0}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="deviceApiGw"
        parentLoading={this.state.loading}
        schema={fleetApiGwSchema}
        schemaKey="api"
        schemaFormInputProps={[
          {
            service: 'DataService',
            subservice: 'trust',
            type: 'options',
            key: 'trustId',
            extraData: ['NONE'],
            navigate: (id: string) => SubdomainRoutePath.trust(id),
            onValueChange: {
              filterKey: 'trustId',
              returnKey: 'trustId',
              staticFilters: { parent: '*' },
            },
          },
        ]}
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
      <TestTab
        tab={1}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <>
      <Dialog
        open={this.state.deleteDialogOpen}
        title={<Trans>action.delete</Trans>}
        description={<Trans>description.delete.deviceApiGw</Trans>}
        continueTitle={<Trans>action.delete</Trans>}
        onClose={() => this.setState({ deleteDialogOpen: false })}
        onContinue={this.delete}
      />
    </>
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={this.props.match.params.apiId}
        icon={itemIcons.deviceApiGw}
        subtitle={i18next.t('form.apiId')}
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => void) => () =>
    dispatch(storeOpenModal(ModalVariants.AddDeviceGroupsToDeviceMC, { callback })),
  ...ViewBaseMapDispatchToProps(dispatch),
});

export default withStyles(ViewBaseStyles)(withRouter(connect(null, mapDispatchToProps)(DeviceApiGwDetailedView)));
