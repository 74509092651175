import React, { Component } from 'react';
import { Paper, IconButton, Grid, Typography, Button, CircularProgress } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SchemaForm } from '..';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Ajv from 'ajv';
import { DeviceService, FirmwareService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import fleetSchema from '../../assets/schemas/fleet.schema.parsed.json';
import shadowScheme from '../../assets/shadow.schema.json';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  data: { [key: string]: any };
  isSaveDisabled: boolean;
  loading: boolean;
  products: string[];
};

class CreateShadowTemplateMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    data: {},
    isSaveDisabled: true,
    loading: false,
    products: [],
  };
  firmwareService!: FirmwareService;
  deviceService!: DeviceService;
  ajv: any;

  constructor(props: Props) {
    super(props);
    this.ajv = new Ajv({ allErrors: true, useDefaults: true });
    this.ajv.addSchema(fleetSchema, 'fleetSchema');
    if (!!this.store.modalStore.data && !!this.store.modalStore.data.template) {
      this.state = {
        ...this.state,
        data: {
          template: this.store.modalStore.data.template,
        },
      };
    }
  }

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    this.deviceService = await DeviceService.create();
  };

  loadProducts = async (productId: string): Promise<string[]> => {
    const { data } = await this.firmwareService.product.list({
      params: { productId: `${productId}*`, limit: 500 },
    });
    return data.data.map((p) => p.productId);
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.deviceService.shadowTemplate.post(this.state.data);
      SnackbarUtils.success(i18next.t('success.create.shadowTemplate'));
      if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      return this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  setValue = (data: { [key: string]: any }, keys: string[], value: any): any => {
    if (keys.length === 0) {
      data = value;
    } else if (keys.length === 1) {
      if (value === null) delete data[keys[0]];
      else data[keys[0]] = value;
    } else {
      data[keys[0]] = this.setValue(data[keys[0]] || {}, keys.slice(1), value);
    }
    return data;
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean) => {
    const data = this.setValue(this.state.data || {}, key === undefined ? [] : key.split('.'), value);
    const ajvIsValid = this.ajv.validate('fleetSchema', { shadowTemplate: data });
    this.setState({ data: data || {}, isSaveDisabled: !(!!Object.keys(data).length && ajvIsValid && isValid) });
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.createShadowTemplate.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.createShadowTemplate.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SchemaForm
              state="CREATE"
              SchemaFormInputProps={{
                productId: {
                  onValueChange: this.loadProducts,
                },
                template: {
                  json: {
                    objectPath: 'state.desired',
                    schema: shadowScheme,
                  },
                },
              }}
              data={{
                ...(fleetSchema as any).properties.shadowTemplate,
              }}
              grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
              defaultValue={this.state.data}
              onChange={this.handleUpdate}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={this.state.isSaveDisabled} onClick={this.create}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.create</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateShadowTemplateMC);
