import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import { DeviceService, getDefaultTableProperties } from '../../../services';
import { ModalVariants, ShadowAlias } from '../../../common/types';
import { storeOpenModal } from '../../../store/actions/modal';
import { tableProperties, itemIcons, TableNames } from '../../../common/constants';
import { storeSetShadowAliases } from '../../../store/actions/device';
import { SnackbarUtils } from '../../../components';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';

type _Props = {
  openModal: (callback: () => any, data?: any) => void;
  setShadowAliases: (shadowAliases: ShadowAlias[]) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class ShadowAliasView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
  };

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.accessControlSection = 'shadowAlias';
    this.title = i18next.t('shadowAliases');
    this.icon = itemIcons.shadowAlias;
    this.idKey = 'alias';
    this.tableConfig = {
      tableProperties: getDefaultTableProperties(tableProperties(TableNames.shadowAliases)) as any,
      emptyTitle: 'form.empty.shadowAliases',
      rowActions: [
        {
          title: 'action.update',
          action: async (shadowAlias: ShadowAlias) =>
            this.props.openModal(this.reloadList, JSON.parse(JSON.stringify(shadowAlias))),
        },
        {
          title: 'action.delete',
          action: async (shadowAlias: ShadowAlias) => await this.removeAlias(shadowAlias.alias),
        },
      ],
      disableRowHover: true,
    };
    this.fabConfig = [
      {
        title: i18next.t('action.createShadowAlias'),
        action: () => this.props.openModal(this.reloadList),
        icon: itemIcons.shadowAlias,
        tryAccessRight: ['shadowAlias', 'create'],
      },
    ];
  }

  componentDidMount = async () => {
    this.service = await DeviceService.create();
    this.list = this.service!.shadowAlias.list;
    this.setState({ initLoaded: true });
  };

  removeAlias = async (alias: string): Promise<void> => {
    try {
      await this.service.shadowAlias.del(alias);
      SnackbarUtils.success(i18next.t('success.delete.shadowAlias'));
      if (!this.isUnmounted) {
        const items = this.state.items.filter((a: ShadowAlias) => a.alias !== alias);
        this.setState({ items });
        this.props.setShadowAliases(items);
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any, shadowAlias?: any) =>
    dispatch(storeOpenModal(ModalVariants.CreateShadowAliasMC, { callback, shadowAlias })),
  setShadowAliases: (shadowAliases: ShadowAlias[]) => dispatch(storeSetShadowAliases(shadowAliases)),
});

export default withStyles(ListBaseStyles)(withRouter(connect(null, mapDispatchToProps)(ShadowAliasView)));
