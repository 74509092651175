import React, { Component } from 'react';
import { Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { Hidden } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { RouteParentType, RouteType, Role, Store, LastVisited, SubdomainRoutePath } from '../common/types';
import { RoutesHierarchy } from '../common/routeUtils';
import { AppBar, NavLeft, Nav, If } from '../components';
import { appBarHeight, drawerWidth } from '../common/constants';
import { storeSetActiveTab } from '../store/actions/common';
import { getTheme } from '../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    mainContainer: {
      paddingTop: appBarHeight,
      [theme.breakpoints.down('md')]: {
        paddingTop: appBarHeight * 2,
      },
      [theme.breakpoints.only('md')]: {
        paddingLeft: 22,
        paddingRight: 22,
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: 22,
      },
    },
    nav: {
      backgroundColor: '#fff',
      borderRight: '1px solid #DCDCDC',
      position: 'fixed' as const,
      height: `calc(100vh - ${appBarHeight}px)`,
      top: appBarHeight,
      width: drawerWidth,
      left: 0,
      padding: '8px 22px',
      'overflow-x': 'hidden',
      'overflow-y': 'hidden',
      '&:hover': {
        'overflow-y': 'auto',
      },
    },
    main: {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
      [theme.breakpoints.only('lg')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        float: 'right' as const,
      },
      [theme.breakpoints.only('xl')]: {
        width: `calc(100% - ${drawerWidth * 2}px)`,
        maxWidth: '70%',
        margin: '0 auto',
      },
    },
    mainAccount: {
      width: '100%',
      [theme.breakpoints.only('lg')]: {
        paddingLeft: 22,
        float: 'right' as const,
      },
      [theme.breakpoints.only('xl')]: {
        paddingLeft: 22,
        maxWidth: '70%',
        margin: '0 auto',
      },
    },
  };
};

type Props = WithStyles<any> &
  RouteParentType &
  RouteComponentProps & {
    role: Role;
    activeTab: string;
    lastVisited: { [key: string]: LastVisited[] };
    setActiveTab: (activeTab: string) => void;
  };

type State = {
  routes: (RouteType | RouteParentType)[];
  activeTab: string;
};

class ValidatedView extends Component<Props, State> {
  state: State = {
    routes: [],
    activeTab: '',
  };

  componentDidMount = (): void => {
    this.setActiveTabBaseOnURL();
    window.addEventListener('popstate', this.handleHistoryPopEvent, false);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.activeTab !== prevProps.activeTab &&
      !!prevProps.activeTab &&
      prevProps.activeTab !== '/' &&
      window.location.pathname.search(this.props.activeTab) === -1
    )
      this.setActiveTab(this.props.activeTab, false);
    if (
      this.props.activeTab === prevProps.activeTab &&
      !!prevProps.activeTab &&
      prevProps.activeTab !== '/' &&
      window.location.pathname.search(this.props.activeTab) === -1
    ) {
      const activeTab = `/${window.location.pathname.split('/')[1]}`;
      const route = this.props.routes.find((route) => route.path === activeTab);
      if (!route) return;
      this.props.setActiveTab(activeTab);
      this.setState({ activeTab, routes: (!!route && route.routes) || [] });
    }
  };

  componentWillUnmount = (): void => {
    window.removeEventListener('popstate', this.handleHistoryPopEvent, false);
  };

  setActiveTabBaseOnURL = (): void => {
    const path = window.location.pathname.split('/')[1];
    const route = this.props.routes.find((route) => route.path === `/${path}`);
    if (path && route) {
      this.props.setActiveTab(`/${path}`);
      this.setState({ activeTab: `/${path}`, routes: (!!route && route.routes) || [] });
    } else {
      this.setActiveTab(
        Array.isArray(this.props.routes[0].path) ? this.props.routes[0].path[0] : this.props.routes[0].path,
      );
    }
  };

  handleHistoryPopEvent = (e: any): void => {
    if (e.currentTarget.location.pathname.search(this.props.activeTab) === -1) this.setActiveTabBaseOnURL();
  };

  findFirstChildRoutePath = (route: RouteType | RouteParentType): string => {
    const { routes } = route;
    if (!!route.routes && route.routes.length) {
      for (let i = 0; i < routes!.length; i++) {
        if ({}.hasOwnProperty.call(routes![i], 'component') || {}.hasOwnProperty.call(routes![i], 'render')) {
          return Array.isArray(routes![i].path) ? routes![i].path[0] : (routes![i].path as string);
        }
        if (!!routes![i].routes && routes![i].routes!.length && !routes![i].hideInMenu) {
          return this.findFirstChildRoutePath(routes![i]);
        }
      }
    }
    return '';
  };

  setActiveTab = (activeTab: string, setActiveTab = true): void => {
    const route = this.props.routes.find((route) => route.path === activeTab);
    if (!route) return;
    if (setActiveTab) this.props.setActiveTab(activeTab);
    this.setState({ activeTab, routes: (!!route && route.routes) || [] });
    this.props.history.push(this.findFirstChildRoutePath(route as any));
  };

  render = (): React.ReactNode => {
    if (
      !this.props.routes ||
      !this.state.routes ||
      !this.props.activeTab ||
      this.state.activeTab !== this.props.activeTab
    )
      return null;
    return (
      <>
        <AppBar
          role={this.props.role}
          routes={this.props.routes}
          hideMenu={!this.props.location.pathname.includes(SubdomainRoutePath.settings())}
        />
        <If statement={!this.props.location.pathname.includes(SubdomainRoutePath.settings())}>
          <Hidden lgUp>
            <NavLeft role={this.props.role} routes={this.state.routes} />
          </Hidden>
        </If>
        <div className={this.props.classes.mainContainer}>
          <If statement={!this.props.location.pathname.includes(SubdomainRoutePath.settings())}>
            <Hidden lgDown>
              <Nav className={this.props.classes.nav} role={this.props.role} routes={this.state.routes} />
            </Hidden>
          </If>
          <main
            className={clsx({
              [this.props.classes.main]: !this.props.location.pathname.includes(SubdomainRoutePath.settings()),
              [this.props.classes.mainAccount]: this.props.location.pathname.includes(SubdomainRoutePath.settings()),
            })}
          >
            <Switch>
              <RoutesHierarchy role={this.props.role} routes={this.props.routes} withoutSwitch />
            </Switch>
          </main>
        </div>
      </>
    );
  };
}

const mapStateToProps = ({ commonStore }: Store) => ({
  activeTab: commonStore.activeTab,
  lastVisited: commonStore.lastVisited,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setActiveTab: (activeTab: string) => dispatch(storeSetActiveTab(activeTab)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(ValidatedView)));
