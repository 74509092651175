import React from 'react';
import { connect } from 'react-redux';
import { SubdomainRoutePath, Package, ModalVariants } from '../../../../common/types';
import { ListTable, Dialog, TabPanel, ListTableActions } from '../../../../components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { tableProperties, TableNames } from '../../../../common/constants';
import { getDefaultTableProperties, FirmwareService } from '../../../../services';
import { Dispatch, Action } from 'redux';
import { storeOpenModal } from '../../../../store/actions/modal';
import { Trans } from 'react-i18next';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';
import i18next from 'i18next';

type Props = RouteComponentProps<{ productId: string }> & {
  openConfirmModal: (cPackage: Package, callback: () => void) => () => void;
};

type State = {
  packages: Package[];
  initLoaded: boolean;
  nextToken?: string;
  deletePackageId?: string;
};

class PackageTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    packages: [],
    initLoaded: false,
    nextToken: undefined,
    deletePackageId: undefined,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    FirmwareService.create().then((service) => (this.service = service));
  }

  reload = (): void =>
    this.setState({ packages: [], initLoaded: false, nextToken: undefined }, () => this.setState({ initLoaded: true }));

  load = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const { data } = await this.service.package.list({
        params: {
          productId: this.props.match.params.productId,
          limit: 50,
          ...(this.state.nextToken ? { nextToken: this.state.nextToken } : {}),
        },
      });
      this.setState({
        loading: false,
        packages: (this.state.packages || []).concat(data.data),
        nextToken: data.nextToken,
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.fetch.packages'),
      );
      this.setState({ loading: false });
    }
  };

  delete = async (): Promise<void> => {
    try {
      await this.service.package.del(this.state.deletePackageId!);
      SnackbarUtils.success(i18next.t('success.delete.package'));
      this.reload();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    if (!this.isUnmounted) this.setState({ deletePackageId: undefined });
  };

  download = async (cPackage: Package): Promise<void> => {
    const fileName = cPackage.name;
    const snackId = SnackbarUtils.download(`Downloading ${fileName}`);
    try {
      const { data } = await this.service.authenticationServiceProvider.axios.get(cPackage.url, {
        responseType: 'blob',
      });
      const blob = new Blob([data as Uint8Array]);
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = cPackage.name.replace(/ /g, '_').replace(/\./g, '-') + '.ota';
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      SnackbarUtils.close(snackId);
      SnackbarUtils.success(`Successfully downloaded ${fileName}`);
    } catch {
      SnackbarUtils.close(snackId);
      SnackbarUtils.error(`Failed to download ${fileName}`);
    }
  };

  clickRow = (cPackage: Package): (() => Promise<void>) => async (): Promise<void> => {
    this.props.history.push(SubdomainRoutePath.firmwarePackage(cPackage.packageId));
  };

  renderContent = (): React.ReactNode => (
    <>
      <ListTable
        InfinityScrollProps={{
          initLoaded: this.state.initLoaded,
          nextToken: this.state.nextToken,
          loadFunc: this.load,
        }}
        TableProps={{ disableBackgroundPaper: true }}
        loading={this.state.loading}
        emptyTitle="form.empty.packages"
        properties={getDefaultTableProperties(tableProperties(TableNames.packageTab))}
        filterActions
        rowActions={([
          {
            title: 'action.download',
            action: (cPackage: Package) => this.download(cPackage),
            filterAction: (cPackage: Package) => !!cPackage.url,
          },
        ] as ListTableActions)
          .concat(
            this.props.accessControl('package', 'update')
              ? ([
                  {
                    title: 'action.confirmPackage',
                    action: (cPackage: Package) => this.props.openConfirmModal(cPackage, this.reload)(),
                    filterAction: (cPackage: Package) => cPackage.state === 'AWAITING_CONFIRM',
                  },
                ] as ListTableActions)
              : [],
          )
          .concat(
            this.props.accessControl('package', 'delete')
              ? ([
                  {
                    title: 'action.delete',
                    action: (cPackage: Package) => this.setState({ deletePackageId: cPackage.packageId }),
                  },
                ] as ListTableActions)
              : [],
          )}
        rows={this.state.packages}
        clickRow={this.clickRow}
      />
      <Dialog
        open={!!this.state.deletePackageId}
        title={<Trans>action.delete</Trans>}
        description={<Trans>description.delete.package</Trans>}
        continueTitle={<Trans>action.delete</Trans>}
        onClose={() => this.setState({ deletePackageId: undefined })}
        onContinue={() => this.delete()}
      />
    </>
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.state.loading,
        },
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openConfirmModal: (cPackage: Package, callback: () => void) => () =>
    dispatch(storeOpenModal(ModalVariants.ConfirmFirmwarePackageMC, { package: cPackage, callback })),
});

export default withRouter(connect(null, mapDispatchToProps)(PackageTab));
