import React, { Component } from 'react';
import { Paper, IconButton, Typography, Grid, Button, CircularProgress, TextField } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { UserGroup } from '../../common/types';
import { UserService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  addUserGroups: string[];
  userGroups: string[];
};

export class AddUserGroupToUserMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    addUserGroups: [],
    userGroups: [],
  };
  userService!: UserService;
  userGroupDebouncer: any;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.userService = await UserService.create()!;
    this.loadUserGroups();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  get isValid(): boolean {
    return !this.state.loading && !!this.state.addUserGroups.length;
  }

  loadUserGroups = async (): Promise<void> => {
    let list: string[] = [];
    let nextToken: string | undefined;
    do {
      try {
        const { data }: any = await this.userService.userGroup.list(nextToken ? { params: { nextToken } } : undefined);
        list = list.concat(data.data.map((dg: UserGroup) => dg.groupName));
        nextToken = data.nextToken;
      } catch {
        SnackbarUtils.error(i18next.t('error.fetch.userGroups'));
        return;
      }
    } while (nextToken);
    this.setState({ userGroups: list });
  };

  addUserGroupsToUser = async (): Promise<void> => {
    this.setState({ loading: true });
    const result = await Promise.all(
      this.state.addUserGroups.map((userGroup) =>
        this.userService!.userGroup.postUserToUserGroup(userGroup, this.store.modalStore.data.userId)
          .then(() => ({
            isError: false,
            title: userGroup,
            subtitle: i18next.t('success.attach.userGroupToUser'),
          }))
          .catch((e) => {
            let subtitle = i18next.t('error.attach.userGroupToUser');
            if (e.response) {
              if (e.response.status === 403) {
                subtitle = i18next.t('error.create.permission');
              } else if (e.response.data && e.response.data.message) {
                subtitle = e.response.data && e.response.data.message;
              }
            }
            return {
              isError: true,
              title: userGroup,
              subtitle,
            };
          }),
      ),
    );

    if (!result.map((res) => res.isError).includes(false)) {
      SnackbarUtils.error(
        `${i18next.t('error.attach.userGroupsToUser')} (${this.state.addUserGroups.length})`,
        result as any,
      );
    } else if (!result.map((res) => res.isError).includes(true)) {
      SnackbarUtils.success(
        `${i18next.t('success.attach.userGroupsToUser')} (${this.state.addUserGroups.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } else {
      SnackbarUtils.warning(
        `${i18next.t('warning.attach.partiallyUserGroupsToUser')} (${this.state.addUserGroups.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    }
    this.setState({ loading: false });
  };

  render = (): React.ReactNode => {
    const { classes } = this.props;
    const { userGroupNames } = this.store.modalStore.data;
    return (
      <Paper tabIndex={-1} square className={classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.addUserGroupsToUser.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.addUserGroupsToUser.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="aug"
              multiple
              filterSelectedOptions
              ChipProps={{ size: 'small' }}
              options={this.state.userGroups.filter((userGroup) => !userGroupNames.includes(userGroup))}
              value={this.state.addUserGroups}
              isOptionEqualToValue={(option: string, value: string) => option === value}
              onChange={(_: any, addUserGroups: string[]) => this.setState({ addUserGroups })}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'aug' }}
                  label={`${i18next.t('userGroups')}*`}
                  placeholder={i18next.t('form.placeholder.selectUserGroups')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={!this.isValid} onClick={this.addUserGroupsToUser}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.add</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(AddUserGroupToUserMC);
