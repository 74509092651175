import React, { Component } from 'react';
import {
  Paper,
  IconButton,
  Typography,
  TextField,
  Grid,
  Button,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { AuthenticationServiceProvider } from '../../services';
import i18next from 'i18next';
import { store } from '../../store';
import { passwordRegex } from '../../common/constants';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  showError?: 'new' | 'old' | 'both';
  errorMessage?: string;
  currentPassword: string;
  newPassword: string;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
};

export class ChangePasswordMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    errorMessage: undefined,
    showError: undefined,
    currentPassword: '',
    newPassword: '',
    showCurrentPassword: false,
    showNewPassword: false,
  };
  authenticationServiceProvider?: AuthenticationServiceProvider;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  changePassword = async (): Promise<void> => {
    this.setState({ loading: true, showError: undefined, errorMessage: undefined });

    if (!passwordRegex.test(this.state.newPassword))
      return this.setState({
        loading: false,
        showError: 'new',
        errorMessage: i18next.t('error.match.passwordRequirements'),
      });

    try {
      if (!this.authenticationServiceProvider) throw new Error('No AuthenticationService available');
      await new Promise<void>((resolve, reject) =>
        this.authenticationServiceProvider!.service.changePassword(
          this.state.currentPassword,
          this.state.newPassword,
          (err: any) => (err ? reject(err) : resolve(undefined)),
        ),
      );
      SnackbarUtils.success(i18next.t('success.update.password'));
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      this.setState({ showError: 'both', errorMessage: !!error && error.message });
      SnackbarUtils.error(i18next.t('error.update.password'));
    }
    this.setState({ loading: false });
  };

  get isValid(): boolean {
    return !this.state.loading && !!this.state.currentPassword && !!this.state.newPassword;
  }

  render = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Paper tabIndex={-1} square className={classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.changePassword.changePassword</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.changePassword.updatePassword</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={`${i18next.t('form.currentPassword')}*`}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle current password visibility"
                      onClick={() => this.setState({ showCurrentPassword: !this.state.showCurrentPassword })}
                      size="large"
                    >
                      {this.state.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!this.state.showError && ['old', 'both'].includes(this.state.showError as string)}
              type={this.state.showCurrentPassword ? 'text' : 'password'}
              value={this.state.currentPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!this.state.loading) this.setState({ currentPassword: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={`${i18next.t('form.newPassword')}*`}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      onClick={() => this.setState({ showNewPassword: !this.state.showNewPassword })}
                      size="large"
                    >
                      {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!this.state.showError && ['new', 'both'].includes(this.state.showError as string)}
              type={this.state.showNewPassword ? 'text' : 'password'}
              value={this.state.newPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!this.state.loading) this.setState({ newPassword: e.target.value });
              }}
            />
          </Grid>
          {!!this.state.showError && !!this.state.errorMessage ? (
            <Grid item xs={12}>
              <Typography variant="caption" color="error">
                <Trans>{this.state.errorMessage}</Trans>
              </Typography>
            </Grid>
          ) : null}
          <Grid item>
            <Button color="secondary" variant="contained" disabled={!this.isValid} onClick={this.changePassword}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.change</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(ChangePasswordMC);
