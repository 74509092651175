import { AuthenticationServiceProvider } from '..';
import { AwsRequestConfig, RoutePathPrefix } from '../../common/types';
import { AxiosResponse } from 'axios';
import { store } from '../../store';
import { storeRemoveLastVisited } from '../../store/actions/common';

export class BaseCRUDService<LI, PO, GE, PU, DE> {
  constructor(
    protected authenticationServiceProvider: AuthenticationServiceProvider,
    protected prefix: RoutePathPrefix,
    protected api: {
      list: () => string;
      get: (id: string) => string;
      post?: () => string;
      put?: (id: string) => string;
      del?: (id: string) => string;
    },
  ) {}

  private getApi: (type: 'list' | 'post' | 'get' | 'put' | 'del', id?: string) => string = (
    type: 'list' | 'post' | 'get' | 'put' | 'del',
    id?: string,
  ) => {
    if ((this.api as any)[type]) return (this.api[type] as (id?: string) => string)(id);
    if (type === 'post') return this.api.list();
    return this.api.get(id!);
  };

  list = (config: Partial<AwsRequestConfig> = {}): Promise<AxiosResponse<{ data: LI[]; nextToken?: string }>> =>
    this.authenticationServiceProvider.Get<{ data: LI[]; nextToken?: string }>({
      ...config,
      path: this.getApi('list'),
    });

  post = (data: any, params?: { [key: string]: any }): Promise<AxiosResponse<PO>> =>
    this.authenticationServiceProvider.Post<PO>({ data, path: this.getApi('post'), params });

  get = (id: string, params?: { [key: string]: any }): Promise<AxiosResponse<GE>> => {
    return this.authenticationServiceProvider.Get<GE>({ path: this.getApi('get', id), params });
  };

  put = (id: string, data: Partial<GE>, params?: { [key: string]: any }): Promise<AxiosResponse<PU>> => {
    return this.authenticationServiceProvider.Put<PU>({ data, path: this.getApi('put', id), params });
  };

  del = async (id: string, params?: { [key: string]: any }): Promise<AxiosResponse<DE>> => {
    const result = await this.authenticationServiceProvider.Delete<DE>({ path: this.getApi('del', id), params });
    store.dispatch(storeRemoveLastVisited(id, this.prefix));
    return result;
  };
}
