import { Component } from 'react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Store } from '../common/types';
import resources, { LOCALES } from '../assets/translations';

export type LanguageProviderProps = DispatchProp & {
  locale: string;
};

class _LanguageProvider extends Component<LanguageProviderProps> {
  UNSAFE_componentWillMount = () => {
    const { locale } = this.props;
    i18next.use(initReactI18next).init({
      resources,
      lng: locale,
      fallbackLng: LOCALES[0],
      interpolation: {
        escapeValue: false,
      },
    });
  };

  componentDidUpdate = (prevProps: LanguageProviderProps) => {
    const { locale } = this.props;
    if (prevProps.locale !== locale) i18next.changeLanguage(locale);
  };

  render = () => this.props.children;
}

const mapStateToProps = ({ userStore }: Store) => ({
  locale: userStore.locale,
});

export const LanguageProvider = connect(mapStateToProps)(_LanguageProvider);
