import React, { Component } from 'react';
import { Paper, IconButton, Typography, Grid, CircularProgress, Button, TextField } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import i18next from 'i18next';
import { Package, Release } from '../../common/types';
import { FirmwareService } from '../../services';
import { store } from '../../store';
import { Dialog } from '..';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';

const styles = () => {
  return {
    ...BaseModalStyles(),
    fileInput: {
      display: 'none',
    },
  };
};

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  releaseId: string;
  packageId: string;
  addFromVersions: string[];
  fromVersions: string[];
  release?: Release;
  package?: Package;
  packages: Package[];
  force: boolean;
  forceDialogOpen: boolean;
};

export class EditReleaseMappingMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    releaseId: '',
    packageId: '',
    addFromVersions: [],
    fromVersions: [],
    release: undefined,
    package: undefined,
    packages: [],
    force: false,
    forceDialogOpen: false,
  };
  firmwareService!: FirmwareService;
  packageDebouncer: any;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    let cPackage: Package | undefined;
    let packages: Package[] = [];
    let nextToken: string | undefined;

    if (!!this.store.modalStore.data && !!this.store.modalStore.data.package) {
      cPackage = this.store.modalStore.data.package as Package;
    } else {
      do {
        const {
          data: { data, nextToken: token },
        } = await this.firmwareService.package.list({
          params: nextToken ? { limit: 500, nextToken } : { limit: 500 },
        });
        packages = packages.concat(data);
        nextToken = token;
      } while (nextToken);
      if (packages.length) cPackage = packages[0];
    }

    const addFromVersions: string[] = this.getCurrentPackageFromVersions;

    if (this._isMounted)
      this.setState(
        {
          packages,
          package: cPackage,
          addFromVersions,
          release: this.store.modalStore.data.release,
          releaseId: this.store.modalStore.data.release.releaseId,
          packageId: cPackage!.packageId,
        },
        this.getVersions,
      );
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  update = async (): Promise<void> => {
    this.setState({ loading: true });

    const initMappings: string[] = this.getCurrentPackageFromVersions;

    const result: any[] = await Promise.all(
      this.state.addFromVersions
        .filter((version) => !initMappings.includes(version))
        .map((fromVersion) =>
          this.firmwareService.release
            .postMapping(
              {
                packageId: this.state.packageId!,
                fromVersion,
                releaseId: this.state.release!.releaseId,
              },
              { force: this.state.force },
            )
            .then(() => ({
              isError: false,
              title: fromVersion,
              subtitle: i18next.t('success.attach.releaseMappingVersion'),
            }))
            .catch((e) => {
              const error: any = e;
              return {
                force: error.response && error.response.data && error.response.data.errorCode === 'FORCE_REQUIRED',
                isError: true,
                title: fromVersion,
                subtitle:
                  (error.response && error.response.data && error.response.data.message) ||
                  i18next.t('error.attach.releaseMappingVersion'),
              };
            }),
        )
        .concat(
          initMappings
            .filter((version) => !this.state.addFromVersions.includes(version))
            .map((fromVersion) =>
              this.firmwareService.release
                .delMapping(
                  {
                    fromVersion,
                    releaseId: this.state.release!.releaseId,
                  },
                  { force: this.state.force },
                )
                .then(() => ({
                  isError: false,
                  title: fromVersion,
                  subtitle: i18next.t('success.detach.releaseMappingVersion'),
                }))
                .catch((e) => {
                  const error: any = e;
                  return {
                    force: error.response && error.response.data && error.response.data.errorCode === 'FORCE_REQUIRED',
                    isError: true,
                    title: fromVersion,
                    subtitle:
                      (error.response && error.response.data && error.response.data.message) ||
                      i18next.t('error.detach.releaseMappingVersion'),
                  };
                }),
            ),
        ),
    );
    // result contains an error
    if (!result.map((res) => res.isError).includes(false)) {
      // opens dialog were user can force the request when force is required
      if (result[0].force && !this.state.force) {
        this.setState({ forceDialogOpen: true });
        // other errors displayed
      } else {
        SnackbarUtils.error(
          `${i18next.t('error.update.releaseMappingVersions')} (${this.state.addFromVersions.length})`,
          result as any,
        );
        this.setState({ loading: false });
      }
      // no errors
    } else if (!result.map((res) => res.isError).includes(true)) {
      SnackbarUtils.success(
        `${i18next.t('success.update.releaseMappingVersions')} (${this.state.addFromVersions.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } else {
      SnackbarUtils.warning(
        `${i18next.t('warning.update.partiallyReleaseMappings')} (${this.state.addFromVersions.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    }
  };

  isVersionLess = (from: string, to: string): boolean => {
    const [fMajor, fMinor, fPatch] = from.split('.') as [string, string, string];
    const [tMajor, tMinor, tPatch] = to.split('.') as [string, string, string];
    return (
      parseInt(fMajor) < parseInt(tMajor) ||
      (parseInt(fMajor) === parseInt(tMajor) && parseInt(fMinor) < parseInt(tMinor)) ||
      (parseInt(fMajor) === parseInt(tMajor) &&
        parseInt(fMinor) === parseInt(tMinor) &&
        parseInt(fPatch) < parseInt(tPatch))
    );
  };

  getVersions = async (): Promise<void> => {
    if (!this.firmwareService) return;

    let list: Release[] = [];
    let nextToken: string | undefined = undefined;
    do {
      try {
        const response: any = await this.firmwareService.release.list({
          params: {
            limit: 500,
            productId: this.state.release!.productId,
            ...(nextToken ? { nextToken } : {}),
          },
        });
        list = list.concat(response.data.data);
        nextToken = response.data.nextToken;
      } catch {
        SnackbarUtils.error(i18next.t('error.fetch.releases'));
        list = [];
        nextToken = undefined;
      }
    } while (nextToken);

    if (!!list && !!list.length && this._isMounted) {
      this.setState({
        fromVersions: list
          .filter(
            (f) =>
              !(
                (!!this.store.modalStore.data.existingFromVersions &&
                  !!this.store.modalStore.data.packageFromVersions &&
                  this.store.modalStore.data.existingFromVersions.filter(
                    (version: string) => !this.store.modalStore.data.packageFromVersions.includes(version),
                  )) ||
                this.store.modalStore.data.existingFromVersions ||
                []
              ).includes(f.toVersion) && this.isVersionLess(f.toVersion, this.state.release!.toVersion),
          )
          .map((release) => release.toVersion),
      });
    }
  };

  get getCurrentPackageFromVersions() {
    return (!!this.store.modalStore.data && this.store.modalStore.data.packageFromVersions) || [];
  }

  get isValid(): boolean {
    if (this.state.loading) return false;
    if (!this.state.releaseId) return false;
    if (!this.state.packageId) return false;
    if (!!this.getCurrentPackageFromVersions.length && !this.state.addFromVersions.length) return true;
    const hasAdded = this.getCurrentPackageFromVersions
      .map((version: string) => this.state.addFromVersions.includes(version))
      .includes(false);
    if (hasAdded) return true;
    const hasRemoved = this.state.addFromVersions
      .map((version: string) => this.getCurrentPackageFromVersions.includes(version))
      .includes(false);
    return hasRemoved;
  }

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.editFirmwareReleaseMapping.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.editFirmwareReleaseMapping.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="erk"
              multiple
              filterSelectedOptions
              ChipProps={{ size: 'small' }}
              options={this.state.fromVersions}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option: string, value: string) => option === value}
              value={this.state.addFromVersions}
              onChange={(_: any, addFromVersions: string[]) => this.setState({ addFromVersions })}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'erk' }}
                  label={`${i18next.t('form.fromVersions')}*`}
                  placeholder={i18next.t('form.placeholder.selectFromVersions')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="erk2"
              disableClearable
              options={
                !!this.store.modalStore.data && !!this.store.modalStore.data.release ? [this.state.release!] : []
              }
              getOptionLabel={(option: Release) => (!!option && option.name) || ''}
              value={this.state.release || undefined}
              disabled={!!this.store.modalStore.data && !!this.store.modalStore.data.release}
              onChange={(_: any, release: Release) => this.setState({ releaseId: release.releaseId, release })}
              isOptionEqualToValue={(option, value) => option.releaseId === value.releaseId}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'erk2' }}
                  label={`${i18next.t('form.release')}*`}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="erk3"
              disableClearable
              options={
                !!this.store.modalStore.data && !!this.store.modalStore.data.package
                  ? [this.state.package!]
                  : this.state.packages
              }
              getOptionLabel={(option: Package) => (!!option && option.name) || ''}
              value={this.state.package || undefined}
              disabled={!!this.store.modalStore.data && !!this.store.modalStore.data.package}
              onChange={(_: any, cPackage: Package) =>
                this.setState({ packageId: cPackage.packageId, package: cPackage })
              }
              isOptionEqualToValue={(option, value) => option.packageId === value.packageId}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'erk3' }}
                  label={`${i18next.t('form.package')}*`}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={!this.isValid} onClick={this.update}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.update</Trans>}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.forceDialogOpen}
          title={<Trans>action.forceMapping</Trans>}
          description={<Trans>description.forceMapping</Trans>}
          continueTitle={<Trans>action.continue</Trans>}
          onClose={() => this.setState({ loading: false, forceDialogOpen: false })}
          onContinue={() => {
            this.setState({ forceDialogOpen: false, force: true }, this.update);
          }}
        />
      </Paper>
    );
  };
}

export default withStyles(styles)(EditReleaseMappingMC);
