import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RouteType, RouteParentType, SubdomainRoutePath, Role } from './types';
import { AuthenticationServiceProvider } from '../services';

type RoutesHierarchyProps = {
  role: Role | undefined;
  routes: (RouteType | RouteParentType)[];
  withoutSwitch?: boolean;
};

const RoutesHierarchy: React.FC<RoutesHierarchyProps> = ({ role, routes, withoutSwitch }: RoutesHierarchyProps) => {
  if (withoutSwitch) {
    return (
      <React.Fragment key={`routes-hierarchy-fragment-${Math.random()}`}>
        {routes.map((route, i) => {
          if (!{}.hasOwnProperty.call(route, 'component') && !{}.hasOwnProperty.call(route, 'render'))
            return (
              <RoutesHierarchy
                role={role}
                key={`routes-hierarchy-${Math.random()}`}
                routes={
                  !route.roles
                    ? route.routes!
                    : Array.from(route.routes!).map((r: RouteType | RouteParentType) => ({
                        ...r,
                        roles: r.roles ? Array.from(new Set(r.roles!.concat(route.roles!))) : route.roles!,
                      }))
                }
                withoutSwitch
              />
            );
          return (
            <RouteWithSubRoutes
              role={role}
              key={`${Array.isArray(route.path) ? route.path[0] : route.path}-${i}`}
              {...(route as RouteType)}
            />
          );
        })}
      </React.Fragment>
    );
  }
  return (
    <Switch key={`route-hierarchy-switch-${Math.random()}`}>
      {routes.map((route, i) => {
        if (!{}.hasOwnProperty.call(route, 'component') && !{}.hasOwnProperty.call(route, 'render'))
          return (
            <RoutesHierarchy
              role={role}
              key={`routes-hierarchy-${Math.random()}`}
              routes={
                !route.roles
                  ? route.routes!
                  : Array.from(route.routes!).map((r: RouteType | RouteParentType) => ({
                      ...r,
                      roles: r.roles ? Array.from(new Set(r.roles!.concat(route.roles!))) : route.roles!,
                    }))
              }
              withoutSwitch
            />
          );
        return (
          <RouteWithSubRoutes
            role={role}
            key={`${Array.isArray(route.path) ? route.path[0] : route.path}-${i}`}
            {...(route as RouteType)}
          />
        );
      })}
    </Switch>
  );
};

type RouteWithSubRoutesProps = RouteType & {
  role: Role | undefined;
};

const RouteWithSubRoutes: React.FC<RouteWithSubRoutesProps> = ({
  role,
  component,
  render,
  roles,
  authenticated,
  ...rest
}: RouteWithSubRoutesProps) => (
  <Route
    {...rest}
    render={(props) => {
      // Authentication required route
      if (authenticated && !AuthenticationServiceProvider.checkCache()) {
        const params = new URLSearchParams(window.location.search);
        params.set('path', window.location.pathname);
        return (
          <Redirect
            to={{
              pathname: SubdomainRoutePath.signInDeepLink(`?${params.toString()}${window.location.hash}`),
              state: { from: props.location },
            }}
          />
        );
      }
      // If not authorize redirect to first
      if ((!role && roles) || (role && roles && !roles.includes(role)))
        return <Redirect to={{ pathname: SubdomainRoutePath.base() }} />;
      // Public route or has required authentication
      if (component)
        return React.createElement(component, {
          ...rest,
          ...props,
          ...{ role, component, render, roles, authenticated },
        });
      if (render) return render({ ...rest, ...props, ...{ role, component, render, roles, authenticated } });
      return null;
    }}
  />
);

export { RoutesHierarchy, RouteWithSubRoutes };
