/* eslint-disable react/no-children-prop */
import React, { Component } from 'react';
import { Paper, IconButton, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { CloseOutlined } from '@mui/icons-material';
import { store } from '../../store';
import { PageLoader } from '..';
import { getTheme } from '../../common/theme';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const licenses = require('../../assets/licenses.txt');

const styles = () => {
  const theme = getTheme();
  return {
    paper: {
      position: 'relative' as const,
      height: '100%',
      outline: 'none',
      backgroundColor: '#fff',
      padding: theme.spacing(4),
      'overflow-x': 'hidden',
      'overflow-y': 'auto',
      width: '100%',
    },
  };
};

type Props = WithStyles<any> &
  WithSnackbarProps & {
    closeModal: () => void;
  };

type State = {
  loading: boolean;
  licenses: string | null;
};

export class OpenSourceMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: true,
    licenses: null,
  };

  componentDidMount = async (): Promise<void> => {
    const response: Response = await fetch(licenses.default);
    const text = await response.text();
    this.setState({ licenses: text, loading: false });
  };

  render = (): React.ReactNode => (
    <Paper className={this.props.classes.paper}>
      <IconButton
        style={{ position: 'fixed' as const, top: getTheme().spacing(1), right: getTheme().spacing(2) }}
        onClick={this.props.closeModal}
        aria-label="close"
        size="large"
      >
        <CloseOutlined />
      </IconButton>
      {this.state.loading ? (
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} md={6} lg={4}>
            <PageLoader />
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} md={6} lg={4}>
            {this.state.licenses && (
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                children={this.state.licenses.trim()}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default withStyles(styles)(withSnackbar(OpenSourceMC));
