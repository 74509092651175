import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import { UserService, FilterService } from '../../../services';
import { SubdomainRoutePath, ModalVariants, User } from '../../../common/types';
import { storeOpenModal } from '../../../store/actions/modal';
import { tableProperties, listFilter, itemIcons, TableNames } from '../../../common/constants';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';
import { SnackbarUtils } from '../../../components';

type _Props = {
  openModal: (callback: () => any) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class UserView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
  };

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.filterService = new FilterService(this, 'user');
    this.accessControlSection = 'users';
    this.title = i18next.t('users');
    this.icon = itemIcons.user;
    this.idKey = 'userId';
    this.filterConfig = {
      listFilter: listFilter.user,
      availableProperties: Object.keys(tableProperties(TableNames.user)),
    };
    this.tableConfig = {
      tableProperties: tableProperties(TableNames.user),
      emptyTitle: 'form.empty.users',
      rowActions: [
        {
          title: '',
          dynamicTitle: (user: User) => (user.enabled ? 'action.disable' : 'action.enable'),
          action: (user: User) => this.toggleEnableUser(user.userId),
        },
        {
          title: 'action.delete',
          action: (user: User) => this.setState({ dialogOpen: user.userId }),
        },
      ],
    };
    this.dialogConfig = {
      title: i18next.t('action.delete'),
      description: i18next.t('description.delete.user'),
      continueTitle: i18next.t('action.delete'),
      onClose: () => this.setState({ dialogOpen: undefined }),
      onContinue: () => this.state.dialogOpen && this.deleteUser(this.state.dialogOpen),
    };
    this.fabConfig = [
      {
        title: i18next.t('action.createUser'),
        action: () => this.props.openModal(this.reloadList),
        icon: itemIcons.user,
        tryAccessRight: ['user', 'create'],
      },
    ];

    // Functions
    this.getUrlPathForItem = SubdomainRoutePath.user;
  }

  componentDidMount = async () => {
    this.service = await UserService.create();
    this.list = this.service!.user.list;

    const predefinedFilters = this.filterService!.get();
    const index = this.filterService!.getIndex();

    this.setState({ initLoaded: true, predefinedFilters, activeTabIndex: index });
  };

  deleteUser = async (user: string): Promise<void> => {
    try {
      await this.service.user.del(user);
      SnackbarUtils.success(i18next.t('success.delete.user'));
      if (!this.isUnmounted) {
        const items = this.state.items.filter((u: User) => u.userId !== user);
        this.setState({ items });
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };

  toggleEnableUser = async (user: string): Promise<void> => {
    try {
      let newUser: any;
      const items = this.state.items.map((u: User) => {
        if (u.userId === user) {
          newUser = { ...u, enabled: !u.enabled };
          return newUser;
        }
        return u;
      });
      if (newUser) {
        await this.service.user.put(user, { enabled: newUser.enabled });
        this.setState({ items });
        SnackbarUtils.success(i18next.t('success.update.user'));
      } else {
        SnackbarUtils.error(i18next.t('error.tryAgain'));
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    if (!this.isUnmounted) this.setState({ loading: false });
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any) => dispatch(storeOpenModal(ModalVariants.InviteUserMC, { callback })),
});

export default withStyles(ListBaseStyles)(withRouter(connect(null, mapDispatchToProps)(UserView)));
