import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { SubdomainRoutePath, Release, ModalVariants } from '../../../../common/types';
import { ListTable, Dialog, TabPanel, ListTableActions } from '../../../../components';
import { tableProperties, TableNames } from '../../../../common/constants';
import { getDefaultTableProperties, FirmwareService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';
import { storeOpenModal } from '../../../../store/actions/modal';

type Props = RouteComponentProps<{ productId: string }> & {
  openPublishRelease: (release: Release, callback: () => void) => void;
};

type State = {
  releases: Release[];
  initLoaded: boolean;
  nextToken?: string;
  disableReleaseId?: string;
  disabling: boolean;
  deleteReleaseId?: string;
};

class ReleaseTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    releases: [],
    initLoaded: false,
    nextToken: undefined,
    disableReleaseId: undefined,
    disabling: false,
    deleteReleaseId: undefined,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    FirmwareService.create().then((service) => (this.service = service));
  }

  reload = (): void =>
    this.setState({ releases: [], initLoaded: false, nextToken: undefined }, () => this.setState({ initLoaded: true }));

  load = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const { data } = await this.service.release.list({
        params: {
          productId: this.props.match.params.productId,
          limit: 50,
          ...(this.state.nextToken ? { nextToken: this.state.nextToken } : {}),
        },
      });
      this.setState({
        loading: false,
        releases: (this.state.releases || []).concat(data.data),
        nextToken: data.nextToken,
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.fetch.releases'),
      );
      this.setState({ loading: false });
    }
  };

  disable = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.service.release.put(this.state.disableReleaseId!, { enabled: false });
      SnackbarUtils.success(i18next.t('success.disable.release'));
      this.reload();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: true });
  };

  delete = async (): Promise<void> => {
    try {
      await this.service.release.del(this.state.deleteReleaseId!);
      SnackbarUtils.success(i18next.t('success.delete.release'));
      this.reload();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    if (!this.isUnmounted) this.setState({ deleteReleaseId: undefined });
  };

  clickRow = (release: Release): (() => Promise<void>) => async (): Promise<void> => {
    this.props.history.push(SubdomainRoutePath.firmwareRelease(release.releaseId));
  };

  renderContent = (): React.ReactNode => (
    <>
      <ListTable
        InfinityScrollProps={{
          initLoaded: this.state.initLoaded,
          nextToken: this.state.nextToken,
          loadFunc: this.load,
        }}
        TableProps={{ disableBackgroundPaper: true }}
        loading={this.state.loading}
        emptyTitle="form.empty.releases"
        properties={getDefaultTableProperties(tableProperties(TableNames.releaseTab))}
        filterActions
        rowActions={(this.props.accessControl('release', 'update')
          ? ([
              {
                title: 'action.unpublish',
                action: (release: Release) => this.setState({ disableReleaseId: release.releaseId }),
                filterAction: (release: Release) => release.enabled,
              },
              {
                title: 'action.editTarget',
                action: (release: Release) => this.props.openPublishRelease(release, this.reload),
                filterAction: (release: Release) => release.enabled,
              },
              {
                title: 'action.publish',
                action: (release: Release) => this.props.openPublishRelease(release, this.reload),
                filterAction: (release: Release) => {
                  return !release.enabled && !!release.fromVersion;
                },
              },
            ] as ListTableActions)
          : []
        ).concat(
          this.props.accessControl('release', 'delete')
            ? ([
                {
                  title: 'action.delete',
                  action: (release: Release) => {
                    this.setState({ deleteReleaseId: release.releaseId });
                    return Promise.resolve();
                  },
                },
              ] as ListTableActions)
            : [],
        )}
        rows={this.state.releases.map((release) => {
          if (
            ((!!release.fromVersion && !release.fromVersion.includes('entrypoint')) || !release.fromVersion) &&
            release.entrypointPackageId
          ) {
            if (Array.isArray(release.fromVersion)) {
              release.fromVersion.push('entrypoint');
            } else {
              release.fromVersion = ['entrypoint'];
            }
          }
          return release;
        })}
        clickRow={this.clickRow}
      />
      <Dialog
        open={!!this.state.disableReleaseId}
        title={<Trans>description.unpublishRelease</Trans>}
        description={<Trans>description.disable.release</Trans>}
        continueTitle={<Trans>action.continue</Trans>}
        onClose={() => this.setState({ disableReleaseId: undefined })}
        onContinue={() => this.disable()}
      />
      <Dialog
        open={!!this.state.deleteReleaseId}
        title={<Trans>action.delete</Trans>}
        description={<Trans>description.delete.release</Trans>}
        continueTitle={<Trans>action.delete</Trans>}
        onClose={() => this.setState({ deleteReleaseId: undefined })}
        onContinue={() => this.delete()}
      />
    </>
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.state.loading,
        },
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openPublishRelease: (release: Release, callback: () => void) =>
    dispatch(storeOpenModal(ModalVariants.PublishReleaseMC, { release, callback })),
});

export default connect(null, mapDispatchToProps)(withRouter(ReleaseTab));
