import React, { Component } from 'react';
import { Paper, IconButton, Typography, Grid, CircularProgress, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Ajv from 'ajv';
import { PostKey } from '../../common/types';
import { FirmwareService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import { SchemaForm } from '../SchemaForm';
import firmwareSchema from '../../assets/schemas/firmware.schema.parsed.json';
import { getTheme } from '../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    ...BaseModalStyles(),
    stepper: {
      padding: 0,
    },
    fileInput: {
      display: 'none',
    },
    warning: {
      color: theme.palette.warning.main,
      textAlign: 'center' as const,
    },
  };
};

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  loading: boolean;
  isSaveDisabled: boolean;
  data: PostKey;
  file?: any;
  keyTypes: string[];
};

export class CreateKeyMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    isSaveDisabled: true,
    data: {} as any,
    keyTypes: [],
  };
  fileUploadRef: React.RefObject<HTMLInputElement>;
  firmwareService!: FirmwareService;
  _isMounted = true;
  ajv: any;

  constructor(props: Props, state: State) {
    super(props, state);
    this.fileUploadRef = React.createRef<HTMLInputElement>();
    this.ajv = new Ajv({ allErrors: true, useDefaults: true });
    this.ajv.addSchema(firmwareSchema, 'firmwareSchema');
  }

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    const { data } = await this.firmwareService.key.getKeyTypes();
    this.setState({ keyTypes: data.map((t) => t.label) });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });

    try {
      await this.firmwareService.key.post(this.state.data);
      SnackbarUtils.success(i18next.t('success.create.key'));
      if (!!this.store.modalStore.data && !!this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  get isValid(): boolean {
    return !!(!this.state.loading && !this.state.isSaveDisabled && this.state.data.key);
  }

  handleFileSelect = (e: any): void => {
    e.preventDefault();
    const data = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.setState({ file: data, data: { ...this.state.data, key: e.target!.result } });
    };
    reader.readAsText(data);
  };

  setValue = (data: { [key: string]: any }, keys: string[], value: any): any => {
    if (keys.length === 0) {
      data = value;
    } else if (keys.length === 1) {
      data[keys[0]] = value;
    } else {
      data[keys[0]] = this.setValue(data[keys[0]] || {}, keys.slice(1), value);
    }
    return data;
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean) => {
    const data = this.setValue(this.state.data || {}, key === undefined ? [] : key.split('.'), value);
    const ajvIsValid = this.ajv.validate('firmwareSchema', { keyCreate: data });
    this.setState({ data: data || {}, isSaveDisabled: !(!!Object.keys(data).length && ajvIsValid && isValid) });
  };

  renderInput = (): React.ReactNode => {
    return (
      <>
        <Grid item xs={12}>
          <SchemaForm
            state="CREATE"
            SchemaFormInputProps={{
              type: {
                options: this.state.keyTypes,
              },
            }}
            data={{
              ...(firmwareSchema as any).properties.keyCreate,
            }}
            grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
            defaultValue={this.state.data}
            onChange={this.handleUpdate}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" fullWidth onClick={() => this.fileUploadRef.current!.click()}>
            <input
              id="file-upload"
              ref={this.fileUploadRef}
              type="file"
              accept=".pem, .key"
              className={this.props.classes.fileInput}
              onChange={this.handleFileSelect}
            />
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Trans>{this.state.file ? 'form.changeKey' : 'form.selectKey'}</Trans>
              </Grid>
              <Grid item xs={12}>
                <pre style={{ display: 'inline-block', margin: 0 }}>
                  {this.state.file ? this.state.file.name : i18next.t('description.noKeyFileSelected')}
                </pre>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </>
    );
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.createKey.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.createKey.description</Trans>
            </Typography>
          </Grid>
          {this.renderInput()}
          <Grid item>
            <Button color="secondary" variant="contained" disabled={!this.isValid} onClick={this.create}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.create</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(styles)(CreateKeyMC as any);
