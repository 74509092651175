import {
  CommonStoreAction,
  StoreName,
  UserGroup,
  Device,
  DeviceGroup,
  User,
  LastVisited,
  MarkdownDocument,
  Subscription,
  Product,
  Package,
  Release,
  DeviceApiGw,
  ShadowTemplate,
  Key,
  Trust,
} from '../../common/types';
import { actions } from '../actions/common';
import { LAST_VISITED_CACHE } from '../../common/constants';
import CommonInitialState from '../init/common';

const getId = (item: LastVisited): [string, string] | undefined => {
  if ({}.hasOwnProperty.call(item, 'deviceId')) return ['deviceId', (item as Device).deviceId];
  if ({}.hasOwnProperty.call(item, 'groupName')) return ['groupName', (item as UserGroup).groupName];
  if ({}.hasOwnProperty.call(item, 'userId')) return ['userId', (item as User).userId];
  if ({}.hasOwnProperty.call(item, 'subscriptionId')) return ['subscriptionId', (item as Subscription).subscriptionId];
  if ({}.hasOwnProperty.call(item, 'path')) return ['path', (item as MarkdownDocument).path];
  if ({}.hasOwnProperty.call(item, 'releaseId')) return ['releaseId', (item as Release).releaseId];
  if ({}.hasOwnProperty.call(item, 'packageId')) return ['packageId', (item as Package).packageId];
  if ({}.hasOwnProperty.call(item, 'productId')) return ['productId', (item as Product).productId];
  if ({}.hasOwnProperty.call(item, 'groupId') && !{}.hasOwnProperty.call(item, 'productId')) {
    return ['productId', (item as DeviceGroup).groupId];
  }
  if ({}.hasOwnProperty.call(item, 'apiId')) return ['productId', (item as DeviceApiGw).apiId];
  if ({}.hasOwnProperty.call(item, 'templateId')) return ['templateId', (item as ShadowTemplate).templateId];
  if ({}.hasOwnProperty.call(item, 'keyId')) return ['keyId', (item as Key).keyId];
  if ({}.hasOwnProperty.call(item, 'trustId')) return ['trustId', (item as Trust).trustId];

  console.error('No id found for', item);
  return undefined;
};

export default (state = CommonInitialState, { store, type, ...data }: CommonStoreAction) => {
  if (store !== StoreName.COMMON) return state;
  switch (type) {
    case actions.ADD_LAST_VISITED: {
      const idObject = getId(data.lastVisited!);
      if (!data.lastVisited || !data.tab || !idObject) return state;

      const [key, id] = idObject;

      if (state.lastVisited[data.tab]) {
        const index = state.lastVisited[data.tab].findIndex((l) => {
          const idTemp = getId(l);
          if (!idTemp) {
            return false;
          }
          const [tmpKey, tmpId] = idTemp;
          return tmpKey === key && tmpId === id;
        });

        if (index > -1) state.lastVisited[data.tab].splice(index, 1);
        const { lastVisited } = state;
        lastVisited[data.tab] = [data.lastVisited].concat(state.lastVisited[data.tab]);

        localStorage.setItem(LAST_VISITED_CACHE, JSON.stringify(lastVisited));
        return { ...state, lastVisited };
      }
      return { ...state, lastVisited: { ...state.lastVisited, [data.tab]: [data.lastVisited] } };
    }
    case actions.REMOVE_LAST_VISITED: {
      if (!data.id || !data.tab) return state;
      const { lastVisited } = state;
      lastVisited[data.tab] = state.lastVisited[data.tab].filter((item) => {
        const idRemove = getId(item);
        if (!idRemove) {
          return false;
        }
        return idRemove[1] !== data.id;
      });

      localStorage.setItem(LAST_VISITED_CACHE, JSON.stringify(lastVisited));
      return { ...state, lastVisited };
    }
    case actions.SET_MENU_TOGGLE_LIST: {
      if (!data.menuListToggle) return state;
      return { ...state, menuListToggle: data.menuListToggle };
    }
    case actions.SET_ACTIVE_TAB: {
      if (!data.activeTab) return state;
      if (!{}.hasOwnProperty.call(state.lastVisited, data.activeTab))
        return { ...state, activeTab: data.activeTab, lastVisisted: { ...state.lastVisited, [data.activeTab]: [] } };
      return { ...state, activeTab: data.activeTab };
    }
    case actions.SET_LEFT_NAV_STATE: {
      if (data.leftNavOpen === undefined) return state;
      return { ...state, leftNavOpen: data.leftNavOpen };
    }
    case actions.SET_RIGHT_NAV_STATE: {
      if (data.rightNavOpen === undefined) return state;
      return { ...state, rightNavOpen: data.rightNavOpen };
    }
    default: {
      return state;
    }
  }
};
