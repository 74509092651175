import React, { Component } from 'react';
import {
  CircularProgress,
  InputBase,
  InputAdornment,
  Popper,
  Fade,
  Paper,
  ClickAwayListener,
  ListItem,
  List,
  ListItemText,
  ListSubheader,
  Grid,
  Box,
  Typography,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DeviceService, UserService } from '../services';
import { Trans } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import i18next from 'i18next';
import { SearchOutlined } from '@mui/icons-material';
import { Device, DeviceGroup, User, SubdomainRoutePath } from '../common/types';
import { itemIcons } from '../common/constants';
import { Sound } from '../assets/sound';
import { AccessControl } from '../common/permissions';
import { getTheme } from '../common/theme';

type SearchItem = {
  match: { [key: string]: string };
};

type SearchDevice = Device & SearchItem;
type SearchDeviceGroup = DeviceGroup & SearchItem;
type SearchUser = User & SearchItem;

export type SearchFieldProps = WithStyles<any> & RouteComponentProps & { accessControl: AccessControl };

type SearchFieldState = {
  searchText: string;
  activeSearchText: string;
  searching: boolean;
  open: boolean;
  devices: SearchDevice[];
  deviceGroups: SearchDeviceGroup[];
  users: SearchUser[];
  goToIfFound: boolean;
};

const formatLabel = (label: any, value: any) => {
  if (!label) return null;
  if (!value) return label;
  label = label.length > 30 ? `${label.substring(0, 27)}...` : label;
  value = value.length > 30 ? `${value.substring(0, 27)}...` : value;
  if (label.toLowerCase().startsWith(value.toLowerCase())) {
    return (
      <span>
        <Box key={label} component="span" fontWeight="bold">
          {label.substr(0, value.length)}
        </Box>
        {label.substr(value.length)}
      </span>
    );
  }
  return label;
};

export type SearchListItemProps = WithStyles<any> & {
  device?: SearchDevice;
  deviceGroup?: SearchDeviceGroup;
  user?: SearchUser;
  searchText: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onClick?: () => void;
};

export const SearchListItem: React.FunctionComponent<SearchListItemProps> = ({
  classes,
  device,
  deviceGroup,
  user,
  searchText,
  onKeyDown,
  onClick,
}: SearchListItemProps) => {
  let title = '';
  let item!: SearchItem;
  let icon!: any;

  if (device) {
    title = device.deviceId;
    item = device as SearchItem;
    icon = itemIcons.device;
  } else if (deviceGroup) {
    title = deviceGroup.groupId;
    item = deviceGroup as SearchItem;
    icon = itemIcons.deviceGroup;
  } else if (user) {
    title = user.email;
    item = user as SearchItem;
    icon = itemIcons.user;
  }

  return (
    <ListItem dense button onKeyDown={onKeyDown || undefined} onClick={onClick || undefined}>
      <ListItemAvatar className={classes.avatarRoot}>
        <Avatar alt="user" className={classes.avatar}>
          {icon}
        </Avatar>
      </ListItemAvatar>
      <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Box display="flex">
            <Typography variant="body2">{formatLabel(title, searchText)}</Typography>
          </Box>
        </Grid>
        <Grid item>
          {Object.entries(item.match).map(([key, value], i: number) => (
            <Box key={i} display="flex">
              <Box fontSize={12} color="primary.light">
                {key}:
              </Box>
              <Box fontSize={12} pl={1} pr={1} color="secondary.dark" key={i}>
                {formatLabel(value, searchText)}
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </ListItem>
  );
};

const deviceExclude: string[] = ['deviceId'];
const deviceGroupExclude: string[] = ['groupId'];
const userExclude: string[] = ['email'];

const styles = () => {
  const theme = getTheme();
  return {
    root: {
      cursor: 'pointer',
      padding: '6px 2px 6px 10px',
      borderRadius: '22px',
      transition: 'background-color .5s',
      '&:hover, &:focus-within, &[data-open=true]': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    input: {
      color: theme.palette.primary.contrastText,
      width: 0,
      transition: 'width .5s',
      cursor: 'text',
      '&:focus, &[data-open=true]': {
        width: 150,
      },
    },
    icon: {
      color: theme.palette.primary.contrastText,
    },
    popper: {
      zIndex: 1,
    },
    paper: {
      width: 370,
      maxWidth: 'calc(100vw - 8px)',
      'overflow-x': 'hidden',
      'overflow-y': 'auto',
    },
    emptySearchText: {
      'text-align': 'center',
    },
    avatarRoot: {
      minWidth: 'auto' as const,
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      width: 34,
      height: 34,
      marginRight: theme.spacing(2),
      '& > svg': {
        fontSize: '1.2rem',
      },
    },
  };
};
class _SearchField extends Component<SearchFieldProps, SearchFieldState> {
  state: SearchFieldState = {
    searchText: '',
    activeSearchText: '',
    searching: false,
    open: false,
    devices: [],
    deviceGroups: [],
    users: [],
    goToIfFound: false,
  };
  deviceService!: DeviceService;
  userService!: UserService;
  timeout?: NodeJS.Timeout;
  inputRef?: HTMLElement;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.deviceService = await DeviceService.create();
    this.userService = await UserService.create();
  };

  componentWillUnmount = (): void => {
    this._isMounted = false;
  };

  clickAway = () => this.setState({ searchText: '', open: false, devices: [], deviceGroups: [] });

  // eslint-disable-next-line @typescript-eslint/ban-types
  parseSearchItem = <T extends {}>(list: T[], exclude: string[]): (T & SearchItem)[] => {
    return list.reduce((newList: (T & SearchItem)[], item: T) => {
      const entries = (Object.entries(item) as [[string, string]]).sort(
        (a, b) => ((a[1] && a[1].length) || 0) - ((b[1] && b[1].length) || 0),
      );
      const match: { [key: string]: string } = {};
      let matchStaticProp = false;

      const value = this.state.activeSearchText.replace(/\*/, '');

      for (let i = 0; i < entries.length; i++) {
        if (Object.entries(match).length === 1) break;
        if (exclude.includes(entries[i][0])) {
          if (typeof entries[i][1] === 'string' && entries[i][1].toLowerCase().startsWith(value.toLowerCase()))
            matchStaticProp = true;
          continue;
        }
        if (typeof entries[i][1] === 'string' && entries[i][1].toLowerCase().startsWith(value.toLowerCase())) {
          match[entries[i][0]] = entries[i][1];
        }
      }

      if (Object.keys(match).length > 0) {
        newList.push({ ...item, match });
      } else if (matchStaticProp) {
        const attr = entries.find((e) => !exclude.includes(e[0]) && typeof e[1] === 'string');
        if (attr) match[attr[0]] = attr[1];
        newList.push({ ...item, match });
      }
      return newList;
    }, []) as (T & SearchItem)[];
  };

  searchDevices = async (): Promise<SearchDevice[]> => {
    if (!this.props.accessControl.access('device').list()) return [];
    try {
      const {
        data: { data: devices },
      } = await this.deviceService.device.list({ params: { query: `${this.state.searchText}*`, limit: 5 } });
      return this.parseSearchItem<Device>(devices, deviceExclude) as SearchDevice[];
    } catch {
      return [];
    }
  };

  searchDeviceGroups = async (): Promise<SearchDeviceGroup[]> => {
    if (!this.props.accessControl.access('deviceGroup').list()) return [];
    try {
      const {
        data: { data: deviceGroups },
      } = await this.deviceService.deviceGroup.list({ params: { groupId: `${this.state.searchText}*`, limit: 5 } });
      return this.parseSearchItem<DeviceGroup>(deviceGroups, deviceGroupExclude) as SearchDeviceGroup[];
    } catch {
      return [];
    }
  };

  searchUsers = async (): Promise<SearchUser[]> => {
    if (!this.props.accessControl.access('user').list()) return [];
    try {
      const {
        data: { data: users },
      } = await this.userService.user.list({ params: { userEmail: `${this.state.searchText}*`, limit: 5 } });
      return this.parseSearchItem<User>(users, userExclude) as SearchUser[];
    } catch {
      return [];
    }
  };

  search = async (): Promise<void> => {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.state.searchText.length < 2) return this.setState({ open: true });
    this.setState({ activeSearchText: this.state.searchText, searching: true });

    const [devices, deviceGroups, users]: [SearchDevice[], SearchDeviceGroup[], SearchUser[]] = await Promise.all([
      this.searchDevices(),
      this.searchDeviceGroups(),
      this.searchUsers(),
    ]);
    if (this.state.goToIfFound) {
      switch (`${devices.length}-${deviceGroups.length}-${users.length}`) {
        case '1-0-0':
          Sound.success();
          this.goTo(SubdomainRoutePath.device(devices[0].deviceId));
          break;
        case '0-1-0':
          Sound.success();
          this.goTo(SubdomainRoutePath.deviceGroup(deviceGroups[0].groupId));
          break;
        case '0-0-1':
          Sound.success();
          this.goTo(SubdomainRoutePath.user(users[0].userId));
          break;
        default:
          Sound.error();
          break;
      }
    }
    if (this._isMounted)
      this.setState({ searching: false, goToIfFound: false, open: true, devices, deviceGroups, users });
  };

  goTo = (path: string): void => {
    this.props.history.push(path);
    this.clickAway();
  };

  render = (): React.ReactNode => {
    const { classes } = this.props;
    const { devices, deviceGroups, users } = this.state;
    return (
      <ClickAwayListener onClickAway={this.clickAway}>
        <div>
          <InputBase
            placeholder={i18next.t('form.placeholder.searchForItems')}
            classes={{
              root: classes.root,
              input: classes.input,
            }}
            value={this.state.searchText}
            ref={(ref: HTMLElement) => (this.inputRef = ref)}
            data-open={this.state.open}
            inputProps={{
              'data-open': this.state.open,
              'aria-label': 'search',
            }}
            onChange={(e) => {
              if (this.timeout) clearTimeout(this.timeout);
              if (e.target.value) this.timeout = setTimeout(this.search, 1000);
              if (this.state.searchText.length < 2 && e.target.value.length > 1) {
                const searchText = e.target.value;
                this.setState({ open: false }, () => this.setState({ searchText }));
              } else {
                this.setState({ searchText: e.target.value });
              }
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter' && this.state.searchText) this.setState({ goToIfFound: true });
              if (event.key === 'Escape') this.setState({ open: false });
            }}
            startAdornment={
              <InputAdornment disablePointerEvents position="start">
                {this.state.searching ? (
                  <CircularProgress className={classes.icon} aria-label="spinner" size={24} />
                ) : (
                  <SearchOutlined className={classes.icon} aria-label="search" />
                )}
              </InputAdornment>
            }
          />
          <Popper
            id="popper"
            open={this.state.open}
            anchorEl={this.inputRef}
            transition
            disablePortal
            placement="bottom-end"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
              },
              {
                name: 'preventOverflow',
                enabled: true,
              },
            ]}
            className={this.props.classes.popper}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={classes.paper}>
                  <List>
                    {devices.length && this.state.searchText.length > 1 ? (
                      <>
                        <ListSubheader disableSticky>
                          <Trans>devices</Trans>
                        </ListSubheader>
                        {devices.map((device: SearchDevice, i: number) => (
                          <SearchListItem
                            key={i}
                            classes={classes}
                            device={device}
                            onKeyDown={(event: React.KeyboardEvent) => {
                              if (event.key === 'Escape') this.setState({ open: false });
                            }}
                            searchText={this.state.activeSearchText}
                            onClick={() => this.goTo(SubdomainRoutePath.device(device.deviceId))}
                          />
                        ))}
                      </>
                    ) : null}
                    {deviceGroups.length && this.state.searchText.length > 1 ? (
                      <>
                        <ListSubheader disableSticky>
                          <Trans>deviceGroups</Trans>
                        </ListSubheader>
                        {deviceGroups.map((deviceGroup: SearchDeviceGroup, i: number) => (
                          <SearchListItem
                            key={i}
                            classes={classes}
                            deviceGroup={deviceGroup}
                            onKeyDown={(event: React.KeyboardEvent) => {
                              if (event.key === 'Escape') this.setState({ open: false });
                            }}
                            searchText={this.state.activeSearchText}
                            onClick={() => this.goTo(SubdomainRoutePath.deviceGroup(deviceGroup.groupId))}
                          />
                        ))}
                      </>
                    ) : null}
                    {users.length && this.state.searchText.length > 1 ? (
                      <>
                        <ListSubheader disableSticky>
                          <Trans>users</Trans>
                        </ListSubheader>
                        {users.map((user: SearchUser, i: number) => (
                          <SearchListItem
                            key={i}
                            classes={classes}
                            user={user}
                            onKeyDown={(event: React.KeyboardEvent) => {
                              if (event.key === 'Escape') this.setState({ open: false });
                            }}
                            searchText={this.state.activeSearchText}
                            onClick={() => this.goTo(SubdomainRoutePath.user(user.userId))}
                          />
                        ))}
                      </>
                    ) : null}
                    {!devices.length &&
                    !deviceGroups.length &&
                    !users.length &&
                    this.state.open &&
                    this.state.searchText.length > 1 ? (
                      <ListItem dense>
                        <ListItemText className={classes.emptySearchText}>
                          <Trans>form.empty.items</Trans>
                        </ListItemText>
                      </ListItem>
                    ) : null}
                    {this.state.open && this.state.searchText.length < 2 ? (
                      <ListItem dense>
                        <ListItemText className={classes.emptySearchText}>
                          <Trans>form.empty.minLength</Trans>
                        </ListItemText>
                      </ListItem>
                    ) : null}
                  </List>
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      </ClickAwayListener>
    );
  };
}

export const SearchField = withStyles(styles)(withRouter(_SearchField));
