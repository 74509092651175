const setValue = (data: { [key: string]: any }, keys: string[], value: any): any => {
  let returnData = data;
  if (keys.length === 0) {
    returnData = value;
  } else if (keys.length === 1) {
    returnData[keys[0]] = value;
  } else {
    returnData[keys[0]] = setValue(data[keys[0]] || {}, keys.slice(1), value);
  }
  return returnData;
};

const deepMergeObjects = (_obj1: { [key: string]: any }, _obj2: { [key: string]: any }): { [key: string]: any } => {
  const obj1 = JSON.parse(JSON.stringify(_obj1));
  const obj2 = JSON.parse(JSON.stringify(_obj2));
  Object.entries(obj2).forEach(([key, value]: [string, any]) => {
    if ({}.hasOwnProperty.call(obj1, key) && value !== null && typeof value === 'object' && !Array.isArray(value)) {
      obj1[key] = deepMergeObjects(obj1[key], value);
    } else {
      obj1[key] = value;
    }
  });
  return obj1;
};

export default { setValue, deepMergeObjects };
