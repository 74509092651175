import React, { Component } from 'react';
import { Paper, IconButton } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { CloseOutlined } from '@mui/icons-material';
import { store } from '../../store';
import { getTheme } from '../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    paper: {
      position: 'relative' as const,
      height: '100%',
      outline: 'none',
      backgroundColor: '#fff',
      padding: theme.spacing(4),
      'overflow-x': 'hidden',
      'overflow-y': 'auto',
      width: '100%',
    },
    img: {
      maxWidth: '100vh',
      maxHeight: '100vh',
      margin: 'auto',
      display: 'block' as const,
    },
  };
};

type Props = WithStyles<any> &
  WithSnackbarProps & {
    closeModal: () => void;
  };

export class FullScreenImageMC extends Component<Props> {
  store = store.getState();

  render = (): React.ReactNode => (
    <Paper tabIndex={-1} square className={this.props.classes.paper}>
      <IconButton
        style={{
          position: 'absolute' as const,
          top: getTheme().spacing(1),
          right: getTheme().spacing(1),
        }}
        onClick={this.props.closeModal}
        aria-label="close"
        size="large"
      >
        <CloseOutlined />
      </IconButton>
      <img
        className={this.props.classes.img}
        src={this.store.modalStore.data.url}
        alt="content visualization full screen"
      />
    </Paper>
  );
}

export default withStyles(styles)(withSnackbar(FullScreenImageMC));
