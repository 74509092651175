import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { DeviceService, FilterService } from '../../../services';
import {
  SubdomainRoutePath,
  ListTableProperty,
  ModalVariants,
  Store,
  ShadowAlias,
  PropertyType,
  ListFilterProperty,
  ListFilterOperator,
  Device,
  DeviceState,
} from '../../../common/types';
import { tableProperties, listFilter, itemIcons, TableNames } from '../../../common/constants';
import { storeOpenModal } from '../../../store/actions/modal';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';
import { SnackbarUtils } from '../../../components';

type _Props = {
  shadowAliases: ShadowAlias[];
  openModal: (callback: () => any) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class DeviceView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
  };

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.filterService = new FilterService(this, 'device');
    this.accessControlSection = 'device';
    this.title = i18next.t('devices');
    this.icon = itemIcons.device;
    this.idKey = 'deviceId';
    this.filterConfig = {
      // Merges standard list filters with shadow alias so they can be filtered on
      listFilter: listFilter.device.concat(
        this.props.shadowAliases.reduce((acc: ListFilterProperty[], val: ShadowAlias) => {
          if (val.isTimestamp) {
            acc.push({
              property: `alias.${val.alias}`,
              displayName: val.alias,
              type: 'date' as PropertyType,
              operators: ['between', 'betweenAbsolute'] as ListFilterOperator[],
            } as ListFilterProperty);
          } else {
            acc.push({
              property: `alias.${val.alias}`,
              displayName: val.alias,
              type: 'string' as PropertyType,
              operators: ['startsWith', 'equal'] as ListFilterOperator[],
            } as ListFilterProperty);
          }
          return acc;
        }, []) as any,
      ),
      availableProperties: Object.keys(tableProperties(TableNames.device)).concat(
        (this.props.shadowAliases || [])
          .filter((sa) => !['firmwareUpdated', 'firmwareVersion'].includes(sa.alias))
          .map((sa) => sa.alias),
      ),
    };
    this.tableConfig = {
      // Merges standard table props with shadow alias props so they can be shown in list
      tableProperties: {
        ...tableProperties(TableNames.device),
        ...(this.props.shadowAliases.reduce((acc: any, val: ShadowAlias) => {
          if (val.isTimestamp) {
            acc[val.alias] = { defaultActive: true, displayName: val.alias, displayType: 'dateTime' };
          } else {
            acc[val.alias] = { defaultActive: true, displayName: val.alias, displayType: 'text' };
          }
          return acc;
        }, {}) as { [key: string]: ListTableProperty }),
      },
      emptyTitle: 'form.empty.devices',
      rowActions: [
        {
          title: 'action.activate',
          dynamicDisabled: (device: Device) => ![DeviceState.activated, DeviceState.deactivated].includes(device.state),
          dynamicTitle: (device: Device) =>
            device.state === DeviceState.activated ? 'action.deactivate' : 'action.activate',
          action: async (device: Device) => await this.toggleBetweebActiveDeactive(device.deviceId),
        },
        {
          title: 'action.delete',
          action: async (device: Device) => this.setState({ dialogOpen: device.deviceId }),
        },
      ],
    };
    this.dialogConfig = {
      title: i18next.t('action.delete'),
      description: i18next.t('description.delete.device'),
      continueTitle: i18next.t('action.delete'),
      onClose: () => this.setState({ dialogOpen: undefined }),
      onContinue: () => this.state.dialogOpen && this.removeDevice(this.state.dialogOpen),
    };
    this.fabConfig = [
      {
        title: i18next.t('action.createDevice'),
        action: () => this.props.openModal(this.reloadList),
        icon: itemIcons.device,
        tryAccessRight: ['device', 'create'],
      },
    ];

    // Functions
    this.getUrlPathForItem = SubdomainRoutePath.device;
  }

  componentDidMount = async () => {
    this.service = await DeviceService.create();
    this.list = this.service!.device.list;

    const predefinedFilters = this.filterService!.get();
    const index = this.filterService!.getIndex();

    this.setState({ initLoaded: true, predefinedFilters, activeTabIndex: index });
  };

  toggleBetweebActiveDeactive = async (device: string): Promise<void> => {
    try {
      this.setState({ loading: true });
      let newItem = this.state.items.find((item) => item.deviceId === device);
      if (newItem && !this.isUnmounted) {
        newItem = {
          ...newItem,
          state: DeviceState.activated === newItem?.state ? DeviceState.deactivated : DeviceState.activated,
        };
        await this.service.device.put(device, { state: newItem.state });
        const items = this.state.items.map((d: Device) => {
          if (d.deviceId === device) {
            return newItem;
          }
          return d;
        });
        this.setState({ items });
        SnackbarUtils.success(i18next.t('success.update.device'));
        if (!this.isUnmounted) this.setState({ loading: false });
      } else {
        SnackbarUtils.error(i18next.t('error.tryAgain'));
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };

  removeDevice = async (device: string): Promise<void> => {
    try {
      await this.service.device.del(device);
      SnackbarUtils.success(i18next.t('success.delete.device'));
      if (!this.isUnmounted) {
        const items = this.state.items.filter((d: Device) => d.deviceId !== device);
        this.setState({ items });
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };
}

const mapStateToProps = ({ deviceStore }: Store) => ({
  shadowAliases: deviceStore.shadowAliases,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any) => dispatch(storeOpenModal(ModalVariants.AuthorizeDeviceMC, { callback })),
});

export default withStyles(ListBaseStyles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceView)));
