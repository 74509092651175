import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine';
import i18next from 'i18next';
import {
  RouteType,
  RouteParentType,
  Role,
  ShadowAlias,
  CustomerConfiguration,
  Store,
  SubdomainRoutePath,
} from '../common/types';
import { RoutesHierarchy } from '../common/routeUtils';
import { MarkdownDocsView } from '.';
import { AuthenticationServiceProvider, DeviceService } from '../services';
import { publicRoutes, subdomainRoutes } from '../common/constants';
import { storeSetShadowAliases } from '../store/actions/device';
import { storeSetCustomerConfiguration } from '../store/actions/user';
import { SnackbarUtils, Modal } from '../components';
import { CustomerService } from '../services/CustomerService';
import { AccessControl } from '../common/permissions';
import { getTheme, getWebsiteTitle } from '../common/theme';
import history from '../common/history';

type RouteList = (RouteType | RouteParentType)[];

type Props = {
  config?: CustomerConfiguration;
  setShadowAliases: (shadowAliases: ShadowAlias[]) => void;
  setCustomerConfiguration: (config: CustomerConfiguration) => void;
};

type State = {
  routes: RouteList;
  loading: boolean;
  role?: Role;
  theme: any;
};
class Routes extends Component<Props, State> {
  state: State = {
    routes: subdomainRoutes(),
    loading: true,
    role: undefined,
    theme: getTheme(),
  };
  rawRoutes?: { [key: string]: any }[];
  parsedRoutes?: RouteParentType[];
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    const [subdomain, hostname] = window.location.hostname.split('.');
    if ((subdomain && hostname) || subdomain === 'localhost') {
      const customerService = new CustomerService();
      try {
        /* iristest | heimdall | carron */
        const { data } = await customerService.get(subdomain === 'localhost' ? 'iristest' : subdomain);
        this.props.setCustomerConfiguration(data);
        this.setState({ theme: getTheme(data.themeName) });
        document.title = getWebsiteTitle(data.owner);
      } catch {
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  };

  componentDidUpdate = (prevProps: Props): void => {
    if (!prevProps.config && !!this.props.config) this.fetchRoutes();
  };

  componentWillUnmount = (): void => {
    this._isMounted = false;
    document.removeEventListener('pushstate', this.fetchRoutes);
  };

  setDocumentRoutes = (routes: RouteParentType[]) => {
    if (this._isMounted) this.setState({ routes: subdomainRoutes(routes) });
  };

  fetchRoutes = async (): Promise<void> => {
    let authenticationServiceProvider;

    if (this._isMounted) this.setState({ loading: true });

    // When loading page check if credentials exists then show credential required pages
    // Else go to signin page and set at listner for when changing page so that routes can be loaded when signed in
    try {
      authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    } catch {
      if (this._isMounted) {
        this.setState({ loading: false });
        // If config (subdomain) is found then listen for pushstate to reload routes when signed in
        if (this.props.config) document.addEventListener('pushstate', this.fetchRoutes);
      }
    }

    if (!authenticationServiceProvider) return;
    // Remove EventListener since its not needed anymore
    if (this.props.config) document.removeEventListener('pushstate', this.fetchRoutes);

    const role = authenticationServiceProvider.getRole();
    this.setState({ role });

    await this.fetchRawRoutes(authenticationServiceProvider).catch((e: any) => e);
    const deviceService = await DeviceService.create()!;

    const accessControl = new AccessControl(role);
    if (accessControl.access('shadowAlias').list()) {
      try {
        const { data } = await deviceService.shadowAlias.list();
        this.props.setShadowAliases(data.data);
      } catch (e) {
        const error: any = e;
        SnackbarUtils.error(
          (error.response && error.response.data && error.response.data.message) ||
            i18next.t('error.fetch.shadowAliases'),
        );
      }
    }

    if (this._isMounted) this.setState({ loading: false, routes: subdomainRoutes(this.parsedRoutes, role) });
  };

  sortRoutes = (list: string[]): string[] => {
    return list
      .sort()
      .map((path) => ({ path, depth: path.split('/').length }))
      .map((r) => r.path);
  };

  fetchRawRoutes = async (authenticationServiceProvider: AuthenticationServiceProvider): Promise<any> => {
    this.rawRoutes = await authenticationServiceProvider.s3ListObjects({
      params: { Bucket: this.props.config!.bucket, Prefix: 'docs/' },
    });
    if (!this.rawRoutes) throw new Error('Failed to get routes');

    this.rawRoutes = (this.rawRoutes as any)!.Contents;
    if (!this.rawRoutes) throw new Error('No content of fetched routes');

    this.parsedRoutes = this.parseRawRoutes(
      this.sortRoutes(this.rawRoutes.filter((route) => !route.Key.includes('images/')).map((route) => route.Key)),
    ) as RouteParentType[];
  };

  parseRawRoutes = (rawRoutes: string[]): RouteList => {
    return rawRoutes.reduce((routes: RouteList, routePath: string) => {
      return this.parseRoutePath(
        routePath,
        routes,
        routePath
          .replace(/docs\//g, '')
          .split('.')[0]
          .split('/'),
      );
    }, []) as RouteList;
  };

  parseRoutePath = (key: string, routes: RouteList, paths: string[]): RouteList => {
    if (paths.length === 1) {
      const name: string = paths[0].replace(/_/g, ' ').replace(/\d+-/g, '');
      const path = `/${key.replace(/.md/g, '')}`;
      return routes.concat([
        {
          name,
          path,
          roles: AccessControl.read('documentation'),
          render: (props: any) => <MarkdownDocsView document={key} path={path} name={name} {...props} />,
        },
      ]);
    }

    const index = routes
      .map((r) => r.path)
      .findIndex((p) => (Array.isArray(p) ? p.includes(paths[0]) : p === paths[0]));
    if (index > -1) {
      routes[index].routes = this.parseRoutePath(key, routes[index].routes || [], paths.slice(1));
    } else {
      routes = routes.concat([
        {
          name: paths[0].replace(/_/g, ' ').replace(/\d+-/g, ''),
          path: paths[0],
          roles: AccessControl.read('documentation'),
          routes: this.parseRoutePath(key, [], paths.slice(1)),
        },
      ]);
    }
    return routes;
  };

  renderLoading = (): React.ReactNode => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={this.state.theme}>
        <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );

  renderPrivateRoutes = (): React.ReactNode => {
    // Redirect if not authorized
    if (
      !AuthenticationServiceProvider.checkCache() &&
      !window.location.pathname.includes(SubdomainRoutePath.signIn())
    ) {
      AuthenticationServiceProvider.clear();
      const params = new URLSearchParams(window.location.search);
      params.set('path', window.location.pathname);
      history.push(SubdomainRoutePath.signInDeepLink(`?${params.toString()}${window.location.hash}`));
    }
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={this.state.theme}>
          <Router history={history}>
            <RoutesHierarchy role={this.state.role} routes={this.state.routes} />
          </Router>
          <Modal />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  };

  renderPublicRoutes = (): React.ReactNode => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={this.state.theme}>
        <Router history={history}>
          <RoutesHierarchy role={undefined} routes={publicRoutes()} />
        </Router>
        <Modal />
      </ThemeProvider>
    </StyledEngineProvider>
  );

  render = (): React.ReactNode => {
    // Loading routes
    if (this.state.loading || !this.state.routes.length) return this.renderLoading();

    // Found config (subdomain exists)
    if (this.props.config) return this.renderPrivateRoutes();

    // No config (subdomain not found or no subdomain provided)
    return this.renderPublicRoutes();
  };
}

const mapStateToProps = ({ userStore }: Store) => ({
  config: userStore.config,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setShadowAliases: (shadowAliases: ShadowAlias[]) => dispatch(storeSetShadowAliases(shadowAliases)),
  setCustomerConfiguration: (config: CustomerConfiguration) => dispatch(storeSetCustomerConfiguration(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
