import React from 'react';
import { connect } from 'react-redux';
import { Modal as MModal, Slide } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { Dispatch, Action } from 'redux';
import { Store, ModalVariants } from '../common/types';
import { storeCloseModal, storeOpenModal } from '../store/actions/modal';
import {
  AddDeviceGroupsToDeviceMC,
  AddDevicesToDeviceGroupMC,
  AddUserGroupsToUserMC,
  AddUsersToUserGroupMC,
  ApplyShadowTemplateMC,
  ChangePasswordMC,
  ConfirmFirmwarePackageMC,
  CreateDeviceApiGwMC,
  CreateTrustMC,
  CreateDeviceMC,
  AuthorizeDeviceMC,
  CreateDeviceGroupMC,
  CreateDeviceGroupCloneMC,
  CreateFirmwarePackageMC,
  CreateFirmwareProductMC,
  CreateFirmwareReleaseMC,
  CreateKeyMC,
  CreateShadowAliasMC,
  CreateShadowTemplateMC,
  CreateSubscriptionMC,
  CreateUserGroupMC,
  DialogMC,
  EditReleaseMappingMC,
  FullScreenImageMC,
  InviteUserMC,
  OpenSourceMC,
  PublishReleaseMC,
  UpdateEntryPackageMC,
  ChangeProductMC,
} from './caModal';

const VERSIONS: any = {
  [ModalVariants.AddDeviceGroupsToDeviceMC]: AddDeviceGroupsToDeviceMC,
  [ModalVariants.AddDevicesToDeviceGroupMC]: AddDevicesToDeviceGroupMC,
  [ModalVariants.AddUserGroupsToUserMC]: AddUserGroupsToUserMC,
  [ModalVariants.AddUsersToUserGroupMC]: AddUsersToUserGroupMC,
  [ModalVariants.ApplyShadowTemplateMC]: ApplyShadowTemplateMC,
  [ModalVariants.ChangePasswordMC]: ChangePasswordMC,
  [ModalVariants.ConfirmFirmwarePackageMC]: ConfirmFirmwarePackageMC,
  [ModalVariants.CreateDeviceApiGwMC]: CreateDeviceApiGwMC,
  [ModalVariants.CreateTrustMC]: CreateTrustMC,
  [ModalVariants.CreateDeviceMC]: CreateDeviceMC,
  [ModalVariants.AuthorizeDeviceMC]: AuthorizeDeviceMC,
  [ModalVariants.CreateDeviceGroupMC]: CreateDeviceGroupMC,
  [ModalVariants.CreateDeviceGroupCloneMC]: CreateDeviceGroupCloneMC,
  [ModalVariants.CreateFirmwarePackageMC]: CreateFirmwarePackageMC,
  [ModalVariants.CreateFirmwareProductMC]: CreateFirmwareProductMC,
  [ModalVariants.CreateFirmwareReleaseMC]: CreateFirmwareReleaseMC,
  [ModalVariants.CreateKeyMC]: CreateKeyMC,
  [ModalVariants.CreateShadowAliasMC]: CreateShadowAliasMC,
  [ModalVariants.CreateShadowTemplateMC]: CreateShadowTemplateMC,
  [ModalVariants.CreateSubscriptionMC]: CreateSubscriptionMC,
  [ModalVariants.CreateUserGroupMC]: CreateUserGroupMC,
  [ModalVariants.DialogMC]: DialogMC,
  [ModalVariants.EditReleaseMappingMC]: EditReleaseMappingMC,
  [ModalVariants.FullScreenImageMC]: FullScreenImageMC,
  [ModalVariants.InviteUserMC]: InviteUserMC,
  [ModalVariants.OpenSourceMC]: OpenSourceMC,
  [ModalVariants.PublishReleaseMC]: PublishReleaseMC,
  [ModalVariants.UpdateEntryPackageMC]: UpdateEntryPackageMC,
  [ModalVariants.ChangeProductMC]: ChangeProductMC,
};

const direction = (version: ModalVariants): 'left' | 'up' => {
  if ([ModalVariants.DialogMC, ModalVariants.FullScreenImageMC, ModalVariants.OpenSourceMC].includes(version as any))
    return 'up';
  return 'left';
};

const ModalStyles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  license: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
});

export type ModalProps = WithStyles<typeof ModalStyles> & {
  open: boolean;
  version?: ModalVariants;
  closeModal: () => void;
  openModal: (variant: ModalVariants, data: any) => void;
};

const _Modal: React.FunctionComponent<ModalProps> = ({ open, version, closeModal, openModal, classes }: ModalProps) => {
  if (!version || !{}.hasOwnProperty.call(VERSIONS, version)) return null;
  const Component = VERSIONS[version];
  return (
    <MModal
      open={open}
      onClose={closeModal}
      className={version === ModalVariants.OpenSourceMC ? classes.license : classes.root}
    >
      <Slide direction={direction(version)} in={open} mountOnEnter unmountOnExit>
        <div style={{ width: '100%' }}>
          <Component closeModal={closeModal} openModal={openModal} />
        </div>
      </Slide>
    </MModal>
  );
};

const mapStateToProps = ({ modalStore }: Store) => ({
  open: modalStore.open,
  version: modalStore.variant,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  closeModal: () => dispatch(storeCloseModal()),
  openModal: (variant: ModalVariants, data: any) => dispatch(storeOpenModal(variant, data)),
});

export const Modal = withStyles(ModalStyles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(_Modal),
);
