import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import { DataService, getDefaultTableProperties } from '../../../services';
import { SubdomainRoutePath, ModalVariants, Subscription } from '../../../common/types';
import { storeOpenModal } from '../../../store/actions/modal';
import { tableProperties, itemIcons, TableNames } from '../../../common/constants';
import { SnackbarUtils } from '../../../components';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';

type _Props = {
  openModal: (callback: () => any) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class SubscriptionView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
  };

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.accessControlSection = 'subscriptions';
    this.title = i18next.t('subscriptions');
    this.icon = itemIcons.subscription;
    this.idKey = 'subscriptionId';
    this.tableConfig = {
      tableProperties: getDefaultTableProperties(tableProperties(TableNames.subscription)),
      emptyTitle: 'form.empty.subscriptions',
      rowActions: [
        {
          title: '',
          dynamicTitle: (subscription: Subscription) => (subscription.enabled ? 'action.disable' : 'action.enable'),
          action: async (subscription: Subscription) =>
            await this.toggleEnabledSubscription(subscription.subscriptionId),
        },
        {
          title: 'action.delete',
          action: async (subscription: Subscription) => this.setState({ dialogOpen: subscription.subscriptionId }),
        },
      ],
    };
    this.dialogConfig = {
      title: i18next.t('action.delete'),
      description: i18next.t('description.delete.subscription'),
      continueTitle: i18next.t('action.delete'),
      onClose: () => this.setState({ dialogOpen: undefined }),
      onContinue: () => this.state.dialogOpen && this.removeSubscription(this.state.dialogOpen),
    };
    this.fabConfig = [
      {
        title: `${i18next.t('action.createSubscription')}`,
        action: () => this.props.openModal(this.reloadList),
        icon: itemIcons.subscription,
        tryAccessRight: ['subscription', 'create'],
      },
    ];

    // Functions
    this.getUrlPathForItem = SubdomainRoutePath.subscription;
  }

  componentDidMount = async () => {
    this.service = await DataService.create();
    this.list = this.service!.subscription.list;
    this.setState({ initLoaded: true });
  };

  toggleEnabledSubscription = async (subscription: string): Promise<void> => {
    try {
      this.setState({ loading: true });
      const itemToEdit = this.state.items.find((item) => item.subscriptionId === subscription);
      if (itemToEdit && !this.isUnmounted) {
        await this.service.subscription.put(subscription, { enabled: !itemToEdit?.enabled });
        this.state.items = [];
        await this.loadData();
        SnackbarUtils.success(i18next.t('success.update.subscription'));
        if (!this.isUnmounted) this.setState({ loading: false });
      } else {
        SnackbarUtils.error(i18next.t('error.tryAgain'));
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };

  removeSubscription = async (subscription: string): Promise<void> => {
    try {
      await this.service.subscription.del(subscription);
      SnackbarUtils.success(i18next.t('success.delete.subscription'));
      if (!this.isUnmounted) {
        const items = this.state.items.filter((s: Subscription) => s.subscriptionId !== subscription);
        this.setState({ items });
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any) => dispatch(storeOpenModal(ModalVariants.CreateSubscriptionMC, { callback })),
});

export default withStyles(ListBaseStyles)(withRouter(connect(null, mapDispatchToProps)(SubscriptionView)));
