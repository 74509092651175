import AWS from 'aws-sdk';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { PromiseResult } from 'aws-sdk/lib/request';
import { IrisUser, SubdomainRoutePath, CustomerConfiguration } from '../../common/types';
import { store } from '../../store';
import {
  _Authentication,
  parseIrisUserData,
  ServiceType,
  CognitoSessionChallange,
  SessionOrChallange,
  MFA_SETUP,
  SecurityCallback,
} from './authenticationUtils';
import history from '../../common/history';
import { NodeCallback } from 'amazon-cognito-identity-js';
export class CognitoServiceProvider implements _Authentication {
  region: string;
  userPool: AmazonCognitoIdentity.CognitoUserPool;
  cognitoUser?: AmazonCognitoIdentity.CognitoUser;
  userSession?: AmazonCognitoIdentity.CognitoUserSession;
  credentials?: AWS.Credentials;
  iris?: any;
  config?: CustomerConfiguration;

  static instance?: CognitoServiceProvider;

  constructor(private userPoolId: string = '', private clientId: string = '') {
    const {
      userStore: { config },
    } = store.getState();
    if (!config) throw new Error('Subdomain does not exist');
    this.config = config;
    if (!this.userPoolId) this.userPoolId = config.userPoolId;
    if (!this.clientId) this.clientId = config.clientId;

    if (!this.userPoolId) throw new Error('Missing required parameter userPoolId');
    if (!this.clientId) throw new Error('Missing required parameter clientId');

    this.region = this.userPoolId.split('_')[0];
    AWS.config.region = this.region;
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId: this.userPoolId, ClientId: this.clientId });
  }

  static checkCache(): boolean {
    if (CognitoServiceProvider.instance) return true;
    try {
      return !!new CognitoServiceProvider().userPool.getCurrentUser();
    } catch {
      return false;
    }
  }

  static clear = (): void => {
    if (CognitoServiceProvider.instance && CognitoServiceProvider.instance.cognitoUser)
      CognitoServiceProvider.instance.cognitoUser.signOut();
    CognitoServiceProvider.instance = undefined;
  };

  /**
   * Create CognitoService from singleton or localStorage
   */
  static async createFromCache(calledFromSelf = false): Promise<ServiceType | undefined> {
    if (CognitoServiceProvider.instance) {
      return CognitoServiceProvider.instance;
    }

    const cognitoService = new CognitoServiceProvider();
    cognitoService.cognitoUser = cognitoService.userPool.getCurrentUser() || undefined;
    if (!cognitoService.cognitoUser) {
      if (calledFromSelf) throw new Error('No cache available');
      return new Promise((resolve) => setTimeout(() => resolve(CognitoServiceProvider.createFromCache(true)), 10));
    }

    CognitoServiceProvider.instance = await new Promise((resolve, reject) => {
      cognitoService.cognitoUser!.getSession(
        async (err: AWS.AWSError | null, session: AmazonCognitoIdentity.CognitoUserSession) => {
          if (err) reject(err);
          cognitoService.userSession = session;
          try {
            await cognitoService.getAwsCredentials();
            await cognitoService.getIAMUserData();
            resolve(cognitoService);
          } catch (e) {
            console.error('Cannot getAwsCredentials or getIAMUserData');
            if (cognitoService.cognitoUser) {
              cognitoService.cognitoUser.signOut();
            }
            CognitoServiceProvider.clear();
            reject(e);
          }
        },
      );
    });
    return CognitoServiceProvider.instance;
  }

  /**
   * Refreshes session if possible
   */
  private getIAMUserData = async (customRoleArn?: string): Promise<IrisUser | undefined> => {
    if (!this.userSession) throw new Error('Cognito user session not defined');
    const roleArn: string = customRoleArn || this.userSession.getIdToken().payload['cognito:preferred_role'];
    const irisRoleName = roleArn.split('/')[1]; // [irisRolePath, irisRoleName]
    const {
      $response: { data, error },
    }: PromiseResult<AWS.IAM.GetRoleResponse, AWS.AWSError> = await new AWS.IAM()
      .getRole({
        RoleName: irisRoleName,
      })
      .promise();

    if (error) throw error;
    if (!data) throw new Error('Missing User');
    if (!data.Role) throw new Error('Missing Role');
    if (!data.Role.Arn) throw new Error('Missing Arn');

    const { Description: description = '', ...other } = data.Role;
    this.iris = { ...other, ...parseIrisUserData(description, data.Role.Arn) };
    return this.iris;
  };

  /**
   * Will request AWS credentials to be used when signing requests
   */
  private getAwsCredentials = async (roleArn?: string): Promise<AWS.Credentials> => {
    if (!this.userSession) throw new Error('Missing Cognito User Session');

    const idToken = this.userSession.getIdToken().getJwtToken() as AWS.CognitoIdentity.IdentityProviderToken;
    const CustomRoleArn: string = roleArn || this.userSession.getIdToken().payload['cognito:preferred_role'];
    const iss: string = this.userSession.getIdToken().payload.iss;
    const AccountId: string | undefined = CustomRoleArn ? CustomRoleArn.split('/')[0].split(':')[4] : undefined;
    const Logins: AWS.CognitoIdentity.LoginsMap = { [iss.replace('https://', '')]: idToken };

    if (!CustomRoleArn) throw new Error('No role assigned to user, contact you administator');
    if (!this.config) throw new Error('Subdomain does not exist');

    const IdentityPoolId: string = this.config.identityPoolId;

    if (!IdentityPoolId) throw new Error('Identity Pool Id is not defiend');

    // @ts-ignore
    const cognitoidentity = new AWS.CognitoIdentity({ region: this.region });
    const { IdentityId } = await cognitoidentity.getId({ IdentityPoolId, AccountId, Logins }).promise();
    const { Credentials } = await cognitoidentity
      .getCredentialsForIdentity({
        IdentityId,
        CustomRoleArn,
        Logins,
      } as AWS.CognitoIdentity.Types.GetCredentialsForIdentityInput)
      .promise();

    if (!Credentials || !Credentials.AccessKeyId || !Credentials.SecretKey || !Credentials.SessionToken)
      throw new Error('AWS credentials invalid');

    this.credentials = new AWS.Credentials(Credentials.AccessKeyId, Credentials.SecretKey, Credentials.SessionToken);
    AWS.config.credentials = this.credentials;

    return this.credentials!;
  };

  /**
   * Get user id
   */
  userId = (): string | undefined =>
    (!!this.userSession &&
      // @ts-ignore
      !!this.userSession!.idToken &&
      // @ts-ignore
      !!this.userSession!.idToken!.payload &&
      // @ts-ignore
      this.userSession!.idToken!.payload!.sub) ||
    undefined;

  /**
   * Refresh session if possible else return to sign in
   */
  refresh = async (): Promise<void> => {
    try {
      if (!this.userSession) throw new Error('Missing Cognito User session');
      this.userSession = await new Promise((resolve, reject) => {
        if (!this.cognitoUser) throw new Error('Missing Cognito User');
        this.cognitoUser.refreshSession(this.userSession!.getRefreshToken(), (error, session) =>
          error ? reject(error) : resolve(session),
        );
      });
      await this.getAwsCredentials();
      CognitoServiceProvider.instance = this;
      return;
    } catch (e) {
      const error: any = e;
      if (!error.code || (!!error.code && error.code !== 'NetworkError')) {
        if (this.cognitoUser) {
          this.cognitoUser.signOut();
        }
        CognitoServiceProvider.clear();
        const params = new URLSearchParams(window.location.search);
        params.set('path', window.location.pathname);
        history.push(SubdomainRoutePath.signInDeepLink(`?${params.toString()}${window.location.hash}`));
      }
      throw e;
    }
  };

  setUpMfa = async (
    stage: MFA_SETUP,
    callback: SecurityCallback,
    validationCode?: string,
    username?: string,
    password?: string,
  ) => {
    const basics = {
      onSuccess: async () => {
        callback(stage, true);
      },
      onFailure: (e: any) => {
        console.error(e);
        callback(stage, false);
      },
    };
    const settings = {
      ...basics,
      associateSecretCode: async (secretCode: string) => {
        callback(stage, true, secretCode);
      },
    };
    switch (stage) {
      case MFA_SETUP.CONFIRM: {
        if (this.cognitoUser && username && password && validationCode) {
          const authDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: username,
            Password: password,
          });
          this.cognitoUser.authenticateUser(authDetails, {
            ...settings,
            totpRequired: () => {
              if (validationCode) {
                this.cognitoUser!.sendMFACode(validationCode, settings, 'SOFTWARE_TOKEN_MFA');
              } else {
                callback(stage, false);
                return;
              }
            },
          });
        } else {
          console.log('Missing credentials');
          callback(stage, false);
        }
        return;
      }
      case MFA_SETUP.QR: {
        if (this.cognitoUser) {
          this.cognitoUser.associateSoftwareToken(settings);
        } else {
          console.log('Missing cognito user');
          callback(stage, false);
        }
        return;
      }
      case MFA_SETUP.VALIDATE: {
        if (validationCode) {
          const un = await this.username();
          this.cognitoUser!.verifySoftwareToken(validationCode, un, basics);
        } else {
          console.log('Missing validation code');
          callback(stage, false);
        }
        return;
      }
      case MFA_SETUP.ENABLE: {
        const totpMfaSettings = {
          PreferredMfa: true,
          Enabled: true,
        };
        if (this.cognitoUser) {
          this.cognitoUser.setUserMfaPreference(null, totpMfaSettings, (err) => {
            if (err) {
              console.error(err);
              callback(stage, false);
            } else {
              callback(stage, true);
            }
          });
        } else {
          console.log('Missing cognito user');
          callback(stage, false);
        }
        return;
      }
      case MFA_SETUP.DISABLE: {
        const totpMfaSettings = {
          PreferredMfa: false,
          Enabled: false,
        };
        if (this.cognitoUser) {
          this.cognitoUser.setUserMfaPreference(null, totpMfaSettings, (err) => {
            if (err) {
              console.error(err);
              callback(stage, false);
            } else {
              callback(stage, true);
            }
          });
        } else {
          console.log('Missing cognito user');
          callback(stage, false);
        }
        return;
      }
      default: {
        return;
      }
    }
  };

  /**
   * Authenticate user with given credentials
   */
  authenticate = async (username: string, password: string, mfaCode?: string): Promise<SessionOrChallange> => {
    this.cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: username,
      Pool: this.userPool,
    });

    const authDetails = new AmazonCognitoIdentity.AuthenticationDetails({ Username: username, Password: password });
    const sessionOrChallange: SessionOrChallange = await new Promise<SessionOrChallange>((resolve, reject): void => {
      if (!this.cognitoUser) {
        throw new Error('Missing Cognito User');
      }
      const basics = {
        onSuccess: resolve,
        onFailure: (e: any) => {
          if ({}.hasOwnProperty.call(e, 'code') && (e as any).code === 'PasswordResetRequiredException') {
            return resolve(CognitoSessionChallange.RESET_REQUIRE);
          }
          reject(e);
        },
      };
      const settings = {
        ...basics,
        newPasswordRequired: () => resolve(CognitoSessionChallange.CHANGE_PASSWORD),
      };
      this.cognitoUser.authenticateUser(authDetails, {
        ...settings,
        totpRequired: () => {
          if (mfaCode) {
            this.cognitoUser!.sendMFACode(mfaCode, settings, 'SOFTWARE_TOKEN_MFA');
          } else {
            return resolve(CognitoSessionChallange.MFA_REQUIRE);
          }
        },
      });
    });

    if (sessionOrChallange instanceof AmazonCognitoIdentity.CognitoUserSession) {
      this.userSession = sessionOrChallange;
      await this.getAwsCredentials();
      await this.getIAMUserData();
    }

    CognitoServiceProvider.instance = this;

    return sessionOrChallange;
  };

  /**
   * Signing in for the first time will trigger a request for new password,
   * this is the method to be called with new password after authenticate
   */
  newPasswordChallange = async (password: string): Promise<AmazonCognitoIdentity.CognitoUserSession> => {
    this.userSession = await new Promise<AmazonCognitoIdentity.CognitoUserSession>((resolve, reject): void => {
      if (!this.cognitoUser) throw new Error('Missing Cognito User');
      this.cognitoUser.completeNewPasswordChallenge(password, {}, { onSuccess: resolve, onFailure: reject });
    });

    await this.getAwsCredentials();
    await this.getIAMUserData();

    return this.userSession;
  };

  /**
   * Sends SMS or email to user with a verification code later used in verifyForgotPassword
   */
  forgotPassword = async (email: string): Promise<void> => {
    this.cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: this.userPool,
    });
    if (!this.cognitoUser) throw new Error('Missing cognito user');
    return new Promise((resolve, reject) =>
      this.cognitoUser!.forgotPassword({ onSuccess: resolve, onFailure: reject }),
    );
  };

  /**
   * Uses verification code sent from forgot password to change password
   */
  verifyForgotPassword = async (password: string, verificationCode: string): Promise<void> => {
    if (!this.cognitoUser) throw new Error('Missing cognito user');
    return new Promise((resolve, reject) =>
      this.cognitoUser!.confirmPassword(verificationCode, password, {
        onSuccess: resolve,
        onFailure: reject,
      }),
    );
  };

  /**
   * Change password
   */
  changePassword = async (
    oldPassword: string,
    newPassword: string,
    callback: NodeCallback<Error, 'SUCCESS'>,
  ): Promise<void> => {
    if (!this.cognitoUser) throw new Error('Missing cognito user');
    this.cognitoUser!.changePassword(oldPassword, newPassword, callback);
  };

  /**
   * Get username
   */
  username = async (): Promise<string> => {
    try {
      const userData: any = await new Promise((resolve) => {
        this.cognitoUser!.getUserAttributes((err, result) => {
          if (err) resolve(err);
          else resolve(result);
        });
      });
      const emailObj = userData!.find((attr: any) => attr.Name === 'email');
      if (emailObj) return emailObj.Value;
      return 'undefined';
    } catch (e) {
      return 'undefined';
    }
  };
}
