import axios, { AxiosResponse } from 'axios';
import { APIPath, CustomerConfiguration } from '../common/types';

export const URL = (() => {
  if (window.location.hostname.includes('localhost')) return 'https://team-api-v2.dev.iris.incubation.io';
  return `https://${process.env.REACT_APP_MILIEU}-api-v2${window.location.hostname.split('webapp')[1]}`;
})();

export class CustomerService {
  get = (id: string): Promise<AxiosResponse<CustomerConfiguration>> => {
    return axios.request<CustomerConfiguration>({
      method: 'get',
      url: `${URL}${APIPath.customer.configuration(id)}`,
    });
  };
}
