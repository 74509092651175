import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { FirmwareService } from '../../../../services';
import { Dialog, TabsHeader, If } from '../../../../components';
import { withRouter } from 'react-router-dom';
import { PropertiesTab } from '../../../baseClasses/PropertiesTab';
import ReleaseTab from './ReleaseTab';
import PackageTab from './PackageTab';
import DevicesTab from './DevicesTab';
import { Trans } from 'react-i18next';
import { ProductDetailed, Release, ModalVariants } from '../../../../common/types';
import { itemIcons } from '../../../../common/constants';
import { Action, Dispatch } from 'redux';
import { storeOpenModal } from '../../../../store/actions/modal';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../../baseClasses/ViewBase';
import i18next from 'i18next';
import firmwareSchema from '../../../../assets/schemas/firmware.schema.parsed.json';

type _Props = {
  openModal: (productId: string, callback: () => void) => () => void;
  createRelease: (product: ProductDetailed, callback: () => void) => void;
  uploadPackage: (product: ProductDetailed, callback: () => void, refreshRelease: () => void) => void;
  openPublishRelease: (release: Release, callback: () => void) => void;
};
type Props = ViewBaseProps<_Props, { productId: string }>;

type _State = {
  speedDialOpen: boolean;
  reloadReleases: number;
  reloadPackages: number;
};
type State = ViewBaseState<_State>;

class FirmwareProductDetailedView extends ViewBase<Props, State, { productId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
    speedDialOpen: false,
    reloadReleases: 0,
    reloadPackages: 0,
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.releases', 'tab.packages', 'tab.devices', 'tab.properties'];
    FirmwareService.create().then((service) => (this.service = service));
    this.subservice = 'product';
    this.messages = {
      load: { error: 'error.fetch.product' },
      update: { success: 'success.update.product', error: 'error.tryAgain' },
      delete: { success: 'success.delete.product', error: 'error.tryAgain' },
    };
  }

  actions: { icon: React.ReactNode; name: string; onClick: () => void }[] = [
    {
      icon: itemIcons.firmwarePackage,
      name: i18next.t('action.uploadPackage'),
      onClick: () =>
        this.props.uploadPackage(
          this.state.item!,
          () => this.setState({ reloadPackages: this.state.reloadPackages + 1 }),
          () => this.setState({ reloadReleases: this.state.reloadReleases + 1 }),
        ),
    },
    {
      icon: itemIcons.firmwareRelease,
      name: i18next.t('action.createRelease'),
      onClick: () =>
        this.props.createRelease(this.state.item!, () =>
          this.setState({ reloadReleases: this.state.reloadReleases + 1 }),
        ),
    },
  ];

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('product', 'delete'))
      list.push(
        <MenuItem key="1" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <ReleaseTab
        tab={0}
        activeTab={this.state.activeTab}
        parentLoading={this.state.loading}
        accessControl={this.accessControl}
        incrementToReload={this.state.reloadReleases}
      />
      <PackageTab
        tab={1}
        activeTab={this.state.activeTab}
        parentLoading={this.state.loading}
        accessControl={this.accessControl}
        incrementToReload={this.state.reloadPackages}
      />
      <DevicesTab
        tab={2}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
      />
      <PropertiesTab
        tab={3}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="product"
        parentLoading={this.state.loading}
        schema={firmwareSchema}
        schemaKey="product"
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <Dialog
      open={this.state.deleteDialogOpen}
      title={<Trans>action.delete</Trans>}
      description={<Trans>description.delete.product</Trans>}
      continueTitle={<Trans>action.delete</Trans>}
      onClose={() => this.setState({ deleteDialogOpen: false })}
      onContinue={this.delete}
    />
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={this.props.match.params.productId}
        icon={itemIcons.firmwareProduct}
        subtitle={i18next.t('form.productId')}
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
        <If statement={this.accessControl('release', 'create') || this.accessControl('package', 'create')}>
          <div className={this.props.classes.fabContainer}>
            <SpeedDial
              ariaLabel="Product actions"
              className={this.props.classes.fab}
              FabProps={{
                color: 'secondary',
              }}
              icon={<SpeedDialIcon />}
              hidden={![0, 1].includes(this.state.activeTab)}
              onClose={() => this.setState({ speedDialOpen: false })}
              onOpen={() => this.setState({ speedDialOpen: true })}
              open={this.state.speedDialOpen}
              direction="up"
            >
              {this.actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => {
                    action.onClick();
                    this.setState({ speedDialOpen: false });
                  }}
                />
              ))}
            </SpeedDial>
          </div>
        </If>
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  createRelease: (product: ProductDetailed, callback: () => void) =>
    dispatch(storeOpenModal(ModalVariants.CreateFirmwareReleaseMC, { product, callback })),
  uploadPackage: (product: ProductDetailed, callback: () => void, refreshRelease: () => void) =>
    dispatch(storeOpenModal(ModalVariants.CreateFirmwarePackageMC, { product, callback, refreshRelease })),
  openPublishRelease: (release: Release, callback: () => void) =>
    dispatch(storeOpenModal(ModalVariants.PublishReleaseMC, { release, callback })),
  ...ViewBaseMapDispatchToProps(dispatch),
});

export default withStyles(ViewBaseStyles)(withRouter(connect(null, mapDispatchToProps)(FirmwareProductDetailedView)));
