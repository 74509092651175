/* eslint-disable react/prop-types */
import React from 'react';
import {
  TableCell as MTableCell,
  TableRow as MTableRow,
  TableBody as MTabTableBody,
  TableHead as MTableHead,
  Table as MTable,
  Paper,
  CircularProgress,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { TableCellProps as MTableCellProps } from '@mui/material/TableCell';
import { TableRowProps as MTableRowProps } from '@mui/material/TableRow/TableRow';
import { TableBodyProps as MTableBodyProps } from 'material-ui';
import { TableHeadProps as MTableHeadProps } from '@mui/material/TableHead';
import { TableProps as MTableProps } from '@mui/material/Table';
import { MoreVert } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { appBarHeight } from '../common/constants';
import { ListTableAction } from '../common/types';
import { getTheme } from '../common/theme';

/** TableCellActions **/
const TableCellActionsStyles = () => ({
  iconButton: {
    marginTop: -5,
    marginBottom: -5,
  },
});

export type TableCellActionsProps = WithStyles<typeof TableCellActionsStyles> & {
  item: any;
  actions: ListTableAction[];
  filterActions?: boolean;
};

type TableCellActionsState = {
  open: boolean;
  loading: boolean;
};

class _TableCellActions extends React.Component<TableCellActionsProps, TableCellActionsState> {
  state: TableCellActionsState = {
    open: false,
    loading: false,
  };
  ref: React.RefObject<HTMLButtonElement>;
  _isMounted = true;

  constructor(props: TableCellActionsProps) {
    super(props);
    this.ref = React.createRef();
  }

  componentWillUnmount = (): void => {
    this._isMounted = false;
  };

  render = (): React.ReactNode => (
    <TableCell align="right">
      <IconButton
        size="small"
        aria-label="more"
        aria-haspopup="true"
        ref={this.ref}
        className={this.props.classes.iconButton}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.stopPropagation();
          if (this.state.loading) return;
          else if (this._isMounted) {
            this.setState({ open: true });
          }
        }}
      >
        {this.state.loading ? <CircularProgress size={24} /> : <MoreVert />}
      </IconButton>
      <Menu
        anchorEl={this.ref.current}
        open={this.state.open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => {
          if (this._isMounted) {
            this.setState({ open: false });
          }
        }}
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation()}
      >
        {(this.props.filterActions
          ? this.props.actions.filter((f) => !f.filterAction || f.filterAction(this.props.item))
          : this.props.actions
        ).map((action) => (
          <MenuItem
            key={`action-menu-item-${action.title}`}
            disabled={action.dynamicDisabled ? action.dynamicDisabled(this.props.item) : action.disabled}
            onClick={async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
              if (this._isMounted) {
                event.stopPropagation();
                this.setState({ open: false, loading: true });
                const possiblePromise = action.action(this.props.item) as any;
                if (possiblePromise && possiblePromise.then && typeof possiblePromise.then === 'function')
                  await possiblePromise;
                if (this._isMounted) {
                  this.setState({ loading: false });
                }
              }
            }}
          >
            <Trans>{action.dynamicTitle ? action.dynamicTitle(this.props.item) : action.title}</Trans>
          </MenuItem>
        ))}
      </Menu>
    </TableCell>
  );
}

export const TableCellActions = withStyles(TableCellActionsStyles)(_TableCellActions);

/** TableCell**/
const TableCellStyles = () => {
  const theme = getTheme();
  return {
    tableCell: {
      height: 55,
    },
    clickable: {
      color: theme.palette.secondary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    noBorder: {
      borderBottom: 'none' as const,
    },
  };
};

export type TableCellProps = WithStyles<typeof TableCellStyles> &
  MTableCellProps & {
    clickable?: boolean;
    noBorder?: boolean;
    disableForceHeight?: boolean;
  };

const _TableCell: React.FunctionComponent<TableCellProps> = ({
  children,
  classes,
  noBorder,
  disableForceHeight,
  clickable,
  ...props
}: TableCellProps) => (
  <MTableCell
    {...props}
    className={clsx({
      [classes.tableCell]: !disableForceHeight,
      [classes.clickable]: !!clickable,
      [classes.noBorder]: !!noBorder,
    })}
  >
    {children}
  </MTableCell>
);

export const TableCell = withStyles(TableCellStyles)(_TableCell);

/** TableRowLoader **/
const TableRowLoaderStyles = () => {
  const theme = getTheme();
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: appBarHeight,
      margin: theme.spacing(2, 0),
    },
  };
};

export type TableRowLoaderProps = WithStyles<typeof TableRowLoaderStyles>;

const _TableRowLoader: React.FunctionComponent<TableRowLoaderProps> = (props: TableRowLoaderProps) => (
  <Box className={props.classes.root}>
    <CircularProgress color="primary" />
  </Box>
);

export const TableRowLoader = withStyles(TableRowLoaderStyles)(_TableRowLoader);

/** TableRow**/
const TableRowStyles = () => ({
  tableRowHover: {
    '&:not(.MuiTableRow-head)': {
      cursor: 'pointer' as const,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, .02)',
      },
    },
  },
});

export type TableRowProps = WithStyles<typeof TableRowStyles> &
  MTableRowProps & {
    disableRowHover?: boolean;
  };

const _TableRow: React.FunctionComponent<TableRowProps> = ({
  children,
  classes,
  disableRowHover,
  ...props
}: TableRowProps) => (
  <MTableRow className={clsx({ [classes.tableRowHover]: !disableRowHover })} {...props}>
    {children}
  </MTableRow>
);

export const TableRow = withStyles(TableRowStyles)(_TableRow);

/** TableBody **/
export type TableBodyProps = { children: any } & MTableBodyProps;

export const TableBody: React.FunctionComponent<TableBodyProps> = ({ children, ...props }: TableBodyProps) => (
  <MTabTableBody {...props}>{children}</MTabTableBody>
);

/** TableHead **/
export type TableHeadProps = MTableHeadProps;

export const TableHead: React.FunctionComponent<TableHeadProps> = ({ children, ...props }: TableHeadProps) => (
  <MTableHead {...props}>{children}</MTableHead>
);

/** Table **/
const TableStyles = () => ({
  container: {
    'overflow-x': 'auto' as const,
  },
  root: {
    borderSpacing: '0 0',
  },
});

export type TableProps = MTableProps &
  WithStyles<typeof TableStyles> & {
    disableBackgroundPaper?: boolean;
  };

const _Table: React.FunctionComponent<TableProps> = ({ children, classes, disableBackgroundPaper, ...props }) =>
  disableBackgroundPaper ? (
    <div className={classes.container}>
      <MTable className={classes.root} {...props}>
        {children}
      </MTable>
    </div>
  ) : (
    <Paper className={classes.container} elevation={2}>
      <MTable className={classes.root} {...props}>
        {children}
      </MTable>
    </Paper>
  );

export const Table = withStyles(TableStyles)(_Table);
