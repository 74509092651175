import React from 'react';
import { connect } from 'react-redux';
import { Grid, ButtonGroup, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import Ajv from 'ajv';
import RefParser from 'json-schema-ref-parser';
import i18next from 'i18next';
import { PageLoader, SchemaFormShadow, TabPanel, SnackbarUtils } from '../../../../components';
import { DeviceService } from '../../../../services';
import { storeOpenModal } from '../../../../store/actions/modal';
import { ModalVariants, Store, CustomerConfiguration } from '../../../../common/types';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { schemas } from '../../../../assets/shadowSchemas';
import schemaUtils from '../../../../common/schemaUtils';
import { getTheme } from '../../../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    shadowSelector: {
      minWidth: 200,
    },
    shadowBox: {
      padding: theme.spacing(2),
      backgroundColor: 'rgba(0, 0, 0, .08)',
    },
    switchThumb: {
      backgroundColor: theme.palette.secondary.main,
    },
    switchTrack: {
      backgroundColor: theme.palette.secondary.main,
    },
  };
};

type Props = RouteComponentProps<{ deviceId: string }> &
  WithStyles<any> & {
    config?: CustomerConfiguration;
    createShadowTemplate: (template: any) => void;
    applyShadowTemplate: (deviceId: string, callback: (error: any) => any) => void;
  };

type State = {
  saveDisabled: boolean;
  showReported: boolean;
  schema?: RefParser.JSONSchema;
  reportedShadow?: { [key: string]: any };
  desiredShadow?: { [key: string]: any };
  editedShadowValues: { [key: string]: any };
  defaultShadowValue: { [key: string]: any };
  callback?: (error: any) => void;
};

class ShadowTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    saveDisabled: true,
    showReported: false,
    schema: undefined,
    reportedShadow: undefined,
    desiredShadow: undefined,
    editedShadowValues: {},
    defaultShadowValue: {},
    callback: undefined,
  };
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  ajv: any;

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    this.ajv = new Ajv({ allErrors: true, useDefaults: true });
    if (props.config && props.config.shadowSchema && {}.hasOwnProperty.call(schemas, props.config.shadowSchema)) {
      this.ajv.addSchema(schemas[props.config.shadowSchema], 'shadow');
      RefParser.dereference(schemas[props.config.shadowSchema]).then((schema) => this.setState({ schema }));
    } else {
      this.ajv.addSchema(schemas.shadowV2, 'shadow');
      RefParser.dereference(schemas.shadowV2).then((schema) => this.setState({ schema }));
    }
    this.textareaRef = React.createRef();
    DeviceService.create().then((service) => (this.service = service));
  }

  reload = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const { data: reportedShadow } = await this.service.device.getShadow({
        deviceId: this.props.match.params.deviceId,
        params: { state: 'reported' },
      });
      const { data: desiredShadow } = await this.service.device.getShadow({
        deviceId: this.props.match.params.deviceId,
        params: { state: 'desired' },
      });
      this.setState({
        reportedShadow,
        desiredShadow,
        defaultShadowValue: schemaUtils.deepMergeObjects(reportedShadow, desiredShadow),
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  putShadow = async (): Promise<void> => {
    if ({}.hasOwnProperty.call(this.state.editedShadowValues, 'app') && this.state.callback) {
      try {
        JSON.parse(this.state.editedShadowValues.app);
      } catch (error: any) {
        this.state.callback(error);
        return;
      }
    }
    if (Object.keys(this.state.editedShadowValues).length === 0) {
      SnackbarUtils.success(i18next.t('success.update.deviceShadow'));
    } else {
      this.setState({ loading: true });
      try {
        const { data: desiredShadow } = await this.service.device.putShadow(
          this.props.match.params.deviceId,
          { state: 'desired' },
          this.state.editedShadowValues,
        );
        const { data: reportedShadow } = await this.service.device.getShadow({
          deviceId: this.props.match.params.deviceId,
          params: { state: 'reported' },
        });
        this.setState({
          reportedShadow,
          desiredShadow: schemaUtils.deepMergeObjects(this.state.desiredShadow || {}, desiredShadow),
          editedShadowValues: {},
          defaultShadowValue: schemaUtils.deepMergeObjects(reportedShadow, desiredShadow),
        });
        SnackbarUtils.success(i18next.t('success.update.deviceShadow'));
      } catch (e) {
        const error: any = e;
        SnackbarUtils.error(
          (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
        );
      }
    }
    this.setState({ loading: false, editing: false });
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean, callback?: (error: any) => void) => {
    // if (isValid) {
    this.setState({ callback });
    /*} else {
      this.setState({ callback: undefined });
    }*/
    const editedShadowValues = schemaUtils.setValue(
      { shadow: this.state.editedShadowValues || {} },
      key === undefined ? [] : key.split('.'),
      value,
    );
    if (editedShadowValues) {
      const shouldBeActive = callback ? true : isValid;
      this.setState({
        editedShadowValues: editedShadowValues.shadow,
        saveDisabled: !(!!Object.keys(editedShadowValues.shadow).length && shouldBeActive),
      });
    }
  };

  renderToggleState = (): React.ReactNode => {
    return (
      <ButtonGroup color="secondary" size="small" disabled={this.state.loading}>
        <Button
          onClick={() => {
            this.setState({ showReported: false });
          }}
          variant={this.state.showReported ? 'outlined' : 'contained'}
        >
          {i18next.t('action.desired')}
        </Button>
        <Button
          onClick={() => {
            this.setState({ showReported: true });
            this.setState({ editing: false });
          }}
          variant={this.state.showReported ? 'contained' : 'outlined'}
        >
          {i18next.t('action.reported')}
        </Button>
      </ButtonGroup>
    );
  };

  renderCustomAction = (): React.ReactNode => (
    <Grid container spacing={2}>
      <Grid item>{this.renderToggleState()}</Grid>
      <Grid item>
        <Button
          color="secondary"
          size="small"
          variant="text"
          disabled={this.state.loading || this.state.editing}
          onClick={this.reload}
        >
          {i18next.t('action.refresh')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          size="small"
          variant="text"
          disabled={this.state.loading || !this.state.reportedShadow || !Object.keys(this.state.reportedShadow!).length}
          onClick={() => {
            const { app, system } = this.state.reportedShadow as any;
            this.props.createShadowTemplate({ system, app });
          }}
        >
          {i18next.t('action.saveAs')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          size="small"
          variant="text"
          disabled={this.state.loading}
          onClick={() => this.props.applyShadowTemplate(this.props.match.params.deviceId, this.reload)}
        >
          {i18next.t('action.apply')}
        </Button>
      </Grid>
    </Grid>
  );

  getDefaultValue = () => {
    const val = {
      shadow: this.state.showReported
        ? this.state.reportedShadow || {}
        : schemaUtils.deepMergeObjects(this.state.defaultShadowValue, this.state.editedShadowValues),
    };
    return val;
  };

  renderContent = () => {
    if (!this.state.schema) return null;
    return (
      <Grid container spacing={4} key={`${this.state.showReported}`}>
        <Grid item xs={12}>
          <SchemaFormShadow
            key={`system-${this.state.editing}`}
            data={{
              ...(this.state.schema as any),
            }}
            disabled={!this.state.editing}
            defaultValue={this.getDefaultValue()}
            onChange={this.handleUpdate}
            hideUnset={this.state.showReported}
          />
        </Grid>
      </Grid>
    );
  };

  render = (): React.ReactNode => {
    return (
      <TabPanel
        tab={this.props.tab}
        activeTab={this.props.activeTab}
        headerProps={{
          customAction: this.renderCustomAction(),
          ...(this.props.accessControl && this.props.accessControl('shadow', 'update')
            ? {
                editProps: {
                  loading: this.state.loading,
                  editing: this.state.editing,
                  saveDisabled: this.state.saveDisabled,
                  editDisabled: this.state.showReported,
                  onEdit: () => this.setState({ editing: true }),
                  onSave: this.putShadow,
                  onCancel: () =>
                    this.setState(
                      {
                        editing: false,
                        editedShadowValues: {},
                      },
                      this.forceUpdate,
                    ),
                },
              }
            : {}),
        }}
      >
        {(this.props.parentLoading && (!this.state.desiredShadow || this.state.editing)) ||
        (!this.state.editing && this.state.loading) ? (
          <PageLoader />
        ) : null}
        {(!this.props.parentLoading && (this.state.desiredShadow || this.state.editing)) ||
        (!this.state.editing && !this.state.loading)
          ? this.renderContent()
          : null}
      </TabPanel>
    );
  };
}

const mapStateToProps = ({ userStore }: Store) => ({
  config: userStore.config,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  createShadowTemplate: (template: any) => dispatch(storeOpenModal(ModalVariants.CreateShadowTemplateMC, { template })),
  applyShadowTemplate: (deviceId: string, callback: (error: any) => any) =>
    dispatch(storeOpenModal(ModalVariants.ApplyShadowTemplateMC, { deviceId, callback })),
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ShadowTab)));
