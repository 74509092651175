import React, { Component } from 'react';
import {
  Paper,
  IconButton,
  Typography,
  Grid,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
  AutocompleteInputChangeReason,
} from '@mui/material';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { DeviceGroup } from '../../common/types';
import { DeviceService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';

type Props = WithStyles<any> & {
  deviceId: string;
  closeModal: () => void;
};

type State = {
  loading: boolean;
  addDeviceGroups: DeviceGroup[];
  deviceGroups: DeviceGroup[];
  defaultDeviceGroups: DeviceGroup[];
};

export class AddDeviceGroupToDeviceMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    addDeviceGroups: [],
    deviceGroups: [],
    defaultDeviceGroups: [],
  };
  deviceService!: DeviceService;
  deviceGroupDebouncer: any;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.deviceService = await DeviceService.create()!;
    const { data } = await this.deviceService.deviceGroup.list({ params: { limit: 50, parent: '*' } });
    if (this._isMounted) this.setState({ deviceGroups: data.data, defaultDeviceGroups: data.data });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  get isValid(): boolean {
    return !this.state.loading && !!this.state.addDeviceGroups.length;
  }

  addDeviceGroupsToDevice = async (): Promise<void> => {
    this.setState({ loading: true });

    const result = await Promise.all(
      this.state.addDeviceGroups.map((deviceGroup) =>
        this.deviceService!.deviceGroup.postDeviceToDeviceGroup(
          deviceGroup.groupId,
          this.store.modalStore.data.deviceId,
        )
          .then(() => ({
            isError: false,
            title: deviceGroup.groupId,
            subtitle: i18next.t('success.attach.deviceGroupToDevice'),
          }))
          .catch((e) => {
            const error: any = e;
            return {
              isError: true,
              title: deviceGroup.groupId,
              subtitle:
                (error.response && e.response.data && e.response.data.message) ||
                i18next.t('error.attach.deviceGroupToDevice'),
            };
          }),
      ),
    );

    if (!result.map((res) => res.isError).includes(false)) {
      SnackbarUtils.error(
        `${i18next.t('error.attach.deviceGroupsToDevice')} (${this.state.addDeviceGroups.length})`,
        result as any,
      );
    } else if (!result.map((res) => res.isError).includes(true)) {
      SnackbarUtils.success(
        `${i18next.t('success.attach.deviceGroupsToDevice')} (${this.state.addDeviceGroups.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } else {
      SnackbarUtils.warning(
        `${i18next.t('warning.attach.partiallyDeviceGroupsToDevice')} (${this.state.addDeviceGroups.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    }
    this.setState({ loading: false });
  };

  getDeviceGroups = async (value: string): Promise<void> => {
    try {
      const { data } = await this.deviceService.deviceGroup.list({
        params: { parent: '*', limit: 50, groupId: value.endsWith('*') ? value : `${value}*` },
      });
      if (!!data && !!data.data && this._isMounted) this.setState({ deviceGroups: data.data });
    } catch {
      SnackbarUtils.error(i18next.t('error.fetch.deviceGroups'));
    }
  };

  handleGetDeviceGroups = (_: React.ChangeEvent<unknown>, value: any, reason: AutocompleteInputChangeReason): void => {
    if (this.deviceGroupDebouncer) clearTimeout(this.deviceGroupDebouncer);
    if (reason === 'reset' || value === '') {
      this.setState({ deviceGroups: this.state.defaultDeviceGroups });
    } else {
      this.deviceGroupDebouncer = setTimeout(() => this.getDeviceGroups(value), 1000);
    }
  };

  render = (): React.ReactNode => {
    const { classes } = this.props;
    const { deviceGroupIds } = this.store.modalStore.data;
    return (
      <Paper tabIndex={-1} square className={classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.addDeviceGroupToDevice.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.addDeviceGroupToDevice.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="adg"
              multiple
              filterSelectedOptions
              ChipProps={{ size: 'small' }}
              options={this.state.deviceGroups.filter((deviceGroup) => !deviceGroupIds.includes(deviceGroup.groupId))}
              getOptionLabel={(option: DeviceGroup) => option.groupId}
              value={this.state.addDeviceGroups}
              onChange={(_: any, addDeviceGroups: DeviceGroup[]) => this.setState({ addDeviceGroups })}
              onInputChange={this.handleGetDeviceGroups}
              isOptionEqualToValue={(option, value) => option.groupId === value.groupId}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'adg' }}
                  label={`${i18next.t('deviceGroups')}*`}
                  placeholder={i18next.t('form.placeholder.selectDeviceGroups')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              disabled={!this.isValid}
              onClick={this.addDeviceGroupsToDevice}
            >
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.add</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(AddDeviceGroupToDeviceMC);
