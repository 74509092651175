import React, { Component } from 'react';
import {
  Paper,
  IconButton,
  Grid,
  Typography,
  Button,
  MobileStepper,
  CircularProgress,
  Box,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SchemaForm } from '../../components';
import { CloseOutlined, KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import i18next from 'i18next';
import Ajv from 'ajv';
import { DeviceService, DataService } from '../../services';
import { store } from '../../store';
import { Store, Trust } from '../../common/types';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import dataSchema from '../../assets/schemas/data.schema.parsed.json';
import translations from '../../assets/translations';
import RefParser from 'json-schema-ref-parser';
import { Trans } from 'react-i18next';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  data: { [key: string]: any };
  isBackDisabled: boolean;
  isNextDisabled: boolean;
  loading: boolean;
  mode: 'PUSH' | 'PULL';
  trusts: { label: string; value: any; description?: string }[];
  activeStep: number;
  schema: Omit<RefParser.JSONSchema, 'type'> & any;
};

class CreateSubscriptionMC extends Component<Props, State> {
  store: Store;
  state: State;
  deviceService!: DeviceService;
  dataService!: DataService;
  ajv: any;
  maxSteps = translations.en.translation.modals.createSubscription.activePageHeader.length;

  constructor(props: Props) {
    super(props);
    this.ajv = new Ajv({ allErrors: true, useDefaults: true });
    for (let i = 0; i < this.maxSteps; i += 1) {
      const schema = this.splitSchema(i);
      this.ajv.addSchema(
        {
          ...dataSchema,
          ['$id']: `data${i}`,
          ['properties']: { ...dataSchema.properties, ['subscription']: schema },
          ['definitions']: { ...dataSchema.definitions, ['subscription']: schema },
        },
        `data${i}`,
      );
    }
    this.store = store.getState();
    this.state = {
      data: {},
      isBackDisabled: false,
      isNextDisabled: false,
      loading: false,
      mode: this.store.modalStore.data.mode || 'PUSH',
      trusts: [],
      activeStep: 0,
      schema: {
        ...this.splitSchema(),
      },
    };
  }

  componentDidMount = async (): Promise<void> => {
    this.deviceService = await DeviceService.create();
    this.dataService = await DataService.create();
    const trusts = await this.loadTrusts();
    this.setState({ trusts: [{ label: 'NONE', value: 'NONE' }].concat(trusts) });
  };

  splitSchema = (step?: number, epReq?: any[]) => {
    const schema = JSON.parse(JSON.stringify((dataSchema as any).properties['subscription']));
    Object.keys((schema as any).properties).forEach((item) => {
      if (!(schema as any).steps[step || 0].content.includes(item)) {
        delete (schema as any).properties[item];
      }
    });
    const required = (schema as any).required.filter((r: any) => (schema as any).steps[step || 0].content.includes(r));
    schema.required = required;
    if (schema.properties?.endpoint && epReq) {
      schema.properties.endpoint.required = epReq;
    }
    return schema;
  };

  loadTrusts = async (): Promise<any[]> => {
    const { data } = await this.dataService.trust.list({
      params: { parent: '*', limit: 500 },
    });
    return data.data.map((t: Trust) => ({ label: t.trustId, description: t.description, value: t.trustId }));
  };

  getCleanEndpoint = (data?: Record<string, any>) => {
    const endpoints = JSON.parse(JSON.stringify((dataSchema as any).properties['subscription'])).properties!.endpoint;
    if (data) {
      const stateEndpoint = Object.assign({}, data.endpoint);
      const cleanEndpoint = Object.keys(stateEndpoint).map((item) => {
        const result = this.shouldBeRemoved((endpoints as any).properties[item], data);
        if (!result) {
          return item;
        }
      });
      return cleanEndpoint.filter((item) => !!item);
    } else {
      const cleanEndpoint = Object.assign({}, this.state.data.endpoint);
      //When user change type the data needs to be cleaned to not send incorrect parameters
      Object.keys(cleanEndpoint).forEach((item) => {
        const result = this.shouldBeRemoved((endpoints as any).properties[item]);
        if (result) {
          delete cleanEndpoint[item];
        }
      });
      return cleanEndpoint;
    }
  };

  getCleanData = () => {
    const sqsRoleArnPull = this.state.data.endpoint?.sqsRoleArnPull;
    const url = this.state.data.endpoint['url(https)'];
    delete this.state.data.endpoint['url(https)'];
    delete this.state.data.endpoint.sqsRoleArnPull;
    delete this.state.data.subtopics;
    delete this.state.data.systemEvents;
    const type = this.state.data.endpoint.endpointType.split(' ')[0];
    return {
      ...this.state.data,
      enabled: true,
      endpoint: {
        ...this.getCleanEndpoint(),
        sqsRoleArn: this.state.data.mode === 'PUSH' ? this.state.data.endpoint?.sqsRoleArn : sqsRoleArnPull,
        endpointType: type,
        url,
      },
    };
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const data = this.getCleanData();
      await this.dataService.subscription.post(data);
      SnackbarUtils.success(i18next.t('success.create.subscription'));
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  getValue = (data: { [key: string]: any }, keys: string[]): any => {
    if (keys.length === 1) {
      return data[keys[0]];
    } else {
      if (data[keys[0]]) {
        return data[keys[0]][keys[1]];
      }
      return undefined;
    }
  };

  setValue = (data: { [key: string]: any }, keys: string[], value: any, extraKey?: string, extraValue?: any): any => {
    if (keys.length === 0) {
      data = value;
    } else if (keys.length === 1) {
      data[keys[0]] = value;
      if (extraKey && extraValue) {
        data[extraKey] = extraValue;
      }
    } else {
      data[keys[0]] = this.setValue(data[keys[0]] || {}, keys.slice(1), value);
    }
    return data;
  };

  shouldBeRemoved = (item: any, data?: any): boolean => {
    if (!!item.hiddenIf && !!item.hiddenIf.length) {
      const results = item.hiddenIf.map((item: any) => {
        if (item[1] === 'EQUAL' && this.getValue(data || this.state.data, item[0].split('.')) === item[2]) {
          return true;
        }
        if (item[1] === 'NOT_EQUAL' && this.getValue(data || this.state.data, item[0].split('.')) !== item[2]) {
          return true;
        }
        return false;
      });
      return results.includes(true);
    }
    return false;
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean) => {
    let dataToUse = this.state.data;
    const validToUse = isValid;
    let extraKey;
    let extraValue;
    if (key === 'subtopics' || key === 'systemEvents') {
      extraKey = 'events';
      if (key === 'systemEvents') {
        const subtopics = this.state.data?.subtopics || [];
        extraValue = [...subtopics, ...value];
      } else {
        const systemEvents = this.state.data?.systemEvents || [];
        extraValue = [...systemEvents, ...value];
      }
    } else if (key === 'endpoint.endpointType') {
      this.ajv.removeSchema(`data${this.state.activeStep}`);
      const endpointType = value.split(' ')[1].replace('(', '').replace(')', '');
      dataToUse = { ...dataToUse, mode: endpointType };
      const map = this.state.schema.properties!.endpoint!.map;
      const schema = this.splitSchema(this.state.activeStep, map[value]);
      this.ajv.addSchema(
        {
          ...dataSchema,
          ['$id']: `data${this.state.activeStep}`,
          ['properties']: { ...dataSchema.properties, ['subscription']: schema },
          ['definitions']: { ...dataSchema.definitions, ['subscription']: schema },
        },
        `data${this.state.activeStep}`,
      );
      this.setState({ schema });
    }
    //Some data in this schema is not valid
    if (key?.startsWith('endpoint') && !isValid) {
      const relevantKeys = this.getCleanEndpoint(dataToUse);
      if (!relevantKeys.includes(key.replace('endpoint.', ''))) {
        relevantKeys.push(key.replace('endpoint.', ''));
      }
    }
    const data = this.setValue(dataToUse || {}, key === undefined ? [] : key.split('.'), value, extraKey, extraValue);
    let ajvIsValid = this.ajv.validate(`data${this.state.activeStep}`, {
      ['subscription']: data,
    });
    if (this.ajv.errors) {
      const endpoints = JSON.parse(JSON.stringify((dataSchema as any).properties['subscription'])).properties!.endpoint;
      const keys = this.ajv.errors.map((error: { dataPath: string }) => error.dataPath.split('.')[3]);
      const result = keys.map(
        (key: string | undefined) => key && this.shouldBeRemoved((endpoints as any).properties[key]),
      );
      if (result.every((res: boolean | undefined) => res === true)) {
        ajvIsValid = true;
      }
    }

    this.setState({
      data: data || {},
      isNextDisabled: !(!!Object.keys(data).length && ajvIsValid && validToUse),
      isBackDisabled: !validToUse,
    });
  };

  updateStep = (direction: number) => {
    const newStep = this.state.activeStep + direction;
    const ajvIsValid = this.ajv.validate(`data${newStep}`, {
      ['subscription']: this.state.data,
    });
    this.setState({
      activeStep: newStep,
      schema: this.splitSchema(newStep),
      isNextDisabled: !(!!Object.keys(this.state.data).length && ajvIsValid),
    });
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container style={{ flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
          <Grid container spacing={4} style={{ flexDirection: 'column', flexWrap: 'nowrap', flex: '1 0 auto' }}>
            <Grid item xs={12} style={{ flex: 1 }}>
              <Typography variant="h3">
                {translations.en.translation.modals.createSubscription.activePageHeader[this.state.activeStep]}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ flex: 1, minHeight: '20%' }}>
              <Typography variant="body1">
                {translations.en.translation.modals.createSubscription.activePageDescription[this.state.activeStep]}
              </Typography>
            </Grid>
            {this.state.activeStep === 0 ? (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: 400 }}>
                  <Stepper alternativeLabel orientation="horizontal" activeStep={-1}>
                    {translations.en.translation.modals.createSubscription.activePageHeader.slice(1).map((step) => (
                      <Step key={step}>
                        <StepLabel>{step}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <SchemaForm
                  state="CREATE"
                  SchemaFormInputProps={{
                    trustId: {
                      options: this.state.trusts,
                    },
                    systemEvents: {
                      options: [
                        '$*',
                        '$lifecycle/*',
                        '$lifecycle/created',
                        '$lifecycle/activated',
                        '$lifecycle/deactivated',
                        '$lifecycle/deleted',
                        '$lifecycle/connected',
                        '$lifecycle/disconnected',
                        '$error/publish',
                        '$shadow/update/documents',
                      ],
                    },
                  }}
                  data={this.state.schema}
                  grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
                  defaultValue={this.state.data}
                  onChange={this.handleUpdate}
                  isRequired={(key: string) => {
                    if (key === 'systemEvents') {
                      return this.state.data.subtopics.length === 0;
                    } else if (key === 'subtopics') {
                      return this.state.data.systemEvents.length === 0;
                    }
                    return false;
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <MobileStepper
              variant="dots"
              steps={this.maxSteps}
              position="static"
              activeStep={this.state.activeStep}
              nextButton={
                <Grid style={{ minWidth: '85px', display: 'flex', justifyContent: 'flex-end' }}>
                  {this.state.activeStep === this.maxSteps - 1 ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={this.state.isNextDisabled}
                      onClick={this.create}
                    >
                      {this.state.loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <Trans>action.create</Trans>
                      )}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      style={{ paddingLeft: '24px' }}
                      onClick={() => this.updateStep(1)}
                      disabled={this.state.isNextDisabled}
                    >
                      {'NEXT'}
                      {<KeyboardArrowRight />}
                    </Button>
                  )}
                </Grid>
              }
              backButton={
                <Grid style={{ minWidth: '85px' }}>
                  {this.state.activeStep !== 0 ? (
                    <Button
                      size="small"
                      style={{ paddingRight: '24px' }}
                      onClick={() => this.updateStep(-1)}
                      disabled={this.state.activeStep === 0 || this.state.isBackDisabled}
                    >
                      {<KeyboardArrowLeft />}
                      BACK
                    </Button>
                  ) : (
                    <Button size="small" disabled={true}></Button>
                  )}
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateSubscriptionMC);
