import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { DeviceService } from '../../../services';
import { Dialog, TabsHeader, SnackbarUtils } from '../../../components';
import { PropertiesTab } from '../../baseClasses/PropertiesTab';
import { itemIcons } from '../../../common/constants';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../baseClasses/ViewBase';
import fleetSchema from '../../../assets/schemas/fleet.schema.parsed.json';
import shadowScheme from '../../../assets/shadow.schema.json';

type _Props = unknown;
type Props = ViewBaseProps<_Props, { templateId: string }>;

type _State = {
  unsetDialogOpen: boolean;
};
type State = ViewBaseState<_State>;

class ShadowTemplateDetailedView extends ViewBase<Props, State, { templateId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
    unsetDialogOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.properties'];
    DeviceService.create().then((service) => (this.service = service));
    this.subservice = 'shadowTemplate';
    this.messages = {
      load: { error: 'error.fetch.shadowTemplate' },
      update: { success: 'success.update.shadowTemplate', error: 'error.tryAgain' },
      delete: { success: 'success.delete.shadowTemplate', error: 'error.tryAgain' },
    };
  }

  setDefault = async (): Promise<void> => {
    const { templateId } = this.props.match.params;
    this.setState({ loading: true });
    try {
      await this.service.shadowTemplate.setDefault(templateId);
      this.load();
      SnackbarUtils.success(i18next.t('success.update.shadowTemplateDefault'));
    } catch (e) {
      const error: any = e;
      this.setState({ loading: false });
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
  };

  unsetDefault = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.service.shadowTemplate.deleteDefault();
      this.load();
      SnackbarUtils.success(i18next.t('success.delete.shadowTemplateDefault'));
    } catch (e) {
      const error: any = e;
      this.setState({ loading: false });
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
  };

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('shadowTemplate', 'update'))
      list.push(
        <MenuItem
          key="1"
          disabled={!this.state.item}
          onClick={
            this.state.item && this.state.item.default
              ? () => this.setState({ unsetDialogOpen: true })
              : this.setDefault
          }
        >
          <Trans>{this.state.item && this.state.item.default ? 'action.unsetAsDefault' : 'action.setAsDefault'}</Trans>
        </MenuItem>,
      );
    if (this.accessControl('shadowTemplate', 'delete'))
      list.push(
        <MenuItem key="2" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <PropertiesTab
      tab={0}
      activeTab={this.state.activeTab}
      accessControl={this.accessControl}
      accessControlSection="shadowTemplate"
      parentLoading={this.state.loading}
      schema={fleetSchema}
      schemaKey="shadowTemplate"
      schemaFormInputProps={[
        {
          type: 'json',
          key: 'template',
          json: {
            objectPath: 'state.desired',
            schema: shadowScheme,
          },
        },
      ]}
      item={this.state.item}
      update={this.update}
      reload={this.load}
    />
  );

  renderDialogs = (): React.ReactNode => (
    <>
      <Dialog
        open={this.state.deleteDialogOpen}
        title={<Trans>action.delete</Trans>}
        description={<Trans>description.delete.shadowTemplate</Trans>}
        continueTitle={<Trans>action.delete</Trans>}
        onClose={() => this.setState({ deleteDialogOpen: false })}
        onContinue={this.delete}
      />
      <Dialog
        open={this.state.unsetDialogOpen}
        title={<Trans>action.unsetAsDefault</Trans>}
        description={<Trans>description.delete.defaultShadowTemplate</Trans>}
        continueTitle={<Trans>action.unset</Trans>}
        onClose={() => this.setState({ unsetDialogOpen: false })}
        onContinue={this.unsetDefault}
      />
    </>
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={this.props.match.params.templateId}
        icon={itemIcons.shadowTemplate}
        subtitle={i18next.t('form.templateId')}
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

export default withStyles(ViewBaseStyles)(
  withRouter(connect(null, ViewBaseMapDispatchToProps)(ShadowTemplateDetailedView)),
);
