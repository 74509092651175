import { RouteProps as ReactRouteProps } from 'react-router-dom';
import { Role } from '../types';

// Expected route props
export interface RouteProps {
  path: string | string[];
  staticPath?: string; // On click in drawer will take you to this path (used for shortcuts)
  routes?: RouteType[];
  roles?: Role[];
  authenticated?: boolean;
  hideInMenu?: boolean;
  name?: string;
  href?: string;
}

type StrictRouteType = Omit<ReactRouteProps, 'path'>;
export type RouteType = StrictRouteType & RouteProps;

export enum RoutePathPrefix {
  operations = '/management',
  docsAndDownloads = '/docs',
  settings = '/settings',
}

export const PublicRoutePath = {
  base: () => '/',
  deviceLicenses: () => '/device-licenses',
};

export const SubdomainRoutePath = {
  signIn: (): string => '/sign-in',
  signInDeepLink: (qp?: string): string => `/sign-in/${qp || ':qp'}`,
  base: () => '/',

  // Operations
  operations: (): string => RoutePathPrefix.operations,
  dashboard: (): string => `${RoutePathPrefix.operations}/dashboard`,
  customerConfig: (): string => `${RoutePathPrefix.operations}/customer-configuration`,

  deviceManagement: (): string => `${RoutePathPrefix.operations}/device-management`,
  devices: (): string => `${RoutePathPrefix.operations}/devices`,
  device: (deviceId?: string): string => `${RoutePathPrefix.operations}/devices/${deviceId || ':deviceId'}`,
  deviceGroups: (): string => `${RoutePathPrefix.operations}/device-groups`,
  deviceGroup: (groupId?: string): string => `${RoutePathPrefix.operations}/device-groups/${groupId || ':groupId'}`,
  shadowAliases: (): string => `${RoutePathPrefix.operations}/shadow-aliases`,
  shadowTemplates: (): string => `${RoutePathPrefix.operations}/shadow-templates`,
  shadowTemplate: (templateId?: string): string =>
    `${RoutePathPrefix.operations}/shadow-templates/${templateId || ':templateId'}`,

  userManagement: (): string => `${RoutePathPrefix.operations}/user-management`,
  users: (): string => `${RoutePathPrefix.operations}/users`,
  user: (userId?: string): string => `${RoutePathPrefix.operations}/users/${userId || ':userId'}`,
  userGroups: (): string => `${RoutePathPrefix.operations}/user-groups`,
  userGroup: (groupName?: string): string => `${RoutePathPrefix.operations}/user-groups/${groupName || ':groupName'}`,

  dataManagement: (): string => `${RoutePathPrefix.operations}/data-management`,
  trusts: (): string => `${RoutePathPrefix.operations}/trusts`,
  trust: (trustId?: string): string => `${RoutePathPrefix.operations}/trusts/${trustId || ':trustId'}`,
  subscriptions: (): string => `${RoutePathPrefix.operations}/subscriptions`,
  subscription: (subscriptionId?: string): string =>
    `${RoutePathPrefix.operations}/subscriptions/${subscriptionId || ':subscriptionId'}`,
  deviceApisGw: (): string => `${RoutePathPrefix.operations}/api-gw`,
  deviceApiGw: (apiId?: string): string => `${RoutePathPrefix.operations}/api-gw/${apiId || ':apiId'}`,

  firmwareManagement: (): string => `${RoutePathPrefix.operations}/firmware-management`,
  firmwarePackages: (): string => `${RoutePathPrefix.operations}/packages`,
  firmwarePackage: (packageId?: string): string =>
    `${RoutePathPrefix.operations}/packages/${packageId || ':packageId'}`,
  firmwareProducts: (): string => `${RoutePathPrefix.operations}/products`,
  firmwareProduct: (productId?: string): string =>
    `${RoutePathPrefix.operations}/products/${productId || ':productId'}`,
  firmwareReleases: (): string => `${RoutePathPrefix.operations}/releases`,
  firmwareRelease: (releaseId?: string): string =>
    `${RoutePathPrefix.operations}/releases/${releaseId || ':releaseId'}`,
  keys: (): string => `${RoutePathPrefix.operations}/keys`,
  key: (keyId?: string): string => `${RoutePathPrefix.operations}/keys/${keyId || ':keyId'}`,

  // Docs and downloads
  docsAndDownloads: (): string => RoutePathPrefix.docsAndDownloads,
  apiSpec: (type?: 'fleet' | 'fleet-api-gw' | 'user' | 'data' | 'firmware'): string =>
    `${RoutePathPrefix.docsAndDownloads}/api-spec${type ? '/' + type : ''}`,
  docs: (): string => `${RoutePathPrefix.docsAndDownloads}/docs`,
  download: (): string => `${RoutePathPrefix.docsAndDownloads}/download`,

  // Settings
  settings: (): string => RoutePathPrefix.settings,
};

// Custom RouteType to combined all
export type RouteParentType = {
  path: string;
  staticPath?: string;
  routes: (RouteType | RouteParentType)[];
  roles?: Role[];
  hideInMenu?: boolean;
  name?: string;
  href?: string;
};

export enum APIDocs {
  device,
  user,
}
