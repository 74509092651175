import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@mui/material';
import i18next from 'i18next';
import { SubdomainRoutePath, UserGroup, ModalVariants } from '../../../../common/types';
import { ListTable, TabPanel } from '../../../../components';
import { storeOpenModal } from '../../../../store/actions/modal';
import { tableProperties, TableNames } from '../../../../common/constants';
import { getDefaultTableProperties, UserService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';

type Props = RouteComponentProps<{ userId: string }> & {
  userGroups?: UserGroup[];
  reload: () => void;
  openModal: (userId: string, userGroupNames: string[], callback: () => void) => () => void;
};

class UserGroupsTab extends TabViewBase<Props> {
  state: TabViewBaseState = {
    loading: false,
    editing: false,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    UserService.create().then((service) => (this.service = service));
  }

  reload = this.props.reload;

  deleteUserFromUserGroup = async (groupName: string): Promise<void> => {
    try {
      await this.service.userGroup.delUserFromUserGroup(groupName, this.props.match.params.userId);
      this.reload();
      SnackbarUtils.success(i18next.t('success.detach.userFromUserGroup'));
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
  };

  clickRow = (userGroup: UserGroup): (() => Promise<void>) => async (): Promise<void> => {
    this.props.history.push(SubdomainRoutePath.userGroup(userGroup.groupName));
  };

  renderContent = (): React.ReactNode => (
    <ListTable
      TableProps={{ disableBackgroundPaper: true }}
      loading={this.props.parentLoading}
      emptyTitle="form.empty.userGroups"
      properties={getDefaultTableProperties(tableProperties(TableNames.userGroup))}
      rows={this.props.userGroups || []}
      rowActions={
        this.props.accessControl('userGroup', 'update')
          ? [
              {
                title: 'action.detach',
                disabled: !this.props.userGroups || this.props.userGroups.length <= 1,
                action: (userGroup: UserGroup) => this.deleteUserFromUserGroup(userGroup.groupName),
              },
            ]
          : []
      }
      clickRow={this.clickRow}
    />
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        customAction: (
          <Button
            color="secondary"
            size="small"
            variant="text"
            disabled={this.props.parentLoading}
            onClick={this.reload}
          >
            {i18next.t('action.refresh')}
          </Button>
        ),
        ...(this.props.accessControl('userGroup', 'update')
          ? {
              actionProps: {
                actionTitle: 'action.attachUserGroups',
                onAction: this.props.openModal(
                  this.props.match.params.userId,
                  this.props.userGroups ? this.props.userGroups.map((userGroup) => userGroup.groupName) : [],
                  this.props.reload,
                ),
              },
            }
          : {}),
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (userId: string, userGroupNames: string[], callback: () => void) => () =>
    dispatch(storeOpenModal(ModalVariants.AddUserGroupsToUserMC, { userId, userGroupNames, callback })),
});

export default withRouter(connect(null, mapDispatchToProps)(UserGroupsTab));
