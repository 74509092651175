import { AuthenticationServiceProvider } from '..';
import expectJson from './helpers';
import { UserService } from '../../services';

const { IotMqttDevice, IotMqttMessageHandler } = require('./IotDevice');
export class MqttWssService {
  callback?: (arg: any) => any;
  userService?: UserService;
  mqttDevice: any;
  customerId: string;

  constructor(public authenticationServiceProvider: AuthenticationServiceProvider) {
    UserService.create().then((us) => {
      this.userService = us;
    });

    const iris = this.authenticationServiceProvider.iris;
    if (!iris) throw new Error('Failed to retreive iris configuration');

    this.customerId = iris.roleArn.split('-')[1];
    const clientId = `wss_${this.customerId}_` + Math.floor(Math.random() * 10000000 + 1);
    console.log('MqttWssService clientId', clientId);

    const credentials = this.authenticationServiceProvider!.service.credentials;
    if (!credentials) throw new Error('Failed to retreive credentials');

    const config = {
      protocol: 'wss',
      region: iris.region,
      host: iris.iotEndpoint,
      clientId,
      accessKeyId: credentials.accessKeyId,
      secretKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      expires: credentials.expireTime,
      timeout: 3,
    };

    this.mqttDevice = new IotMqttDevice(config);
  }

  static create = async (): Promise<MqttWssService> => {
    const authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (!authenticationServiceProvider) throw new Error('No cached AuthenticationService available in UserService');
    return new MqttWssService(authenticationServiceProvider);
  };

  start = async (topic: string, callback: (arg: any) => any): Promise<Record<string, unknown>> => {
    console.log('MqttWssService start');
    if (!this.mqttDevice) return Promise.reject('MqttDevice not initialized');
    if (!this.userService) return Promise.reject('User service not initialized');
    await this.userService.user.userSelfCheck(); // check policy
    const device = this.mqttDevice;
    this.callback = callback;
    return new Promise((resolve, reject) => {
      device.addListener('expired', () => {
        console.log('Experied');
        const iris = this.authenticationServiceProvider.iris;
        if (!iris) throw new Error('Failed to retreive iris configuration');
        const credentials = this.authenticationServiceProvider!.service.credentials;
        if (!credentials) throw new Error('Failed to retreive credentials');

        const config = {
          accessKeyId: credentials.accessKeyId,
          secretKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
          expires: credentials.expireTime,
        };
        device.updateConfig(config);
      });

      // Add listener for error (eg lost network)
      device.addListener('error', (error: any) => {
        const { userSession, refresh } = this.authenticationServiceProvider.service;
        if (userSession && !userSession?.isValid()) {
          console.log('MqttWssService not valid credentials');
          refresh().then(() => {
            console.log('MqttWssService refresh credentails');
            resolve(error);
          });
        } else if (error.startsWith('Max reconnect attempts reached')) {
          console.log('MqttWssService max reconnect attempts reached');
          if (this.callback) this.callback(null);
          reject(error);
        } else {
          console.log('MqttWssService, valid credentials, other error', error);
          device.disconnect();
          if (this.callback) this.callback(null);
          reject(error);
        }
      });

      // Create message handler with callback on each message
      const messageHandler = new IotMqttMessageHandler().addCallback((topic: string, message: Buffer) => {
        const output = {
          message: message.toString(),
          topic,
          ts: new Date().toISOString(),
          length: Buffer.byteLength(message),
        };
        const binary = message.toString('base64');
        if (output.message !== '{}' && !expectJson(topic)) {
          callback({ ...output, binary });
        } else {
          try {
            callback({ ...output, backup: binary });
          } catch (error) {
            reject(error);
          }
        }
      });

      // Start subscription and let handler process each received message...
      device.subscribe(topic, messageHandler, 1).then(() => {
        resolve({});
      });
    });
  };

  stop = async (): Promise<Record<string, unknown> | undefined> => {
    console.log('MqttWssService stop');
    const device = this.mqttDevice;
    device.removeListener('error', () => {
      console.log('remove error listener');
    });
    if (device) {
      const disconnetPromise = device.disconnect();

      const timePromise = new Promise((resolve) => {
        setTimeout(resolve, 5000);
      });

      Promise.race([disconnetPromise, timePromise]).then((value) => {
        console.log('race value', value);
        return Promise.resolve({});
      });
    } else {
      return Promise.resolve({});
    }
  };
}
