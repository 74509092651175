import React, { Component } from 'react';
import { Paper, IconButton, Typography, Grid, Button, CircularProgress, TextField } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { Device } from '../../common/types';
import { deviceFilterProperty, ADD_DEVICE_TO_DEVICE_GROUP_PROPERTY_CACHE } from '../../common/constants';
import { DeviceService } from '../../services';
import { store } from '../../store';
import { Sound } from '../../assets/sound';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';

const styles = () => {
  return {
    ...BaseModalStyles(),
    autocompleteMultiInput: {
      '&:not(:first-child)': {
        padding: '6px 4px 6px !important',
      },
    },
  };
};

type Props = WithStyles<any> & {
  groupId: string;
  closeModal: () => void;
};

type State = {
  loading: boolean;
  addDevices: Device[];
  devices: Device[];
  defaultDevices: Device[];
  property: { value: string; label: string };
  addWhenFound: boolean;
};

export class AddDeviceToDeviceGroupMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    loading: false,
    addDevices: [],
    devices: [],
    defaultDevices: [],
    property: { ...deviceFilterProperty[0], label: i18next.t(deviceFilterProperty[0].label) },
    addWhenFound: false,
  };
  deviceService!: DeviceService;
  deviceDebouncer: any;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.deviceService = await DeviceService.create()!;
    const cache = localStorage.getItem(ADD_DEVICE_TO_DEVICE_GROUP_PROPERTY_CACHE);
    const { data } = await this.deviceService.device.list({ params: { limit: 50 } });
    if (this._isMounted) {
      this.setState({
        devices: data.data,
        defaultDevices: data.data,
        ...(cache ? { property: JSON.parse(cache) } : {}),
      } as any);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  get isValid(): boolean {
    return !this.state.loading && !!this.state.addDevices.length;
  }

  addDevicesToDeviceGroup = async (): Promise<void> => {
    this.setState({ loading: true });
    const result = await Promise.all(
      this.state.addDevices.map((device) =>
        this.deviceService!.deviceGroup.postDeviceToDeviceGroup(this.store.modalStore.data.groupId, device.deviceId)
          .then(() => ({
            isError: false,
            title: (device as any)[this.state.property.value],
            subtitle: i18next.t('success.attach.deviceToDeviceGroup'),
          }))
          .catch((e) => {
            const error: any = e;
            return {
              isError: true,
              title: (device as any)[this.state.property.value],
              subtitle:
                (error.response && error.response.data && error.response.data.message) ||
                i18next.t('error.attach.deviceToDeviceGroup'),
            };
          }),
      ),
    );

    if (!result.map((res) => res.isError).includes(false)) {
      SnackbarUtils.error(
        `${i18next.t('error.attach.devicesToDeviceGroup')} (${this.state.addDevices.length})`,
        result as any,
      );
    } else if (!result.map((res) => res.isError).includes(true)) {
      SnackbarUtils.success(
        `${i18next.t('success.attach.devicesToDeviceGroup')} (${this.state.addDevices.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } else {
      SnackbarUtils.warning(
        `${i18next.t('warning.attach.partiallyDevicesToDeviceGroup')} (${this.state.addDevices.length})`,
        result as any,
      );
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    }
    this.setState({ loading: false });
  };

  getDevices = async (value: string): Promise<void> => {
    try {
      const { data } = await this.deviceService.device.list({
        params: { limit: 50, [this.state.property.value]: value.endsWith('*') ? value : `${value}*` },
      });
      if (!!data && !!data.data && this._isMounted) {
        const state = { devices: data.data, addWhenFound: false };
        if (this.state.addWhenFound) {
          if (
            (data.data || []).length === 1 &&
            value === (data.data[0] as any)[this.state.property.value] &&
            !this.state.addDevices.map((d) => d.deviceId).includes(data.data[0].deviceId)
          ) {
            (state as any).addDevices = this.state.addDevices.concat(data.data);
            Sound.success();
          } else {
            Sound.error();
          }
        }

        this.setState(state);
      }
    } catch {
      SnackbarUtils.error(i18next.t('error.fetch.devices'));
    }
  };

  handleGetDevices = (_: React.ChangeEvent<unknown>, value: any, reason: AutocompleteInputChangeReason): void => {
    if (this.deviceDebouncer) clearTimeout(this.deviceDebouncer);
    if (reason === 'reset' || value === '') {
      this.setState({ devices: this.state.defaultDevices });
    } else {
      this.deviceDebouncer = setTimeout(() => this.getDevices(value), 1000);
    }
  };

  render = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Paper tabIndex={-1} square className={classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.addDevicesToDeviceGroup.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.addDevicesToDeviceGroup.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="adtd"
              disableClearable
              options={deviceFilterProperty}
              classes={{ input: this.props.classes.autocompleteMultiInput }}
              getOptionLabel={(option: any) => i18next.t(option.label)}
              value={this.state.property}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(_: any, property: any) => {
                this.setState({ property }, () =>
                  localStorage.setItem(ADD_DEVICE_TO_DEVICE_GROUP_PROPERTY_CACHE, JSON.stringify(property)),
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'adtd' }}
                  label={i18next.t('form.property')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="adtd2"
              multiple
              filterSelectedOptions
              ChipProps={{ size: 'small' }}
              options={this.state.devices.filter((o) => !!(o as any)[this.state.property.value])}
              getOptionLabel={(option: Device) => (option as any)[this.state.property.value]}
              value={this.state.addDevices}
              onChange={(_: any, addDevices: Device[]) => {
                const key = this.state.property.value;
                const device = addDevices.slice(-1)[0];
                if (
                  !addDevices.length ||
                  addDevices.length < this.state.addDevices.length ||
                  !this.state.addDevices.map((d) => (d as any)[key]).includes((device as any)[key])
                ) {
                  this.setState({ addDevices });
                } else {
                  this.setState({ addDevices: this.state.addDevices });
                }
              }}
              onInputChange={this.handleGetDevices}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.key === 'Enter') this.setState({ addWhenFound: true });
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, htmlFor: 'adtd2' }}
                  label={`${i18next.t('devices')}*`}
                  placeholder={i18next.t('form.placeholder.selectDevices')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              disabled={!this.isValid}
              onClick={this.addDevicesToDeviceGroup}
            >
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.add</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(styles)(AddDeviceToDeviceGroupMC);
