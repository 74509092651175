import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { UserService } from '../../../../services';
import { Dialog, TabsHeader } from '../../../../components';
import { PropertiesTab } from '../../../baseClasses/PropertiesTab';
import UsersTab from './UsersTab';
import { itemIcons } from '../../../../common/constants';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../../baseClasses/ViewBase';
import userSchema from '../../../../assets/schemas/user.schema.parsed.json';

type _Props = unknown;
type Props = ViewBaseProps<_Props, { groupName: string }>;

type _State = unknown;
type State = ViewBaseState<_State>;

class UserGroupDetailedView extends ViewBase<_Props, _State, { groupName: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.properties', 'tab.users'];
    UserService.create().then((service) => (this.service = service));
    this.subservice = 'userGroup';
    this.messages = {
      load: { error: 'error.fetch.userGroup' },
      update: { success: 'success.update.userGroup', error: 'error.tryAgain' },
      delete: { success: 'success.delete.userGroup', error: 'error.tryAgain' },
    };
  }

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('userGroup', 'delete'))
      list.push(
        <MenuItem key="1" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <PropertiesTab
        tab={0}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="userGroup"
        parentLoading={this.state.loading}
        schema={userSchema}
        schemaKey="userGroup"
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
      <UsersTab
        tab={1}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <Dialog
      open={this.state.deleteDialogOpen}
      title={<Trans>action.delete</Trans>}
      description={<Trans>description.delete.userGroup</Trans>}
      continueTitle={<Trans>action.delete</Trans>}
      onClose={() => this.setState({ deleteDialogOpen: false })}
      onContinue={this.delete}
    />
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={this.props.match.params.groupName}
        icon={itemIcons.userGroup}
        subtitle={i18next.t('form.groupName')}
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

export default withStyles(ViewBaseStyles)(withRouter(connect(null, ViewBaseMapDispatchToProps)(UserGroupDetailedView)));
