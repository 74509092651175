import React from 'react';
import {
  WatchOutlined,
  DevicesOutlined,
  PersonOutlined,
  GroupOutlined,
  SwapHoriz,
  DescriptionOutlined,
  CloudDownloadOutlined,
  ArchiveOutlined,
  GraphicEq,
  LocalOfferOutlined,
  Label,
  ArrowRightAlt,
  LibraryBooksOutlined,
  LockOutlined,
  Settings,
  VpnKeySharp,
} from '@mui/icons-material';

export * from './filter';
export * from './routes';
export * from './table';

// prettier-ignore
export const passwordRegex = new RegExp(
  /(?=(^[a-zA-Z\d\!\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`\¤]{8,}$))(?=.*?\d)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[\!\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`\¤])/, // eslint-disable-line
);

export const drawerWidth = 300;
export const transparentSignInBackground = 'rgba(245, 245, 245, .8)';

export const LAST_VISITED_CACHE = '4295c528-a246-4acc-aef7-dbf1cf2e3747';
export const ADD_DEVICE_TO_DEVICE_GROUP_PROPERTY_CACHE = 'a98ef6f5-a30f-4734-b285-fd6a2628137e';

export const appBarHeight: 64 | 48 = 48;

export const itemIcons = {
  account: <Settings />,
  device: <WatchOutlined />,
  deviceGroup: <DevicesOutlined />,
  deviceApiGw: <SwapHoriz />,
  user: <PersonOutlined />,
  shadowAlias: <Label />,
  shadowTemplate: <LibraryBooksOutlined />,
  userGroup: <GroupOutlined />,
  subscription: <ArrowRightAlt />,
  docs: <DescriptionOutlined />,
  download: <CloudDownloadOutlined />,
  firmwarePackage: <ArchiveOutlined />,
  firmwareProduct: <GraphicEq />,
  firmwareRelease: <LocalOfferOutlined />,
  key: <LockOutlined />,
  trust: <VpnKeySharp />,
};
