import React, { Component } from 'react';
import { Paper, IconButton, Grid, Typography, Button, CircularProgress } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SchemaForm } from '../../components';
import { DeviceService } from '../../services';
import { store } from '../../store';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import fleetSchema from '../../assets/schemas/fleet.schema.parsed.json';
import i18next from 'i18next';
import Ajv from 'ajv';
import { Trigger } from '../../common/types';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  data: { [key: string]: any };
  isSaveDisabled: boolean;
  loading: boolean;
  oldGroupId: string;
};

class CreateDeviceGroupCloneMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    data: {},
    isSaveDisabled: true,
    loading: false,
    oldGroupId: '',
  };
  deviceService!: DeviceService;
  ajv: any;

  constructor(props: Props) {
    super(props);
    this.ajv = new Ajv({ allErrors: true, useDefaults: true, nullable: true });
    this.ajv.addSchema(fleetSchema, 'fleetSchema');
  }

  componentDidMount = async (): Promise<void> => {
    if (this.store.modalStore.data.callback) {
      const copiedData = this.store.modalStore.data.callback();
      this.setState({ data: { ...copiedData }, oldGroupId: copiedData.groupId });
    }
    this.deviceService = await DeviceService.create();
  };

  loadDeviceGroups = async (groupId: string): Promise<string[]> => {
    const { data } = await this.deviceService.deviceGroup.list({
      params: { parent: '*', groupId: `${groupId}*`, limit: 500 },
    });
    return data.data.map((dg) => dg.groupId);
  };

  create = async () => {
    this.setState({ loading: true });
    const { data, oldGroupId } = this.state;
    try {
      const newDg = await this.deviceService.deviceGroup.post(data);
      const triggersToCopy = await this.deviceService.deviceGroup.getTrigger(oldGroupId);
      const triggersToUpdate = await this.deviceService.deviceGroup.getTrigger(data.groupId);
      triggersToCopy.data.data.forEach(async (triggerToCopy: Trigger) => {
        const triggerToUpdate = triggersToUpdate.data.data.find(
          (t: Trigger) => t.groupChangeTrigger.action === triggerToCopy.groupChangeTrigger.action,
        );
        const updatedTrigger = {
          enabled: triggerToCopy.enabled,
          shadowTemplateAction: triggerToCopy.shadowTemplateAction,
        };
        await this.deviceService.deviceGroup
          .updateTrigger(updatedTrigger, triggerToUpdate.triggerId)
          .catch((e: any) => {
            const error: any = e;
            SnackbarUtils.error(
              (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
            );
          });
      });
      SnackbarUtils.success(i18next.t('success.create.clone'));
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback(newDg.data);
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  setValue = (data: { [key: string]: any }, keys: string[], value: any): any => {
    if (keys.length === 0) {
      data = value;
    } else if (keys.length === 1) {
      if (value === null) delete data[keys[0]];
      else data[keys[0]] = value;
    } else {
      data[keys[0]] = this.setValue(data[keys[0]] || {}, keys.slice(1), value);
    }
    return data;
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean) => {
    const data = this.setValue(this.state.data || {}, key === undefined ? [] : key.split('.'), value);
    const ajvIsValid = this.ajv.validate('fleetSchema', { deviceGroupClone: data });
    this.setState({ data: data || {}, isSaveDisabled: !(!!Object.keys(data).length && ajvIsValid && isValid) });
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.createDeviceGroupClone.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.createDeviceGroupClone.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {Object.keys(this.state.data).length > 0 && (
              <SchemaForm
                state="CREATE"
                SchemaFormInputProps={{
                  parent: {
                    onValueChange: this.loadDeviceGroups,
                  },
                }}
                data={{
                  ...(fleetSchema as any).properties.deviceGroupClone,
                }}
                grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
                defaultValue={this.state.data}
                onChange={this.handleUpdate}
              />
            )}
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={this.state.isSaveDisabled} onClick={this.create}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.create</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateDeviceGroupCloneMC);
