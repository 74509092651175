import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { DeviceGroup, SubdomainRoutePath } from '../../../../common/types';
import { ListTable, TabPanel } from '../../../../components';
import { tableProperties, TableNames } from '../../../../common/constants';
import { getDefaultTableProperties, DeviceService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';

type Props = RouteComponentProps<{ groupId: string }>;

type State = {
  childGroups: DeviceGroup[];
  initLoaded: boolean;
  nextToken?: string;
};

class ChildGroupsTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    childGroups: [],
    initLoaded: false,
    nextToken: undefined,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    DeviceService.create().then((service) => (this.service = service));
  }

  reload = (): void =>
    this.setState({ childGroups: [], initLoaded: false, nextToken: undefined }, () =>
      this.setState({ initLoaded: true }),
    );

  load = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const { data } = await this.service.deviceGroup.list({
        params: {
          parent: this.props.match.params.groupId,
          limit: 50,
          ...(this.state.nextToken ? { nextToken: this.state.nextToken } : {}),
        },
      });
      this.setState({
        loading: false,
        childGroups: this.state.childGroups!.concat(data.data),
        nextToken: data.nextToken,
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) ||
          i18next.t('error.fetch.deviceChildGroups'),
      );
      this.setState({ loading: false });
    }
  };

  clickRow = (deviceGroup: DeviceGroup): (() => Promise<void>) => async (): Promise<void> => {
    this.props.history.push(SubdomainRoutePath.deviceGroup(deviceGroup.groupId));
  };

  renderContent = (): React.ReactNode => (
    <ListTable
      InfinityScrollProps={{
        initLoaded: this.state.initLoaded,
        nextToken: this.state.nextToken,
        loadFunc: this.load,
      }}
      TableProps={{ disableBackgroundPaper: true }}
      loading={this.state.loading}
      emptyTitle="form.empty.childGroups"
      properties={getDefaultTableProperties(tableProperties(TableNames.deviceGroupChildren))}
      rows={this.state.childGroups}
      clickRow={this.clickRow}
    />
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.state.loading,
        },
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

export default withRouter(ChildGroupsTab);
