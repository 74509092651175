import React from 'react';
import { SubdomainRoutePath, Device, ReleaseDetailed } from '../../../../common/types';
import { ListTable, TabPanel } from '../../../../components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { tableProperties, TableNames } from '../../../../common/constants';
import { getDefaultTableProperties, DeviceService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';
import i18next from 'i18next';

type Props = RouteComponentProps & {
  release?: ReleaseDetailed;
};

type State = {
  devices: Device[];
  initLoaded: boolean;
  nextToken?: string;
  count?: number;
};

class DevicesTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    devices: [],
    initLoaded: false,
    nextToken: undefined,
    count: undefined,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    DeviceService.create().then((service) => (this.service = service));
  }

  reload = (): void =>
    this.setState({ devices: [], initLoaded: false, nextToken: undefined }, () => this.setState({ initLoaded: true }));

  load = async (): Promise<void> => {
    if (!this.props.release) return;
    this.setState({ loading: true });
    try {
      const { data } = await this.service.device.list({
        params: {
          productId: this.props.release!.productId,
          'alias.firmwareVersion': this.props.release!.toVersion,
          limit: 50,
          ...(this.state.nextToken ? { nextToken: this.state.nextToken } : {}),
        },
      });
      this.setState({
        devices: (this.state.devices || []).concat(data.data),
        nextToken: data.nextToken,
        count: (data as any).count,
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.fetch.devices'),
      );
    }
    this.setState({ loading: false });
  };

  clickRow = (device: Device): (() => Promise<void>) => async (): Promise<void> => {
    this.props.history.push(SubdomainRoutePath.device(device.deviceId));
  };

  renderContent = (): React.ReactNode => (
    <ListTable
      InfinityScrollProps={{
        initLoaded: this.state.initLoaded,
        nextToken: this.state.nextToken,
        loadFunc: this.load,
      }}
      TableProps={{ disableBackgroundPaper: true }}
      loading={this.state.loading}
      emptyTitle="form.empty.devices"
      properties={getDefaultTableProperties(tableProperties(TableNames.deviceTabRelease))}
      rows={this.state.devices}
      clickRow={this.clickRow}
    />
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        count: this.state.count,
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.state.loading,
        },
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

export default withRouter(DevicesTab);
