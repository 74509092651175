import React from 'react';
import {
  Tabs as MTabs,
  Tab as MTab,
  TabsProps as MTabsProps,
  Grid,
  Button,
  Menu,
  Typography,
  Box,
  Divider,
  Avatar,
  Paper,
  AppBar,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { ListFilterProperty, FilterCombination } from '../common/types';
import { appBarHeight } from '../common/constants';
import { getTheme } from '../common/theme';
import moment from 'moment-timezone';

/** TabsPageHeaderAction **/
export type TabsPageHeaderActionProps = {
  actionTitle: string;
  onAction: () => void;
  disabled?: boolean;
};

export const TabsPageHeaderAction: React.FunctionComponent<TabsPageHeaderActionProps> = (
  props: TabsPageHeaderActionProps,
) => (
  <Grid item>
    <Button size="small" color="secondary" onClick={props.onAction} disabled={props.disabled}>
      <Trans>{props.actionTitle}</Trans>
    </Button>
  </Grid>
);

/** TabsPageHeaderEdit **/
export type TabsPageHeaderEditProps = {
  editing?: boolean;
  saveDisabled?: boolean;
  editDisabled?: boolean;
  loading?: boolean;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  customEditName?: string;
};

export const TabsPageHeaderEdit: React.FunctionComponent<TabsPageHeaderEditProps> = (
  props: TabsPageHeaderEditProps,
) => {
  if (props.editing)
    return (
      <>
        <Grid item>
          <Button size="small" color="secondary" onClick={props.onCancel} disabled={props.loading}>
            <Trans>action.cancel</Trans>
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={props.onSave}
            disabled={props.saveDisabled || props.loading}
          >
            {props.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.save</Trans>}
          </Button>
        </Grid>
      </>
    );
  return (
    <Grid item>
      <Button size="small" color="secondary" onClick={props.onEdit} disabled={props.editDisabled || props.loading}>
        {props.customEditName ? <Trans>{props.customEditName}</Trans> : <Trans>action.edit</Trans>}
      </Button>
    </Grid>
  );
};

/** TabsPageHeader **/
export type TabsPageHeaderProps = {
  customAction?: React.ReactNode;
  actionProps?: TabsPageHeaderActionProps;
  editProps?: TabsPageHeaderEditProps;
  count?: number;
};

export const TabsPageHeader: React.FunctionComponent<TabsPageHeaderProps> = (props: TabsPageHeaderProps) => {
  if (!props.customAction && !props.actionProps && !props.editProps && props.count === undefined)
    return <Box pb="46px" />;

  const items = (
    <>
      {props.customAction ? <Grid item>{props.customAction}</Grid> : null}
      {props.actionProps ? <TabsPageHeaderAction {...props.actionProps} /> : null}
      {props.editProps ? <TabsPageHeaderEdit {...props.editProps} /> : null}
    </>
  );

  return (
    <Box mt={1} mb={1}>
      {props.count === undefined ? (
        <Grid container spacing={2} justifyContent="flex-end">
          {items}
        </Grid>
      ) : (
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Box fontSize="14px" lineHeight="30px">
              Found: <b>{props.count}</b>
            </Box>
          </Grid>
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              {items}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

/** TabPanel **/
export type TabPanelProps = {
  tab: number;
  activeTab: number;
  headerProps?: TabsPageHeaderProps;
  children: React.ReactNode;
};

export const TabPanel: React.FunctionComponent<TabPanelProps> = (props: TabPanelProps) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={props.activeTab !== props.tab}
    style={{ position: 'relative' as const, minHeight: '100%' }}
  >
    <TabsPageHeader {...props.headerProps} />
    {props.children}
  </Typography>
);

/** Tabs **/
const a11yProps = (index: number): { [key: string]: string } => ({
  id: `scrollable-auto-tab-${index}`,
});

export type TabsProps = MTabsProps & {
  tabs: string[];
  css: string;
  activeTabs?: string[];
};

export const Tabs: React.FunctionComponent<TabsProps> = ({ tabs, ...props }: TabsProps) => (
  <AppBar position="static" color="inherit" elevation={0}>
    <MTabs
      indicatorColor="secondary"
      textColor="secondary"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="Scrollable tabs"
      {...props}
    >
      {tabs.map((title, index) => (
        <MTab
          className={props.css}
          disabled={props.activeTabs ? !props.activeTabs.includes(title) : false}
          key={index}
          label={i18next.t(title)}
          style={{ minWidth: '160px', fontSize: '0.8rem' }}
          {...a11yProps(index)}
        />
      ))}
    </MTabs>
  </AppBar>
);

/** TabsHeader **/
const TabsHeaderStyles = () => {
  const theme = getTheme();
  return {
    root: {
      padding: theme.spacing(4),
      outline: 'none',
      minHeight: `calc(100vh - ${appBarHeight + parseInt(theme.spacing(2)) * 2}px)`,
      [theme.breakpoints.down('md')]: {
        minHeight: `calc(100vh - ${appBarHeight * 2}px)`,
      },
    },
    avatar: {
      width: 65,
      height: 65,
      backgroundColor: theme.palette.secondary.main,
      '& > *': {
        fontSize: 45,
      },
    },
    tabs: {
      '&.Mui-disabled': {
        color: 'rgb(0, 0, 0, 0.24)',
      },
    },
  };
};

export type TabsHeaderProps = RouteComponentProps &
  WithStyles<typeof TabsHeaderStyles> & {
    icon?: React.ReactNode;
    title: string | React.ReactNode;
    activeTabs?: string[];
    device?: {
      type: string;
      name: string;
      description: string;
      lastSeen: string;
      created: string;
      activated: string;
      state: string;
    };
    subtitle?: string | React.ReactNode;
    buttons?: React.ReactNode[];
    menuItems?: React.ReactNode[];
    tabIndex?: number;
    onChangeTab?: (activeTabIndex: number) => void;
    tabs?: string[];
    filterProps?: {
      predefinedFilters?: FilterCombination[];
      availableFilters: ListFilterProperty[];
      setParams: (params: { [key: string]: string }, callback?: () => void) => void;
    };
  };

type TabsHeaderState = {
  actionsOpen: boolean;
};

class _TabsHeader extends React.Component<TabsHeaderProps, TabsHeaderState> {
  state: TabsHeaderState = {
    actionsOpen: false,
  };
  actionButtonRef: React.RefObject<HTMLButtonElement>;

  constructor(props: TabsHeaderProps) {
    super(props);
    this.actionButtonRef = React.createRef();
  }

  componentDidMount = (): void => {
    if (
      !window.location.search &&
      this.props.filterProps &&
      this.props.filterProps.predefinedFilters &&
      this.props.filterProps.predefinedFilters.length
    )
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}${this.props.filterProps.predefinedFilters[0].value}`,
      );
  };

  getLastSeen = (extra?: boolean): string => {
    if (this.props.device) {
      const today = moment().isSame(
        `${moment(this.props.device.lastSeen).tz(moment.tz.guess()).format('YYYY-MM-DD')}`,
        'day',
      );
      if (extra && !today) {
        return moment(this.props.device.lastSeen).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm');
      } else if (today) {
        return `${moment(this.props.device.lastSeen).tz(moment.tz.guess()).format('YYYY-MM-DD')} (Today)`;
      } else {
        return `${moment(this.props.device.lastSeen).tz(moment.tz.guess()).format('YYYY-MM-DD')} (${moment(
          this.props.device.lastSeen,
        ).fromNow()})`;
      }
    } else {
      return '';
    }
  };

  getTs = (): string => {
    if (this.props.device && this.props.device.state) {
      if (this.props.device.state === 'CREATED')
        return moment(this.props.device.created).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm');
      else if (this.props.device.state === 'ACTIVATED')
        return moment(this.props.device.activated).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm');
      else if (this.props.device.state === 'DEACTIVATED')
        return moment(this.props.device.lastSeen).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm');
    }
    return '';
  };

  render = (): React.ReactNode => (
    <Paper elevation={2} className={this.props.classes.root}>
      <Grid container>
        <Grid item xs={12}>
          {this.props.device ? (
            <Box mt={1} mb={2}>
              <div>
                <div>
                  <div style={{ width: '100%' }}>
                    <div>
                      <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
                        <div>
                          <div style={{ display: 'flex' }}>
                            {this.props.icon ? (
                              <Avatar style={{ marginRight: '20px' }} className={this.props.classes.avatar}>
                                {this.props.icon!}
                              </Avatar>
                            ) : null}
                            <div>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: 12,
                                  position: 'relative',
                                  marginTop: -8,
                                  color: getTheme().palette.text.disabled,
                                }}
                              >
                                {i18next.t('label.deviceId')}
                              </Typography>
                              <Typography variant="h4">{this.props.title}</Typography>
                              {this.props.subtitle ? (
                                <Typography variant="body1">{this.props.subtitle}</Typography>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '20% 20% 40% 20%',
                          }}
                        >
                          <Grid item>
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 12,
                                position: 'relative',
                                marginTop: -8,
                                color: getTheme().palette.text.disabled,
                              }}
                            >
                              {i18next.t('label.type')}
                            </Typography>
                            <Typography variant="body2">{this.props.device.type}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 12,
                                position: 'relative',
                                marginTop: -8,
                                color: getTheme().palette.text.disabled,
                              }}
                            >
                              {i18next.t('label.state')}
                            </Typography>
                            <Tooltip title={this.getTs()} placement="top-start">
                              <Typography variant="body2">{this.props.device.state}</Typography>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 12,
                                position: 'relative',
                                marginTop: -8,
                                marginLeft: 8,
                                color: getTheme().palette.text.disabled,
                              }}
                            >
                              {i18next.t('label.lastSeen')}
                            </Typography>
                            {this.props.device.lastSeen ? (
                              <Tooltip title={this.getLastSeen(true)} placement="top-start">
                                <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                  {this.getLastSeen()}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                N/A
                              </Typography>
                            )}
                          </Grid>
                          {(!!this.props.menuItems && !!this.props.menuItems.find((mi) => mi !== undefined)) ||
                          (!!this.props.buttons && !!this.props.buttons.length) ? (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Grid container style={{ display: 'flex', justifyContent: 'end' }}>
                                {!!this.props.buttons &&
                                  this.props.buttons.map((n, i) => (
                                    <Grid key={i} item>
                                      {n}
                                    </Grid>
                                  ))}
                                {!!this.props.menuItems && !!this.props.menuItems.find((mi) => mi !== undefined) ? (
                                  <Grid item>
                                    <Button
                                      size="small"
                                      color="secondary"
                                      variant="text"
                                      ref={this.actionButtonRef}
                                      style={{ marginTop: '-26px' }}
                                      onClick={() => this.setState({ actionsOpen: true })}
                                    >
                                      <Trans>action.actions</Trans>
                                      {this.state.actionsOpen ? <ExpandLess /> : <ExpandMore />}
                                    </Button>
                                    <Menu
                                      open={this.state.actionsOpen}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                      anchorEl={this.actionButtonRef.current}
                                      onClose={() => this.setState({ actionsOpen: false })}
                                      onClick={() => this.setState({ actionsOpen: false })}
                                    >
                                      {this.props.menuItems}
                                    </Menu>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          ) : (
            <Box mt={1} mb={2}>
              <Grid container justifyContent="space-between" alignContent="flex-end">
                <Grid item>
                  <Grid container spacing={2} alignItems="center">
                    {this.props.icon ? (
                      <Grid item>
                        <Avatar className={this.props.classes.avatar}>{this.props.icon!}</Avatar>
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="h4">{this.props.title}</Typography>
                        </Grid>
                        {this.props.subtitle ? (
                          <Grid item>
                            <Typography variant="body1">{this.props.subtitle}</Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {(!!this.props.menuItems && !!this.props.menuItems.find((mi) => mi !== undefined)) ||
                (!!this.props.buttons && !!this.props.buttons.length) ? (
                  <Grid item>
                    <Grid container spacing={2} alignItems="center">
                      {!!this.props.buttons &&
                        this.props.buttons.map((n, i) => (
                          <Grid key={i} item>
                            {n}
                          </Grid>
                        ))}
                      {!!this.props.menuItems && !!this.props.menuItems.find((mi) => mi !== undefined) ? (
                        <Grid item>
                          <Button
                            size="small"
                            color="secondary"
                            variant="text"
                            ref={this.actionButtonRef}
                            onClick={() => this.setState({ actionsOpen: true })}
                          >
                            <Trans>action.actions</Trans>
                            {this.state.actionsOpen ? <ExpandLess /> : <ExpandMore />}
                          </Button>
                          <Menu
                            open={this.state.actionsOpen}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            anchorEl={this.actionButtonRef.current}
                            onClose={() => this.setState({ actionsOpen: false })}
                            onClick={() => this.setState({ actionsOpen: false })}
                          >
                            {this.props.menuItems}
                          </Menu>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          )}
        </Grid>
        {this.props.tabs ? (
          <>
            {this.props.tabs.length ? (
              <Grid item xs={12}>
                <Tabs
                  value={this.props.tabIndex}
                  onChange={
                    this.props.onChangeTab
                      ? (((_: any, value: any): void => {
                          this.props.onChangeTab!(value as number);
                        }) as any)
                      : undefined
                  }
                  tabs={this.props.tabs}
                  activeTabs={this.props.activeTabs}
                  css={this.props.classes.tabs}
                />
                <Divider />
              </Grid>
            ) : null}
          </>
        ) : null}
        {this.props.children ? (
          <Grid item xs={12}>
            {this.props.children}
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}

export const TabsHeader = withRouter(withStyles(TabsHeaderStyles)(_TabsHeader));
