/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSnackbar, WithSnackbarProps, SnackbarProvider } from 'notistack';
import {
  CheckCircleOutlineOutlined,
  WarningAmberOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  CloseOutlined,
  ExpandMoreOutlined,
} from '@mui/icons-material';
import {
  Collapse,
  Paper,
  Typography,
  Card,
  CardActions,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Box,
} from '@mui/material';
import { getTheme } from '../common/theme';
import { WithStyles, withStyles } from '@mui/styles';

import clsx from 'clsx';

/** SnackbarUtils **/
type SnackbackUtilsProps = {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
};

type SnackMessage = {
  title: string;
  subtitle?: string;
  isError?: boolean;
  action?: {
    title?: string;
    icon?: React.ReactNode;
    run: () => any;
  };
};

type SnackMessageListProps = WithStyles<any> & {
  id: string | number | undefined;
  variant: 'success' | 'warning' | 'error' | 'info';
  message: string | React.ReactNode;
  list?: SnackMessage[];
  isDownload?: boolean;
};

const InnerSnackbarUtilsConfigurator: React.FC<SnackbackUtilsProps> = (props: SnackbackUtilsProps) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = (): any => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

export const SnackbarUtils = {
  close(id: string | number | undefined): void {
    useSnackbarRef.closeSnackbar(id);
  },
  success(
    message: string | React.ReactNode,
    list: SnackMessage[] | undefined = undefined,
    options: any = {},
  ): string | number | undefined {
    return this.toast(message, { ...options, variant: 'success' }, list);
  },
  warning(
    message: string | React.ReactNode,
    list: SnackMessage[] | undefined = undefined,
    options: any = {},
  ): string | number | undefined {
    return this.toast(message, { ...options, variant: 'warning' }, list);
  },
  error(
    message: string | React.ReactNode,
    list: SnackMessage[] | undefined = undefined,
    options: any = {},
  ): string | number | undefined {
    return this.toast(message, { ...options, variant: 'error', autoHideDuration: 30000 }, list);
  },
  info(
    message: string | React.ReactNode,
    list: SnackMessage[] | undefined = undefined,
    options: any = {},
  ): string | number | undefined {
    return this.toast(message, { ...options, variant: 'info' }, list);
  },
  download(
    message: string | React.ReactNode,
    list: SnackMessage[] | undefined = undefined,
    options: any = {},
  ): string | number | undefined {
    return this.toast(message, { ...options, variant: 'info' }, list, true);
  },
  toast(
    message: string | React.ReactNode,
    { variant, ...options }: any = {},
    list: SnackMessage[] | undefined = undefined,
    isDownload = false,
  ): string | number | undefined {
    const content = (key: any) => (
      <div>
        <SnackMessageList id={key} variant={variant} message={message} list={list} isDownload={isDownload} />
      </div>
    );
    const snackId = useSnackbarRef.enqueueSnackbar(message, {
      ...options,
      content,
    });
    return snackId === null ? undefined : snackId;
  },
};

/** SnackMessage **/
const SnackMessageStyles = () => {
  const theme = getTheme();
  return {
    card: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '40%',
        minWidth: 500,
      },
    },
    typography: {
      'font-weight': 'bold',
    },
    actionRoot: {
      padding: '8px 8px 8px 16px',
    },
    action: {
      margin: 0,
    },
    icons: {
      display: 'inline-flex',
      marginLeft: 'auto !important',
    },
    expand: {
      padding: '8px 8px',
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    collapse: {
      padding: 0,
    },
    checkIcon: {
      fontSize: 20,
      color: '#b3b3b3',
      paddingRight: 4,
    },
    button: {
      padding: 0,
      'text-transform': 'none',
    },
    success: { backgroundColor: theme.palette.success.main },
    info: { backgroundColor: theme.palette.info.main },
    warning: { backgroundColor: theme.palette.warning.main },
    error: { backgroundColor: theme.palette.error.main },
    successLight: { backgroundColor: theme.palette.success.light },
    warningLight: { backgroundColor: theme.palette.warning.light },
    errorLight: { backgroundColor: theme.palette.error.light },
    infoLight: { backgroundColor: theme.palette.info.light },
  };
};
const _SnackMessageList = (props: SnackMessageListProps) => {
  const [expanded, handleExpandClick] = useState(false);

  const handleDismiss = (): void => useSnackbarRef.closeSnackbar(props.id);

  const renderListItem = (item: SnackMessage, key: number) => (
    <ListItem key={key}>
      <ListItemText primary={item.title} secondary={item.subtitle || null} />
      {item.action && item.action.icon ? (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label={item.title || ''} onClick={item.action.run} size="large">
            {item.action.icon}
          </IconButton>
        </ListItemSecondaryAction>
      ) : item.action && item.action.title ? (
        <ListItemSecondaryAction>
          <Button size="small" onClick={item.action.run}>
            {item.title}
          </Button>
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );

  const icons = {
    success: <CheckCircleOutlineOutlined className={props.classes.icon} />,
    warning: <WarningAmberOutlined className={props.classes.icon} />,
    error: <ErrorOutlineOutlined className={props.classes.icon} />,
    info: <InfoOutlined className={props.classes.icon} />,
  };

  const renderListContent = () =>
    props &&
    props.list && (
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper
          className={props.classes.collapse}
          classes={{
            root: clsx(props.classes.actionRoot, {
              [props.classes.successLight]: props.variant === 'success',
              [props.classes.warningLight]: props.variant === 'warning',
              [props.classes.errorLight]: props.variant === 'error',
              [props.classes.infoLight]: props.variant === 'info',
            }),
          }}
        >
          <List disablePadding dense>
            {props.list!.map((item, i) => renderListItem(item, i))}
          </List>
        </Paper>
      </Collapse>
    );
  return (
    <Card className={props.classes.card}>
      <CardActions
        classes={{
          root: clsx(props.classes.actionRoot, {
            [props.classes.success]: props.variant === 'success',
            [props.classes.warning]: props.variant === 'warning',
            [props.classes.error]: props.variant === 'error',
            [props.classes.info]: props.variant === 'info',
          }),
        }}
      >
        <div style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
          {!props.list && icons[props.variant]}
        </div>
        <Typography variant="subtitle2" className={props.classes.typography}>
          {props.message}
        </Typography>
        <div className={props.classes.icons}>
          {props.list ? (
            <IconButton
              aria-label="Show more"
              className={clsx(props.classes.expand, props.classes.icons, {
                [props.classes.expandOpen]: expanded,
              })}
              onClick={() => handleExpandClick(!expanded)}
              size="large"
            >
              <ExpandMoreOutlined />
            </IconButton>
          ) : null}
          {props.isDownload ? (
            <Box p={1}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <IconButton className={props.classes.expand} onClick={handleDismiss} aria-label="close" size="large">
              <CloseOutlined />
            </IconButton>
          )}
        </div>
      </CardActions>
      {renderListContent()}
    </Card>
  );
};

const SnackMessageList = withStyles(SnackMessageStyles)(_SnackMessageList);

/** StyledSnackbarProvider **/

const StyledSnackbarProviderStyles = () => {
  const theme = getTheme();
  return {
    root: {
      minWidth: '50%',
    },
    success: { backgroundColor: theme.palette.success.main },
    info: { backgroundColor: theme.palette.info.main },
    warning: { backgroundColor: theme.palette.warning.main },
    error: { backgroundColor: theme.palette.error.main },
    icon: {
      width: 20,
      height: 20,
      marginRight: 8,
    },
  };
};

export type StyledSnackbarProviderProps = WithStyles<any> & { children: any };
const _StyledSnackbarProvider = (props: StyledSnackbarProviderProps) => {
  return (
    <SnackbarProvider
      classes={{
        root: props.classes.root,
        variantSuccess: props.classes.success,
        variantError: props.classes.error,
        variantWarning: props.classes.warning,
        variantInfo: props.classes.info,
      }}
      autoHideDuration={15000}
      maxSnack={1}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      iconVariant={{
        success: <CheckCircleOutlineOutlined className={props.classes.icon} />,
        warning: <WarningAmberOutlined className={props.classes.icon} />,
        error: <ErrorOutlineOutlined className={props.classes.icon} />,
        info: <InfoOutlined className={props.classes.icon} />,
      }}
    >
      <SnackbarUtilsConfigurator />
      {props.children}
    </SnackbarProvider>
  );
};
export const StyledSnackbarProvider = withStyles(StyledSnackbarProviderStyles)(_StyledSnackbarProvider);
