import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, Button, Fab, Grow, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { FirmwareService } from '../../../../services';
import { Dialog, TabsHeader, SnackbarUtils, If } from '../../../../components';
import { withRouter } from 'react-router-dom';
import { PropertiesTab } from '../../../baseClasses/PropertiesTab';
import PackageTab from './PackageTab';
import DevicesTab from './DevicesTab';
import { Trans } from 'react-i18next';
import { ReleaseDetailed, ModalVariants } from '../../../../common/types';
import { itemIcons } from '../../../../common/constants';
import { Action, Dispatch } from 'redux';
import { storeOpenModal } from '../../../../store/actions/modal';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../../baseClasses/ViewBase';
import i18next from 'i18next';
import firmwareSchema from '../../../../assets/schemas/firmware.schema.parsed.json';

type _Props = {
  openModal: (releaseId: string, callback: () => void) => () => void;
  openPublishRelease: (release: ReleaseDetailed, callback: () => void) => void;
  openUpdateEntry: (release: ReleaseDetailed, callback: () => void) => void;
  uploadPackage: (release: ReleaseDetailed, callback: () => void) => void;
};
type Props = ViewBaseProps<_Props, { releaseId: string }>;

type _State = {
  disableDialogOpen: boolean;
};
type State = ViewBaseState<_State>;

class FirmwareReleaseDetailedView extends ViewBase<_Props, _State, { releaseId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
    disableDialogOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.mappedPackages', 'tab.devices', 'tab.properties'];
    FirmwareService.create().then((service) => (this.service = service));
    this.subservice = 'release';
    this.messages = {
      load: { error: 'error.fetch.release' },
      update: { success: 'success.update.release', error: 'error.tryAgain' },
      delete: { success: 'success.delete.release', error: 'error.tryAgain' },
    };
  }

  disable = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.service.release.put(this.props.match.params.releaseId, { enabled: false });
      SnackbarUtils.success(i18next.t('success.disable.release'));
      this.load();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      this.setState({ loading: true });
    }
  };

  renderTabsHeaderButtons = (): React.ReactNode[] | undefined => {
    if (!this.accessControl || !this.accessControl('release', 'update')) return undefined;
    if (!!this.state.item && !!this.state.item.enabled)
      return [
        <Button
          key={0}
          size="small"
          color="secondary"
          variant="text"
          disabled={!this.state.item}
          onClick={() => this.setState({ disableDialogOpen: true })}
        >
          <Trans>action.unpublish</Trans>
        </Button>,
        <Button
          key={1}
          size="small"
          color="secondary"
          variant="text"
          disabled={!this.state.item}
          onClick={() => this.props.openPublishRelease(this.state.item!, this.load)}
        >
          <Trans>action.editTarget</Trans>
        </Button>,
      ];
    return [
      <Button
        key={0}
        size="small"
        color="secondary"
        variant="text"
        disabled={!this.state.item || (!this.state.item.packageMapping && !this.state.item.entrypointPackageId)}
        onClick={() => this.props.openPublishRelease(this.state.item!, this.load)}
      >
        <Trans>action.publish</Trans>
      </Button>,
    ];
  };

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('release', 'update'))
      list.push(
        <MenuItem
          key="1"
          disabled={!this.state.item}
          onClick={() => this.props.openUpdateEntry(this.state.item!, this.load)}
        >
          <Trans>action.updateEntry</Trans>
        </MenuItem>,
      );
    if (this.accessControl('release', 'delete'))
      list.push(
        <MenuItem key="2" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <PackageTab
        tab={0}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
        release={this.state.item}
        reload={this.load}
      />
      <DevicesTab
        tab={1}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
        release={this.state.item}
      />
      <PropertiesTab
        tab={2}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="release"
        parentLoading={this.state.loading}
        schema={firmwareSchema}
        schemaKey="release"
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <>
      <Dialog
        open={this.state.deleteDialogOpen}
        title={<Trans>action.delete</Trans>}
        description={<Trans>description.delete.release</Trans>}
        continueTitle={<Trans>action.delete</Trans>}
        onClose={() => this.setState({ deleteDialogOpen: false })}
        onContinue={this.delete}
      />
      <Dialog
        open={this.state.disableDialogOpen}
        title={<Trans>description.unpublishRelease</Trans>}
        description={<Trans>description.disable.release</Trans>}
        continueTitle={<Trans>action.continue</Trans>}
        onClose={() => this.setState({ disableDialogOpen: false })}
        onContinue={this.disable}
      />
    </>
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={
          this.state.item ? `${this.state.item.toVersion} ${this.state.item.name}` : this.props.match.params.releaseId
        }
        icon={itemIcons.firmwareRelease}
        subtitle={
          this.state.item ? `${i18next.t('release')} | ${this.state.item.productId}` : i18next.t('form.releaseId')
        }
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        buttons={this.renderTabsHeaderButtons()}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
        <If statement={this.accessControl('package', 'create')}>
          <div className={this.props.classes.fabContainer}>
            <Grow in={this.state.activeTab === 0}>
              <Tooltip title={<Trans>action.createFirmwarePackage</Trans>} placement="left">
                <Fab
                  aria-label="add new package"
                  className={this.props.classes.fab}
                  color="secondary"
                  onClick={() => this.props.uploadPackage(this.state.item!, this.load)}
                >
                  {itemIcons.firmwarePackage}
                </Fab>
              </Tooltip>
            </Grow>
          </div>
        </If>
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openPublishRelease: (release: ReleaseDetailed, callback: () => void) =>
    dispatch(storeOpenModal(ModalVariants.PublishReleaseMC, { release, callback })),
  openUpdateEntry: (release: ReleaseDetailed, callback: () => void) =>
    dispatch(storeOpenModal(ModalVariants.UpdateEntryPackageMC, { release, callback })),
  uploadPackage: (release: ReleaseDetailed, callback: () => void) =>
    dispatch(storeOpenModal(ModalVariants.CreateFirmwarePackageMC, { release, callback })),
  ...ViewBaseMapDispatchToProps(dispatch),
});

export default withStyles(ViewBaseStyles)(withRouter(connect(null, mapDispatchToProps)(FirmwareReleaseDetailedView)));
