import React from 'react';
import { DispatchProp, connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@mui/material';
import i18next from 'i18next';
import { SubdomainRoutePath, ModalVariants, DeviceGroup } from '../../../../common/types';
import { ListTable, TabPanel } from '../../../../components';
import { storeOpenModal } from '../../../../store/actions/modal';
import { tableProperties, TableNames } from '../../../../common/constants';
import { DeviceService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';

type Props = DispatchProp &
  RouteComponentProps<{ deviceId: string }> & {
    deviceGroupIds: string[];
    reload: () => Promise<void>;
    openModal: (deviceId: string, deviceGroupIds: string[], callback: () => void) => () => void;
  };

class GroupsTab extends TabViewBase<Props> {
  state: TabViewBaseState = { loading: false, editing: false };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    DeviceService.create().then((service) => (this.service = service));
  }

  reload = this.props.reload;

  deleteDeviceFromDeviceGroup = async (deviceGroupId: string): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.service.deviceGroup.delDeviceFromDeviceGroup(deviceGroupId, this.props.match.params.deviceId);
      await this.reload();
      await new Promise<void>((resolve) =>
        setTimeout(async () => {
          await this.props.reload();
          resolve();
        }, 500),
      );
      SnackbarUtils.success(i18next.t('success.detach.deviceFromDeviceGroup'));
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    if (!this.isUnmounted) this.setState({ loading: false });
  };

  clickRow = (deviceGroup: DeviceGroup): (() => Promise<void>) => async (): Promise<void> => {
    this.props.history.push(SubdomainRoutePath.deviceGroup(deviceGroup.groupId));
  };

  renderContent = (): React.ReactNode => (
    <ListTable
      TableProps={{ disableBackgroundPaper: true }}
      loading={this.props.parentLoading}
      emptyTitle="form.empty.deviceGroups"
      properties={tableProperties(TableNames.deviceDeviceGroupIds)}
      rows={this.props.deviceGroupIds.map((id) => ({ groupId: id }))}
      rowActions={
        this.props.accessControl('deviceGroup', 'update')
          ? [
              {
                title: 'action.detach',
                action: async (deviceGroup: DeviceGroup) => {
                  this.deleteDeviceFromDeviceGroup(deviceGroup.groupId);
                },
              },
            ]
          : undefined
      }
      clickRow={this.clickRow}
    />
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        customAction: (
          <Button
            color="secondary"
            size="small"
            variant="text"
            disabled={this.props.parentLoading}
            onClick={this.props.reload}
          >
            {i18next.t('action.refresh')}
          </Button>
        ),
        ...(this.props.accessControl('deviceGroup', 'update')
          ? {
              actionProps: {
                actionTitle: 'action.attachDeviceGroups',
                onAction: this.props.openModal(
                  this.props.match.params.deviceId,
                  this.props.deviceGroupIds || [],
                  this.reload,
                ),
              },
            }
          : {}),
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (deviceId: string, deviceGroupIds: string[], callback: () => void) => () =>
    dispatch(storeOpenModal(ModalVariants.AddDeviceGroupsToDeviceMC, { deviceId, deviceGroupIds, callback })),
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(GroupsTab));
