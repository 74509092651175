import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SubdomainRoutePath, Device, ModalVariants } from '../../../../common/types';
import { ListTable, TabPanel } from '../../../../components';
import { Dispatch, Action } from 'redux';
import { storeOpenModal } from '../../../../store/actions/modal';
import { tableProperties, TableNames } from '../../../../common/constants';
import { getDefaultTableProperties, DeviceService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';

const styles = () => ({});

type Props = RouteComponentProps<{ groupId: string }> &
  WithStyles<any> & {
    openModal: (groupId: string, callback: () => void) => () => void;
  };

type State = {
  devices: Device[];
  initLoaded: boolean;
  nextToken?: string;
  count?: number;
};

class DevicesTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    devices: [],
    initLoaded: false,
    nextToken: undefined,
    count: undefined,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    DeviceService.create().then((service) => (this.service = service));
  }

  reload = (): void =>
    this.setState({ devices: [], initLoaded: false, nextToken: undefined }, () => this.setState({ initLoaded: true }));

  load = async (): Promise<void> => {
    const { groupId } = this.props.match.params;
    this.setState({ loading: true });
    try {
      const { data } = await this.service.device.list({
        params: { groupId, limit: 50, ...(this.state.nextToken ? { nextToken: this.state.nextToken } : {}) },
      });
      this.setState({
        devices: this.state.devices.concat(data.data as any),
        nextToken: data.nextToken,
        count: (data as any).count,
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.fetch.devices'),
      );
    }
    this.setState({ loading: false });
  };

  delete = async (deviceId: string): Promise<void> => {
    const { groupId } = this.props.match.params;
    try {
      await this.service.deviceGroup.delDeviceFromDeviceGroup(groupId, deviceId);
      this.setState({ devices: this.state.devices.filter((device) => device.deviceId !== deviceId) });
      SnackbarUtils.success(i18next.t('success.detach.deviceFromDeviceGroup'));
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
  };

  clickRow = (device: Device): (() => Promise<void>) => async (): Promise<void> => {
    this.props.history.push(SubdomainRoutePath.device(device.deviceId));
  };

  renderContent = (): React.ReactNode => (
    <ListTable
      InfinityScrollProps={{
        initLoaded: this.state.initLoaded,
        nextToken: this.state.nextToken,
        loadFunc: this.load,
      }}
      TableProps={{ disableBackgroundPaper: true }}
      loading={this.state.loading}
      emptyTitle="form.empty.devices"
      properties={getDefaultTableProperties(tableProperties(TableNames.device))}
      rows={this.state.devices}
      rowActions={
        this.props.accessControl('deviceGroup', 'update')
          ? [
              {
                title: 'action.detach',
                action: async (device: Device) => await this.delete(device.deviceId),
              },
            ]
          : undefined
      }
      clickRow={this.clickRow}
    />
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        count: this.state.count,
        customAction: (
          <Button color="secondary" size="small" variant="text" disabled={this.state.loading} onClick={this.reload}>
            {i18next.t('action.refresh')}
          </Button>
        ),
        actionProps: this.props.accessControl('deviceGroup', 'update')
          ? {
              actionTitle: 'action.attachDevices',
              onAction: this.props.openModal(this.props.match.params.groupId, this.reload),
            }
          : undefined,
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (groupId: string, callback: () => void) => () =>
    dispatch(storeOpenModal(ModalVariants.AddDevicesToDeviceGroupMC, { groupId, callback })),
});

export default withStyles(styles)(withRouter(connect(null, mapDispatchToProps)(DevicesTab)));
