const successSound = require('./success_sound.wav');
const errorSound = require('./error_sound.wav');

export class Sound {
  static play = (sound: any): void => {
    new Audio(sound).play();
  };

  static success = (): void => Sound.play(successSound);

  static error = (): void => Sound.play(errorSound);
}
