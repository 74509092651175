import React from 'react';
import { Paper, IconButton, Grid, Typography, Button, CircularProgress, Collapse } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ArrowDropDown, ArrowDropUp, CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Ajv from 'ajv';
import { SchemaForm } from '../../components';
import { DeviceService, FirmwareService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import fleetSchema from '../../assets/schemas/fleet.schema.parsed.json';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  data: { [key: string]: any };
  optionals: { [key: string]: any };
  isDataValid: boolean;
  isOptionalsValid: boolean;
  loading: boolean;
  products: string[];
  types: string[];
  showOptionals: boolean;
};

class CreateDeviceMC extends React.Component<Props, State> {
  store = store.getState();
  state: State = {
    data: {},
    optionals: {},
    isDataValid: false,
    isOptionalsValid: true,
    loading: false,
    products: [],
    types: [],
    showOptionals: false,
  };
  firmwareService!: FirmwareService;
  deviceService!: DeviceService;
  ajv: any;

  constructor(props: Props) {
    super(props);
    this.ajv = new Ajv({ allErrors: true, useDefaults: true });
    this.ajv.addSchema(fleetSchema, 'fleetSchema');
  }

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
    this.deviceService = await DeviceService.create();
    const { data } = await this.firmwareService.product.list({ params: { limit: 500 } });
    const types = await this.loadDeviceTypes();
    console.log('types', types);
    this.setState({ products: data.data.map((p) => p.productId), types });
  };

  loadDeviceTypes = async (): Promise<any[]> => {
    /*const test = await this.deviceService.deviceType.deviceTypeParameters(
      'legacy',
      {
      params: { parent: '*', limit: 500 },
    });*/
    const { data } = await this.deviceService.deviceType.list({
      params: { parent: '*', limit: 500 },
    });
    //console.log('test', test.data)
    return data.data.map((t: any) => t.id);
  };

  get isSaveDisabled() {
    return !this.state.isDataValid || !this.state.isOptionalsValid;
  }

  loadDeviceGroups = async (groupId: string): Promise<string[]> => {
    const { data } = await this.deviceService.deviceGroup.list({
      params: { parent: '*', groupId: `${groupId}*`, limit: 500 },
    });
    return data.data.map((dg) => dg.groupId);
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });

    try {
      const { groups, ...data } = this.state.data;
      const mergedData = Object.keys(this.state.optionals).length ? { ...this.state.optionals, ...data } : data;
      const { data: device } = await this.deviceService.device.post(mergedData);

      if (!groups || !groups.length) {
        SnackbarUtils.success(i18next.t('success.create.device'));
        if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
        return this.props.closeModal();
      }

      const result = await Promise.all(
        (groups as string[]).map((groupId) =>
          this.deviceService!.deviceGroup.postDeviceToDeviceGroup(groupId, device.deviceId)
            .then(() => ({
              isError: false,
              title: groupId,
              subtitle: i18next.t('success.attach.deviceToDeviceGroup'),
            }))
            .catch((e) => {
              const error: any = e;
              return {
                isError: true,
                title: groupId,
                subtitle:
                  (error.response && error.response.data && error.response.data.message) ||
                  i18next.t('error.attach.deviceToDeviceGroup'),
              };
            }),
        ),
      );

      if (!result.map((res) => res.isError).includes(false)) {
        SnackbarUtils.warning(
          `${i18next.t('error.create.deviceCreatedFailedToAddGroups')} (${groups.length})`,
          result as any,
        );
      } else if (!result.map((res) => res.isError).includes(true)) {
        SnackbarUtils.success(`${i18next.t('success.create.device')} (${groups.length})`, result as any);
      } else {
        SnackbarUtils.warning(
          `${i18next.t('warning.create.deviceCreatedPartiallyAddedGroups')} (${groups.length})`,
          result as any,
        );
      }

      if (this.store.modalStore.data && this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  setValue = (data: { [key: string]: any }, keys: string[], value: any): any => {
    if (keys.length === 0) {
      data = value;
    } else if (keys.length === 1) {
      if (value === null) delete data[keys[0]];
      else data[keys[0]] = value;
    } else {
      data[keys[0]] = this.setValue(data[keys[0]] || {}, keys.slice(1), value);
    }
    return data;
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean) => {
    const data = this.setValue(this.state.data || {}, key === undefined ? [] : key.split('.'), value);
    const ajvIsValid = this.ajv.validate('fleetSchema', { device: data });
    this.setState({ data: data || {}, isDataValid: !!Object.keys(data).length && ajvIsValid && isValid });
  };

  handleUpdateOptional = (key: string | undefined, value: any, isValid: boolean) => {
    const data = this.setValue(this.state.optionals || {}, key === undefined ? [] : key.split('.'), value);
    const ajvIsValid = this.ajv.validate('fleetSchema', { deviceOptionals: data });
    this.setState({ optionals: data || {}, isOptionalsValid: !!Object.keys(data).length && ajvIsValid && isValid });
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.createDevice.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.createDevice.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SchemaForm
              state="CREATE"
              SchemaFormInputProps={{
                productId: {
                  options: this.state.products,
                },
                type: {
                  options: this.state.types,
                },
                groups: {
                  onValueChange: this.loadDeviceGroups,
                },
              }}
              data={{
                ...(fleetSchema as any).properties.device,
              }}
              grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
              defaultValue={this.state.data}
              onChange={this.handleUpdate}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              size="small"
              color="secondary"
              onClick={() => this.setState({ showOptionals: !this.state.showOptionals })}
            >
              {`${this.state.showOptionals ? i18next.t('description.hide') : i18next.t('description.show')} ${i18next.t(
                'description.optional',
              )}`}
              {this.state.showOptionals ? <ArrowDropUp /> : <ArrowDropDown />}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingTop: 0, paddingBottom: this.state.showOptionals ? 16 : 0, marginTop: '20px' }}
          >
            <Collapse in={this.state.showOptionals}>
              <SchemaForm
                state="CREATE"
                data={{
                  ...(fleetSchema as any).properties.deviceOptionals,
                }}
                grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
                defaultValue={this.state.data}
                onChange={this.handleUpdateOptional}
              />
            </Collapse>
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={this.isSaveDisabled} onClick={this.create}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.authorize</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateDeviceMC);
