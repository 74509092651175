import { AuthenticationServiceProvider } from './';
import { AxiosResponse } from 'axios';
import {
  APIPath,
  RoutePathPrefix,
  Package,
  PackageDetailed,
  Product,
  ProductDetailed,
  Release,
  ReleaseDetailed,
  DelReleaseMapping,
  PostReleaseMapping,
  Key,
  KeyDetailed,
  KeyType,
  CheckForUpdate,
} from '../common/types';
import { BaseCRUDService } from './common/BaseCRUDService';

class PackageDataService extends BaseCRUDService<
  Package,
  { bucket: string; key: string; token: string; packageId: string },
  PackageDetailed,
  Package,
  Package
> {
  confirm = ({
    packageId,
    ...data
  }: Pick<Package, 'packageId' | 'name' | 'description' | 'version' | 'buildType'>): Promise<AxiosResponse<Package>> =>
    this.authenticationServiceProvider.Put<Package>({ data, path: APIPath.firmware.packageConfirm(packageId) });
}

class ProductDataService extends BaseCRUDService<Product, Product, ProductDetailed, Product, Product> {}

class ReleaseDataService extends BaseCRUDService<Release, Release, ReleaseDetailed, Release, Release> {
  postMapping = (
    { releaseId, ...data }: PostReleaseMapping,
    params?: { [key: string]: any },
  ): Promise<AxiosResponse<PostReleaseMapping>> =>
    this.authenticationServiceProvider.Post<PostReleaseMapping>({
      data,
      path: APIPath.firmware.releaseMapping(releaseId),
      params,
    });

  delMapping = (
    { releaseId, fromVersion }: DelReleaseMapping,
    params?: { [key: string]: any },
  ): Promise<AxiosResponse<PostReleaseMapping>> =>
    this.authenticationServiceProvider.Delete<PostReleaseMapping>({
      path: APIPath.firmware.releaseMapping(releaseId, fromVersion),
      params,
    });

  setEntrypoint = (
    releaseId: string,
    data: { packageId: string },
    params?: { force?: boolean },
  ): Promise<AxiosResponse<PostReleaseMapping>> => {
    return this.authenticationServiceProvider.Post({
      path: APIPath.firmware.releaseEntrypoint(releaseId),
      data,
      params,
    });
  };

  deleteEntrypoint = (releaseId: string, params?: { force?: boolean }): Promise<AxiosResponse<PostReleaseMapping>> =>
    this.authenticationServiceProvider.Delete({ path: APIPath.firmware.releaseEntrypoint(releaseId), params });
}

class KeyDataService extends BaseCRUDService<Key, Key, KeyDetailed, Key, Key> {
  getKeyTypes = (): Promise<AxiosResponse<KeyType[]>> =>
    this.authenticationServiceProvider.Get<KeyType[]>({ path: APIPath.firmware.keyType() });
}

class CheckForUpdateDataService extends BaseCRUDService<CheckForUpdate, CheckForUpdate, CheckForUpdate, CheckForUpdate, CheckForUpdate> {
  checkForUpdate = (deviceId: string, desiredProductId?: string): Promise<AxiosResponse<CheckForUpdate>> => this.authenticationServiceProvider.Post({
    path: APIPath.firmware.checkForUpdate(),
    data: {
      deviceId,
      desiredProductId
    }
  })
}

export class FirmwareService {
  package: PackageDataService;
  product: ProductDataService;
  release: ReleaseDataService;
  key: KeyDataService;
  checkForUpdate: CheckForUpdateDataService;

  constructor(public authenticationServiceProvider: AuthenticationServiceProvider) {
    this.package = new PackageDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.firmware.package,
      get: APIPath.firmware.packageDetailed,
      post: APIPath.firmware.packagePrepare,
    });
    this.product = new ProductDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.firmware.product,
      get: APIPath.firmware.productDetailed,
    });
    this.release = new ReleaseDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.firmware.release,
      get: APIPath.firmware.releaseDetailed,
    });
    this.key = new KeyDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.firmware.key,
      get: APIPath.firmware.keyDetailed,
    });
    this.checkForUpdate = new CheckForUpdateDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.firmware.checkForUpdate,
      get: APIPath.firmware.checkForUpdate,
      post: APIPath.firmware.checkForUpdate,
    })
  }

  static create = async (): Promise<FirmwareService> => {
    const authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (!authenticationServiceProvider) throw new Error('No cached AuthenticationService available in FirmwareService');
    return new FirmwareService(authenticationServiceProvider);
  };
}
