import React, { useRef, useCallback, ChangeEvent, FocusEvent, FC } from 'react';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/base';

type OnChangeFn = (data: string, error?: string) => void;

type JsonInputNativeProps = Omit<
  TextareaAutosizeProps,
  'onChange' | 'onBlur' | 'value'
> & { onChange: OnChangeFn };

const isSaving = (target: HTMLButtonElement | null) => {
  if (!target || !('innerText' in target)) {
    return false;
  }

  return target.innerText === 'SAVE';
};

export const JsonInputNative: FC<JsonInputNativeProps> = ({
  defaultValue,
  onChange,
  ...props
}) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }

      try {
        const jsonData = JSON.parse(value);
        onChange(jsonData);
      } catch (error: unknown) {
        timer.current = setTimeout(() => {
          onChange(value, (error as SyntaxError).message);
        }, 1000);
      }
    },
    [onChange],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      const relatedTarget = e.relatedTarget as HTMLButtonElement | null;

      if (isSaving(relatedTarget)) {
        return;
      }

      try {
        JSON.parse(value);
      } catch (error: unknown) {
        onChange(value, (error as SyntaxError).message);
      }
    },
    [onChange],
  );

  return (
    <TextareaAutosize
      defaultValue={defaultValue}
      onChange={handleChange}
      onBlur={handleBlur}
      spellCheck={false}
      {...props}
    />
  );
};
