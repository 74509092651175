import { createTheme } from '@mui/material/styles';
import { store } from '../store';

// iris
import irisDev from '../assets/config/styles/iristest-dev.json';
import irisStage from '../assets/config/styles/iristest-stage.json';
import irisProd from '../assets/config/styles/iristest-prod.json';

// mSafety
import mSafetyDev from '../assets/config/styles/carron-dev.json';
import mSafetyStage from '../assets/config/styles/carron-stage.json';
import mSafetyProd from '../assets/config/styles/carron-prod.json';

import { red, green, blue, yellow } from '@mui/material/colors';

// iris
const irisBLACK = require('../assets/images/iris-black.svg');
const irisWHITE = require('../assets/images/iris-white.svg');

// mSafety
const mSafetyBLACK = require('../assets/images/mSafety_logo_black.png');
const mSafetyWHITE = require('../assets/images/mSafety_logo_white.png');

/*
  WARNING! LOGOS is based on theme, if you create a custom theme you need to add a logo for that theme.
*/
const THEMES = {
  // default
  '0': {
    logoblack: irisBLACK,
    logowhite: irisWHITE,
    theme: irisDev,
  },

  // iris
  '1': {
    logoblack: irisBLACK,
    logowhite: irisWHITE,
    theme: irisDev,
  },
  '21': {
    logoblack: irisBLACK,
    logowhite: irisWHITE,
    theme: irisStage,
  },
  '31': {
    logoblack: irisBLACK,
    logowhite: irisWHITE,
    theme: irisProd,
  },

  // carron
  '2': {
    logoblack: mSafetyBLACK,
    logowhite: mSafetyWHITE,
    theme: mSafetyDev,
  },
  '22': {
    logoblack: mSafetyBLACK,
    logowhite: mSafetyWHITE,
    theme: mSafetyStage,
  },
  '32': {
    logoblack: mSafetyBLACK,
    logowhite: mSafetyWHITE,
    theme: mSafetyProd,
  },
};

const isObj = (data: any): boolean => data && typeof data === 'object' && !Array.isArray(data);
const mergeDeep = (target: any, ...sources: any): any => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObj(target) && isObj(source)) {
    for (const key in source) {
      if (isObj(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

const theme = {
  palette: {
    primary: {
      light: '#718792',
      main: '#455a64',
      dark: '#1c313a',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#718792',
      main: '#455a64',
      dark: '#1c313a',
      contrastText: '#ffffff',
    },
    success: {
      light: green[300],
      main: green[500],
      dark: green[800],
    },
    warning: {
      light: yellow[300],
      main: yellow[500],
      dark: yellow[800],
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[800],
      contrastText: '#ffffff',
    },
    info: {
      light: blue[300],
      main: blue[500],
      dark: blue[800],
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.97)',
      secondary: 'rgba(0, 0, 0, 0.68)',
      disabled: 'rgba(0, 0, 0, 0.54)',
      hint: 'rgba(0, 0, 0, 0.54)',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 2048,
    },
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2.25rem',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 300,
    },
    h4: {
      fontSize: '1.8rem',
    },
  },
};

export const getTheme = (themeName: string | undefined = undefined): any => {
  const { userStore } = store.getState();
  const _themeName = themeName || (userStore.config && userStore.config.themeName) || '0';
  if (_themeName && {}.hasOwnProperty.call(THEMES, _themeName)) {
    if (userStore.config?.themeName === '1' && userStore.config?.alias.startsWith('hack')) {
      return createTheme(mergeDeep(theme, (THEMES as any)['22'].theme));
    } else {
      return createTheme(mergeDeep(theme, (THEMES as any)[_themeName].theme));
    }
  }
  throw new Error(`Theme "${themeName}" does not exist!`);
};

export const getLogo = (color: 'white' | 'black' = 'black'): any => {
  const { userStore } = store.getState();
  const themeName = (userStore.config && userStore.config.themeName) || '0';
  if (themeName && {}.hasOwnProperty.call(THEMES, themeName)) {
    return (THEMES as any)[themeName][`logo${color}`].default;
  }
  throw new Error(`Theme "${themeName}" does not exist!`);
};

export const getWebsiteTitle = (owner: string): string => {
  switch (owner) {
    case 'carron':
      return 'mSafety portal';
    case 'iris':
      return 'IRIS portal';
    default:
      return 'Portal';
  }
};
