import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { appBarHeight } from '../../common/constants';
import { getTheme } from '../../common/theme';

import logo from '../../assets/images/mSafety_logo_white.png';

const styles = () => {
  const theme = getTheme();
  return {
    container: {
      width: '100%',
      minHeight: `calc(100vh - ${appBarHeight}px)`,
      [theme.breakpoints.down('md')]: {
        minHeight: `calc(100vh - ${appBarHeight * 2}px)`,
      },
    },
  };
};

type Props = WithStyles<any>;

class HomeView extends Component<Props> {
  render() {
    return (
      <Grid className={this.props.classes.container} container alignContent="center" justifyContent="center">
        <Grid item xs={12} md={9} lg={8} xl={6}>
          <img src={logo} width="100%" alt="logo" />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(HomeView);
