import React, { Component } from 'react';
import { Paper, IconButton, Grid, Typography, Button, CircularProgress } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CloseOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Ajv from 'ajv';
import { SchemaForm } from '../../components';
import { FirmwareService } from '../../services';
import { store } from '../../store';
import { BaseModalStyles } from './common/modalUtils';
import { SnackbarUtils } from '../StyledSnackbarProvider';
import firmwareSchema from '../../assets/schemas/firmware.schema.parsed.json';

type Props = WithStyles<any> & {
  closeModal: () => void;
};

type State = {
  data: { [key: string]: any };
  isSaveDisabled: boolean;
  loading: boolean;
};

class CreateFirmwareProductMC extends Component<Props, State> {
  store = store.getState();
  state: State = {
    data: {},
    isSaveDisabled: true,
    loading: false,
  };
  firmwareService!: FirmwareService;
  ajv: any;

  constructor(props: Props) {
    super(props);
    this.ajv = new Ajv({ allErrors: true, useDefaults: true });
    this.ajv.addSchema(firmwareSchema, 'firmwareSchema');
  }

  componentDidMount = async (): Promise<void> => {
    this.firmwareService = await FirmwareService.create();
  };

  create = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.firmwareService.product.post(this.state.data);
      SnackbarUtils.success(i18next.t('success.create.product'));
      if (this.store.modalStore.data.callback) this.store.modalStore.data.callback();
      this.props.closeModal();
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false });
  };

  setValue = (data: { [key: string]: any }, keys: string[], value: any): any => {
    if (keys.length === 0) {
      data = value;
    } else if (keys.length === 1) {
      data[keys[0]] = value;
    } else {
      data[keys[0]] = this.setValue(data[keys[0]] || {}, keys.slice(1), value);
    }
    return data;
  };

  handleUpdate = (key: string | undefined, value: any, isValid: boolean) => {
    const data = this.setValue(this.state.data || {}, key === undefined ? [] : key.split('.'), value);
    const ajvIsValid = this.ajv.validate('firmwareSchema', { product: data });
    this.setState({ data: data || {}, isSaveDisabled: !(!!Object.keys(data).length && ajvIsValid && isValid) });
  };

  render = (): React.ReactNode => {
    return (
      <Paper tabIndex={-1} square className={this.props.classes.paper}>
        <IconButton
          style={{ ...BaseModalStyles().closeButton }}
          onClick={this.props.closeModal}
          aria-label="close"
          size="large"
        >
          <CloseOutlined />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              <Trans>modals.createFirmwareProduct.title</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans>modals.createFirmwareProduct.description</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SchemaForm
              state="CREATE"
              data={{
                ...(firmwareSchema as any).properties.product,
              }}
              grid={{ sm: 12, md: 12, lg: 12, xl: 12 }}
              defaultValue={this.state.data}
              onChange={this.handleUpdate}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={this.state.isSaveDisabled} onClick={this.create}>
              {this.state.loading ? <CircularProgress size={24} color="inherit" /> : <Trans>action.create</Trans>}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(BaseModalStyles)(CreateFirmwareProductMC);
