import React from 'react';
import { Box, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import i18next from 'i18next';
import { PageLoader, TabPanel } from '../../../../components';
import { TabViewBase, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { getTheme } from '../../../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    shadowBox: {
      padding: theme.spacing(2),
      backgroundColor: 'rgba(0, 0, 0, .08)',
    },
  };
};

type Props = WithStyles<any> & {
  metadata?: { [key: string]: any };
  reload: () => Promise<void>;
};

class MetadataTab extends TabViewBase<Props> {
  state: TabViewBaseState = {
    loading: false,
    editing: false,
  };

  reload = this.props.reload;

  renderContent = (): React.ReactNode => (
    <Box className={this.props.classes.shadowBox}>
      <Typography component="pre" variant="body1">
        {this.props.metadata
          ? JSON.stringify(this.props.metadata, undefined, 2)
          : i18next.t('description.noMetadataAvailable')}
      </Typography>
    </Box>
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.props.parentLoading,
        },
      }}
    >
      {this.props.parentLoading && <PageLoader />}
      {!this.props.parentLoading && this.renderContent()}
    </TabPanel>
  );
}

export default withStyles(styles)(MetadataTab);
