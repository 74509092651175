import React, { Component } from 'react';
import { ListItemText, Menu, MenuItem, Divider } from '@mui/material';
import { AuthenticationServiceProvider } from '../../services';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { SubdomainRoutePath, ModalVariants, Role, Store } from '../../common/types';
import { storeOpenModal } from '../../store/actions/modal';
import { storeSetRightNavOpen } from '../../store/actions/common';
import packageJson from '../../../package.json';

type Props = RouteComponentProps & {
  open: boolean;
  role: Role;
  buttonRef: React.RefObject<HTMLButtonElement>;
  setRightNavOpen: (open: boolean) => void;
  openChangePasswordModal: () => void;
  openOpenSourceModal: () => void;
};

type State = {
  email: string;
  milieu: string;
  role: Role | undefined;
};

class NavRight extends Component<Props, State> {
  state: State = {
    email: '',
    milieu: '',
    role: undefined,
  };
  _isMounted = true;
  authenticationServiceProvider?: AuthenticationServiceProvider;

  componentDidMount = async (): Promise<void> => {
    this.authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (this.authenticationServiceProvider) {
      const { userSession, refresh } = this.authenticationServiceProvider.service;
      if (userSession && !userSession?.isValid()) {
        await refresh();
      }
      if (this._isMounted) {
        this.setState({
          email: await this.authenticationServiceProvider!.service.username(),
          milieu: this.authenticationServiceProvider!.iris?.milieu || '',
          role: this.authenticationServiceProvider.getRole(),
        });
      }
    }
  };

  render = (): React.ReactNode => {
    const { setRightNavOpen, openChangePasswordModal, open, buttonRef, openOpenSourceModal, history } = this.props;

    return (
      <Menu
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={buttonRef.current}
        onClose={() => setRightNavOpen(false)}
        onClick={() => setRightNavOpen(false)}
      >
        {this.state.email ? (
          <MenuItem disabled>
            <ListItemText primary={this.state.email} secondary={i18next.t('navigation.right.email')} />
          </MenuItem>
        ) : null}
        {this.state.role ? (
          <MenuItem disabled>
            <ListItemText primary={this.state.role} secondary={i18next.t('navigation.right.role')} />
          </MenuItem>
        ) : null}
        {this.state.milieu ? (
          <MenuItem disabled>
            <ListItemText primary={this.state.milieu} secondary={i18next.t('navigation.right.region')} />
          </MenuItem>
        ) : null}
        {packageJson.version && (
          <MenuItem
            onClick={() =>
              history.push(`${SubdomainRoutePath.docsAndDownloads()}/documentation/10-backend/90-release_notes`)
            }
          >
            <ListItemText primary={packageJson.version} secondary={i18next.t('navigation.right.version')} />
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={openChangePasswordModal}>
          <Trans>action.changePassword</Trans>
        </MenuItem>
        <MenuItem onClick={openOpenSourceModal}>
          <Trans>action.openSourceLicenses</Trans>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (this.props.role === Role.DOCUMENTATION || this.props.role === Role.USER) {
              history.push(SubdomainRoutePath.settings());
            } else {
              const userId = this.authenticationServiceProvider?.service.userId();
              history.push(SubdomainRoutePath.user(userId));
            }
          }}
        >
          <Trans>routes.settings</Trans>
        </MenuItem>
        <MenuItem
          onClick={() => {
            AuthenticationServiceProvider.clear();
            window.location.href = window.location.origin + SubdomainRoutePath.signIn();
          }}
        >
          <Trans>action.signOut</Trans>
        </MenuItem>
      </Menu>
    );
  };
}

const mapStateToProps = ({ commonStore }: Store) => ({
  open: commonStore.rightNavOpen,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openChangePasswordModal: () => dispatch(storeOpenModal(ModalVariants.ChangePasswordMC)),
  openOpenSourceModal: () => dispatch(storeOpenModal(ModalVariants.OpenSourceMC)),
  setRightNavOpen: (open: boolean) => dispatch(storeSetRightNavOpen(open)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavRight));
