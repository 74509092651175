import { AuthenticationServiceProvider } from './';
import { AxiosResponse } from 'axios';
import {
  Device,
  DeviceTypes,
  DeviceDetailed,
  DeviceGroup,
  DeviceGroupDetailed,
  ShadowAlias,
  ShadowTemplate,
  ShadowTemplateDetailed,
  AwsRequestConfig,
  APIPath,
  DeviceConnection,
  RoutePathPrefix,
  Trigger,
} from '../common/types';
import { BaseCRUDService } from './common/BaseCRUDService';

class DeviceDataService extends BaseCRUDService<Device, Device, DeviceDetailed, Device, Device> {
  getConnections = (deviceId: string): Promise<AxiosResponse<{ data: DeviceConnection[] }>> =>
    this.authenticationServiceProvider.Get<{ data: DeviceConnection[] }>({
      path: APIPath.fleet.deviceConnections(deviceId),
    });

  isConnected = async (deviceId: string): Promise<boolean> => {
    const connections = await this.getConnections(deviceId);
    if (connections) {
      if (connections.data.data.length === 0) return false;
      const { disconnected } = connections.data.data[0];
      return !disconnected;
    }
    return false;
  };

  getShadow = (
    config: Partial<AwsRequestConfig> & { deviceId: string },
  ): Promise<AxiosResponse<{ [key: string]: any }>> => {
    const { deviceId, ...partilConfig } = config;
    return this.authenticationServiceProvider.Get<{ [key: string]: any }>({
      ...partilConfig,
      path: APIPath.fleet.deviceShadow(deviceId),
    });
  };

  putShadow = (deviceId: string, params: any, data: any): Promise<AxiosResponse<DeviceDetailed>> =>
    this.authenticationServiceProvider.Put<DeviceDetailed>({
      params,
      data,
      path: APIPath.fleet.deviceShadow(deviceId),
    });
}

class DeviceTypeService extends BaseCRUDService<DeviceTypes, DeviceTypes, DeviceDetailed, DeviceTypes, DeviceTypes> {
  deviceTypeParameters = (deviceId: string, params: any): Promise<AxiosResponse<any[]>> => {
    return this.authenticationServiceProvider.Get<any[]>({
      data: { deviceId },
      params,
      path: APIPath.fleet.deviceType(deviceId),
    });
  };
}

class DeviceGroupDataService extends BaseCRUDService<
  DeviceGroup,
  DeviceGroup,
  DeviceGroupDetailed,
  DeviceGroup,
  DeviceGroup
> {
  postDeviceToDeviceGroup = async (
    groupId: string,
    deviceId: string,
  ): Promise<AxiosResponse<{ groupId: string; deviceId: string }>> =>
    this.authenticationServiceProvider.Post<{ groupId: string; deviceId: string }>({
      data: { deviceId },
      path: APIPath.fleet.deviceAddToDeviceGroup(groupId),
    });

  delDeviceFromDeviceGroup = async (
    groupId: string,
    deviceId: string,
  ): Promise<AxiosResponse<{ groupId: string; deviceId: string }>> =>
    this.authenticationServiceProvider.Delete<{ groupId: string; deviceId: string }>({
      path: APIPath.fleet.deviceRemoveFromDeviceGroup(groupId, deviceId),
    });

  getTrigger = async (
    groupId: string,
  ): Promise<AxiosResponse<{ [key: string]: any; groupChangeTrigger: { groupId: string } }>> => {
    return this.authenticationServiceProvider.Get({
      path: APIPath.fleet.trigger(),
      params: { triggerType: 'GROUP_CHANGE', groupId: groupId },
    });
  };

  getTriggers = async (): Promise<AxiosResponse<{ [key: string]: any; groupChangeTrigger: { groupId: string } }>> => {
    return this.authenticationServiceProvider.Get({
      path: APIPath.fleet.triggers(),
    });
  };

  updateTrigger = async (
    data: Partial<Trigger>,
    triggerId: string,
  ): Promise<AxiosResponse<{ data: Trigger; triggerId: string }>> => {
    return this.authenticationServiceProvider.Put<{ data: Trigger; triggerId: string }>({
      data,
      path: APIPath.fleet.triggers(triggerId),
    });
  };

  createTrigger = async (data: Trigger): Promise<AxiosResponse<{ data: Trigger }>> => {
    return this.authenticationServiceProvider.Post<{ data: Trigger }>({
      data,
      path: APIPath.fleet.triggers(),
    });
  };

  deleteTrigger = async (triggerId: string): Promise<AxiosResponse<{ triggerId: string }>> => {
    return this.authenticationServiceProvider.Delete<{ triggerId: string }>({
      path: APIPath.fleet.triggers(triggerId),
    });
  };
}

class ShadowAliasDataService extends BaseCRUDService<ShadowAlias, ShadowAlias, ShadowAlias, ShadowAlias, ShadowAlias> {}

class ShadowTemplateDataService extends BaseCRUDService<
  ShadowTemplate,
  ShadowTemplate,
  ShadowTemplateDetailed,
  ShadowTemplate,
  ShadowTemplate
> {
  setDefault = async (templateId: string): Promise<AxiosResponse<unknown>> =>
    this.authenticationServiceProvider.Post<{ groupId: string; deviceId: string }>({
      data: { templateId },
      path: APIPath.fleet.shadowTemplatesDefault(),
    });

  deleteDefault = async (): Promise<AxiosResponse<unknown>> =>
    this.authenticationServiceProvider.Delete<{ groupId: string; deviceId: string }>({
      path: APIPath.fleet.shadowTemplatesDefault(),
    });
}

export class DeviceService {
  device: DeviceDataService;
  deviceGroup: DeviceGroupDataService;
  shadowAlias: ShadowAliasDataService;
  shadowTemplate: ShadowTemplateDataService;
  deviceType: DeviceTypeService;

  constructor(public authenticationServiceProvider: AuthenticationServiceProvider) {
    this.device = new DeviceDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.fleet.devices,
      get: APIPath.fleet.deviceDetailed,
    });
    this.deviceGroup = new DeviceGroupDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.fleet.deviceGroups,
      get: APIPath.fleet.deviceGroupDetailed,
    });
    this.shadowAlias = new ShadowAliasDataService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.fleet.shadowAliases,
      get: APIPath.fleet.shadowAliasDetailed,
    });
    this.shadowTemplate = new ShadowTemplateDataService(
      this.authenticationServiceProvider,
      RoutePathPrefix.operations,
      {
        list: APIPath.fleet.shadowTemplates,
        get: APIPath.fleet.shadowTemplateDetailed,
      },
    );
    this.deviceType = new DeviceTypeService(this.authenticationServiceProvider, RoutePathPrefix.operations, {
      list: APIPath.fleet.deviceTypes,
      get: APIPath.fleet.deviceType,
    });
  }

  static create = async (): Promise<DeviceService> => {
    const authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (!authenticationServiceProvider)
      throw new Error('No cached AuthenticationServiceProvider available in DeviceService');
    return new DeviceService(authenticationServiceProvider);
  };
}
