import React, { Component } from 'react';
import { Typography, Box, Paper, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { AuthenticationServiceProvider } from '../../../services';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { CustomerConfiguration, Store } from '../../../common/types';
import { TabsHeader } from '../../../components';
import { itemIcons } from '../../../common/constants';
import i18next from 'i18next';
import { getTheme } from '../../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    root: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
      },
    },
    paper: {
      padding: theme.spacing(2, 2, 1),
      height: '100%',
    },
    h6Item: {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
    subItem: {
      paddingTop: '8px',
      paddingBottom: '16px',
    },
    caption: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '12px',
      marginTop: '-8px',
    },
    body1: {
      fontSize: '16px',
      marginTop: '6px',
    },
  };
};

type Props = WithStyles<any> & {
  config?: CustomerConfiguration;
};

type State = {
  customerId: string;
  apiKey: string;
  apiV2: string;
  iotEndpoint: string;
  roleArns: string[];
};

class ConfigurationView extends Component<Props, State> {
  state: State = {
    customerId: '',
    apiKey: '',
    apiV2: '',
    iotEndpoint: '',
    roleArns: [],
  };
  authenticationServiceProvider?: AuthenticationServiceProvider;
  _isMounted = true;

  componentDidMount = async (): Promise<void> => {
    this.authenticationServiceProvider = await AuthenticationServiceProvider.createFromCache();
    if (!this.authenticationServiceProvider || !this.authenticationServiceProvider.iris) return;
    const roleArnPrefixSplit = this.authenticationServiceProvider.iris.roleArn.split('-');
    const roleArnPrefix = roleArnPrefixSplit.slice(0, roleArnPrefixSplit.length - 1).join('-');

    const data = {
      customerId: this.authenticationServiceProvider.iris.roleArn.split('-')[1],
      apiKey: this.authenticationServiceProvider.iris.apiKey,
      apiV2: `https://${this.authenticationServiceProvider.iris.apiV2}.${this.authenticationServiceProvider.iris.domain}`,
      iotEndpoint: this.authenticationServiceProvider.iris.iotEndpoint,
      roleArns: ['admin', 'developer', 'poweruser', 'user', 'access'].map((role) => `${roleArnPrefix}-${role}`),
    };
    if (this._isMounted) this.setState(data);
  };

  renderProperty = (key: string, value: string | string[]): React.ReactNode => (
    <Box pb={1}>
      <Typography variant="caption" classes={{ caption: this.props.classes.caption }}>
        {key}
      </Typography>
      {Array.isArray(value) ? (
        value.map((v, i) => (
          <Typography key={i} variant="body1" classes={{ body1: this.props.classes.body1 }}>
            {v}
          </Typography>
        ))
      ) : (
        <Typography variant="body1" classes={{ body1: this.props.classes.body1 }}>
          {value}
        </Typography>
      )}
    </Box>
  );

  renderContent = (): React.ReactNode => {
    const { classes, config } = this.props;
    const { apiKey, apiV2, customerId, roleArns, iotEndpoint } = this.state;
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Typography variant="h6" classes={{ h6: this.props.classes.h6Item }}>
                    <Trans>config.customer</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12} classes={{ root: classes.subItem }}>
                  {this.renderProperty('customerId', customerId)}
                </Grid>
                <Grid item xs={12} classes={{ root: classes.subItem }}>
                  {this.renderProperty('alias', (config && config.alias) || '')}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Typography variant="h6" classes={{ h6: this.props.classes.h6Item }}>
                    <Trans>config.api</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12} classes={{ root: classes.subItem }}>
                  {this.renderProperty('apiKey', apiKey)}
                </Grid>
                <Grid item xs={12} classes={{ root: classes.subItem }}>
                  {this.renderProperty('apiV2', apiV2)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Typography variant="h6" classes={{ h6: this.props.classes.h6Item }}>
                    <Trans>config.cognito</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12} classes={{ root: classes.subItem }}>
                  {this.renderProperty('identityPoolId', (config && config.identityPoolId) || '')}
                </Grid>
                <Grid item xs={12} classes={{ root: classes.subItem }}>
                  {this.renderProperty('userPoolId', (config && config.userPoolId) || '')}
                </Grid>
                <Grid item xs={12} classes={{ root: classes.subItem }}>
                  {this.renderProperty('clientId', (config && config.clientId) || '')}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Typography variant="h6" classes={{ h6: this.props.classes.h6Item }}>
                    <Trans>config.role</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12} classes={{ root: classes.subItem }}>
                  {this.renderProperty('roleArns', roleArns)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Typography variant="h6" classes={{ h6: this.props.classes.h6Item }}>
                    <Trans>config.iot</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12} classes={{ root: classes.subItem }}>
                  {this.renderProperty('iotEndpoint', iotEndpoint)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  };

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={this.state.customerId}
        icon={itemIcons.account}
        subtitle={i18next.t('routes.customerConfiguration')}
      >
        {this.renderContent()}
      </TabsHeader>
    </div>
  );
}

const mapStateToProps = ({ userStore }: Store) => ({
  config: userStore.config,
});

export default withStyles(styles)(connect(mapStateToProps, null)(ConfigurationView));
