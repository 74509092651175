import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import { DeviceService } from '../../../../services';
import { ModalVariants, Trigger } from '../../../../common/types';
import { Dialog, TabsHeader } from '../../../../components';
import { PropertiesTab } from '../../../baseClasses/PropertiesTab';
import ChildGroupsTab from './ChildGroupsTab';
import DevicesTab from './DevicesTab';
import TriggersTab from './TriggersTab';

import { itemIcons } from '../../../../common/constants';
import { storeOpenModal } from '../../../../store/actions/modal';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../../baseClasses/ViewBase';
import fleetSchema from '../../../../assets/schemas/fleet.schema.parsed.json';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';

type _Props = {
  openModal: (groupId: string, callback: () => void) => () => void;
};
type Props = ViewBaseProps<_Props, { groupId: string }>;

type _State = unknown & { triggers: Trigger[] };
type State = ViewBaseState<_State>;

class DeviceGroupDetailedView extends ViewBase<_Props, _State, { groupId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
    triggers: [],
  };

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.devices', 'tab.childGroups', 'tab.properties', 'tab.triggers'];
    DeviceService.create()
      .then((service) => (this.service = service))
      .then(() => this.loadTriggers());
    this.subservice = 'deviceGroup';
    this.messages = {
      load: { error: 'error.fetch.deviceGroup' },
      update: { success: 'success.update.deviceGroup', error: 'error.tryAgain' },
      delete: { success: 'success.delete.deviceGroup', error: 'error.tryAgain' },
    };
  }

  loadTriggers = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const { data } = await this.service.deviceGroup.getTrigger(this.props.match.params.groupId);
      this.setState({
        loading: false,
        triggers: data.data,
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.fetch.triggers'),
      );
      this.setState({ loading: false });
    }
  };

  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('deviceGroup', 'delete'))
      list.push(
        <MenuItem key="1" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <DevicesTab
        tab={0}
        activeTab={this.state.activeTab}
        parentLoading={this.state.loading}
        accessControl={this.accessControl}
      />
      <ChildGroupsTab
        tab={1}
        activeTab={this.state.activeTab}
        parentLoading={this.state.loading}
        accessControl={this.accessControl}
      />
      <PropertiesTab
        tab={2}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="deviceGroup"
        parentLoading={this.state.loading}
        schema={fleetSchema}
        schemaKey="deviceGroup"
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
      <TriggersTab
        tab={3}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="deviceGroup"
        parentLoading={this.state.loading}
        schema={fleetSchema}
        schemaKey="triggers"
        item={this.state.item}
        reload={this.loadTriggers}
        triggers={this.state.triggers}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <Dialog
      open={this.state.deleteDialogOpen}
      title={<Trans>action.delete</Trans>}
      description={<Trans>description.delete.deviceGroup</Trans>}
      continueTitle={<Trans>action.delete</Trans>}
      onClose={() => this.setState({ deleteDialogOpen: false })}
      onContinue={this.delete}
    />
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={this.props.match.params.groupId}
        icon={itemIcons.deviceGroup}
        subtitle={i18next.t('form.groupId')}
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (groupId: string, callback: () => void) => () =>
    dispatch(storeOpenModal(ModalVariants.AddDevicesToDeviceGroupMC, { groupId, callback })),
  ...ViewBaseMapDispatchToProps(dispatch),
});

export default withStyles(ViewBaseStyles)(withRouter(connect(null, mapDispatchToProps)(DeviceGroupDetailedView)));
