import React, { Component } from 'react';
import { Paper, Typography, Grid, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from 'react-i18next';
import { store } from '../../store';
import { getTheme } from '../../common/theme';

const styles = () => {
  const theme = getTheme();
  return {
    grid: {
      outline: 'none',
    },
    paper: {
      position: 'relative' as const,
      maxHeight: '100vh',
      backgroundColor: '#fff',
      padding: theme.spacing(4),
      'overflow-x': 'hidden',
      'overflow-y': 'auto',
    },
  };
};

type Props = WithStyles<any> & {
  closeModal: () => void;
};

export class DialogMC extends Component<Props> {
  store = store.getState();
  render = (): React.ReactNode => {
    return (
      <Grid container alignItems="center" justifyContent="center" className={this.props.classes.grid}>
        <Grid item xs={12} md={8} lg={5} xl={4}>
          <Paper tabIndex={-1} className={this.props.classes.paper}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  <Trans>{this.store.modalStore.data!.title}</Trans>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Trans>{this.store.modalStore.data!.description}</Trans>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <Button color="secondary" onClick={this.props.closeModal}>
                      <Trans>action.cancel</Trans>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button color="secondary" variant="contained" onClick={this.store.modalStore.data!.onContinue}>
                      <Trans>action.add</Trans>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  };
}

export default withStyles(styles)(DialogMC);
