import { DeviceStoreAction, StoreName } from '../../common/types';
import { actions } from '../actions/device';
import DeviceInitialState from '../init/device';

export default (state = DeviceInitialState, { store, type, ...data }: DeviceStoreAction) => {
  if (store !== StoreName.DEVICE) return state;
  switch (type) {
    case actions.SET_SHADOW_ALIASES:
      return { ...state, ...data };
    default:
      return state;
  }
};
