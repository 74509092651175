import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Action, Dispatch } from 'redux';
import i18next from 'i18next';
import { FirmwareService, FilterService } from '../../../services';
import { SubdomainRoutePath, ModalVariants, Product } from '../../../common/types';
import { connect } from 'react-redux';
import { storeOpenModal } from '../../../store/actions/modal';
import { tableProperties, listFilter, itemIcons, TableNames } from '../../../common/constants';
import { SnackbarUtils } from '../../../components';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';

type _Props = {
  openModal: (callback: () => any) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class FirmwareProductView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
  };

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.filterService = new FilterService(this, 'product');
    this.accessControlSection = 'product';
    this.title = i18next.t('products');
    this.icon = itemIcons.firmwareProduct;
    this.idKey = 'productId';
    this.filterConfig = {
      listFilter: listFilter.firmwareProduct,
      availableProperties: Object.keys(tableProperties(TableNames.product)),
    };
    this.tableConfig = {
      tableProperties: tableProperties(TableNames.product),
      emptyTitle: 'form.empty.products',
      rowActions: [
        {
          title: 'action.delete',
          action: async (product: Product) => this.setState({ dialogOpen: product.productId }),
        },
      ],
    };
    this.dialogConfig = {
      title: i18next.t('action.delete'),
      description: i18next.t('description.delete.product'),
      continueTitle: i18next.t('action.delete'),
      onClose: () => this.setState({ dialogOpen: undefined }),
      onContinue: () => this.state.dialogOpen && this.removeProduct(this.state.dialogOpen),
    };
    this.fabConfig = [
      {
        title: i18next.t('action.createFirmwareProduct'),
        action: () => this.props.openModal(this.reloadList),
        icon: itemIcons.firmwareProduct,
        tryAccessRight: ['product', 'create'],
      },
    ];

    // Functions
    this.getUrlPathForItem = SubdomainRoutePath.firmwareProduct;
  }

  componentDidMount = async () => {
    this.service = await FirmwareService.create();
    this.list = this.service!.product.list;

    const predefinedFilters = this.filterService!.get();
    const index = this.filterService!.getIndex();

    this.setState({ initLoaded: true, predefinedFilters, activeTabIndex: index });
  };

  removeProduct = async (product: string): Promise<void> => {
    try {
      await this.service.product.del(product);
      SnackbarUtils.success(i18next.t('success.delete.product'));
      if (!this.isUnmounted) {
        const items = this.state.items.filter((p: Product) => p.productId !== product);
        this.setState({ items });
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any) => dispatch(storeOpenModal(ModalVariants.CreateFirmwareProductMC, { callback })),
});

export default withStyles(ListBaseStyles)(withRouter(connect(null, mapDispatchToProps)(FirmwareProductView)));
